import React, {useState} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Button ,Modal} from 'react-bootstrap';
import './verifyotp.css'

function VerifyOtp(props) {

    const [otp, setOtp]= useState('')
    const [resetShow, setResetShow]= useState(false)
    const [successShow, setSuccessShow]= useState(false)
    const [errorShow, setErrorShow]= useState(false)
    const [errorMsg, setErrorMsg]= useState('')
    const [resetSuccess, setResetSuccess]= useState(false)
    const [resetFail, setResetFail]= useState(false)
    const [current, setCurrent]= useState('')
    const [newPass, setNewPass]= useState('')
    const [confirmPass, setConfirmPass]= useState('')
    const [error,setError]=useState({
        currentPass:"",
        reset: '',
        confirmReset:"",
       });

    const handleChange= (e)=> {
        e.preventDefault()
        if(e.target.name === 'otp'){
            setOtp(e.target.value)
        }
        else if(e.target.name === 'current'){
            setCurrent(e.target.value)
        }
        else if(e.target.name === 'new'){
            setNewPass(e.target.value)
        }
        else if(e.target.name === 'confirm'){
            setConfirmPass(e.target.value)
        }
    }

    const handleOtp = (e)=> {
      debugger;
        e.preventDefault()

        const data = {
            username: props.username,           
            otp: otp,
            is_valid:true
        }

        const tokenString= localStorage.getItem('usertoken');
        let str= tokenString.replace(/["]+/g, '');        

       axios.put(`${APIURL}/api/v1/account/reset-password/`, data , { headers: {
         
        "Authorization" : 'Token '+str,
        'Content-Type': 'application/json' }
   
   
       })
   
       .then(res=>{
        
         if(res.data.status=== "success"){
             if(res.data.is_valid){
                setSuccessShow(true)
             }
             
             if(res.data.is_valid === false) {
                setErrorShow(true)
                setErrorMsg('Otp verification failed. Please try again')
               }

            }
                   
        })
        
        .catch(err =>{
            setErrorMsg('Error in Data Submission.Please try again')
            setErrorShow(true)
     })
    }

    const validate= async()=>{
      debugger;
        let errors = {};
        let isValid = true;
        const pw =sessionStorage.getItem('pw')
      setError({});
  
  
        if ( current === "" ) {
          isValid = false;
          errors["currentPass"] = "Please enter Current Password";
        }
        if (current !== pw){
            isValid = false
            errors["currentPass"] = "Current Password is Invalid";

        }
         if ( newPass === "" ) {
          isValid = false;
          errors["reset"] = "Please enter New Password";
        }
        if ( confirmPass === "" ) {
            isValid = false;
            errors["confirmReset"] = "Please Confirm New Password";
          }

        if ( newPass !== confirmPass){
            isValid = false;
            errors["reset"] = "Password does not match";
            errors["confirmReset"] = "Password does not match";
        }
        if (newPass !== "undefined") {
          let pattern = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/);
          if (!pattern.test(newPass)) {
            isValid = false;
            errors["newPass"] = "Password must contain at least 1 UPPERCASE, 1 lower case, 1 number, 1 special character and don't use space";
          }
        }
        if(newPass !== "undefined") {
          if (newPass.length > 0 && newPass.length < 8) {
            isValid = false;
            errors["newPass"] = "Must contain atleast 8 characters";
          }
        }
    
    

         setError(currentstate=>({
              ...currentstate,
              ...errors
            }));
        
  
        return isValid;
    }

    const handleReset = async(e)=> {
      debugger;
        e.preventDefault();

        if( await validate()) {
            const data = {
                username: props.username,  
                password: newPass,         
                otp: otp,
            }
    
            const tokenString= localStorage.getItem('usertoken');
            let str= tokenString.replace(/["]+/g, '');        
    
           axios.put(`${APIURL}/api/v1/account/reset-password/`, data , { headers: {
             
            "Authorization" : 'Token '+str,
            'Content-Type': 'application/json' }
       
       
           })
       
           .then(res=>{
            
             if(res.data.status=== "success"){
                sessionStorage.setItem('pw',newPass)
                setResetSuccess(true)
                }
            else {
                setResetFail(true)
            }
                       
            })
            
            .catch(err =>{
                setResetFail(true)
         })
        }
        
    }

    function SubmitPopUp(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className="caps">Otp verified Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
      
            <Button onClick={props.onHide} className='btn btn-primary btn-col' >Ok</Button>
      
             
            </Modal.Footer>
          </Modal>
        );
      }
      
      
      function ErrorPopUp(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className="caps" style={{color:"red"}}>{errorMsg}</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
      
            <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
            </Modal.Footer>
          </Modal>
        );
      }

      function ResetPopUp(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className="caps">Password Reset Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
      
            <Button onClick={props.onHide} className='btn btn-primary btn-col' >Ok</Button>
      
             
            </Modal.Footer>
          </Modal>
        );
      }

      function ResetFailPopUp(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className="caps" style={{color:"red"}}>Error in Data Submission.Please try again</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
      
            <Button onClick={props.onHide} className='btn btn-primary btn-col' >Ok</Button>
      
             
            </Modal.Footer>
          </Modal>
        );
      }


    return (<>
        <div>
            {
                resetShow === false ?
                    <>
                    <h5>Please enter the Otp that has been sent to your email <span className='reset-mail'>{props.mail}</span></h5>   
                    <input type='text' style={{width:'60%'}} className='form-control' name='otp' value={otp} placeholder='Enter OTP' onChange={handleChange} />
                    <br/>
                    <button disabled={otp===''} onClick={handleOtp} className='btn btn-primary btn-col'>Submit</button>
                    </>
                
                : <div className='reset-input'>
                    <h5>Reset Password</h5>
                  <input type='password' style={{width:'70%'}} className='form-control' name='current' value={current} placeholder='Enter Current Password' onChange={handleChange} />
                  {error.currentPass ?<div  style={{width:'70%'}} className="error-validation-msg"> {error.currentPass}</div>: null} 
                    <br/>
                  <input type='password' style={{width:'70%'}} className='form-control' name='new' value={newPass} placeholder='Enter New Password' onChange={handleChange} />
                  {error.reset ?<div  style={{width:'70%'}} className="error-validation-msg"> {error.reset}</div>: null} 
                    <br/>
                  <input type='password' style={{width:'70%'}} className='form-control'name='confirm' value={confirmPass} placeholder='Confirm New Password' onChange={handleChange} />
                  {error.confirmReset ?<div  style={{width:'70%'}} className="error-validation-msg"> {error.confirmReset}</div>: null} 
                    <br/>
                  <button onClick={handleReset} className='btn btn-primary btn-col'>Reset</button>
                  </div>
            }
         
        </div>


        {successShow ?
   <SubmitPopUp
        show={successShow}
        onHide={() =>{setSuccessShow(false); 
                      setResetShow(true)
}}
      />

 : null }

  {errorMsg!=="" ?
   <ErrorPopUp
        show={errorShow}
        onHide={() => {setErrorShow(false);
                       setErrorMsg("")}}
      />

 : null }

{resetSuccess?
   <ResetPopUp
        show={resetSuccess}
        onHide={() =>{setResetSuccess(false); 
                     props.handle();
}}
      />

 : null }

{resetFail ?
   <ResetFailPopUp
        show={resetFail}
        onHide={() =>{setResetFail(false); 
}}
      />

 : null }

        </>
    )
}

export default VerifyOtp
