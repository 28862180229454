import React, {useState ,useEffect}  from 'react';
import {Link} from 'react-router-dom';
import axios from "axios";
import "./patientinfo.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import "./doctordetails.css";

import {APIURL} from "../../Global";

function DoctorDetails(props){
const [ownhospital,setOwnHospital]=useState("no");
const [ownEstablishment,setOwnEstablishment]=useState(false);
const [fileName,setFileName]=useState("*Upload ID Proof");
const [refresh,setRefresh]=useState(false);
const [eduList,setEduList] =useState([{
      degree: "",
      collage_institution: "",
      completion_year:null
}]);
const [workingHospitalList,setWorkingHospitalList]=useState([
{
    working_hospital_name: "",
    working_hospital_location:""
}]);

const [ownHospitalList,setOwnHospitalList]=useState([
{
     establishment_name:"",
    establishment_location:""
}])

const [doctorDetails,setDoctorDetails]=useState({
      specialization: "",
      id_proof:"",
    id_proof_image: null,
    doctor_description: "",
    registration_number:"",
    registration_year: null,
    council:""
    
})
const [experience,setExperience]= useState({
      year:null,
      month:null
    });
 const [errorSubmitMsg,setErrorSubmitMsg]=useState(null);
 const [errorSubmit,setErrorSubmit]=useState(false);


 const [successSubmitMsg,setSuccessSubmitMsg]=useState(null);
 const [successSubmit,setSuccessSubmit]=useState(false);
 const [error,setError]=useState({
    registration_number:"",
     exp_years:'',
    exp_month: '',
    id_proof_image:'',
    specialization:'',
    id_proof:'',
    completion_year:"",
    registration_year:"",
    completion_yr_Array:[],
    degree_Array:[]
    
   });

   const [sizeShow ,setSizeShow] =useState(false)
const [validShow ,setValidShow] =useState(false)
const [contentShow ,setContentShow] =useState(false)
const [uploadShow ,setUploadShow] =useState(false)

   let formData =new FormData();
   const reader =new FileReader();

useEffect(()=>{
    setEduList(eduList);
},[refresh, eduList])
useEffect(()=>{
    setWorkingHospitalList(workingHospitalList);
},[refresh, workingHospitalList])
useEffect(()=>{
    setOwnHospitalList(ownHospitalList);
},[refresh, ownHospitalList])
const handleChange= e=>{
  const val=e.target.value;
      const key=e.target.name;
    
         setDoctorDetails(currentstate=>({
            ...currentstate,
            [key]: val
          }));
      
}
const handleChangeExperience= e=>{
  const val=e.target.value;
      const key=e.target.name;
    
         setExperience(currentstate=>({
            ...currentstate,
            [key]: val
          }));
      
}
const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorSubmitMsg} </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         

       
       

      </Modal.Footer>
    </Modal>
  );
}
const SubmitSuccessPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h4>Data submitted successfully !! </h4>
        <h6>
         We are in process of implementing Web app with all functionalities.
         Please use our mobile app to use our services until then.  
          <br />
        </h6>
        <h6> Thanks for your understanding! </h6>
        
       <a href='https://play.google.com/store/apps/details?id=intp.purpc&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer"><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className="google-play-text"/></a>
                              
       
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>

         <Link to="/login"><Button className='btn btn-primary btn-col'>Goto Login</Button></Link>


       
       

      </Modal.Footer>
    </Modal>
  );
}

const handleChangeEdu= (e,i)=>{
    e.preventDefault();
  
  const val=e.target.value;
      const key=e.target.name;
      const newArray= eduList;
      if(key==="degree"){
      newArray[i].degree= val;
      }
      if(key==="collage_institution"){
        newArray[i].collage_institution= val;
      }
      if(key==="completion_year"){
        newArray[i].completion_year= val;
      }
     setEduList(newArray);

   
    setRefresh(!refresh);


        
}
const handleChangeHospital= (e,i)=>{
    e.preventDefault();
  const val=e.target.value;
      const key=e.target.name;
    
         const newArray= workingHospitalList;
      if(key==="working_hospital_name"){
      newArray[i].working_hospital_name= val;
      }
      if(key==="working_hospital_location"){
        newArray[i].working_hospital_location= val;
      }
     
     setWorkingHospitalList(newArray);

   
    setRefresh(!refresh);
      
}
const handleChangeEstablishment= (e,i)=>{
    e.preventDefault();
  const val=e.target.value;
      const key=e.target.name;
    
      const newArray= ownHospitalList;
      if(key==="establishment_name"){
      newArray[i].establishment_name= val;
      }
      if(key==="establishment_location"){
        newArray[i].establishment_location= val;
      }
     
     setOwnHospitalList(newArray);

   
    setRefresh(!refresh);
}


/***********************/


const validate=(data,imgData)=>{
     
      let errors = {};
      let isValid = true;
    setError({});
   

      if (data.profession[0].registration_number === null || data. profession[0].registration_number==="") {
        isValid = false;
        errors["registration_number"] = "*Registration number is mandatory!";

    
      }
      if (data.specialization === null || data.specialization==="") {
        isValid = false;
        errors["specialization"] = "*Specialization is mandatory!";

    
      }

      if (data.specialization !== "undefined") {
        var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
        if(pattern2.test(data.specialization)) {
          isValid = false;
          errors["specialization"] = "Special Characters are not allowed";
        }
      }
      if (imgData.id_proof_image === null || imgData.id_proof_image==="") {
        isValid = false;
        errors["id_proof_image"] = "*ID Proof Image is mandatory!";

      }

      if (data.id_proof === null || data.id_proof==="") {
        isValid = false;
        errors["id_proof"] = "*ID Proof is mandatory!";

      }

      if (data.id_proof !== "undefined") {
        var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
        if (pattern2.test(data.id_proof)) {
          isValid = false;
          errors["id_proof"] = "Special Characters are not allowed";
        }
      }

      if (data.experience.year !== null ) {
          let exp_yrs= data.experience.year;
        if(isNaN(exp_yrs) || !(parseInt(Number(exp_yrs)) === exp_yrs) || 
         isNaN(parseInt(exp_yrs, 10)) || exp_yrs <0 || exp_yrs>90){
      
          isValid = false;
         
          errors["exp_years"] = "Please enter a valid integer";
        }

  
      }
      if (data.experience.month !== null) {
         let exp_mnths= data.experience.month;
        if(isNaN(exp_mnths) || !(parseInt(Number(exp_mnths)) === exp_mnths) || exp_mnths<0 || exp_mnths>11){
        
      
          isValid = false;
          errors["exp_month"] = "Please enter a number between 0 and 11";
        }

   
      }

        if (data.profession[0].registration_year!== null && data.profession[0].registration_year!== "" && data.profession[0].registration_year!== undefined) {
         let regn_yr= data.profession[0].registration_year
        if(isNaN(regn_yr) || !(parseInt(Number(regn_yr)) == regn_yr) || regn_yr<1900 || regn_yr> new Date().getFullYear()){
        
      
          isValid = false;
          errors["registration_year"] = "Please enter a valid year";
        }

   
      }

      if(data.education_qualifications.length>0){
        let eduList=data.education_qualifications;
        errors["completion_yr_Array"]=[...new Array(eduList.length)].map(()=>(""));
        errors["degree_Array"]=[...new Array(eduList.length)].map(()=>(""));
        eduList.map((item,index)=>{
           if (item.completion_year!== null && item.completion_year!== "" && item.completion_year!== undefined) {
         let compl_yr= item.completion_year;
        
         if(eduList[index].degree===null || eduList[index].degree===""){
     
           errors["degree_Array"].splice(index, 0,"Please enter degree");
         }

        if(isNaN(compl_yr) || !(parseInt(Number(compl_yr)) == compl_yr) || compl_yr<1900 || compl_yr> new Date().getFullYear()){
        
      
          isValid = false;
         /* errors["completion_year"] = "Please enter a valid year of Completion";*/
         
             errors["completion_yr_Array"].splice(index, 0,"Please enter a valid year of Completion");
       
       
        }

   
      }
        })
      }



       setError(currentstate=>({
            ...currentstate,
            ...errors
          }));
      
  
      return isValid;
  }

const handleSubmitDetails=e=>{

  debugger;
   
     e.preventDefault();


  const tokenString= localStorage.getItem('usertoken');
   
   let str = tokenString.replace(/["]+/g,"");



  const dataToPost={
    specialization: doctorDetails.specialization,
    id_proof: doctorDetails.id_proof,
    
    doctor_description: doctorDetails.doctor_description,
    education_qualifications: eduList,
    profession: [{
         registration_number: doctorDetails.registration_number,
         registration_year: doctorDetails.registration_year,
         council: doctorDetails.council,
         working_hospital: workingHospitalList,
         own_establishment: ownEstablishment,
         establishment: ownHospitalList,

    }],
    experience: {
      year:parseInt(experience.year),
      month:parseInt(experience.month)
    }
}

const imgData={
  id_proof_image: doctorDetails.id_proof_image
}


    if(validate(dataToPost,imgData)){

      debugger;
    axios.post(`${APIURL}/api/v1/patient/user-details/`, dataToPost, { headers: {
      
      "Authorization" : "Token " + str ,
      'Content-Type': 'application/json' }})
    .then(res=>{

      debugger;
      if(res.data.status==="success"){
        
    

   
   
      }else{
        setErrorSubmitMsg(res.data.message);
          setErrorSubmit(true); 
       

      }


    })
    .catch(err=>{
       setErrorSubmitMsg("Error in data submission!");
          setErrorSubmit(true); 
       
}
 
  
)

if(doctorDetails.id_proof_image !== null) {
  formData.append('id_proof_image',doctorDetails.id_proof_image)
}

axios.post(`${APIURL}/api/v1/patient/user-details/`, formData, { headers: {
      
  "Authorization" : 'Token '+str ,
  'Content-Type': 'multipart/form-data' }})
.then(res=>{

  if(res.data.status==="success"){
    setSuccessSubmitMsg("success");
      setSuccessSubmit(true);




  }else{
    setErrorSubmitMsg(res.data.message);
      setErrorSubmit(true); 
   

  }


})
.catch(err=>{
   setErrorSubmitMsg("Error in data submission!");
      setErrorSubmit(true); 
   
}


)
  }        

}
const handleOwnHospital=e=>{
 setOwnHospital(e.target.value);
if(e.target.value==="yes"){
 setOwnEstablishment(true);
 
}
else if(e.target.value==="no"){

  setOwnEstablishment(false);
}
}
const handleFile=e=>{
    
    const file=  e.target.files[0];
 
  if(!file){
    setDoctorDetails(currentstate=>({...currentstate, id_proof_image:null}))
    setUploadShow(!uploadShow)
    return false
  }
  if (!file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
    setDoctorDetails(currentstate=>({...currentstate, id_proof_image:null}))
    setValidShow(!validShow)
    setFileName('Upload ID Proof');
    return false;
  }
  if(file.size>2e6){
    setDoctorDetails(currentstate=>({...currentstate, id_proof_image:null}))
    setSizeShow(!sizeShow)
    setFileName('Upload ID Proof');
    return false
  }

  reader.onload = (evt) => {
    const img = new Image();
    img.onload = () => {
      setDoctorDetails(currentstate=>({...currentstate, id_proof_image:file}))
      setFileName(file.name);
    };

    img.onerror = () => {
      setDoctorDetails(currentstate=>({...currentstate, id_proof_image:null}))
      setContentShow(!contentShow)
      setFileName('Upload ID Proof');
   
      return false;
    };
    img.src = evt.target.result;
  };
  reader.readAsDataURL(file);

  //const objectURL = URL.createObjectURL(file);
  
  
 
}

const SizePopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h5 id="confirm-reject">You have exceeded image size limit 2MB</h5>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const LoadPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Please select an Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ValidPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Please select a valid Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ContentPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Invalid Image Content</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const handleAddDegree=e=>{
    e.preventDefault();
  
    const newArray=eduList;
    const newEdu= newArray.push({
        degree: "",
        collage_institution: "",
        completion_year :null
    });

    setEduList(newArray);
    setRefresh(!refresh);
}
const handleAddWorkingHospital=e=>{
    e.preventDefault();
  
    const newArray= workingHospitalList;
    newArray.push({
        working_hospital_name: "",
       working_hospital_location :""
    });

    setWorkingHospitalList(newArray);
    setRefresh(!refresh);
}
const handleAddOwnHospital=e=>{
    e.preventDefault();
   
    const newArray= ownHospitalList;
    newArray.push({
        name: "",
       location :""
    });

    setOwnHospitalList(newArray);
    setRefresh(!refresh);
}

const handleRemoveDegree=(e,index)=>{
e.preventDefault();

const eduNewList= eduList.filter((degree,i)=>{
    return i!== index
});
setEduList(eduNewList);
setRefresh(!refresh);

}
const handleRemoveHospital=(e,index)=>{
    e.preventDefault();


    const hospList = workingHospitalList.filter((hosp,i)=>{
     return i!==index
    });
    
    setWorkingHospitalList(hospList);
    setRefresh(!refresh);
}
const handleRemoveOwnHospital=(e,index)=>{
    e.preventDefault();
   

    const hospList = ownHospitalList.filter((hosp,i)=>{
     return i!==index
    });
    
    setOwnHospitalList(hospList);
    setRefresh(!refresh);
}
const eduDisplay = eduList.length>0  ? eduList.map((edu,index)=>{
    return  <div className="edu-box-parent">
                          <div className="edu-box" key={index}>

                                        <div className="item"> <input className="form-control"  type="text" maxLength='150' name="degree" placeholder="Degree" onChange={(e)=>handleChangeEdu(e,index)}
                                        /> </div>
                                         
                                          <div className="item"> <input className="form-control"  type="number" min={1900} max={new Date().getFullYear()} name="completion_year" placeholder="Year of Completion" onChange={(e)=>handleChangeEdu(e,index)}
                                        /> </div> 
                                         <div className="item"> <input className="form-control"  type="text" maxLength='150' name="collage_institution" placeholder="College/Institution" onChange={(e)=>handleChangeEdu(e,index)}
                                        /> </div> 
                               
                                         { error.degree_Array[index] ? <div className="error-validation-msg">{error.degree_Array[index]}</div> : null}
                               
                                         { error.completion_yr_Array[index] ? <div className="error-validation-msg">{error.completion_yr_Array[index]}</div> : null}
                               
                           </div>  

                                      
                                        {index>0 ? <div className="btn-del text-right"> <button className="btn btn-danger btn-small btn-square" onClick={(e)=>handleRemoveDegree(e,index)} > Remove <i className="fas fa-trash-alt" style={{color:"var(--theme-red)"}}> </i> </button>
                                        </div>  : null }
                                     
                                        
                                        </div>

}) : null ;


const workingHospitalsDisplay = workingHospitalList.length>0  ? workingHospitalList.map((hosp,index)=>{
    return  <div className="hospital-box-parent">
                <div className="hospital-box" key={index}>
                  <div className="item"> <input className="form-control" type="text" maxLength='150' value={hosp.working_hospital_name} name="working_hospital_name" placeholder="Name" onChange={(e)=>handleChangeHospital(e,index)}
                                        /> </div>
                <div className="item"> <input className="form-control" type="text" maxLength='150' value={hosp.working_hospital_location} name="working_hospital_location" placeholder="Location" onChange={(e)=>handleChangeHospital(e,index)}
                                        /> </div>
                 </div>
                {index>0 ? <div className="btn-del text-right "> <button className="btn btn-small btn-danger btn-square" onClick={(e)=>handleRemoveHospital(e,index)} >  Remove <i className="fas fa-trash-alt" style={{color:"var(--theme-red)"}}> </i></button>
                                        </div>  : null }
                
       
           </div>
}) : null ;

const ownHospitalsDisplay = ownHospitalList.length>0  ? ownHospitalList.map((hosp,index)=>{
    return  <div className="hospital-box-parent">
                 <div className="hospital-box" key={index}>
                  <div className="item"> <input className="form-control" type="text" maxLength='150' value={hosp.establishment_name} name="establishment_name" placeholder="Name" onChange={(e)=>handleChangeEstablishment(e,index)}
                                        /> </div>
                <div className="item"> <input className="form-control" type="text" maxLength='150' value={hosp.establishment_location} name="establishment_location" placeholder="Location" onChange={(e)=>handleChangeEstablishment(e,index)}
                                        /> </div>
               </div>
                {index>0 ? <div className="btn-del text-right"> <button className="btn btn-small btn-danger btn-square" onClick={(e)=>handleRemoveOwnHospital(e,index)} >  Remove <i className="fas fa-trash-alt" style={{color:"var(--theme-red)"}}> </i></button>
                                        </div>  : null }
                
           
           </div>
}) : null ;

    return (
                <div className="form-body">
                   
                    <div className="">
                        
                        <div className="form-holder form-container" id="details-form-holder">
                            <div className="form-content" id="details-form">
                                <div className="form-items">
                                   {/*  <h3>Get access to the innovative AI platform for people's wellbeing.</h3> */}
                                    <p></p>
                                    <div className="page-links">
                                        <Link to="/login" >Login</Link><Link to="register" className="active">Register</Link>
                                       
                                    </div>

                                    

                                         <form className="reg-form doctor-details-form" >
                                     <h3 className="form-heading"> Doctor Details </h3>  
                                      <div className="divider"></div> 
                                     <div className="reg-form-div">
                                          
                                     
                                         
                       
                                        
                                       
                                        
                                        <h6 className="sub-headings"> About Yourself: <span className="text-muted" >(visible to patients) </span></h6>
                                           <textarea rows="6" className="text-area-about" name="doctor_description" placeholder="" onChange={handleChange}
                                        /> 
                                        
                                         

                                         
                                         
                                         <div className="divider"></div> 
                                        {/***********Educational Qualification*******/}
                                         <h5 className="sub-headings"> Academic Qualification </h5>   
                                         <div>
                                        {eduDisplay}
                                       </div>
                                        
                                        <div className="item "> <button className="btn btn-secondary btn-square" onClick={handleAddDegree} > + Add Degree </button>
                                        </div>

                                        {/********ENDS***Academic Qualification*******/}
                                        
                                        <div className="divider"></div> 
                                         <h5 className="sub-headings"> Specializations </h5> 
                                         <span className="row-left">  
                                           <div className="item text-left"><input className="form-control" type="text" maxLength='150' name="specialization" placeholder="*Specialization" onChange={handleChange}
                                        />        </div>
                                        { error.specialization ? <div className="error-validation-msg">{error.specialization}</div> : null}</span>
                                 
                                         <input className="form-control" type="text" name="id_proof" placeholder="*Identity Proof" onChange={handleChange}
                                        />
                                        { error.id_proof ? <div className="error-validation-msg">{error.id_proof}</div> : null}
                                        <div className="input-group mb-3">
                <div className="custom-file">
                  <input type="file" name="id_proof_image" className="custom-file-input" id="inputGroupFile02"  onChange={handleFile}/>
                  <label className="custom-file-label" htmlFor="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{fileName} </label>                 
                </div>
               
              </div>
              { error.id_proof_image ? <div className="error-validation-msg">{error.id_proof_image}</div> : null}                      


                                         <div className="divider"></div> 
                                        {/***********Experience*******/}
                                         <h5 className="sub-headings"> * Experience </h5>   
                                      <div className="exp-box med-regn-box">
                                        <div className="item"> <input className="form-control" type="number" min={0} max={90} name="year" placeholder="Number of years" onChange={handleChangeExperience}
                                        />
                                           { error.exp_years ? <div className="error-validation-msg">{error.exp_years}</div> : null}
                                            </div>
                                       
                                        <div className="item"> <input className="form-control" type="number" min={0} max={11}  name="month" placeholder="months" onChange={handleChangeExperience}
                                        /> 
                                        { error.exp_month ? <div className="error-validation-msg">{error.exp_month}</div> : null}
                                         </div>
                                        </div>
                                        

                                          {/********ENDS***Experience*******/}

                                          <div className="divider"></div> 
                                         <h5 className="sub-headings"> Medical Registration </h5>   
                                       <div className="med-regn-box">
                                        <div className="item"> <input className="form-control" type="text" maxLength='50' name="registration_number" placeholder="Registration Number*" onChange={handleChange}
                                        /> { error.registration_number ? <div className="error-validation-msg">{error.registration_number}</div> : null}</div>
                                       
                                        <div className="item"> <input className="form-control" type="number" min={1900} max={new Date().getFullYear()}  name="registration_year" placeholder="Registration Year" onChange={handleChange}
                                        />   { error.registration_year ? <div className="error-validation-msg">{error.registration_year}</div> : null}</div>
                                         <div className="item"> <input className="form-control" type="text" maxLength='150' name="council" placeholder="Registration Council" onChange={handleChange}
                                        /> </div>
                                         </div> 
                                           <div className="divider"></div> 
                                         <h5 className="sub-headings"> Working Clinic/Hospital </h5>   
                                       
                                          {workingHospitalsDisplay}
                                           <div className="item"> <button className="btn btn-secondary" onClick={handleAddWorkingHospital}> + Add Hospital </button>
                                        </div> 

                                                 <div className="divider"></div> 

                                     {/**************OWN hospital*******/}

                                      <h5 className="sub-headings"> Own Clinic/Hospital </h5> 
                                       
                                         


                                        
                                         <div className="row question-establishment">
                                         <h6 className="questions"> Do You own a Clinic/ Hospital? </h6>
                                       <div> 
                                          <input 
                                             type="radio" 
                                             id="yes" 
                                             name="ownhospital" 
                                             value="yes"
                                             checked={ownhospital === "yes"}
                                             onChange={handleOwnHospital}
                                            
                                                 />
                                          <label htmlFor="yes">Yes</label>
                                          </div>
                                          <div>
                                          <input 
                                             type="radio" 
                                             id="no" 
                                             name="ownhospital" 
                                             value="no"
                                             checked={ownhospital === "no"}
                                             onChange={handleOwnHospital}
                                            
                                                 />
                                          <label htmlFor="no">No</label>
                                        </div>
                                        </div>

                                         {ownEstablishment===true?  (<>
                                         {ownHospitalsDisplay}
                                           <div className="item"> <button className="btn btn-secondary" onClick={handleAddOwnHospital}> + Add Hospital </button>
                                           </div> 
                                           </>)
                                        : null}
                                    
                
                                         
                                     
                                 
                                        

                                         

                                       </div>
                                       </form>
                                       <button className="btn btn-primary btn-col" onClick={handleSubmitDetails}> Submit Details </button>

                                 </div>
                            </div>

                         </div>

                    </div>
                    {errorSubmitMsg!==null ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  setErrorSubmit(false)}
       
      />: null}

       {successSubmitMsg === "success" ?
    <SubmitSuccessPopup
        show={successSubmit}
        onHide={() =>  setSuccessSubmit(false)}
       
      />: null}

{
        sizeShow ? 
        <SizePopUp
        show={sizeShow}
        onHide={()=>{
          setSizeShow(false)
        }} /> : null
      } 

{
        uploadShow ? 
        <LoadPopUp
        show={uploadShow}
        onHide={()=>{
          setUploadShow(false)
        }} /> : null
      } 

{
        validShow ? 
        <ValidPopUp
        show={validShow}
        onHide={()=>{
          setValidShow(false)
        }} /> : null
      } 

{
        contentShow ? 
        <ContentPopUp
        show={contentShow}
        onHide={()=>{
          setContentShow(false)
        }} /> : null
      }

                </div>
            )
}
    export default DoctorDetails;

                                     