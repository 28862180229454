import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import { Link } from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import './featurelist.css'
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import {ToggleButton} from 'react-bootstrap';
import {ToggleButtonGroup} from 'react-bootstrap';
import { expand } from 'urijs';
import AddEquipmenthosp from './AddEquipmenthosp';
import EditEquipmenthosp from './EditEquipmenthosp';
//import AddInventory from './AddInventory';
//import EditInventory from './EditInventory';

function FeaturesList() {

    const [featureList, setFeatureList]= useState([])
    const [rentalList, setRentalList]= useState([])
    const [editShow, setEditShow]= useState(false)
    const [current, setCurrent]= useState() 
    const [refresh, setRefresh]= useState(false)
    const [addShow, setAddShow]= useState(false)
    const [addRentalShow, setAddRentalShow]= useState(false)
    const [editRentalShow, setEditRentalShow]= useState(false)
    const [editInventoryShow, setEditInventoryShow]= useState(false)
    const [category, setCategory]= useState(1)
    const [expand, setExpand]= useState()
    const [modalShow, setModalShow]= useState(false)
    const [flag, setFlag]= useState(false)

   
    useEffect(()=> {
        window.scrollTo(0,0)
    },[])

    useEffect(() => {
        const tokenString = localStorage.getItem("usertoken");
    
        let v2 = tokenString.replace(
          /["]+/g,
          ""
        ); /* REGEX To remove double quotes from tokenstring */
    
     
    
        axios
          .get(`${APIURL}/api/v1/hospital/equipments-list/ `, {
            headers: { Authorization: "Token " + v2 },
          })
          .then((res) => {
            if (res.status==200) {
              debugger;
              const datas = res.data;
              // const filteredData = datas.filter(item => {
              //   return item.service_type !== 'rental'
              // })

              // const filteredRentalData = datas.filter(item => {
              //   return item.service_type === 'rental'
              // })
              // setFeatureList(filteredData);
              setRentalList(datas);
              
              // if(flag){
              //   const idVal = filteredRentalData.length-1
              //   sessionStorage.setItem('rentalId',filteredRentalData[idVal].id)
              //   setModalShow(true)
              }
             else {
              
            }
          })
          .catch((err) => {
           
          });
      }, [refresh]);

      const handleChangeRadio=(val)=>{
        setCategory(val);
       }

    const modalClose = () => {
      setEditShow(false)
      setRefresh(!refresh)
    }

    const addModalClose = () => {
      setAddShow(false)
      setRefresh(!refresh)
    }

    const rentalModalClose = () => {
      setAddRentalShow(false)
      setRefresh(!refresh)
      setFlag(true)
    }

    const notProceedClose = () => {
      setAddRentalShow(false)
      setRefresh(!refresh)
    }

    const rentalEditModalClose = () => {
      setEditRentalShow(false)
      setRefresh(!refresh)
    }

    const inventoryModalClose = () => {
      setModalShow(false)
      setFlag(false)
      setRefresh(!refresh)
    }

    const editInventoryModalClose = () => {
      setEditInventoryShow(false)
      setRefresh(!refresh)
    }


//     const AddPopUp = (props) => {
//       return(
//         <Modal
//         {...props}
//         aria-labelledby="example-custom-modal-styling-title"
//    backdrop="static"
//     keyboard={false}
//     size='lg'
//   >
//   <Modal.Header closeButton>
//     <Modal.Title id="contained-modal-title-vcenter">
    
     
//     </Modal.Title>
//   </Modal.Header>
//   <Modal.Body>

//    <AddFeature modalClose={addModalClose} />
   
//   </Modal.Body>
//   <Modal.Footer>
   
//      <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>   

//   </Modal.Footer>
// </Modal>
//       )
//   }

  const AddRentalPopUp = (props) => {
    return(
      <Modal
      {...props}
      aria-labelledby="example-custom-modal-styling-title"
 backdrop="static"
  keyboard={false}
  dialogClassName="modal-80w"
>
<Modal.Header closeButton>
  <Modal.Title id="contained-modal-title-vcenter">
  
   
  </Modal.Title>
</Modal.Header>
<Modal.Body>

 <AddEquipmenthosp modalClose={rentalModalClose} notProceedClose={notProceedClose} />
 
</Modal.Body>
<Modal.Footer>
 
   <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>   

</Modal.Footer>
</Modal>
    )
}

  //     const EditPopUp = (props) => {
  //       return(
  //         <Modal
  //         {...props}
  //         aria-labelledby="example-custom-modal-styling-title"
  //    backdrop="static"
  //     keyboard={false}
  //     size='lg'
  //   >
  //   <Modal.Header closeButton>
  //     <Modal.Title id="contained-modal-title-vcenter">
      
       
  //     </Modal.Title>
  //   </Modal.Header>
  //   <Modal.Body>

  //    <EditFeature data={current} modalClose={modalClose} />
     
  //   </Modal.Body>
  //   <Modal.Footer>
     
  //      <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>   

  //   </Modal.Footer>
  // </Modal>
  //       )
  //   }

    const EditRentalPopUp = (props) => {
      return(
        <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
   backdrop="static"
    keyboard={false}
    dialogClassName="modal-80w"
  >
  <Modal.Header closeButton>
    <Modal.Title id="contained-modal-title-vcenter">
    
     
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>

   <EditEquipmenthosp data={current} modalClose={rentalEditModalClose} />
   
  </Modal.Body>
  <Modal.Footer>
   
     <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>   

  </Modal.Footer>
</Modal>
      )
  }

//   const InventoryPopUp = (props) => {
//     return(
//       <Modal
//       {...props}
//       aria-labelledby="example-custom-modal-styling-title"
//  backdrop="static"
//   keyboard={false}
//   size='lg'
// >
// <Modal.Header closeButton>
//   <Modal.Title id="contained-modal-title-vcenter">
  
   
//   </Modal.Title>
// </Modal.Header>
// <Modal.Body>

//  <AddInventory modalClose={inventoryModalClose} />
 
// </Modal.Body>
// <Modal.Footer>
 
//    <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>   

// </Modal.Footer>
// </Modal>
//     )
// }

// const EditInventoryPopUp = (props) => {
//   return(
//     <Modal
//     {...props}
//     aria-labelledby="example-custom-modal-styling-title"
// backdrop="static"
// keyboard={false}
// size='lg'
// >
// <Modal.Header closeButton>
// <Modal.Title id="contained-modal-title-vcenter">

 
// </Modal.Title>
// </Modal.Header>
// <Modal.Body>

// <EditInventoryhosp data={current} modalClose={editInventoryModalClose} />

// </Modal.Body>
// <Modal.Footer>

//  <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>   

// </Modal.Footer>
// </Modal>
//   )
// }

    const handleEdit = (index) => {
      const list = featureList[index]
      setCurrent(list)
      setEditShow(true)
    }

    const handleRentalEdit = (index) => {
     
      const list = rentalList[index]
      setCurrent(list)
      setEditRentalShow(true)
    }

    const handleEditInventory = (index) => {
      const list = rentalList[index]
      setCurrent(list)
      setEditInventoryShow(true)
    }

    const handleDetail = (index ) => {
      if(expand !== index){
        setExpand(index)
      }
      else{
        setExpand()
      }
    }

    // "id": 1,
    //     "created_name": "mhospital@tirur.com",
    //     "hospital_name": "Mission Hospital",
    //     "equipment_name": "Wheel chair",
    //     "model": "Wheelchair new",
    //     "category": null,
    //     "image": null,
    //     "price": 500.0,
    //     "serial_number": "WC0001",
    //     "on_service": false,
    //     "is_active": false,
    //     "service_type": "general",
    //     "service_unit": "count",
    //     "service_price": 100.0,
    //     "manufacturer_name": "CP Distributors",
    //     "manufacturing_date": null,
    //     "expiry_date": null,
    //     "purchase_date": null,
    //     "distributor": null,
    //     "company_address": null,
    //     "distributor_address": null,
    //     "description": null,
     

const dataDisplay=category === 2 ? rentalList.length > 0 ? rentalList.map((item,index) => {

          return (
            
            <div className='care-features' key={item.id}>
              <div className='feature-data'>
                <h5 className='feature-title'>{index+1}.{item.equipment_name}</h5>
                <h5 style={{marginLeft:'auto', marginRight:'auto'}} className='feature-title'>{item.service_type.replace("_"," ")}</h5>
                <button onClick={()=> handleRentalEdit(index)} className='btn btn-primary btn-col'>Edit</button>
              </div>
              {
                expand !==index ?
                  <div className='seperate-content'/>
                  : null
              }
                

                <div style={{cursor:'pointer'}} onClick={()=> handleDetail(index)}  className={ expand === index ?'feature-data mt-3' : 'feature-data'}>
                  <h6 className='feature-request'>On Request Service : {item.on_service=== true? 'Yes' : item.on_service=== false? 'No' : 'Nil'}</h6>
                  <h6 className='feature-service'>Service Unit : {item.service_unit}</h6>
                  <h6 className='feature-service'>Active : {item.is_active === true? 'Yes': item.is_active === false? 'No' : 'Nil'}</h6>
                  <h6 className='feature-service'><i className={expand===index ? "fas fa-sort-up detail-open" : "fas fa-sort-down detail-open"}></i>Inventory Details</h6>
              </div>
              {
                expand === index ? <>
                    <div className='seperate-content'/>
                      <div className='row-of-features'>
                        <div className='col-of-features'>
                          <h6>Name : {item.equipment_name ? <span>{item.equipment_name}</span> : <span>Nil</span>}</h6>
                          <h6>Serial : {item.serial_number? <span>{item.serial_number}</span>: <span>Nil</span>}</h6>
                          <h6>Hospital Name : {item.hospital_name? <span>{item.hospital_name}</span> : <span>Nil</span>}</h6>
                          <h6>Model : {item.model? <span>{item.model}</span> : <span>Nil</span>}</h6>     
                          <h6>Price : {item.price ? <span>{item.price}</span> : <span>Nil</span>}</h6>
                          <h6>Service Price : {item.service_price ? <span>{item.service_price}</span> :  <span>Nil</span>}</h6>
                        </div>

                        <div className='col-of-features'>
                          <h6>Distributor Address : {item.distributor_address ? <span>{item.distributor_address}</span> : <span>Nil</span>}</h6>
                          <h6>Company Address : {item.company_address ? <span>{item.company_address}</span> : <span>Nil</span>}</h6>
                          <h6>Manufacture : {item.manufacturer_name ? <span>{item.manufacturer_name}</span> : <span>Nil</span>}</h6>     
                          <h6>Manufacture Year : {item.manufacturing_date ? <span>{item.manufacturing_date}</span> : <span>Nil</span> }</h6>                   
                          <h6>Expiry Date : {item.expiry_date? <span>{item.expiry_date}</span>: <span>Nil</span> }</h6>                   
                        </div>
                      </div>
                      {/* <button onClick={()=>handleEditInventory(index)} style={{alignSelf:'flex-end'}} className='btn btn-primary btn-col'>Edit Inventory</button> */}
                  </>
                  : null
              }
              
            </div>
          )
          
      }) : <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
              <h4>No Equipments to show!!</h4>
           </div>

           :null
           


    
  

    return (
      <>
      <HospitalNavbar /> <br/>

             <div className="container displaylist-container ">
             <h2 style={{ width: "95%" }} className="title-of-tasks">Hospital Equipment Details</h2>
    
        <div className="header-display">

       


         <div className="buttons-wrapper">
        
         
              <button className="btn btn-primary btn-col" onClick={()=> setAddRentalShow(true)}>
                Add New Equipment Here <i class="fas fa-plus"></i>
              </button> 
      
          
</div>  


        </div>

        <div className='mb-5'>
      <ToggleButtonGroup type="radio" name="options" defaultValue={category} onChange={handleChangeRadio}>
 
    <ToggleButton id="tbg-radio-2" value={2} className="btn btn-light toggle-btns toggle-btn-2">
   View Equipment Details
    </ToggleButton>
  
      </ToggleButtonGroup>
    </div>

        <div className="test-reports-display-list">

          <div className='feature-container'>
            {dataDisplay}
          </div>
          
        </div>

{/* 
    {
      editShow ?
      <EditPopUp
        show={editShow}
        onHide={() => setEditShow(false)}
        /> : null
    } */}

{
      editRentalShow ?
      <EditRentalPopUp
        show={editRentalShow}
        onHide={() => setEditRentalShow(false)}
        /> : null
    }

{/* {
      addShow ?
      <AddPopUp
        show={addShow}
        onHide={() => setAddShow(false)}
        /> : null
    } */}

{
      addRentalShow ?
      <AddRentalPopUp
        show={addRentalShow}
        onHide={() => setAddRentalShow(false)}
        /> : null
    }

{/* {modalShow ? 
<InventoryPopUp
show={modalShow}
onHide={() =>  {setModalShow(false); setFlag(false) }}
/> 
: null} */}

{/* {editInventoryShow ? 
<EditInventoryPopUp
show={editInventoryShow}
onHide={() =>  {setEditInventoryShow(false) }}
/> 
: null} */}

      </div>
      </>
    )


}

export default FeaturesList
