import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./addheadingstoreport.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../../Global";

function AddHeadingsToReport({id,parentURL,parentName}) {
 
  const [test, setTest] = useState("");
  const [result, setResult] = useState("");
  const [units, setUnits] = useState("");
  const [referenceRange, setReferenceRange] = useState("");
  const [submitmsg, setSubmitmsg] = useState("");
   const [modalShow, setModalShow] = useState(false);


const SubmitPopUp = (props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data submitted sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
      
        <Link to={parentURL}><Button className='btn btn-primary btn-col' > Goto {parentName} </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}

  const handleSubmitData = (e) => {
    e.preventDefault();

    const datatosend = {
      report_id: id,
      test: test,
      result: result,
      units: units,
      reference_range: referenceRange,
    };
  if(test === "" && result === "" && units=== "" && referenceRange===""){
    setSubmitmsg("error");
  }
  else{
    const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

  

    axios
      .post(
        `${APIURL}/api/v1/staff/lab-reports/headings/`,
        datatosend,
        { headers: {
      'Content-Type': 'application/json',
      "Authorization" :"Token " + v2}})
      .then((res) => {
        if (res.data.status === "success") {
         
          setSubmitmsg("success");
          setModalShow(true);
        } else {
          
          setSubmitmsg("error");
        }
      })
      .catch((err) => {
        
        setSubmitmsg("error");
      });
  }
}

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "test") {
      setTest(e.target.value);
    }
    if (e.target.name === "units") {
      setUnits(e.target.value);
    }
    if (e.target.name === "result") {
      setResult(e.target.value);
    }
    if (e.target.name === "reference_range") {
      setReferenceRange(e.target.value);
    }
  };

  return (
    <div>
      <div className="add-row">
       <h5 className="title-of-page"> Add Headings Synonyms From Report </h5>
        <form className="add-form">
         

          <div className="inputs">
            <input
              type="text"
              name="test"
              className="form-control add-syn"
              value={test}
              onChange={handleChange}
              maxLength="150"
              placeholder="Test Name"
            />

            <input
              type="text"
              name="units"
              className="form-control add-syn"
              value={units}
              onChange={handleChange}
              maxLength="150"
              placeholder="Units"
            />

            <input
              type="text"
              name="result"
              className="form-control add-syn"
              value={result}
              onChange={handleChange}
              maxLength="150"
              placeholder="Result"
            />

            <input
              type="text"
              name="reference_range"
              className="form-control add-syn"
              value={referenceRange}
              onChange={handleChange}
              maxLength="150"
              placeholder="Reference Range"
            />
          </div>
          <button
            type="submit"
            onClick={handleSubmitData}
            className="btn btn-primary btn-col"
          >
            Submit Data{" "}
          </button>
        </form>
      </div>

      <br />
      <div>
        {submitmsg === "success" ? (

          <SubmitPopUp
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

     
        ) : submitmsg === "error" ? (
          <h5 style={{ color: "red" }}>
            {" "}
            Error in Data submission.Please check again!{" "}
          </h5>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AddHeadingsToReport;
