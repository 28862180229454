import React,{useState} from "react";
import {useHistory} from "react-router-dom";
import "./hospitaldoctor.css";
import pic from "../../images/illustration_med.JPG";
import HospDocNavbarBanner from "../HospDocNavbar/HospDocNavbarBanner";
import {Modal} from 'react-bootstrap';
import graphic from "../../images/graphic3.svg";
import CallLogout from "../Navbar/Logout";
import "./loginbdo.css";
function HospitalDoctorBanner(props) {
   //const [searchTerm, setSearchTerm] = useState("");
     const [bannerSubmit,]=useState(true);
   
    const [notification,] =useState();
    const [hospDocData,] =useState(JSON.parse(sessionStorage.getItem('hosp_doc_data'))? JSON.parse(sessionStorage.getItem('hosp_doc_data')) : null);
   const dr_name= hospDocData ? hospDocData.doctor_name : null;
    //const doctor_specializations = hospDocData ? hospDocData.doctor_specializations : null;
   const department  = hospDocData ? hospDocData.doctor_department : null;
   //const Id = hospDocData ? hospDocData.user_id : null;
   const hospitalName= hospDocData ? hospDocData.hospital_name: null;
   //const specializations = hospDocData ? hospDocData.specializations : null;
   //const location = hospDocData ? hospDocData.location: null;

   const history= useHistory();
  

  function BannerPopup(props){
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"red"}}> Your Licence seems to be invalid/expired.</h5><br/>
        <h5 style={{color:"red"}}> Kindly contact your Hospital admin to renew your Licence. </h5>
        <img src={graphic} alt='Licence Expired' height="200px" width="auto" />
        
        
     
       
      </Modal.Body>
      <Modal.Footer>
         
          <CallLogout />
     

   
         

       
       

      </Modal.Footer>
    </Modal>
  );
}


  return (
    <> <HospDocNavbarBanner />
       <div className="hospital-dashboard-container"> 
    

       {/*<HospitalJumbotron /> */}
         <div className="jumbotron jumbotron-fluid jumbo-div" id="jumbo-dr-dashboard">
         {/*<span className="dr-icon"><i className="fas fa-user-md"></i></span> */}
         {/*<span className="welcome-msg pull-left"> 
         Dr. {dr_name} <br/>
         Dept: {department} 
         </span>
          */}
  <div className=" jumbo-content">


    <div className="captions">

        <h3 className="title-dr"> Dr. {dr_name} </h3>
         <h5 className="additional-text dept-name">  {department!=="" && department!==null ? department +" Department" : null}</h5>
        
        <h5 className="additional-text hosp-name">  {hospitalName} </h5>
        
       
    </div>

  

  
  </div>
</div>
<h2 className="title-of-lists title-of-tasks"> My Tasks </h2>  

<div className="patients-section">

{/*
<div className="search-form-div">

<form >
          <div className="search-div">
            <input
              className="form-control search-input"
              type="text"
              placeholder="Search Patient Name/phone"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="submit" className="btn btn-primary btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </button>
          </div>
        </form>  

</div>
*/}

<div className="container flex-center doc">
   

            <div onClick={()=> history.push('/')} className="each-list-title col-lg-3 box-1" id="box-1">

           <i className="fas fa-search task-icon" id="task-icon-color" aria-hidden="true"></i>
           
            <h5 className="each-title">


              {" "}
              
                <h4> Patient Records </h4>
                  
              
            </h5>
            </div>
            

          
            <div onClick={()=> history.push('/')} className="each-list-title col-lg-3">
            <span>
           <i className="fa fa-stethoscope task-icon" id="task-icon-color" aria-hidden="true"></i>

            <h5 className="each-title">

            
              {" "}
              
                <h4> Appointments  </h4>
              
            </h5>
            
            </span>
          </div>
     

           <div onClick={()=> history.push('/')} className="each-list-title col-lg-3">
           <i className="fa fa-calendar task-icon" id="task-icon-color" aria-hidden="true"></i>
           
            <h5 className="each-title">

            
              {" "}

               <h4> Schedules </h4>

            </h5>

           
          </div>


          <div onClick={()=> history.push('/')} className="each-list-title col-lg-3">

          { notification >=1 ?
            <a style={{pointerEvents:'none'}} className="notification">
            <svg width="2.3em" height="2.3em" viewBox="0 0 36 36"><path className="clr-i-solid-doc--badged clr-i-solid-path-1--badged" d="M18 34.28A2.67 2.67 0 0 0 20.58 32h-5.26A2.67 2.67 0 0 0 18 34.28z" fill="red"></path><path className="clr-i-solid-doc--badged clr-i-solid-path-2--badged" d="M32.85 28.13l-.34-.3A14.37 14.37 0 0 1 30 24.9a12.63 12.63 0 0 1-1.35-4.81v-4.94a10.92 10.92 0 0 0-.16-1.79A7.5 7.5 0 0 1 22.5 6v-.63a10.57 10.57 0 0 0-3.32-1V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.3 10.73v4.94a12.63 12.63 0 0 1-1.35 4.81a14.4 14.4 0 0 1-2.47 2.93l-.34.3v2.82h29.8z" fill="currentColor"></path></svg>
            <span style={{position:'absolute',top:'-6px',right:'-2px'}} className="badge">{notification}</span>
            </a>
          : <svg width="2.3em" height="2.3em" viewBox="0 0 36 36"><path className="clr-i-solid-doc clr-i-solid-path-1" d="M32.85 28.13l-.34-.3A14.37 14.37 0 0 1 30 24.9a12.63 12.63 0 0 1-1.35-4.81v-4.94A10.81 10.81 0 0 0 19.21 4.4V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.33 10.73v4.94a12.63 12.63 0 0 1-1.35 4.81a14.4 14.4 0 0 1-2.47 2.93l-.34.3v2.82h29.8z" fill="currentColor"></path><path className="clr-i-solid-doc clr-i-solid-path-2" d="M15.32 32a2.65 2.65 0 0 0 5.25 0z" fill="currentColor"></path></svg> }
            <h5 style={{marginTop:'35px'}} className="each-title">

            
              {" "}

               <h4> Notifications </h4> 

            </h5>

           
          </div>

          <div onClick={()=> history.push('/')} className="each-list-title col-lg-3">
           <i className="fa fa-comments task-icon" id="task-icon-color" aria-hidden="true"></i>
            <h5 className="each-title">

            
              {" "}
               <h4> Messages </h4>
            </h5>

           
          </div>

          

          <div onClick={()=> history.push('/')} className="each-list-title col-lg-3">
           <i className="fa fa-address-card task-icon" id="task-icon-color" aria-hidden="true"></i>
            <h5 className="each-title">

            
              {" "}

               <h4> Analytics </h4>

            </h5>

           
          </div>

            <div onClick={()=> history.push('/')} className="each-list-title col-lg-3 box-theme">
          <i className="fas fa-ambulance" id="task-icon-color" aria-hidden="true"></i>
          
            <h5 className="each-title">

            
              {" "}

               <h4> Service Requests </h4>

            </h5>

           
          </div>


           

        
</div> 
</div>


<div className="illustration">
<img src={pic} width="400px" height="auto" alt="illustration"/> 
</div>

{bannerSubmit===true ?
 <BannerPopup
        show={bannerSubmit}
        
        
       
      />

      : null}
</div>

    </>
  );
}
export default HospitalDoctorBanner;
