import React, {useState} from "react";
import "./dumbdata.css";
import AddHeaderToDumbData from "../AddHeaderToDumbData/AddHeaderToDumbData";
function DumbDataTable({dataDumb,id,parentURL,parentName}) {
 
  

 const [showDumbData,setShowDumbData] = useState(false);


const dumbDataDisplay= dataDumb.map((data,i)=>{

    return <>
          <tbody>
           <tr className="outer"> 
               <td className="row-number" > Row {i+1} </td>
           {data.map(row=>{
           return <>
              
                  <td className="row-content"  >

                 
                 {row}</td>
              
                  </>
                })}
           </tr>
           </tbody>
          </>
        
      });
 const handleDisplayDumbData =()=>{
  setShowDumbData(!showDumbData);
 }
  return (
   
      <div className="dumbdata-display">

      {showDumbData ? 
      <div>
      <h5 className="title-of-page">Dumb Data </h5>

      <AddHeaderToDumbData id={id} dumbData={dataDumb} parentURL={parentURL} parentName={parentName}/>
   
      <table>{dumbDataDisplay}</table><br/>
      
      <button className="btn btn-danger" onClick={handleDisplayDumbData}>  <i className="fa fa-angle-up down-up-icon" aria-hidden="true"></i> Hide Dumb Data Table </button>
      <div className="seperator"> </div>
      </div>

      : 

       <button className="btn btn-primary btn-col dumb-data-button " onClick={handleDisplayDumbData}> <i className="fa fa-angle-down down-up-icon" aria-hidden="true"></i> Show Dumb Data Table </button>
      }
      
      </div>

     
  );
}

export default DumbDataTable;
