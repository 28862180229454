import React, { useState, createContext } from "react";

export const BdoLoginContext = createContext();

export const BdoLoginProvider = (props) => {
  const [bdoData,setBdoData] = useState();
   
  return (
    <BdoLoginContext.Provider value={[bdoData,setBdoData]}>
      {props.children}
    </BdoLoginContext.Provider>
  );
};
