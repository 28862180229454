import React, { useEffect,useState} from "react";
import Chart from "chart.js";

import axios from "axios";
import DatePicker from 'react-date-picker';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {APIURL} from "../../Global";
import './vitalschol.css';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
let myChart;

function VitalsChol(props) {
  const [startDate, setStartDate]=useState(new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]);
  const [endDate,setEndDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset()*60*1000)).toISOString().split('T')[0]);
  const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [patientId,] =useState(props.id);
  const [hdlChol, setHdlChol]= useState([]);
  const [ldlChol, setLdlChol]= useState([]);
  const [serumChol, setSerumChol]= useState([]);
  const [totalChol, setTotalChol]= useState([]);
  const [inferenceLoad, setInferenceLoad]= useState('')
    const [hdlArray,setHdlArray]=useState([]);
    const [ldlArray,setLdlArray]=useState([]);
    const [cholArray,setCholArray]=useState([]);
    const [serumArray,setSerumArray]=useState([]);
   const [cholDatesArray,setCholDatesArray]=useState([]);
   const [cholList,setCholList]=useState([]);
  const [updateRender,setUpdateRender]=useState(false);
 const [unit,setUnit]=useState();
 const [load ,setLoad]= useState('')
 const [deleteIndex, setDeleteIndex]= useState('');
 const [errorMsg,setErrorMsg]=useState("");
 const [modalShow,setModalShow]=useState(false);
const [errorSubmit,setErrorSubmit]=useState(false);
const [editShow, setEditShow]= useState(false)
const [deleteShow, setDeleteShow]= useState(false)
const [submitMsg,setSubmitMsg] = useState("");

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const ctx = document.getElementById("chart-chol").getContext('2d');
   

if (myChart) {
        myChart.destroy();
      }
  myChart =  new Chart(ctx, {
      type: "line",
      data: {
        labels: cholDatesArray,
        datasets: [
          {
            label: "Cholesterol",
            data: cholArray,
            backgroundColor: 'purple',
              
              
              pointBorderWidth:2,
              pointHoverRadius: 5,
            fill: false,
            borderColor: 'purple',

           
          },
           {
            label: "HDL Cholesterol",
            data: hdlArray,
            backgroundColor: 'rgba(54, 162, 235, 0.9)',
              
              
              pointBorderWidth:2,
              pointHoverRadius: 5,
            fill: false,
            borderColor: 'rgba(54, 162, 235, 0.9)',

              

               
          },
          {
            label: "LDL Cholesterol",
            data: ldlArray,
            backgroundColor: 'rgba(255, 99, 132, 0.9)',
            pointBorderWidth:2,
            pointHoverRadius: 5,
            fill: false,
            borderColor: 'rgba(255, 99, 132, 0.9)',     
          },
          {
            label: "Serum Cholesterol",
            data: serumArray,
            backgroundColor: 'rgba(255, 206, 86, 0.9)',                            
            pointBorderWidth:2,
            pointHoverRadius: 5,
            fill: false,
            borderColor: 'rgba(255, 206, 86, 0.9)',      
          }
        ],
        tooltips: {
          backgroundColor: 'purple',
             
              

        }
      },

      options:{
        scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: `Cholesterol Values  ${unit ? "(" +unit+ ")" : null} `
          },

        ticks: {
          beginAtZero: true,
          stepSize: 10,
          padding: 20,
        },
        }],
        xAxes:[{
          scaleLabel: {
          display: true,
            labelString: 'Date'
          }
        }
        ]
      },
          legend:{
          position:'right',
          labels:{
            fontColor: '#000'
          }
        }
      }
    });
  },[cholArray,ldlArray,hdlArray,serumArray,cholDatesArray,unit]);

  
  
useEffect(()=>{

 handleGraph();
},[]);

useEffect(()=> {
  handleTableData();
},[])

const handleTableData = () =>
{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
   setLoad('Loading Data...')
  
    axios.get(`${APIURL}/api/v1/doctor/patient-cholesterol/?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}&timeZone=${timezoneOffset}&sort_by=asc`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
        setLoad('')
        let arr= res.data.results;
        let chol=[];
        let ldl=[];
        let hdl=[];
        let serum=[];
        arr.map(result=>{
          chol.push(result.cholesterol);
          ldl.push(result.ldl_cholesterol);
           hdl.push(result.hdl_cholesterol);
            serum.push(result.serum_cholesterol);
        })
     setCholArray(chol);
     setLdlArray(ldl);
     setHdlArray(hdl);
     setSerumArray(serum);
     
     let newArrayDates = [];
     arr.map((result) => {
       newArrayDates.push(result.date.slice(0, 16).split("T").join("\n"));
     });
     setCholDatesArray(newArrayDates);

     setCholList(res.data.results);
     let unit1=null;
     if(res.data.results.length>0){
      unit1=res.data.results[0].unit
     }
     else{
      unit1=null;
     }
    
     setUnit(unit1);

      }
      else {
        setLoad('Error in Fetching Data')
      }

    })
    .catch(err=>{
      setLoad('Error in Fetching Data')
})

}
const handleGraph =() =>
{
  const tokenString= localStorage.getItem('usertoken');
   
  let str= tokenString.replace(/["]+/g, '')  ;
         
  setInferenceLoad('Loading Data...')
 
   axios.get(`${APIURL}/api/v1/patient/cholesterol-inferences/?patient_id=${patientId}&date_from=${startDate}&date_to=${endDate}&timeZone=${timezoneOffset}`, { headers: {
     
     "Authorization" : 'Token '+str  }})
   .then(res=>{
    

  
  
     if(res.data.status==="success"){

       setInferenceLoad('')
       setHdlChol(res.data.cholesterol_inference_data.hdl_cholesterol_data)
       setLdlChol(res.data.cholesterol_inference_data.ldl_cholesterol_data)
       setSerumChol(res.data.cholesterol_inference_data.serum_cholesterol_data)
       setTotalChol(res.data.cholesterol_inference_data.total_cholesterol_data)
     }
     else {
       setInferenceLoad('Error in Fetching Data')
     }

   })
   .catch(err=>{
     setInferenceLoad('Error in Fetching Data')
})
}

// useEffect(()=> {
// 

// console.log(cholList)

// },[deleteIndex])




 const handleGo=(e)=>{
      e.preventDefault();
      setUpdateRender(!updateRender);
}

const SubmitPopUp=({show,onHide})=> {
 

  return (
    <Modal
      show
      onHide
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{submitMsg}</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button onClick={onHide}>Ok</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}



const handleFromDate = (date)=>{
  
  if(date!==null){
const offset = date.getTimezoneOffset();

let dateUpdated = new Date(date.getTime() - (offset*60*1000));



let DateExtracted = dateUpdated.toISOString().split('T')[0];



  setStartDate(DateExtracted);
  }
}

const handleToDate = (date)=>{
  
  if(date!==null){
const offset = date.getTimezoneOffset();

let dateUpdated = new Date(date.getTime() - (offset*60*1000));

let DateExtracted = dateUpdated.toISOString().split('T')[0];



  setEndDate(DateExtracted);
  }
}

const renderChol=(cellInfo)=> {
  const date4= cholList
  const date5 = [...date4].reverse();
  return(<h6> {date5[cellInfo.index][cellInfo.column.id]}  </h6>)
}
const renderDate=(cellInfo)=> {
  const date4= cholList
  const date5 = [...date4].reverse();
  return(<h6> {date5[cellInfo.index].date.slice(0,10)} </h6>)
}
const renderFitData= (cellInfo)=> {
  const data= cholList
  const fitData = [...data]

  return(<h6> { fitData[cellInfo.index][cellInfo.column.id] ? 'Yes': 'No' } </h6>)
}

const callDelete=(idToDelete)=>{
  ;
  const tokenString= localStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
       
         axios.delete(`${APIURL}/api/v1/patient/cholestrol-history/?id=${idToDelete}&patient_id=${patientId}`, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     

     
         .then(res=>{
        
           if(res.status==200){


            setSubmitMsg("Deleted Successfully")
            setModalShow(true)
            
            
               
       }
       else{
         setErrorMsg('in Data Submission. Please try again!')
         setErrorSubmit(true)
        
       }

      
       setDeleteShow(false);
     
         })
         .catch(err=>{
            setErrorMsg('in Data Submission. Please try again!')
            setErrorSubmit(true)
     })

}

const deleteRow=(index)=>{
  

  let b=cholList[index.index]
 
  callDelete(b.test_id);

}

const DeleteConfirmPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="confirm-reject">Are you sure you want to delete?</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

        <Button variant="danger" onClick={()=>{deleteRow(deleteIndex)}}> Confirm</Button>

       

      </Modal.Footer>
    </Modal>
  );
}


const columnsChol= [
{
  Header: "Date",
  accessor: "date",
  Cell: renderDate,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  }
   

},
{
  Header: <>
            <h6>Cholesterol</h6>
            <h6>(mg/dL)</h6>
         </>,
  accessor: "cholesterol",
  Cell: renderChol,
  
  width: 120,
  minWidth:50,
   
},
{
  Header: <><h6>HDL</h6>
            <h6>Cholesterol</h6>
            <h6>(mg/dL)</h6>
         </>,
  accessor: "hdl_cholesterol",
  Cell: renderChol,
   width: 120,
  minWidth:50,
   
},
{
  Header: <><h6>LDL</h6>
            <h6>Cholesterol</h6>
            <h6>(mg/dL)</h6>
         </>,
  accessor: "ldl_cholesterol",
  Cell: renderChol,
  width: 120,
  minWidth:50
   
},
{
  Header: <><h6>Serum</h6>
            <h6>Cholesterol</h6>
            <h6>(mg/dL)</h6>
         </>,
  accessor: "serum_cholesterol",
  Cell: renderChol,
  width: 120,
  minWidth:50,
   
},
{
  Header: "Smart Device Data",
  accessor: "is_wearables",
  Cell: renderFitData,
   width: 150,
  minWidth:50,
   
},
{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "Delete Row"><i className="fas fa-trash-alt"></i></span>,
  Cell: props=>{
    return (
    <i  className="fas fa-times close-button"
       onClick={()=>{setDeleteIndex(props);
       setDeleteShow(true);}}> </i>
    )
  },
  sortable:false,
  filterable:false,
  minWidth:50,
  width:50
   
}]
const Chol_Table = <ReactTable 
         columns={columnsChol}
         data={cholList}
         defaultPageSize = {10}
         className="chol-table"
         resizable
                           
                 
         >

         </ReactTable> 

const inferenceData =

inferenceLoad===''? 
   <>
  {
    hdlChol !==null? 
    <>
    <h5 className='text-success font-weight-bold'>{hdlChol.summary}</h5>
    <h6  className='text-muted font-weight-bold'>Maximum Cholesterol Level : </h6> <p >{hdlChol.max_cholesterol}mg/dL {hdlChol.max_cholesterol_date}</p><br/>
    <h6  className='text-muted font-weight-bold'>Minimum Cholesterol Level : </h6> <p >{hdlChol.min_cholesterol}mg/dL {hdlChol.min_cholesterol_date}</p><br/><br/>
    </>
    : null
  }
    
    {
    ldlChol !==null?
    <>
    <h5 className='text-success font-weight-bold'>{ldlChol.summary}</h5>
    <h6  className='text-muted font-weight-bold'>Maximum Cholesterol Level : </h6> <p >{ldlChol.max_cholesterol}mg/dL {ldlChol.max_cholesterol_date}</p><br/>
    <h6  className='text-muted font-weight-bold'>Minimum Cholesterol Level : </h6> <p >{ldlChol.min_cholesterol}mg/dL {ldlChol.min_cholesterol_date}</p><br/><br/>
    </>
    : null
  }

{
    serumChol !==null? 
    <>
    <h5 className='text-success font-weight-bold'>{serumChol.summary}</h5>
    <h6  className='text-muted font-weight-bold'>Maximum Cholesterol Level : </h6> <p >{serumChol.max_cholesterol}mg/dL {serumChol.max_cholesterol_date}</p><br/>
    <h6  className='text-muted font-weight-bold'>Minimum Cholesterol Level : </h6> <p >{serumChol.min_cholesterol}mg/dL {serumChol.min_cholesterol_date}</p><br/><br/>
    </>
    : null }

{
    totalChol !==null?
    <>
    <h5 className='text-success font-weight-bold'>{totalChol.summary}</h5>
    <h6  className='text-muted font-weight-bold'>Maximum Cholesterol Level : </h6> <p >{totalChol.max_cholesterol}mg/dL {totalChol.max_cholesterol_date}</p><br/>
    <h6  className='text-muted font-weight-bold'>Minimum Cholesterol Level : </h6> <p >{totalChol.min_cholesterol}mg/dL {totalChol.min_cholesterol_date}</p><br/><br/>
    </>
    : null }

</>
   
  

: inferenceLoad==='Error in Fetching Data'? <h6 style={{color: "red"}}>{inferenceLoad}</h6> : <h6 style={{color: "grey"}}>{inferenceLoad}</h6>



  return (
 

    <>
     <div className="food-habits-page">
     <div className="food-habits-header">
    <h2 className="title-of-tasks"> Cholesterol Details </h2>
         {/* <h3 className=""> Patient  ID : {patientId} </h3><br/><br/> */}
s


<div className="calender-pickers-from-to">
<div className="span-date">           
<span> From </span>

<DatePicker
                          value={new Date(startDate)}
                          onChange={date=>{handleFromDate(date)}}
                         
                          maxDate = {new Date()}
                        
                          
                     /> 
</div>
<div className="span-date">             
<span> To </span>

<DatePicker
                          value={new Date(endDate)}
                          onChange={date=>{handleToDate(date)}}
                         
                          maxDate = {new Date()}
                        
                          
                     /> 
</div>
<button type="submit" className="btn btn-primary btn-col" onClick={handleGo}> Check </button>
</div>

</div> 
<div className="food-habits-sections chol-section">
   <div>
   <h5 className="title-of-lists"> Graph </h5>
    <div className="chart-div-glucose">
   
        <canvas id="chart-chol" width="800" height="400"></canvas>

      
    </div>
    </div>
    
     <div className="food-table">
     <h5 className=" title-of-lists"> Table</h5>
    {load===''? cholList.length>0 ?
     <>
    

 
     
   {Chol_Table}
     </> 
     : <h6 style={{color: "red"}}> No data to show! </h6> : load==='Error in Fetching Data'? <h6 style={{color: "red"}}>{load}</h6> : <h6 style={{color: "grey"}}>{load}</h6>
   }
     </div>

     <div className="food-table">
     <h5 className=" title-of-lists"> Inference </h5>
     {
       hdlChol===null && ldlChol===null && serumChol===null && totalChol===null ? <h6 style={{color: "red"}}>No data to show!</h6> :
       <div style={{maxHeight:'565px'}} className="inference-container analysis-subsection">
  <div className="inference-container-header box-theme">
   <h6 className="inference-container-title"> Analysis</h6>
  </div>
  <div className="inference-container-body">

  {inferenceData}

  </div>

        
     
     </div>
     }
     

  </div>
   
   
   
     </div>
     </div>
     
     {deleteIndex!=='' ?
    <DeleteConfirmPopup
        show={deleteShow}
        onHide={() =>  {setDeleteShow(false);
                       setDeleteIndex('');}}
       
      />: ''

      
    }
    {submitMsg!=="" && submitMsg!== null ? 
          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {
          handleGraph();
          handleTableData();
          setModalShow(false);
                        setSubmitMsg('');
                      }}

       
      /> : null }
     </>
  );
}

export default VitalsChol;