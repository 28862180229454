import React from "react";

import "./Appointment.css";
import { Link} from "react-router-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import "./pagination.css";
import {APIURL} from "../../Global";


class Appointments extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        date:"",
        datalist: [],
         activePage: 1,
        order: "asc",
        dropdownOpen : false,
        total_page:1,
        sort: "sort",
        messagesShow: false,
        idval:0,
        keys : {
          'p': false,
          'n': false,
          'm': false,
          'l': false,
          's': false
         }
  
      };

    }
    
  
    componentDidMount() {
   

      const tokenString = localStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
  
     
      let date=this.props.match.params.date

      axios
        .get(`${APIURL}/api/v1/doctor/appointments/patients/v2/?date=${date}`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            
            
            // this.setState({total_page:res.data.total_pages})
            const datas = res.data.appointments;
      
  
            this.setState({ datalist: datas });
          } else {
           
          }
        })
        .catch((err) => {
       
        });

            //////* Shortcuts ********/ 

    window.onkeydown =(e) => {
    
      this.state.keys[e.key]=true

      if(e.altKey && this.state.keys['p']){
        this.props.history.push('/patients-directory')
      }
      if(e.altKey && this.state.keys['n']){
        this.props.history.push('/doctor/notifications')
      }
      if(e.altKey && this.state.keys['m']){
        this.props.history.push('/message')
      }
      if(e.altKey && this.state.keys['l']){
        this.props.history.push('/doctor/analytics')
      }
      if(e.altKey && this.state.keys['s']){
        this.props.history.push('/service-requestlist')
      }
  
  
    }
    window.onkeyup=(ev)=> {
      
      this.state.keys[ev.key]= false
    }
}
  
componentWillUnmount(){ 
  window.onkeydown=null
  window.onkeyup=null
}
  
    render() {

            const {datalist} =this.state;     
     
      let dataDisplay = this.state.datalist.length>0 ? this.state.datalist.map((data, index) => {
        const idval1 = data.patient_id;

      //let url = `/patient-record/${idval1}`;
      
    //  let serialNum = this.state.activePage*10 - 10;
     return (



     
<div>
<h1> <span className="badge badge-pill badge-info">  <pre><h4> {data.heading}           No of patients : {(data.patients).length} </h4></pre></span></h1>

    

      {data.patients.length>0 ? data.patients.map((patient, index) =>
      
       <div className="list-item list-msg">
                  <img className="profile-picture" src={patient.photo} alt='Patient Photo' width="50px" height="auto"/>                

         <div className="d-flex justify-content-start">

<div className="p-6 sm-highlight mb-6">
<h4> Name :{patient.name}</h4>
      <h4>Gender :{patient.gender}</h4>
      <h4>Age :{patient.age}</h4>


         
  

</div>

      
    </div> 
    <div className="d-flex flex-row-reverse bd-highlight">


  <div className="p-2 bd-highlight"> </div>
         
       </div>
      
       <div className="msg-section">
  <div className="p-2  bd-highlight"></div>
  
 </div>


      
                  {/* <Link to={url}>{data.patient_name}    -      Patient_id - {data.patient_id} </Link> */}

         <h5 className="each-list-link">
         
           
                        



         </h5>

         
         <div className="details">

         
        
         
         
                         

           {/* <p> Date : {data.date_time} </p> */}

         </div>
       </div>
       
       ): null}
       </div>
     );
   })
   : null;


        




     
       









         
  
      const handlePageChange=(pageNumber)=> {
     
      this.setState({activePage: pageNumber});
      const tokenString = localStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
  
     
  
      axios
      .get(`${APIURL}/api/v1/doctor/message-list/`, {
        headers: { Authorization: "Token "+ v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          
          
          this.setState({total_page:res.data.total_pages})
          const datas = res.data.data;
       

          this.setState({ datalist: datas });
        } else {
          
        }
      })
      .catch((err) => {
      
      });
  }


  
     


   

  
      return (
          
        
        <div className="container displaylist-container ">
          <div className="dashboard-navigation">
            <div>

              {" "}







              







              <Link to="/doctor">
                {" "}
                <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
              </Link>
            </div>

  
            <div>

              {" "}
              <i className="fas fa-angle-double-right angle-icon"></i>{" "}

            </div>

            <div>
            {" "}
              <Link to="/appointment"> Appointment</Link>{" "}


              </div>
          </div>
          
          <div>
      <h2 className="sub-title"> Appointments on {this.props.match.params.date}</h2>
</div>
          <div className="header-display">
  
  
           <div className="buttons-wrapper">




  
  


  
  </div>
  
  
          </div>
  
          <div className="test-reports-display-list">
          
             {datalist.length>0 ? (
               dataDisplay
             ) : (


             <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
                <h4>No more Pending list to show!!</h4>
              </div>
            )}
          </div>
          
          {this.state.total_page*10 >10 ?
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.total_page*10}
            pageRangeDisplayed={this.state.total_page}
            onChange={handlePageChange}
            disabledClass = "disabled-class"
          />
         : null}
        </div>
      );
    }
  }
  
  export default Appointments;
  