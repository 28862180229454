import React from "react";
import graphic4 from "../../images/graphic4.svg";
import "./success_reg.css";

function SuccessPatient() {
  return (
    <div className="container page-container">
      <div className="success-msg">
        <h4>You have successfully registered !! </h4>
        <h4>
         We are in process of implementing Web app with all functionalities.
         Please use our mobile app to use our services till then.  
          <br />
        </h4>
        <h4> Thanks for understanding! </h4>
       {/* <p>
          {" "}
          Goto <Link to="/login">Login</Link>
        </p> */}

        <img src={graphic4} width="30%" height="auto" alt="success"/>
      </div>
    </div>
  );
}

export default SuccessPatient;
