import React,{useState,useEffect} from "react";
import "./caredetails.css";
import axios from "axios";
import {APIURL} from "../../Global";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';


function CareProgram(props) {

   const [careProgramId,]=useState(props.careId);

   /* const doctor_specializations = hospBdoData ? hospBdoData.doctor_specializations : null; 
   const [department,]  = useState(bdoData ? bdoData.department : null);
   const [Id,] = useState(bdoData ? bdoData.user_id : null);
   const [hospitalName,]= useState(bdoData ? bdoData.hospital_username: null);
   const specializations = hospBdoData ? hospBdoData.specializations : null;
   const [location,] = useState(bdoData ? bdoData.location: null); */
   const [title,setTitle]=useState();
   const [price,setPrice]=useState();
   const [discount,setDiscount]=useState();
 const [currency,setCurrency]=useState();
  const [isActive,setIsActive]=useState();
  const [taxAmount,setTaxAmount]=useState();
  const [activeSubs,setActiveSubs]=useState();
   const [pricingType,setPricingType]=useState();
 const [offerText,setOfferText]=useState();
      const [,setGetError]=useState('');
   /*const [commonFeatures,setCommonFeatures]=useState([]);
   const [commonFeaturesActive,setCommonFeaturesActive]=useState([]);*/
   const [featuresList,setFeaturesList]=useState([]);
   //const [refresh,setRefresh]=useState(false);
    const [isObsolete,setIsObsolete]=useState();
   /*const [customFeatures,setCustomFeatures]=useState([...new Array(5)].map(()=>({
      title: "",
      limit: "",
      is_default:false,
      extra_cost:""
   })));
   const [count,setCount]=useState(20);*/

   let featuresTable=null;

  
   useEffect(()=>{

    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;


  
    axios.get(`${APIURL}/api/v1/hospital/care-programs/${careProgramId}/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){

       setTitle(res.data.data.title);
       setDiscount(res.data.data.discounted_price);
       setPrice(res.data.data.price);
       setCurrency(res.data.data.currency);
       setIsActive(res.data.data.is_active);
       setIsObsolete(res.data.data.is_obselete);
          setActiveSubs(res.data.data.active_subscribers)
         

       let priceType=null;

  if(res.data.data.pricing_type==="one_month"){
    priceType="1 month (Monthly)";
  }
   else if(res.data.data.pricing_type==="three_months"){
    priceType="3 months (Qaurterly";
  }
  else if(res.data.data.pricing_type==="six_months"){
    priceType="6 months (Half-yearly)";
  }
  else if(res.data.data.pricing_type==="one_year"){
    priceType="1 year (Yearly)";
  }
  else if(res.data.data.pricing_type==="two_year"){
    priceType="Life time (2 years)";
  }
  setPricingType(priceType);

       setTaxAmount(res.data.data.tax_percentage)
       setOfferText(res.data.data.description);
     
       let fList=res.data.data.features;
       const activeList= fList.filter((item)=>{
        return item.is_active===true
       })

     
         setFeaturesList(activeList);

      }

        else{
        setGetError(res.data.message);
      }
      


    })
    .catch(err=>{

 setGetError("Error in fetching data");
})


},[careProgramId]);




const renderTableId=(cellInfo)=> {
     
    return (

      <div > {cellInfo.index + 1} </div>
      
    );
  
  
  }




const renderTable=(cellInfo)=> {
     
    return (

      <div >  {featuresList[cellInfo.index][cellInfo.column.id] ? <h6 className="feature-rt-td">{featuresList[cellInfo.index][cellInfo.column.id]}</h6> : null }</div>
      
    );
  
  
  }
   const renderService=(cellInfo)=> {
    
     return (
  
      <div > {featuresList[cellInfo.index][cellInfo.column.id]===true ? <h6 className="feature-rt-td">Yes</h6> :featuresList[cellInfo.index][cellInfo.column.id]===false? <h6 className="feature-rt-td">No</h6>: null }</div>
       
     );
  }

 /* const renderDefault=(cellInfo)=> {
     
    return (

      <div > {featuresList[cellInfo.index].is_default ? <button className="btn btn-secondary btn-smallest btn-square btn-default"> Default </button>: <button className="btn btn-info btn-smallest btn-square btn-default"> Custom </button>}</div>
      
    );
  
  
  } */
 const columnsFeaturesList= [
  {
  Header: "ID",
  accessor: "feature",
  Cell: renderTableId,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:60,
   sortable:false

},
{
  Header: "Feature",
  accessor: "title",
  Cell: renderTable,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:280,
   sortable:false

},{
   Header: <div className="header-features-table"><h6>Free</h6>
          <h6>(Service count. Eg: 2)</h6> </div>,
  accessor: "limit",
 
  Cell: renderTable,
   sortable:false,
  minWidth:160

},
{
  Header: <div className="header-features-table"><h6>Extra Price</h6>
          <h6>(for additional Service)</h6> </div>,
  accessor: "extra_cost",
  Cell: renderTable,
   sortable:false,
   minWidth:120,
   

},
{
  Header: "Service Request",
  accessor: "on_request_service",
  Cell: renderService,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  width:150,
   sortable:false

},
]

 
 

  featuresTable=<ReactTable 
         columns={columnsFeaturesList}
         data={featuresList}
         defaultPageSize = {10}
         resizable
        className="features-reacttable"            
                 
         >

         </ReactTable> 



  
  return (
    <> 
    


       <div className="hospital-dashboard-container hosp-bdo-page">


         <h2 className="title-bdo text-center"> {title} </h2>
   
    <br/>

    <div className=" features-subs ">

  {/*******************details **********/}
   <div className="pgm-details-box">
    <div className="pgm-details-header box-theme">
                               

                      <h6 className="sub-section-title pgm-header-title"> Care Plan Details </h6>
                      </div>
   <br/>
   <div className="care-pgm-details-body">
    <table className="featuresdetails-table">
     <tr>
    <td>
          <h6 className="features-form text-left">Standard Price : </h6>
      </td>

      <td>
         <span className="price-box">
            <span className="currency-uppercase"> {currency}</span>
             {price}
             </span>
      </td>
  </tr>

      <tr>
    <td>
    
       <h6 className="features-form text-left"> Price after Discount : </h6> 
   </td>

      <td>
  <span className="price-box"><span className="currency-uppercase"> {currency}</span> {discount} </span>
   </td>
  </tr>

      <tr>
    <td>
    <h6 className="features-form text-left"> Price plan : </h6>
    </td>

      <td>
      <span className="price-box">{pricingType} </span>
    </td>
  </tr>

      <tr>
    <td>
      <h6 className="features-form text-left"> Tax : </h6>
          </td>

      <td>
         <span className="price-box"> {taxAmount ? taxAmount +" %" : "nil"} </span>
          </td>
  </tr>
  <tr>
    <td>
      <h6 className="features-form text-left"> Offer/Info Text:</h6>
     </td>

      <td>
     <span className="">  <p className="text-area  price-box"> {offerText ? offerText : 'Nil' }</p></span>
   </td>
  </tr>

<tr>
    <td>
      <h6 className="features-form text-left"> Active Subscribers count:</h6>
     </td>

      <td>
     <span className="">  <p className="text-area  price-box"> {activeSubs}</p></span>
   </td>
  </tr>

      <tr>
    <td>
     <h6 className="features-form text-left"> Status (Active/ Inactive):</h6>
   </td>

      <td> 
     <span className="price-box"> {isActive=== true ? "Active" : "Inactive"} </span>
      </td>
  </tr>

  <tr>
    <td>
     <h6 className="features-form text-left " style={{color:"red"}}> Obsolete status:</h6>
   </td>
 
      <td> 
     <span> {isObsolete=== true ? <span className="price-box grey-box" style={{color:"red"}}> Is obsolete </span> : <span className="price-box"> Not obsolete </span>}</span>
      </td>
  </tr>
    </table>
    </div>
    
   </div>
  

    <div className="">
     {featuresTable}
     
     </div>

 </div>

<br/>
<br/>

    
<br/>
<br/>
<br/>


<br/>
<br/>
</div>

    </>
  );
}
export default CareProgram;
