import React,{useState, useEffect} from "react";
import "./changepasswordhospital.css";
import axios from 'axios';
import HospitalNavbar  from "../HospitalNavbar/HospitalNavbar";

import { Link,useHistory } from "react-router-dom";

import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";

function SubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4> Password updated successfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
        
        

        <Link to="/bdo-list"><Button className='btn btn-primary btn-col' > Goto BDO List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}

function ChangePasswordHospitalBdo(props) {

    const [modalShow, setModalShow] = useState(false);
   const [password, setPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState(null);
      const [submitMsg, setSubmitMsg]= useState("");
      const [error,setError]=useState({
   
    password: '',
    confirm_password:"",
   
   });
   const id = props.match.params.id;

   const history =useHistory();

   const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});
   
   useEffect(()=> {
    window.scrollTo(0,0)
  },[])
  
   
   useEffect(()=>{
   
   window.onkeydown =(e) => {
    
    keys[e.key]=true
   
    if(e.altKey && keys['d']){
      history.push('/doctors-list')
    }
    if(e.altKey && keys['a']){
      history.push('/hospital/add')
    }
    if(e.altKey && keys['b']){
      history.push('/bdo-list')
    }
    if(e.altKey && keys['o']){
      history.push('/hospital/add-bdo')
    }
    if(e.altKey && keys['n']){
      history.push('/emergency-number')
    }
    if(e.altKey && keys['s']){
      history.push('/service-request-list')
    }
    if(e.altKey && keys['l']){
      history.push('/license-details')
    }
    if(e.altKey && keys['p']){
      history.push('/license-payment')
    }
    if(e.altKey && keys['c']){
      history.push('/hosp-calllog')
    }
    if(e.altKey && keys['h']){
      history.push('/payment-history')
    }
   
   }
   window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
   }
   
   return()=>{
    window.onkeydown=null
    window.onkeyup=null
   }
   },[]);

    const handlePasswordChange =(e)=>{

    
   	setPassword(e.target.value);
   }
    const handleConfirmPassword =(e)=>{

    setConfirmPassword(e.target.value);
   }

const validate=async ()=>{
   
      let errors = {};
      let isValid = true;
    setError({});

 
  

    if (password !== "undefined") {
      let pattern = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/);
      if (!pattern.test(password)) {
        isValid = false;
        errors["password"] = "Password must contain at least 1 UPPERCASE, 1 lower case, 1 number, 1 special character and don't use space";
      }
    }
      if (!password || password==="" || password===null) {
        isValid = false;
        errors["password"] = "Please enter password";
      }
  
      if (!confirmPassword || confirmPassword==="" || confirmPassword=== null || typeof confirmPassword === "undefined") {
        isValid = false;
        errors["confirm_password"] = "Please enter confirm password";
      }
  
      if (password && typeof password !== "undefined") {
        if(password.length >0 && password.length < 8){
            isValid = false;
            errors["password"] = "Must contain atleast 8 characters";
        }
      }
  
      if (typeof password !== "undefined" && password!=="" && password!==null && typeof confirmPassword !== "undefined" && confirmPassword!=="" && confirmPassword!== null) {
       if (password !== confirmPassword) {
          isValid = false;
          errors["confirm_password"] = "Passwords don't match";
        }
      }
     
        


    
       await setError(currentstate=>({
            ...currentstate,
            ...errors
          }));
      

      return isValid;
  }
   
  const handleSubmit=async (e)=>{
    e.preventDefault();
    if(await validate()){
    callSubmit();

  }
}
  const callSubmit=()=>{
   
   	const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); 

   
  const data = {
  	  	password : password
  }

    axios
      .put(`${APIURL}/api/v1/hospital/bdo/${id}/`, data, {
        headers: { 'Content-Type': 'application/json',
                     Authorization: "Token "+v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {

          setModalShow(true);
          
        } else {
     
          setSubmitMsg("error");
        }
      })
      .catch((err) => {
       
        setSubmitMsg("error");
      });
  }

   
 return( 
 <>
<HospitalNavbar/>
 <div className="liner">

  </div>
    <div className="container">
       <form className="change-pw-form col-lg-6" onSubmit={handleSubmit}>
       <h4 className="title-of-page"> Change Password </h4><br/>
         <label className="form-control" style={{backgroundColor:"#f0f0f0",fontWeight:"bold"}}> BDO ID : {id} </label>
         <div className="form-group ">
           <input className="form-control " type="password" maxLength='30' placeholder="*Enter new Password" value={password} onChange={handlePasswordChange} />
          {error.password ?<div className="error-validation-msg"> {error.password}</div>: null} 
           </div>
          <div className="form-group ">
           <input className="form-control " type="password" maxLength='30' placeholder="*Confirm Password" value={confirmPassword} onChange={handleConfirmPassword} />
         
          {error.confirm_password ?<div className="error-validation-msg"> {error.confirm_password}</div>: null} 
                                         
         </div>

  <div className="form-group ">
          <Link to="/bdo-list"><Button variant="secondary btn-cancel" > Cancel</Button></Link>
          <button type="submit" className="btn btn-primary btn-col">Update password </button>
  </div>







       </form>

       <SubmitPopUp
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      {submitMsg=== "error" ?
       <h5 style={{ color: "red" }}>
            
            Error in data submission.Please try again!
          </h5>
      :
      null}

    </div>
 </>
);
}
export default ChangePasswordHospitalBdo;