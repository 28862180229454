import React, {useState}  from 'react';

import {Link , useHistory } from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import '../../css/bootstrap.min.css';
import '../../css/iofrm-style.css';
import '../../css/iofrm-theme4.css';
import graphic from "../../images/flat.jpg";
import "./register.css"
import axios from "axios";

import "./country_region_selector.css";
import "./phoneinput.css";
import {APIURL} from "../../Global";



function HospitalRegister(){
 
   const [hospitalDatas,setHospitalDatas] = useState({
     name: '',
     phone: '',
     email:'',
     country:'',
     state:'',
     location:'',
     specializations: '',
     password : ''
 })

   //
    let history = useHistory()


    const handleChange=e=>{
      e.preventDefault();
      const val=e.target.value;
      const key=e.target.name;
    
         setHospitalDatas(currentstate=>({
            ...currentstate,
            [key]: val
          }));
      
}


             /*Handle Signup */
  const  handleRegister=(e)=>{
      
  
       
                  
       
         e.preventDefault();
       
                 const dataToSend={
                    name:hospitalDatas.name,
                    location: hospitalDatas.location,
                    state:hospitalDatas.state,
                    country: hospitalDatas.country,
                    specializations : hospitalDatas.specializations,
                    email: hospitalDatas.email,
                    password : hospitalDatas.password
                 }
                  
    

       axios.post(`${APIURL}/api/v1/hospital/register/`, dataToSend, { headers: {
     'Content-Type': 'application/json'}})
    .then(res =>{
            
            if(res.data.status === "success"){
                
                  
                    history.push('/success')

                }
            else {                      
              history.push('/error')
            }
                
            })
     .catch(err =>{
  })

   
 
    }
   

   
    
  
  const selectCountry =(val)=> {
     setHospitalDatas(currentstate=>({
        ...currentstate,
           country: val
         }));
     
    
  }

  const selectRegion= (val)=> {
    setHospitalDatas(currentstate=>({
        ...currentstate,
           state: val
         }));
  }

 const handlePhoneInput= (value, data, event, formattedValue)=>{
  
     setHospitalDatas(currentstate=>({
        ...currentstate,
           phone: value

                       }));
  
  

 }
  
     
   
              return (
                <div className="form-body">
                   
                    <div className="row">
                        <div className="img-holder">
                            <div className="bg"></div>
                            <div className="info-holder">
                               <h3 className="grey-title">The innovative AI platform in Healthcare</h3>
                                <img src={graphic} alt=""/>
                            </div>
                        </div>
                        <div className="form-holder form-container">
                            <div className="form-content">
                                <div className="form-items">
                                    
                                    <p></p>
                                    <div className="page-links">
                                        <Link to="/login" >Login</Link><Link to="register" className="active">Register</Link>
                                       
                                    </div>

                                    

                                         <form className="reg-form hosp-form" onSubmit={handleRegister}>

                                         
                                         <h3 className="form-heading"> Hospital Registration </h3>
                                         <div className="reg-form-div">
                                                      
                                        <div className="item"><input className="form-control" type="text" name="name" value={hospitalDatas.name} placeholder="Name*" onChange={handleChange} maxLength='150'
                                        required/> </div>
                                        

                                        <div className="item"><input className="form-control" type="email" name="email" value={hospitalDatas.email} placeholder="E-mail Address*" maxLength='50' onChange={handleChange} required />
                                        </div>
                                        
                                        <div className="form-control phone-input">
                                        <PhoneInput
                                            inputProps={{
                                                  
                                                  required: true,
                                                  
                                                }}
                                            country={'in'}
                                            value={hospitalDatas.phone}
                                            onChange={handlePhoneInput}

                                          />
                                          </div>
                                       
                                    

                                     
                                        <div className="country-selector">
                                            <CountryDropdown
                                              value={hospitalDatas.country}
                                              onChange={(val) => selectCountry(val)} 
                                              required />
                                        </div>
                                        <div className="region-selector">
                                            <RegionDropdown
                                              country={hospitalDatas.country}
                                              value={hospitalDatas.state}
                                              onChange={(val) => selectRegion(val)} 
                                              required/>
                                              <small id="emailHelp" className="form-text text-muted">*Select Region - (Enabled once you select Country)</small>
                                          </div>
                                           
                                    

                                    
                                     <div className="item"> <input className="form-control" type="text" name="location" placeholder="Location/city*" maxLength='150' onChange={handleChange} required /> 
                                     </div>    
                                     <div className="divider"></div>
                                     <h6 className="sub-headings"> Specialization Details </h6>   
                                     
                                     <div className="item"> <input className="form-control" type="text" value={hospitalDatas.specializations} name="specializations" maxLength='150' placeholder="Specializations*" onChange={handleChange} required /> 
                                         </div>
                                         <div className="item"> <input className="form-control" type="text" value={hospitalDatas.department} name="department" placeholder="Departments" maxLength='150' onChange={handleChange} /> 
                                          </div>     

                                        <div className="divider"></div>
                                         <div className="item"> <input className="form-control pswd" type="password" name="password" placeholder="Password*" maxLength='30' onChange={handleChange} required /> 
                                         </div>
                                         <div className="item"> <input className="form-control pswd" type="password" name="confirmpassword" placeholder="Confirm Password*" maxLength='30' onChange={handleChange} required /> 
                                         </div> 
                                         
                                               
                   

       



                                        </div>
                                        
                                        <div className="form-button">
                                            <button id="submit" type="submit" className="btn btn-primary btn-col" >Register</button>
                                        </div>

                      
                            
      
                                      </form>

                              
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              )
       
    
}



export default HospitalRegister;