import React,{useState,useEffect,useContext} from "react";

import { Link } from "react-router-dom";
import axios from "axios";

import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";
import logo from "../../images/felixacare/logo_512.png";
import Invoice from "../BDO_2/GenerateInvoice/Invoice"
import {RazorpayContext} from "../../contexts/RazorpayContext";
function RazorPay({orderId,orderAmount,orderCurrency,orderTenure,orderCount}) {
	const [amount,]=useState(orderAmount);
    const [currency,]=useState(orderCurrency);
    const [order_Id,]=useState(orderId)
     const [submitMsg,setSubmitMsg] = useState("");
      const [errorMsg,setErrorMsg]=useState("");
      const [successSubmit,setSuccessSubmit]=useState(false);
    const [errorSubmit,setErrorSubmit]=useState(false);
     const [loadRP,setLoadRP]=useContext(RazorpayContext);
     //const [hospData,] =useState(JSON.parse(sessionStorage.getItem('hosp_data'))? JSON.parse(sessionStorage.getItem('hosp_data')) : null);

	
useEffect(()=>{
  loadRazorPay()
})

function SuccessPopup(props){
    

  return (
    <Modal
      {...props}
       aria-labelledby="contained-modal-title-vcenter"
                centered
                 backdrop="static"
                  keyboard={false}
                   dialogClassName='modal-90w'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"green"}}>{props.msg} </h5>
        
        
        <Invoice tenure={orderTenure} amount={amount} userCount={orderCount}/>
     
       
      </Modal.Body>
      <Modal.Footer>
         
         <Link to={{pathname:'license-details', flag:"buy-licence"}}><Button variant="secondary" >Goto Licence Details</Button></Link>

         

       
       

      </Modal.Footer>
    </Modal>
  );
}
function ErrorPopup(props){
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"red"}}>{props.msg} </h5>
        
        
     
       
      </Modal.Body>
      <Modal.Footer>
         
      

         <Link to={{pathname:'hospital'}}><Button variant="secondary" >Goto Homepage</Button></Link>


       
       

      </Modal.Footer>
    </Modal>
  );
}

{/**************
"key": "rzp_live_JNsTPGuoJCgQhW", 
rzp_test_kXul9ySc3QXVH5


LIVE MODE:
----------------
var options = {
    "key": "rzp_live_JNsTPGuoJCgQhW",
    "amount": amount , 
    "currency": "INR",
    "name": "FelixaCare",
    "description": "Buy Licence",
    "image": logo,
    "order_id": order_Id, 
    "handler": function (response){
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature)
    },
    "prefill": {
        "name": "Gaurav Kumar",
        "email": "gaurav.kumar@example.com",
        "contact": "9999999999"
    },
    "notes": {
        
    },
    "theme": {
        "color": "#17a2b8"
    }

  };



LIVE MODE FINAL::
--------------------
var options = {
    "key": "rzp_live_JNsTPGuoJCgQhW",
    "amount": amount , 
    "currency": currency.toUpperCase(),
    "name": "FelixaCare",
    "description": "Buy Licence",
    "image": logo,
    "order_id": order_Id, 
    "handler": function (response){
        
        callPostSuccess(response)
    },

    
    "notes": {
        
    },
    "theme": {
        "color": "#17a2b8"
    }

  };


{/
  *******TEST MODE*****

  var options = {
    "key": "rzp_test_kXul9ySc3QXVH5",
    "amount": 100 , 
    "currency": "INR",
    "name": "FelixaCare",
    "description": "Buy Licence",
    "image": logo,
    "handler": function (response){
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature)
    },
    "prefill": {
        "name": "",
        "email": "",
        "contact": ""
    },
    "notes": {
        "address": ""
    },
    "theme": {
        "color": "#17a2b8"
    }

  };

  TEST MODE FINAL
  ------------------

  var options = {
    "key": "rzp_test_kXul9ySc3QXVH5",
    "amount": 100 , 
    "currency": currency.toUpperCase(),
    "name": "FelixaCare",
    "description": "Buy Licence",
    "image": logo,
    
    "handler": function (response){
        
        callPostSuccess(response)
    },

    
    "notes": {
        
    },
    "theme": {
        "color": "#17a2b8"
    }

  };
  **********/}
const _DEV_= document.domain==="localhost" || document.domain==='cianlogic.com';
 if(document.domain==="localhost" || document.domain==='cianlogic.com'){
    
   }
   else {
    

   }
  const callPostSuccess=(response)=>{
    let razorpay_payment_id = response.razorpay_payment_id;
    let  razorpay_order_id= response.razorpay_order_id;
     let  razorpay_signature = response.razorpay_signature;

     const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
  const dataToSend={
    razorpay_payment_id,
     razorpay_order_id,
     razorpay_signature

  }    
  
    axios.post(`${APIURL}/api/v1/payment/razorpay-transaction/`,dataToSend, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
                                     
       
          
setSubmitMsg(res.data.message ? res.data.message : "Paid successfully!");

          setSuccessSubmit(true);


      }else{
        setErrorMsg(res.data.message);
              setErrorSubmit(true);
                
      }



    })
    .catch(err=>{
   
  setErrorMsg("Error in submission");
  
              setErrorSubmit(true);
               
})
    setLoadRP(false)
  }
  const displayRazorpay=()=>{

  
var options = {
    "key": _DEV_ ? "rzp_test_608iQQdfRzzpp2" : "rzp_live_jLioJQsP1xy0NJ",
    "amount": amount, 
    "currency": currency.toUpperCase(),
    "name": "FelixaCare",
    "description": "Buy Licence",
    "image": logo,
    "order_id": order_Id,
    
    "handler": function (response){
        
        callPostSuccess(response)
    },

    
    "notes": {
        
    },
    "theme": {
        "color": "#17a2b8"
    }

  };
var rzp1 = new window.Razorpay(options);
rzp1.open();
rzp1.on('payment.failed', function (response){
   
    setErrorMsg("Payment Failed");
    setErrorSubmit(true);
})
  }



  const loadRazorPay=()=>{
  const script= document.createElement('script');
  script.src= "https://checkout.razorpay.com/v1/checkout.js"
  document.body.appendChild(script);
  script.onload= displayRazorpay


  }
	
  return (<>
    <div className="container page-container">
      <div className="flex-col">
       
         
        
       
      </div>


    </div>
    {submitMsg !== "" ?
    <SuccessPopup
        show={successSubmit}
        msg={submitMsg}
        onHide={() => { setSuccessSubmit(false);
                        setSubmitMsg('');
                      }}
       
      />: ''}
    
    {errorMsg !== "" ?
    <ErrorPopup
        show={errorSubmit}
        msg={errorMsg}
        onHide={() => { setErrorSubmit(false);
                        setErrorMsg('');
                      }}
       
      />: ''}
      </>
  );
}

export default RazorPay;
