import React, { useEffect,useState} from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import axios from "axios";
import "./targetset.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import Select from 'react-select';
import EditTarget from './EditTarget';
import EditDate from './EditDate';

import Slider from 'react-rangeslider';


import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
import 'react-rangeslider/lib/index.css';
import {APIURL} from "../../Global";
import {ButtonGroup} from 'react-bootstrap';
import {ToggleButton} from 'react-bootstrap';
import {ToggleButtonGroup} from 'react-bootstrap';

function SuccessPopup(props){
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"green"}}>{props.msg} </h5>
        
        
     
       
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

         

       
       

      </Modal.Footer>
    </Modal>
  );
}






function DeleteSuccessPopup (props){
    

  return (
    <Modal
    {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {props.msg} </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}



export default function TargetSet(props) {

  const getDate= ()=> {
    let date= new Date()
    let dateUpdated = date;
    const offset = date.getTimezoneOffset();
    dateUpdated = new Date(date.getTime() - (offset*60*1000))
    let DateExtracted = dateUpdated.toISOString().split('T')[0];

    return DateExtracted
  }

   const diabeticTargets = props.diabeticTargets!== undefined ?  props.diabeticTargets.map((item)=> {
     return({
      newVitalId: item.vital_id,
       newTargetName: item.vital_name,
       target_name: {value: item.vital_name, label: item.vital_name},
       min: item.min_value,
       max: item.max_value,
       tolerance: item.tolerance,    
       compValue:  item.vital_name==='LDL CHOLESTEROL' ? { value: "Lower", label: "Max limit"}

        : item.vital_name==='HDL CHOLESTEROL' ? {value:  "Greater", label: "Min limit"}

        : item.vital_name==='HbA1c' ? {value:  "Lower", label: "Max limit"}

        : item.vital_name==='DIASTOLIC BP' ? {value:  "Lower", label: "Max limit"}

        : item.vital_name==='SYSTOLIC BP' ? {value:  "Lower", label: "Max limit"}

        : item.vital_name==='BLOOD SUGAR NON FASTING' ? {value:  "Lower", label: "Max limit"}

        : item.vital_name==='BLOOD SUGAR FASTING' ? {value:  "Equal", label: "Equals"}

        : item.vital_name==='BLOOD SUGAR RANDOM' ? {value:  "Lower", label: "Max limit"}

        : item.vital_name==='WAIST-HIP RATIO' ? {value:  "Lower", label: "Max limit"}

        : item.vital_name==='WAIST CIRCUMFERENCE' ? {value:  "Lower", label: "Max limit"}

        : item.vital_name==='Body Weight' ? {value:  "Lower", label: "Max limit"}

        : item.vital_name==='BMI' ? {value:  "Equal", label: "Equals"}

        : item.vital_name==='CARBOHYDRATES' ? {value:  "Equal", label: "Equals"}

        : item.vital_name==='FAT' ? {value:  "Equal", label: "Equals"}

        : item.vital_name==='WALKING' ? {value:  "Equal", label: "Equals"}

        : item.vital_name==='CREATINE' ? {value:  "Equal", label: "Equals"}

        : {value: "Lower", label: "Max limit"}, 

       comparisonOptions: item.comparison.map((item)=> {
        if(item==="Greater"){
          return ({
          value: item,
          label: "Min limit"
        })
      }
       else if(item==="Lower"){
          return ({
          value: item,
          label: "Max limit"
        })
      }
       else if(item==="Equal"){
          return ({
          value: item,
          label: "Equals"
        })
      }
       else {
          return ({
          value: item,
          label: item                                          })
      }
    }),
       startDate: new Date(),
       newFromDate: getDate(),
       target_unit: item.vital_unit,
       newTargetValue:  item.vital_name==='LDL CHOLESTEROL' ? '100'

       : item.vital_name==='HDL CHOLESTEROL' ? '60'

       : item.vital_name==='HbA1c' ? '5.7'
       
       : item.vital_name==='DIASTOLIC BP' ? '80'

       : item.vital_name==='SYSTOLIC BP' ? '120'

       : item.vital_name==='BLOOD SUGAR NON FASTING' ? '150'

       : item.vital_name==='BLOOD SUGAR FASTING' ? '85'

       : item.vital_name==='BLOOD SUGAR RANDOM' ? '140'

       : item.vital_name==='WAIST-HIP RATIO' ? '0.9' 

       : item.vital_name==='WAIST CIRCUMFERENCE' ? '32' 

       : item.vital_name==='Body Weight' ? '60' 

       : item.vital_name==='BMI' ? '21' 

       : item.vital_name==='CARBOHYDRATES' ? '260' 

       : item.vital_name==='FAT' ? '60' 

       : item.vital_name==='WALKING' ? '7500' 

       : item.vital_name==='CREATINE' ? '1.0' 

       : '1000'

       }
     )
          
   }) : null
console.log(diabeticTargets)

  const [patientId, setPatientId] =useState(props.id);
const [targetVitals,setTargetVitals]=useState([]);
const [targetOptions,setTargetOptions]=useState([]);
const [,setTargetListCounter]=useState(0);
const [min,setMin]=useState();
const [max,setMax]=useState();
const [step,setStep]=useState(0.1);
const [vitalUnit,setVitalUnit]=useState('');
const [,setVitalDescription]=useState("");
const [tolerance,setTolerance]=useState(null);
const [,setComparison]=useState([]);
const [comparisonOptions,setComparisonOptions]=useState([]);
const [compValue,setCompValue]= useState();
const [patientTargets,setPatientTargets]= useState([]);
const [tableTargets,setTableTargets]=useState([]);
const [tableTargets2,setTableTargets2]=useState([]);
const [tableTargetsNew,setTableTargetsNew]=useState(props.select === undefined ? [] : diabeticTargets);
const [pastTargets,setPastTargets]= useState([]);
const [targetName,setTargetName]=useState("");
const [newTargetName,setNewTargetName]=useState('');
const [editTargetShow,setEditTargetShow]=useState(false);
const [editDateShow,setEditDateShow]=useState(false);
const [editShow,setEditShow]=useState("");

  const [observationsShow, setObservationsShow] = useState(false);
  const [targetData, setTargetData]= useState('');
  const [dateData, setDateData]= useState('');

const [weightAddShow, setWeightAddShow] = useState(false);

console.log(tableTargetsNew)
const [newTargetValue,setNewTargetValue] =useState(null);
const [,setNewTargetUnit]=useState('');
const [newVitalId,setNewVitalId]=useState();
const [newFromDate,setNewFromdate]=useState('');
const [newToDate,setNewToDate]=useState('');
const [newLifeLong,]=useState(false);
const [reRender,setReRender]=useState(false);
const [,setTargetToDelete]=useState('');
  const [errorMsg,setErrorMsg]=useState("");
 const [successSubmit,setSuccessSubmit]=useState(false);
    const [errorSubmit,setErrorSubmit]=useState(false);
    const [deleteSubmit,setDeleteSubmit]=useState(false);
    const [deleteSubmitMsg,setDeleteSubmitMsg]=useState("");
     
           const [submitMsg,setSubmitMsg] = useState("");
          
           const [getError,setGetError]=useState('');
           
           const [startDate,setStartDate]=useState();
            const [endDate,setEndDate]=useState();
           const [toggle, setToggle]= useState(false);
           
           const [render,setRender]=useState(true);
           const [selected,setSelected]=useState(props.select === undefined ? 1 : props.select);

          //  const unitOptions = [{
          //    value:'mg', label:'mg/dL',
          //    value:''
          //  }]

useEffect(()=>{
  console.log("props from patient", props);
    setTableTargetsNew(tableTargetsNew)
  },[render,tableTargetsNew]);
useEffect(()=>{
	                   targetVitals.map(target=>{
	                  
                                   	if(targetName.value === target.vital_name){
                                     

                                   		setMin(target.min_value);
                                   		setMax(target.max_value);
                                      let stepDiff= target.max_value - target.min_value;
                                      let stepCheck=0.1;
                                      if(stepDiff>0 && stepDiff<2){
                                        stepCheck=0.1;
                                      }
                                      else if(stepDiff>0 && stepDiff<10){
                                         stepCheck=0.5;
                                      }
                                      else if(stepDiff>0 && stepDiff>10){
                                         stepCheck=1;
                                      }
                                      else if(stepDiff<0 && stepDiff>-2){
                                        stepCheck=0.1;
                                      }
                                      else if(stepDiff<0 && stepDiff>-10){
                                         stepCheck=0.5;
                                      }
                                      else if(stepDiff<0 && stepDiff<-10){
                                         stepCheck=1;
                                      }
                                      setStep(stepCheck);
                                   		setVitalUnit(target.vital_unit);
                                      setNewTargetUnit(target.vital_unit);
                                      setNewTargetName(targetName.value);
                                      setVitalDescription(target.vital_description);
                                      setNewTargetValue(target.min_value);
                                      setTolerance(target.tolerance);
                                      setComparison(target.comparison);
                                      setNewVitalId(target.vital_id);
                                      let compArray= [];

                                      let comparisonArray=target.comparison;
                                      compArray= comparisonArray.map(item=>{
                                        if(item==="Greater"){
                                            return ({
                                            value: item,
                                            label: "Min limit"
                                          })
                                        }
                                         else if(item==="Lower"){
                                            return ({
                                            value: item,
                                            label: "Max limit"
                                          })
                                        }
                                         else if(item==="Equal"){
                                            return ({
                                            value: item,
                                            label: "Equals"
                                          })
                                        }
                                         else {
                                            return ({
                                            value: item,
                                            label: item                                          })
                                        }
                                         
                                   	
                                   	}
                                   );
                                   
                                    setComparisonOptions(compArray);
                                    
    

                                  }
                                })
                                 
                                   
},[targetName]);

const callComp=(index)=>{
  
  let newList=tableTargetsNew;
                     targetVitals.forEach(target=>{
                    
                                    if(tableTargetsNew[index].target_name.value === target.vital_name){
                                     

                                      setMin(target.min_value);
                                      setMax(target.max_value);
                                        newList[index].min=target.min_value
                                      newList[index].max=target.max_value
                                      let stepDiff= target.max_value - target.min_value;
                                      let stepCheck=0.1;
                                      if(stepDiff>0 && stepDiff<2){
                                        stepCheck=0.1;
                                      }
                                      else if(stepDiff>0 && stepDiff<10){
                                         stepCheck=0.5;
                                      }
                                      else if(stepDiff>0 && stepDiff>10){
                                         stepCheck=1;
                                      }
                                      else if(stepDiff<0 && stepDiff>-2){
                                        stepCheck=0.1;
                                      }
                                      else if(stepDiff<0 && stepDiff>-10){
                                         stepCheck=0.5;
                                      }
                                      else if(stepDiff<0 && stepDiff<-10){
                                         stepCheck=1;
                                      }
                                      setStep(stepCheck);
                                      setVitalUnit(target.vital_unit);
                                      setNewTargetUnit(target.vital_unit);
                                      setNewTargetName(targetName.value);
                                      setVitalDescription(target.vital_description);
                                      setNewTargetValue(target.min_value);

                                      setTolerance(target.tolerance);
                                      setComparison(target.comparison);
                                      setNewVitalId(target.vital_id);
                                      newList[index].step=stepCheck;
                                      newList[index].vitalUnit=target.vital_unit;
                                      newList[index].newTargetUnit=target.vital_unit;
                                      newList[index].newTargetName=tableTargetsNew[index].target_name.value;
                                      newList[index].vitalDescription=target.vital_description;
                                      newList[index].newTargetValue=target.min_value;
                                        newList[index].tolerance=target.tolerance;
                                          newList[index].comparison=target.comparison;
                                            newList[index].newVitalId=target.vital_id;
                                             newList[index].target_unit=target.vital_unit;
                                      let compArray= [];

                                      let comparisonArray=target.comparison;
                                      compArray= comparisonArray.map(item=>{
                                        if(item==="Greater"){
                                            return ({
                                            value: item,
                                            label: "Min limit"
                                          })
                                        }
                                         else if(item==="Lower"){
                                            return ({
                                            value: item,
                                            label: "Max limit"
                                          })
                                        }
                                         else if(item==="Equal"){
                                            return ({
                                            value: item,
                                            label: "Equals"
                                          })
                                        }
                                         else {
                                            return ({
                                            value: item,
                                            label: item                                          })
                                        }
                                         
                                    
                                    }
                                   );
                                   
                                    setComparisonOptions(compArray);
                                   
                                    newList[index].comparisonOptions=compArray;
                                    setTableTargetsNew(newList)
                                    setRender(!render)
                           
                                  }
                                })                               
                                                       
 }
 console.log(tableTargetsNew)
useEffect(()=>{
	const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
    setToggle(!toggle)
  
    axios.get(`${APIURL}/api/v1/patient/target-vitals/V2/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
  setTargetVitals(res.data.target_vital_details);

  const OptionsArray= [];
 res.data.target_vital_details.map(item=>{
 	return OptionsArray.push({
 		value: item.vital_name,
 		label: item.vital_name
 	})
 })
     
     setTargetOptions(OptionsArray);
  
     

      }

        else{
        setGetError(res.data.message);
      }
      


    })
    .catch(err=>{

 setGetError("Error in fetching data");
})


},[]);

useEffect(()=>{
	const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
  
  
    axios.get(`${APIURL}/api/v1/patient/patient-target/V2/?patient_id=${patientId}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){


    setPatientTargets(res.data.targets.current_targets);  

    setPastTargets(res.data.targets.targets_finished);
    setTargetListCounter(res.data.targets.current_targets.length); 
     let newArray= res.data.targets.current_targets;
    
  setTableTargets(newArray);
  let newArray2=[];
  let newArrayNew=[];

  if( tableTargetsNew.length === 0  ){
    newArrayNew.push(
      {target_name:''}
      
    )
    setTableTargetsNew(newArrayNew);
    
  }
  
  
   newArray2.push({target_name:''});
  setTableTargets2(newArray2);
  
      }
      
        else{
        setGetError(res.data.message);
      }
      


    })
    .catch(err=>{
  setGetError("Error in fetching data");
})


},[patientId,reRender,newTargetName]);



const renderFromDate=(cellInfo)=> {

 

 	return (<h6> {tableTargets[cellInfo.index].from_date}</h6>)
 
}

useEffect(()=> {
  let date =new Date();
const offset = date.getTimezoneOffset();
let dateUpdated = new Date(date.getTime() - (offset*60*1000))
let finalDate = dateUpdated.toISOString().split('T')[0];

setNewFromdate(finalDate)
},[toggle])

const renderFromDate2=(cellInfo)=> {

return (<div className="date-browser">
        
        <DatePicker selected={startDate} 
           minDate = {new Date()}
           value={newFromDate}
           onChange={(date) => 
                                           { 
                                                    let dateUpdated= date;
                                                    setStartDate(date);
                                    if(date!==null){
                                  const offset = date.getTimezoneOffset();

                                  dateUpdated = new Date(date.getTime() - (offset*60*1000))

                                  let DateExtracted = dateUpdated.toISOString().split('T')[0];
                              
                               setNewFromdate(DateExtracted);
                             }

 }} />
        </div>
         )
  
}

const renderToDate=(cellInfo)=> {


 	return (
    <h6>
     
     {tableTargets[cellInfo.index].to_date}
    <span> {" "}</span>
    <i
                     style={{ justifySelf: "start", color: "black" , cursor:"pointer"}}
                     onClick={()=>{handleDateEdit(cellInfo.index)}}
                     className="fas fa-edit"
                     aria-hidden="true"
                   ></i>
    
    </h6>
   );
 
}
  
const renderToDate2=(cellInfo)=> {

 

return (<div className="date-browser">
        
        <DatePicker selected={endDate} minDate = {startDate? startDate: new Date()}
        
           onChange={(date) => 
                                           { 
                                                    let dateUpdated= date;
                                                    setEndDate(date);
                                                   
                                    if(date!==null){
                                  const offset = date.getTimezoneOffset();

                                  dateUpdated = new Date(date.getTime() - (offset*60*1000))

                                  let DateExtracted = dateUpdated.toISOString().split('T')[0];
                              
                               setNewToDate(DateExtracted);
                             }

}} />
        </div>
         )
 
}
  



const renderValue=(cellInfo)=> {

 
 	return (  <div className='target-bubbles'>
     
     {tableTargets[cellInfo.index].target_value ? tableTargets[cellInfo.index].target_value :null}
     <span> {" "}</span>
     <i
                      style={{ justifySelf: "start", color: "white" , cursor:"pointer"}}
                      onClick={()=>{handleTargetEdit(cellInfo.index)}}
                      className="fas fa-edit"
                      aria-hidden="true"
                    ></i>
     
     </div>


 )
 
}

const handleTargetEdit = (cellInfo) => {
  setTargetData(tableTargets[cellInfo])
  setEditTargetShow(true);
};

const closeTargetEdit = () => {
  setEditTargetShow(false);
  setReRender(!reRender);
};

const handleDateEdit = (cellInfo) => {
  setDateData(tableTargets[cellInfo])
  setEditDateShow(true);
};

const closeDateEdit = () => {
  setEditDateShow(false);
  setReRender(!reRender);
};

const handleChangeRadio=(val)=>{
   
    setSelected(val);

   }

const handleChange =(e)=> {
  const val =e.target.value
  if(val.length<=6){
    setNewTargetValue(val)
  } else{
    return false;
} 

}

const renderValue2=(cellInfo)=> {

 
return (<div className="slider-parent">
                 {targetName ? 

                    (<div className="range-slider">
                         <span className="min-max">
                                  <h6 className="text-muted">Min:{min}</h6>
                                  <h6 className="text-muted">Max:{max}</h6>
                         </span>
                         <div className='slider slider-line'>
                         
                          <Slider
                          min={min}
                          max={max}
                       step   ={step}
                          value={newTargetValue}
                          orientation={"horizontal"}
                          onChange={(value)=>{setNewTargetValue(value.toFixed(2));
                                           }}
                          tooltip={true}

                         

                        /><br/>
                        </div>
                         {newTargetValue!== null ? 
                          <div style={{backgroundColor:'transparent',boxShadow:'none',}} className='value bubble'>
                            <input type="number" className="form-group num-position" min={min} max={max} step={step} value={newTargetValue}
                                  onChange={handleChange} />
                          </div>
                          : null }
                       
 

                        
                         
                 </div>)
                 : null}
               
                </div>)
 
}
const renderCurrentValue=(cellInfo)=> {

          
          if(tableTargets[cellInfo.index].current_target_details ){

                if(tableTargets[cellInfo.index].current_target_details.status && tableTargets[cellInfo.index].current_target_details.status=== 2){
                 return <>
                        <div className='target-bubbles green-bubble'>{tableTargets[cellInfo.index].current_target_details.last_updated_value }</div>
                       
                       </>
                }

                else if(tableTargets[cellInfo.index].current_target_details.status=== 0){
                 return <><div className='target-bubbles red-bubble'>{tableTargets[cellInfo.index].current_target_details.last_updated_value} </div></>
                }
                 else if(tableTargets[cellInfo.index].current_target_details.status && tableTargets[cellInfo.index].current_target_details.status=== 1){
                 return <><div className='target-bubbles yellow-bubble'>{tableTargets[cellInfo.index].current_target_details.last_updated_value} </div></>
                }

                 else {
          return <><div className='target-bubbles neutral-bubble'> {tableTargets[cellInfo.index].current_target_details.last_updated_value} </div></>
        }

          }

          
       





         
          else{
              return (
              <div className='target-bubbles empty-bubble'> Nil</div>
              )
          }
   
  }

/***********************************/

const renderLastUpdatedDate=(cellInfo)=> {

   
          if(tableTargets[cellInfo.index].current_target_details && tableTargets[cellInfo.index].current_target_details.last_updated_date){
                return <>
                        
                        <h6 style={{color:"grey"}}>{tableTargets[cellInfo.index].current_target_details.last_updated_date.slice(0,10)}</h6>
                       </>

          } 

              



         
          else{
              return (
              <div > Nil</div>
              )
          }
   
  }

  const renderFitData=(cellInfo)=> {

   
    if(tableTargets[cellInfo.index].current_target_details && tableTargets[cellInfo.index].current_target_details.is_wearables!==null){
          return <>
                  
                  <h6 style={{color:"grey"}}>{tableTargets[cellInfo.index].current_target_details.is_wearables? 'Yes' : 'No'}</h6>
                 </>

    } 

        



   
    else{
        return (
        <div > -</div>
        )
    }

} 



const renderRemarks=(cellInfo)=> {

   
          
          if(tableTargets[cellInfo.index].current_target_details && tableTargets[cellInfo.index].current_target_details.dates_taken){
                return <div className="remarks-target">
                        
                         <h6 style={{color:"grey"}}> No. of days considered <br/> to get the average <br/>value(current value) = {tableTargets[cellInfo.index].current_target_details.dates_taken.dates_taken.length}</h6>
                       </div>

          } 

              



         
          else{
              return (
              <div >  Nil</div>
               )
          }
   
  }
/***********************************************/

const renderUnit=(cellInfo)=> {


 	return (<h6 className="text-muted"> {tableTargets[cellInfo.index].target_unit}</h6>)
 
} 

const renderUnit2=(cellInfo)=> {

 
return <><h6>{vitalUnit} </h6>
      
</>

 
}    

const handleTolerance=e=>{
  e.preventDefault();
  setTolerance(e.target.value);
}
const renderTolerance=(cellInfo)=> {

 
  return (<h6 className="text-muted"> +/- {tableTargets[cellInfo.index].tolerance}</h6>)
 
}

const renderTolerance2=(cellInfo)=> {

 
  return (<input type="number" className="form-group tolerance-input" min="0"  value={tolerance} onChange={handleTolerance}/> )
 
}

const renderTargetStatus=(cellInfo)=>{


        if(tableTargets[cellInfo.index].current_target_details && tableTargets[cellInfo.index].current_target_details.status && tableTargets[cellInfo.index].current_target_details.description){
             
                if(tableTargets[cellInfo.index].current_target_details.status=== 2){
                 return <h6 style={{color:"green"}}> {tableTargets[cellInfo.index].current_target_details.description}</h6>

                }

                else if(tableTargets[cellInfo.index].current_target_details.status=== 0){
                 return <h6 style={{color:"red"}}> {tableTargets[cellInfo.index].current_target_details.description}</h6>
                }
                else if(tableTargets[cellInfo.index].current_target_details.status=== 1){
                 return <h6 style={{color:"orange"}}>{tableTargets[cellInfo.index].current_target_details.description} </h6>
                }
                else{
          return <h6> - </h6>
            }
       
          }else if(tableTargets[cellInfo.index].current_target_details && tableTargets[cellInfo.index].current_target_details.status=== 0 && tableTargets[cellInfo.index].current_target_details.description){
          return <h6 style={{color:"red"}}> {tableTargets[cellInfo.index].current_target_details.description} </h6>
                }
           else{
          return <h6> - </h6>
            }

        
        

      
 
}
/*const renderAchievedStatus=(cellInfo)=> {

 if(cellInfo.index > targetListCounter-1){
return <h6 className="text-muted"> N/A </h6>
 }

 else{
 	return (<h6> {tableTargets[cellInfo.index].achieved ? <h6 style={{color:"green"}}>Acheived</h6> : <h6 style={{color:"red"}}>Not Achieved</h6>}</h6>)
 }
}*/


const renderTargetName=(cellInfo)=> {

 
return (<h6> {tableTargets[cellInfo.index].target_name}</h6>)


	
    
}

const renderDr=(cellInfo)=> {

 
return (<>
   <h6> {tableTargets[cellInfo.index].created_by.creator_name ? tableTargets[cellInfo.index].created_by.creator_name : "-"}</h6>
   <h6> {tableTargets[cellInfo.index].created_by.hospital_name ? tableTargets[cellInfo.index].created_by.hospital_name : null}</h6>
   </>)


  
    
}
 const renderTargetName2=(cellInfo)=> {
     return (
           <div className='select-target'>
                  <Select
                    value={targetName}
                    onChange={(data)=>{
                                        
                         setTargetName(data);
                       }}
                    options={targetOptions}
                    className="select-frequency"
                  />
           </div>
      )

}



const renderComparison=(cellInfo)=> {

  if(tableTargets[cellInfo.index].comparison==="Greater"){
                                            return (<h6> Min limit</h6>)
                                        }
                                         else if(tableTargets[cellInfo.index].comparison==="Lower"){
                                            return (<h6> Max limit</h6>)
                                        }
                                         else if(tableTargets[cellInfo.index].comparison==="Equal"){
                                            return (<h6> Equals</h6>)
                                        }
                                         else {
                                           
   
return (<h6> {tableTargets[cellInfo.index].comparison}</h6>)


  
    
}
}


const renderComparison2=(cellInfo)=> {

 
    return (
           <div className='select-frequency'>
                  <Select
                  
                       value={compValue}
                       onChange={(data)=>{
                     
                      setCompValue(data);
                                
                       }}
                    options={comparisonOptions}
                    className=""
                  />


                



               </div>
      )

}
const deleteRow=(i)=>{

let targetIDToDelete=null;
patientTargets.map((item,index)=>{
  
  if(index=== i){

  setTargetToDelete(item.target_id);
  targetIDToDelete=item.target_id
  }
 })

   const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
    

  
    axios.delete(`${APIURL}/api/v1/patient/patient-target/V2/?patient_id=${patientId}&target_id=${targetIDToDelete}`, { headers: {
      
      "Authorization" : 'Token '+str,
      'Content-Type': 'application/json'  }
   })
    .then(res=>{
    
      if(res.data.status ==="success"){
       
        setDeleteSubmitMsg("Target deleted successfully!");
          setDeleteSubmit(true);
      
      

      
   


      }else{
        setErrorMsg(res.data.message);
              setErrorSubmit(true);
              
      }


    })
    .catch(err=>{
   
  setErrorMsg("Error in submission");
                setErrorSubmit(true);
                setReRender(!reRender);
})



}

const renderTable=(cellInfo)=> {

    return (

      <div > {pastTargets[cellInfo.index][cellInfo.column.id]}</div>
      
    );
  }
const renderStatus=(cellInfo)=> {
  
    return (

      <div > {pastTargets[cellInfo.index][cellInfo.column.id]===true? <h6 style={{color:"green"}}>Acheived</h6> : <h6 style={{color:"red"}}>Not Achieved</h6>}</div>
      
    );
  }



  /*******************set table fns******/
const renderToDateN=(cellInfo)=> {
let end_Date=tableTargetsNew[cellInfo.index].endDate;

 let start_Date=tableTargetsNew[cellInfo.index].startDate;
return (<div className="date-browser">
        
        <DatePicker selected={end_Date} minDate = {start_Date? start_Date: new Date()}
        value={tableTargetsNew[cellInfo.index].newToDate}
           onChange={(date) => 
                                           { 
                                                    let dateUpdated= date;
                                                    let list=tableTargetsNew;
                                                     list[cellInfo.index].endDate=date;
                                                   
                                                   
                                    if(date!==null){
                                  const offset = date.getTimezoneOffset();

                                  dateUpdated = new Date(date.getTime() - (offset*60*1000))

                                  let DateExtracted = dateUpdated.toISOString().split('T')[0];
                              
                               
                                list[cellInfo.index].newToDate=DateExtracted;
                             
                                 setTableTargetsNew(list);
                                 setRender(!render)
                             }

}} />
        </div>
         )
 
}
  

  const renderFromDateN=(cellInfo)=> {

return (<div className="date-browser">
        
        <DatePicker selected={tableTargetsNew[cellInfo.index].startDate} 
           minDate = {new Date()}
           value={tableTargetsNew[cellInfo.index].newFromDate}
           onChange={(date) => 
                                           { 
                                                    let dateUpdated= date;
                                                    let list=tableTargetsNew;
                                                     list[cellInfo.index].startDate=date;
                                                    setStartDate(date)
                                    if(date!==null){
                                  const offset = date.getTimezoneOffset();

                                  dateUpdated = new Date(date.getTime() - (offset*60*1000))

                                  let DateExtracted = dateUpdated.toISOString().split('T')[0];
                              
                              list[cellInfo.index].newFromDate=DateExtracted;
                             
                              

                              setTableTargetsNew(list);
                              
                             }

 }} />
        </div>
         )
  
}


const handleToleranceN=(e,index)=>{
  e.preventDefault();
  let list=tableTargetsNew;
  list[index].tolerance=e.target.value;
  setTableTargetsNew(list);
  setRender(!render)
  
}
const renderUnitN=(cellInfo)=> {


  return (<h6 className="text-muted"> {tableTargetsNew[cellInfo.index].target_unit}</h6>)

//   return (
//     {
//       tableTargetsNew[cellInfo.index].target_name ?
       
//       <div className='select-target'>
//       <Select
//         value={tableTargetsNew[cellInfo.index].target_unit}
// onChange={(data)=>{
//  const List = tableTargetsNew;
//  List[cellInfo.index].target_unit = data;

 
 
//     setTableTargetsNew(List);
 
  
//     //callComp(cellInfo.index);


// }}


     
//         options={unitOptions}
//         className="select-frequency"
//       />
// </div>

//   :  null

//     }
   
// )
 
} 

const renderToleranceN=(cellInfo)=> {

 let idx=cellInfo.index
  return (<input type="number" className="form-group tolerance-input" min="0"  value={tableTargetsNew[cellInfo.index].tolerance} onChange={(e)=>handleToleranceN(e,idx)}/> )
 
}
  const deleteRowN=(index)=>{
  
  const newList = tableTargetsNew.filter((elt,i)=>{
    return i!== index
  })
if(newList.length===0){
   newList.push(  
  {target_name:null,
    newTargetName:null,
    newTargetUnit:null,
    newTargetValue:null,
    newVitalId:null,
    min:null,
    max:null,
    comparisonOptions:null,
    comparison:null,
    compValue:null,
    step:null,
    tolerance:null,
    vitalDescription: null,
    vitalUnit: null,
    startDate:null,
    endDate:null,
    newFromDate:null,
    newToDate:null,
   target_unit:null


   });
  
}
setTableTargetsNew(newList);
 
}

const AddRow=()=>{
  const list = tableTargetsNew;
  list.push(  
  {target_name:null,
    newTargetName:null,
    newTargetUnit:null,
    newTargetValue:null,
    newVitalId:null,
    min:null,
    max:null,
    comparisonOptions:null,
    comparison:null,
    compValue:null,
    step:null,
    tolerance:null,
    vitalDescription: null,
    vitalUnit: null,
    startDate:null,
    endDate:null,
    newFromDate:null,
    newToDate:null,
    target_unit:null


   });
  
  setTableTargetsNew(list);
  setRender(!render);

  
}

const renderTargetNameN=(cellInfo)=> {

     return (
           <div className='select-target'>
                  <Select
                    value={tableTargetsNew[cellInfo.index].target_name}
        onChange={(data)=>{
             const List = tableTargetsNew;
             List[cellInfo.index].target_name = data;
            
             
             
                setTableTargetsNew(List);
             
              
                callComp(cellInfo.index);


            }}


                 
                    options={targetOptions}
                    className="select-frequency"
                  />
           </div>
      )
    

}


const renderComparisonN=(cellInfo)=> {

   if(cellInfo.index > tableTargetsNew.length -1 || tableTargetsNew.length !==0){
 
    return (
           <div className='select-frequency'>
                    <Select
                    value={tableTargetsNew[cellInfo.index].compValue}
        onChange={(data)=>{
             const List = tableTargetsNew;
             List[cellInfo.index].compValue = data;
            
             
             
                setTableTargetsNew(List);
             setRender(!render);
            }}
                    options={tableTargetsNew[cellInfo.index].comparisonOptions}
                    className=""
                  />

               </div>
      )
}

}


const renderValueN=(cellInfo)=> {

 
return (<div className="slider-parent">
                 {tableTargetsNew[cellInfo.index].target_name ? 

                    (<div className="range-slider">
                         <span className="min-max">
                                  <h6 className="text-muted">Min:{tableTargetsNew[cellInfo.index].min}</h6>
                                  <h6 className="text-muted">Max:{tableTargetsNew[cellInfo.index].max}</h6>
                         </span>
                         <div className='slider slider-line'>
                         
                          <Slider
                          min={tableTargetsNew[cellInfo.index].min}
                          max={tableTargetsNew[cellInfo.index].max}
                       step   ={step}
                          value={tableTargetsNew[cellInfo.index].newTargetValue}
                          orientation={"horizontal"}
                          onChange={(value)=>{
                            const List = tableTargetsNew;
             List[cellInfo.index].newTargetValue = value.toFixed(2);
            
             
             
                setTableTargetsNew(List);
             setRender(!render);
                               
                                           }}
                          tooltip={true}

                         

                        /><br/>
                        </div>
                         {tableTargetsNew[cellInfo.index].newTargetValue!== null ? 
                          <div style={{backgroundColor:'transparent',boxShadow:'none',}} className='value bubble'>
                            <input type="number" className="form-group num-position" min={tableTargetsNew[cellInfo.index].min} max={tableTargetsNew[cellInfo.index].max} step={step} value={tableTargetsNew[cellInfo.index].newTargetValue}
                                  onChange={(e)=>handleChangeN(e,cellInfo.index)} />
                          </div>
                          : null }
                       
 

                        
                         
                 </div>)
                 : null}
               
                </div>)
 
}
const handleChangeN=(e,index)=> {
  const val =e.target.value
  if(val.length<=6){
   
   const List = tableTargetsNew;
             List[index].newTargetValue = val;
            
             
             
                setTableTargetsNew(List);
             setRender(!render);

  } else{
    return false;
} 

}


  /*******set fns -ends********/
 const columns = [
{
  Header: "Target Name",
  accessor: "target_name",
  Cell: renderTargetName,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },

  minWidth:250,

   sortable:false

},
{
  Header: "Limit",
  accessor: "comparison",
  Cell: renderComparison,
   sortable:false,
   width:100

},
{
  Header: <><h6>Target</h6>
           <h6> value</h6></>,
  accessor: "target_value",
  Cell: renderValue,
   style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
   sortable:false,
   width:100

},
{
  Header: "Unit",
  accessor: "target_unit",
  Cell: renderUnit,
   sortable:false,
   width:80

},
{
  Header: <><h6>Tolerance</h6>
            <h6> level for</h6>
           <h6> Status check</h6></>,
  accessor: "tolerance",
  Cell: renderTolerance,
   sortable:false,
   width:90

},
{
  Header: <><h6>Latest</h6>
           <h6> value</h6></>,
 
  Cell: renderCurrentValue,
   style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
   sortable:false,
   width:100

},

{
  Header: <><h6>Latest value</h6>
           <h6> updated on</h6></>,
 
  Cell: renderLastUpdatedDate,
   sortable:false,
   width:100

},
{
  Header: <><h6>Smart Device</h6>
           <h6> data</h6></>,
 
  Cell: renderFitData,
   sortable:false,
   width:110

},
{
  Header: <><h6>Remarks</h6>
          </>,
 
  Cell: renderRemarks,
   sortable:false,
   width:160

},

{
  Header: "Starts",
  accessor: "date",
  Cell: renderFromDate,
   sortable:false,
   minWidth:100,
  
},
{
  Header: "Ends",
  accessor: "date",
  Cell: renderToDate,
   sortable:false,
   minWidth:150
 

},

{
  Header: "Status",
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  Cell: renderTargetStatus,
   sortable:false,
   width:120

},
{
  Header: "Set by Doctor",
  
  Cell: renderDr,
   sortable:false,
   minWidth:220

},

{
  Header: "Delete",
   Cell: props=>{
    
   
      return (
    <button className="delete-btn" 
       onClick={()=>{deleteRow(props.index)}}> X </button>
    
    )
  
  },
  sortable:false,
  filterable:false
}







]


const setColumns = [
{
  Header: "*Target Name",
  accessor: "target_name",
  Cell: renderTargetName2,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:250,
  minWidth:100,
   sortable:false

},
{
  Header: "*Comparison criteria",
  accessor: "target_value",
  Cell: renderComparison2,
   sortable:false,
      minWidth:140
   

},
{
  Header: "*Target Value",
  accessor: "target_value",
  Cell: renderValue2,
   sortable:false,
   minWidth:200
  

},
{
  Header: "Unit*",
  accessor: "target_unit",
  Cell: renderUnit2,
   sortable:false,
  
},
{
  Header: "Tolerance*",
  accessor: "tolerance",
  Cell: renderTolerance2,
   sortable:false,
    width:100
  
},
{
  Header: "*From Date",
  accessor: "date",
  Cell: renderFromDate2,
   sortable:false,
   minWidth:160

  
  
},
{
  Header: "*To Date",
  accessor: "date",
  Cell: renderToDate2,
   sortable:false,
   minWidth:160
  

},
{
  Header: "Save",
   Cell: props=>{
    
   
    return (<button className="btn btn-primary btn-col btn-submit btn-small" onClick={handleSubmit} > Save </button>
   )
  
  },
  sortable:false,
  filterable:false,
  width:100
}









]

/********set table new**********/

const setColumnsNew = [
{
  Header: "*Target Name",
  accessor: "target_name",
  Cell: renderTargetNameN,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:250,
  minWidth:100,
   sortable:false

},
{
  Header: "*Comparison criteria",
  accessor: "target_value",
  Cell: renderComparisonN,
   sortable:false,
      minWidth:140
   

},

{
  Header: "*Target Value",
  accessor: "target_value",
  Cell: renderValueN,
   sortable:false,
   minWidth:200
  

},

{
  Header: "Unit*",
  accessor: "target_unit",
  Cell: renderUnitN,
   sortable:false,
  
},
{
  Header: "Tolerance*",
  accessor: "tolerance",
  Cell: renderToleranceN,
   sortable:false,
    width:100
  
},
{
  Header: "*From Date",
  accessor: "date",
  Cell: renderFromDateN,
   sortable:false,
   minWidth:160

  
  
},
{
  Header: "*To Date",
  accessor: "date",
  Cell: renderToDateN,
   sortable:false,
   minWidth:160
  

},

{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "Delete Row"><i className="fas fa-trash-alt"></i></span>,
  Cell: props=>{
    return (
    <button className="delete-btn" 
       onClick={()=>{deleteRowN(props.index)}}> <i className="fas fa-trash-alt"></i> </button>
    )
  },
  sortable:false,
  filterable:false,
  minWidth:50,
  width:50
},

{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "Add a New Row">ADD</span> ,
  Cell: props=>{
    return (
    props.index === tableTargetsNew.length- 1 ? 
    <button className="btn btn-primary btn-col btn-small" onClick={AddRow}> + Row</button> 
    : null 
    )
  },
  sortable:false,
  filterable:false,
  minWidth:50,
  width:100
}



]



/**********set table new -ends *********/

const columnsPast = [
{
  Header: "Target Name",
  accessor: "target_name",
  Cell: renderTable,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:250,
  minWidth:100,
  sortable:false

},{
  Header: "Target Value",
  accessor: "target_value",
  Cell: renderTable,
   sortable:false,
  

},
{
  Header: "Unit",
  accessor: "target_unit",
  Cell: renderTable,
   sortable:false,
   width:80

},
{
  Header: "From Date",
  accessor: "from_date",
  Cell: renderTable,
   sortable:false,
  
  
},
{
  Header: "To Date",
  accessor: "to_date",
 Cell: renderTable,
   sortable:false,
   

},
{
  Header: "Status",
  accessor: "achieved",
  Cell: renderStatus,
   sortable:false,
  

}







]

const validate=()=>{
   let isValid = true;
 
  if(newFromDate>newToDate){
    isValid=false;
    setErrorMsg("End date must not exceed From Date");
     setErrorSubmit(true);
  }
  
  if(newToDate=== null || newToDate==="" || typeof newToDate === "undefined"){
     isValid=false;
    setErrorMsg("End Date is mandatory");
     setErrorSubmit(true);
  }
if(newFromDate=== null || newFromDate==="" || typeof newFromDate === "undefined"){
     isValid=false;
    setErrorMsg("Start Date is mandatory");
     setErrorSubmit(true);
  }
 
  

   if(newTargetValue==="" || newTargetValue===null || typeof newTargetValue==="undefined"){
      isValid=false;
    setErrorMsg("Target Value is mandatory");
     setErrorSubmit(true);
  }
  if(newTargetValue > max ){
    isValid=false;
    setErrorMsg(`Target Value has exceeded the maximum limit ${max}`);
     setErrorSubmit(true);
  }
  if(newTargetValue < min ){
    isValid=false;
    setErrorMsg(`Target Value should be greater than ${min}`);
     setErrorSubmit(true);
  }
  if(compValue==="" || compValue===null || typeof compValue==="undefined"){
      isValid=false;
    setErrorMsg("Comparison criteria is mandatory");
     setErrorSubmit(true);
  }
   if(tolerance==="" || tolerance===null || typeof tolerance==="undefined"){
      isValid=false;
    setErrorMsg("Tolerance level (for status checking) is mandatory");
     setErrorSubmit(true);

  }
    if(newTargetName==="" || newTargetName===null || typeof newTargetName==="undefined"){
      isValid=false;
    setErrorMsg("Target Name is mandatory");
     setErrorSubmit(true);
  }
   if(newTargetName==="" && newTargetValue=== null && compValue === null && typeof startDate==="undefined" && typeof endDate==="undefined"){
      isValid=false;
    setErrorMsg("**Target Name, Target Value, Comparison criteria, From Date and To Date are mandatory fields");
     setErrorSubmit(true);
  }


  return isValid;
}


const validateTargets=()=>{
   let isValid = true;
 tableTargetsNew.forEach((item,index)=>{
  if(item.newFromDate>item.newToDate){
    isValid=false;
    setErrorMsg(`End date must not exceed From Date at row ${index+1} `);
     setErrorSubmit(true);
  }
  
  if(item.newToDate=== null || item.newToDate==="" || typeof item.newToDate === "undefined"){
     isValid=false;
    setErrorMsg(`To Date is mandatory at row ${index+1} `);
     setErrorSubmit(true);
  }
if(item.newFromDate=== null || item.newFromDate==="" || typeof item.newFromDate === "undefined"){
     isValid=false;
    setErrorMsg(`From Date is mandatory at row ${index+1} `);
     setErrorSubmit(true);
  }
 
  

   if(item.newTargetValue==="" || item.newTargetValue===null || typeof item.newTargetValue==="undefined"){
      isValid=false;
    setErrorMsg(`Target Value is mandatory at row ${index+1} `);
     setErrorSubmit(true);
  }
  if(item.newTargetValue > item.max ){
    isValid=false;
    setErrorMsg(`Target Value has exceeded the maximum limit ${item.max} at row ${index+1} `);
     setErrorSubmit(true);
  }
  if(item.newTargetValue < item.min ){
    isValid=false;
    setErrorMsg(`Target Value should be greater than ${item.min} at row ${index+1} `);
     setErrorSubmit(true);
  }
  if(item.compValue==="" || item.compValue===null || typeof item.compValue==="undefined"){
      isValid=false;
    setErrorMsg(`Comparison criteria is mandatory at row ${index+1} `);
     setErrorSubmit(true);
  }
   if(item.tolerance==="" || item.tolerance===null || typeof item.tolerance==="undefined"){
      isValid=false;
    setErrorMsg(`Tolerance level (for status checking) is mandatory at row ${index+1} `);
     setErrorSubmit(true);

  }
    if(item.newTargetName==="" || item.newTargetName===null || typeof item.newTargetName==="undefined"){
      isValid=false;
    setErrorMsg(`Target Name is mandatory at row ${index+1} `);
     setErrorSubmit(true);
  }
   if(item.newTargetName==="" && item.newTargetValue=== null && item.compValue === null && typeof item.startDate==="undefined" && typeof item.endDate==="undefined"){
      isValid=false;
    setErrorMsg(`*Target Name, Target Value, Comparison criteria, From Date and To Date are mandatory fields at row ${index+1} `);
     setErrorSubmit(true);
  }

})
  return isValid;
}

const handleAdd=()=>{

}


/*************multi targets submit -starts**********/
  const handleSubmitTargets=()=>{
    if(validateTargets()){
    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
         
        const dataArrayToSend= tableTargetsNew.map((item,index)=> {
          return {
            target_vital_id:item.newVitalId,
         
        target_value: item.newTargetValue,
        from_date:item.newFromDate,
        to_date: item.newToDate,
        life_long: false,
         comparison: item.compValue.value,
         tolerance:item.tolerance,

          }
         })
  
  
    axios.post(`${APIURL}/api/v1/patient/patient-target/V2/?multiple=True&patient_id=${patientId}`,dataArrayToSend, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
                                    
                                     
       setSubmitMsg("Target added successfully!");

          setSuccessSubmit(true);
          setTableTargetsNew([])



      }else{
        setErrorMsg(res.data.message);
              setErrorSubmit(true);
                
      }


    })
    .catch(err=>{
   
  setErrorMsg("Error in submission");
  
              setErrorSubmit(true);
               
})
  
  }
}





/***********multi targets submit -ends***********/
  const handleSubmit=()=>{
    if(validate()){
    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
  const dataToSend={
    target_vital_id:newVitalId,
         
        target_value: newTargetValue,
        from_date:newFromDate,
        to_date: newToDate,
        life_long: newLifeLong,
         comparison: compValue.value,
         tolerance:tolerance,

  }    
  
    axios.post(`${APIURL}/api/v1/patient/patient-target/V2/?patient_id=${patientId}`,dataToSend, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
                                      setMin("");
                                      setMax("");
                                      setVitalUnit("");
                                      setNewTargetUnit("");
                                      setNewTargetName("");
                                      setVitalDescription("");
                                      setNewVitalId();
                                      setTargetName('');
                                      setNewFromdate();
                                      setNewToDate();
                                      setEndDate();
                                      setStartDate();
                                      setTolerance(null);
                                      setComparison([]);
                                      setCompValue(null);
                                      setToggle(!toggle)
       setSubmitMsg("Target added successfully!");

          setSuccessSubmit(true);
   



      }else{
        setErrorMsg(res.data.message);
              setErrorSubmit(true);
                
      }


    })
    .catch(err=>{
   
  setErrorMsg("Error in submission");
  
              setErrorSubmit(true);
               
})
  }
  }


  const EditTargetModal = (props) => {
    ;
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditTarget
            id={patientId}
            data={targetData}
            onHide={closeTargetEdit}
            // submitweight={submitweightHandle}
            // seteditweight={weight}
            // onHide={PatientDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
          {/* <Button variant="secondary" > */}

            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const EditDateModal = (props) => {
    ;
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditDate
            id={patientId}
            data={dateData}
            onHide={closeDateEdit}
            // submitweight={submitweightHandle}
            // seteditweight={weight}
            // onHide={PatientDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
          {/* <Button variant="secondary" > */}

            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Data Submission. Please try again!" } </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}

    const rTable=  <ReactTable 
         columns={columns}
         data={tableTargets}
         defaultPageSize = {5}
         className="current-target-table"
         resizable
                           
                
         >

         </ReactTable> 



  const setTable=  <ReactTable 
         columns={setColumns}
         data={tableTargets2}
         defaultPageSize = {1}
         
         resizable
         className="setTarget-table"
                           
                 
         >

         </ReactTable> 

const setTableNew=  <ReactTable 
         columns={setColumnsNew}
         data={tableTargetsNew}
         pageSize = {tableTargetsNew.length}
         showPagination={false}
         resizable
         className="setTarget-table"
                           
                 
         >

         </ReactTable> 




  const pastTargetsTable= <ReactTable 
         columns={columnsPast}
         data={pastTargets}
         defaultPageSize = {10}
         className="past-target-table"
         resizable
                           
                 
         >

         </ReactTable> 


   const displaySelected=selected===1 ?
             <div className="">
               <h2 className="title-of-page"> Current/Active Targets</h2>
              <div className="text-center">
        <div> {rTable} 
        </div>
           </div>
       
        </div> 
        : 
        selected ===2 ?
        <div className="">
        <h2 className="title-of-page"> Set Targets</h2>
              <div className="text-center">
        <div>
        {setTableNew}
         </div>
         <br/>
          <button className="btn btn-primary btn-col" onClick={handleSubmitTargets} > Submit </button>
           </div>
       
        </div> 
        : selected===3 ?
          <div className="">
          <h2 className="title-of-page"> Past Targets</h2>
              <div className="text-center">
        <div>
         {pastTargetsTable}
        </div>
           </div>
       
        </div>
                  : null

  return (<>


{getError==="" ?
(<>

<div className="flex-col">   
{/**** <h2 className="additional-text"> Target Tables</h2>***/}

   
 <ToggleButtonGroup type="radio" name="options" defaultValue={selected} onChange={handleChangeRadio}>
    <ToggleButton id="tbg-radio-1" value={1} className="btn btn-light toggle-btns toggle-btn-1">
     Current Targets
    </ToggleButton>
    <ToggleButton id="tbg-radio-2" value={2} className="btn btn-light toggle-btns toggle-btn-2">
     Set Targets 
    </ToggleButton>
     <ToggleButton id="tbg-radio-2" value={3} className="btn btn-light toggle-btns toggle-btn-4">
     Past Targets
    </ToggleButton>
   
    
    
  </ToggleButtonGroup>
  </div>
   <div className="target-overlay">
   
   {displaySelected}

{/**      <h2 className="title-of-tasks"> Current/Active Targets</h2>
      <div className="text-center">
        <div> {rTable} 
        </div>
        <br/>
         <div className="divider"></div>
         <h2 className="title-of-tasks"> Set New Target</h2>
       {/**** <div> {setTable}
        </div>****/}

{/***
         <div> {setTableNew}
        </div>
        <br/>
        <button className="btn btn-primary btn-col" onClick={props.onHide} > Exit </button>
      </div>
        <br/>
         <div className="divider"></div>
         <h2 className="title-of-tasks"> Past Targets </h2>
        <div> {pastTargetsTable} </div>
        <br/>
      {/*  <button className="btn btn-primary btn-col btn-submit" onClick={handleSubmit} > Submit </button>*/}
</div>



  {errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                        setErrorMsg('');
                       }}
       
      />: ''
    }
{/* {editShow ? (
            <ObservationsPopup
              show={editShow}
              onHide={() => setEditShow(false)}
              // id={id}
            />
          ) : null} */}

    {submitMsg !== "" ?
    <SuccessPopup
        show={successSubmit}
        msg={submitMsg}
        onHide={() => { setSuccessSubmit(false);
                        setSubmitMsg('');
                       setReRender(!reRender)}}
       
      />: ''}


{editTargetShow ? (
        <EditTargetModal
          show={editTargetShow}
          // seteditweight={weight}
          onHide={() => {
            setEditTargetShow(false);
          }}
        />
      ) : null}

{editDateShow ? (
        <EditDateModal
          show={editDateShow}
          // seteditweight={weight}
          onHide={() => {
            setEditDateShow(false);
          }}
        />
      ) : null}

      {deleteSubmitMsg !== "" ?
    <DeleteSuccessPopup
        show={deleteSubmit}
        msg={deleteSubmitMsg}
        onHide={() =>  {setDeleteSubmit(false);
                        setDeleteSubmitMsg('');
                         setReRender(!reRender);
                         }}
       
      />: ''}

 </>)
 :  
  <p style={{color:"red"}}> {getError}</p>}

    </>
  );
}
