import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link,useHistory } from "react-router-dom";
import "./searchhosp.css";
import Pagination from "react-js-pagination";
import "./pagination.css";
import { Button, Modal } from 'react-bootstrap';
import {ToggleButton} from 'react-bootstrap';
import {ToggleButtonGroup} from 'react-bootstrap';
//import {OverlayTrigger} from 'react-bootstrap';

//import {Tooltip} from 'react-bootstrap';

import {APIURL} from "../../../Global";
function SearchHospital() {

    const [list ,setList] =useState([]);
    //const [list1 ,setList1] =useState([]);
    //const [list2 ,setList2] =useState([]);
    const [searchTerm ,setSearchTerm] =useState('');
    const [category ,setCategory] =useState('individual_doctors');
    //const [filter ,setFilter] =useState('All');
    const [refresh ,setRefresh] =useState(false);
    const [modalShow ,setModalShow] =useState(false);
    const [enableShow ,setEnableShow] =useState(false);
    const [disableShow ,setDisableShow] =useState(false);
    const [deleteShow ,setDeleteShow] =useState(false);
    const [id ,setId] =useState();
    const [param ,setParam] =useState('');
    const [load, setLoad]=useState('')
    const [currentPage, setCurrentPage]= useState(1)
    const [totalPages, setTotalPages]= useState(0)
    const [totalRecords, setTotalRecords]= useState(0)

    //let resultDisplay=null;

    const history =useHistory();

const [keys,] = useState({'h': false,'d': false,'r': false,'l': false,'i': false,'a': false,'p': false,'v': false,'t': false,'n': false});


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['h']){
   history.push('/hospitallist')
 }
 if(e.altKey && keys['d']){
   history.push('/doctorslist')
 }
 if(e.altKey && keys['r']){
   history.push('/rejectedlist')
 }
 if(e.altKey && keys['l']){
   history.push('/reportlist')
 }
 if(e.altKey && keys['i']){
   history.push('/addsynonyms')
 }
 if(e.altKey && keys['a']){
   history.push('/failedlist')
 }
 if(e.altKey && keys['p']){
   history.push('/prescriptionlist')
 }
 if(e.altKey && keys['v']){
   history.push('/written-prescriptionlist')
 }
 if(e.altKey && keys['t']){
   history.push('/testlist')
 }
 if(e.altKey && keys['n']){
  history.push('/nutrition')
}

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);


   /* useEffect(()=>{
        
        resultDisplay= <>{list.length>0 ?
                  <div >
                  <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing results in </p>{'  '}
                   <h3 style={{display:'inline-block'}} className=" additional-text">Doctors</h3>
                   </div> : null}
                
                  {list.length>0 ?
                  
                  display : null}
      
                {category==='individual_doctors' ? searchTerm!==null && searchTerm!=="" && list.length <1 && <h6 style={{color:"red"}}>No results found in doctors</h6> :null} 
      
                {<br/>}
      
                 {list1.length>0 ?
                <div>
                  <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing results in </p>{'  '}
                  <h3 style={{display:'inline-block'}} className="additional-text">Hospitals</h3></div> : null}
                  
      
                {list1.length>0 ?
                  display1 : null}
      
                {category==='hospitals' ? searchTerm!==null && searchTerm!=="" && list1.length <1 && <h6 style={{color:"red"}}>No results found in hospitals</h6> : null} 
      
                  {<br/>}
                {list2.length>0 ?
                <div>
                  <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing results in </p>{'  '}
                  <h3 style={{display:'inline-block'}} className="additional-text">Patients</h3></div> : null}
                  
      
                {list2.length>0 ?
                  display2 : null}
    
                {category==='patient' ? searchTerm!==null && searchTerm!=="" &&  list2.length <1 && <h6 style={{color:"red"}}>No results found in patients</h6> : null}  
      
                {category===''? searchTerm!==null && searchTerm!=="" &&  list.length<1 && <h6 style={{color:"red"}}>No results found</h6> : null}
                </>
       
        },[list,searchTerm,category]); */

    const handleSearchChange= (e)=> {
        e.preventDefault();
        setSearchTerm(e.target.value)
        setCurrentPage(1)
    }

    /*const handleFilter =(value) =>{
        setCategory(value)
        setList([]);
        setList1([]);
        setList2([]);
    }*/

    const handleChangeRadio=(val)=>{
      setCurrentPage(1)
      setCategory(val);
  
     }

    const handlePageChange = (pageNumber)=> {
      setCurrentPage(pageNumber)
    }

    useEffect(()=>{
      setLoad('Loading...')
        const tokenString = localStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );

        axios.get(`${APIURL}/api/v1/staff/disable_delete_search/?q=${searchTerm}&category=${category}&per_page=10&page=${currentPage}`, {
             headers: { Authorization: "Token " + v2 },
        })
        .then((res) =>{
        if(res.data.status==='success'){
            let data= res.data.result
            setTotalPages(res.data.result[0].pagination.total_pages)
            setTotalRecords(res.data.result[0].pagination.total_records)

            if(data.length>0){
              if(data[0].category==='individual_doctors'){
                setList(data[0].individual_doctors)
              }
              else if(data[0].category==='hospitals'){
                setList(data[0].hospitals)
              }
              else if(data[0].category==='patient'){
                setList(data[0].patient)
              }
            }          
            else{
              
              setList([])
              setLoad('No results found')
            }

           /* let check =[]
           data.map((item)=>{
             check.push(item.category)
           })
           
            if(data.length>0){
                if(category===''){
                    data.map((item)=>{                     
                        if(item.category==='individual_doctors'){
                            setList(item.individual_doctors)
                        }
                        if(item.category==='hospitals'){
                            setList1(item.hospitals)
                        }
                        if(item.category==='patient'){
                            setList2(item.patient)
                        }
                          if(check.includes('individual_doctors')===false){
                            setList([])
                          }
                          if(check.includes('hospitals')===false){
                            setList1([])
                          }
                          if(check.includes('patient')===false){
                            setList2([])
                          }
           
                    })
                }
                else if(category==='individual_doctors'){
                    setList(res.data.result[0].individual_doctors)                             
                }
                else if(category==='hospitals'){
                    setList1(res.data.result[0].hospitals) 
                }
                else if(category==='patient'){
                    setList2(res.data.result[0].patient) 
                }
            }
            
            else{
                setList([]);
                setList1([]);
                setList2([]);
            }*/

        }
            else{
              setLoad('No results found')
            }
        })
        .catch(() => {
          setLoad('Error in Fetching Data')
        })
    
    

    },[searchTerm,category,refresh,currentPage])

    const handleSearch= (e)=> {
      e.preventDefault();

        const tokenString = localStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
      
        axios.get(`${APIURL}/api/v1/staff/disable_delete_search/?q=${searchTerm}&category=${category}&per_page=10&page=${currentPage}`, {
             headers: { Authorization: "Token " + v2 },
        })
        .then((res) =>{
        if(res.data.status==='success'){
          let data= res.data.result

            if(data.length>0){
              if(data[0].category==='individual_doctors'){
                setList(data[0].individual_doctors)
              }
              else if(data[0].category==='hospitals'){
                setList(data[0].hospitals)
              }
              else if(data[0].category==='patient'){
                setList(data[0].patient)
              }
            }          
            else{
              
              setList([])
              setLoad('No results found')
            }

            /*if(data.length>0){
                if(category===''){
                    data.map((item)=>{
                        if(item.category==='individual_doctors'){
                            setList(item.individual_doctors)
                        }
                        if(item.category==='hospitals'){
                            setList1(item.hospitals)
                        }
                        if(item.category==='patient'){
                            setList2(item.patient)
                        }
                    })
                }
                else if(category==='individual_doctors'){
                    setList(res.data.result[0].individual_doctors)                             
                }
                else if(category==='hospitals'){
                    setList1(res.data.result[0].hospitals) 
                }
                else if(category==='patient'){
                    setList2(res.data.result[0].patient) 
                }
            }  
            else{
                setList([]);
                setList1([]);
                setList2([]);
            }  */
        }
            else{
              setLoad('No results found')
            }
        })
        .catch((err) => {
          setLoad('Error in Fetching Data')
        })

    }

    const handleRefresh = () => {
        const tokenString = localStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
   
        axios
          .get(
            `${APIURL}/api/v1/staff/disable_delete_search/?q=${searchTerm}&category=${category}&per_page=10&page=${currentPage}`,
            { headers: { Authorization: "Token " + v2 } }
          )
          .then((res) => {
            if (res.data.status === "success") {

              let data= res.data.result

            if(data.length>0){
              if(data[0].category==='individual_doctors'){
                setList(data[0].individual_doctors)
              }
              else if(data[0].category==='hospitals'){
                setList(data[0].hospitals)
              }
              else if(data[0].category==='patient'){
                setList(data[0].patient)
              }
            }          
            else{
              
              setList([])
              setLoad('No results found')
            }

               /* let data= res.data.result

            if(data.length>0){
                if(category===''){
                    data.map((item)=>{
                        if(item.category==='individual_doctors'){
                            setList(item.individual_doctors)
                        }
                        if(item.category==='hospitals'){
                            setList1(item.hospitals)
                        }
                        if(item.category==='patient'){
                            setList2(item.patient)
                        }
                    })
                }
                else if(category==='individual_doctors'){
                    setList(res.data.result[0].individual_doctors)                             
                }
                else if(category==='hospitals'){
                    setList1(res.data.result[0].hospitals) 
                }
                else if(category==='patient'){
                    setList2(res.data.result[0].patient) 
                }
            }
            else{
                setList([]);
                setList1([]);
                setList2([]);
            }*/

            } else {
              setLoad('No results found')
            }
          })
          .catch((err) => {
            setLoad('Error in Fetching Data')
                  });
      };

      const handleDeleteModal= (e, id, type)=> {
        e.preventDefault();
        setId(id)
        setParam(type)
        setModalShow(true)
      }

      const handleDisable =(e, id ,type ,action) =>{
        e.preventDefault();
        const tokenString = localStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
          let data ={}
        if(type==='doctor'){
          if(action==='Disable'){
            data ={            
              account_status :'Disable',
              user_id :id
            }
          }
          else if(action==='Enable'){
            data ={            
              account_status :'Active',
              user_id :id
            }
          }
          
        }
        else if(type==='hospital'){
          if(action==='Disable'){
            data ={            
              account_status :'Disable',
              hospital_email :id
            }
          }
          else if(action==='Enable'){
            data ={            
              account_status :'Active',
              hospital_email :id
            }
          }
        }

          axios.post(`${APIURL}/api/v1/staff/disable_delete_account/`, data, {
            headers: { Authorization: "Token " + v2 },
       })
       .then((res) =>{
           if(res.data.status==='success'){
            if(type==='doctor'){
              if(action==='Disable'){
                setDisableShow(true)
              }
              else if(action==='Enable'){
                setEnableShow(true)
              }
            }
            else if(type==='hospital'){
              if(action==='Disable'){
                setDisableShow(true)
              }
              else if(action==='Enable'){
                setEnableShow(true)
              }
            } 


           }
           else{

           }
       })
       .catch((err) => {

       })
      }


      const handleDelete =() =>{

        const tokenString = localStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
          let data ={}
        if(param==='user'){
          data ={            
            account_status :'Delete',
            user_id :id
          }
        }
        else if(param==='hospital'){
          data ={            
            account_status :'Delete',
            hospital_email :id
          }
        }
          axios.post(`${APIURL}/api/v1/staff/disable_delete_account/`, data, {
            headers: { Authorization: "Token " + v2 },
       })
       .then((res) =>{
           if(res.data.status==='success'){

             setDeleteShow(true)      

           }
           else{

           }
       })
       .catch((err) => {
         
       })
      }

      const ConfirmPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 className='text-danger'>Are you sure to delete this user ?</h4>
               
              </Modal.Body>
              <Modal.Footer>

                 <Button variant="secondary" onClick={props.onHide}>No</Button>
                 <Button variant="danger" onClick={props.onCall}>Yes</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const EnablePopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 className='text-success'>User enabled successfully</h4>
               
              </Modal.Body>
              <Modal.Footer>

                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>        
        
              </Modal.Footer>
            </Modal>
          )
      }

      const DisablePopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 className='text-success'>User disabled successfully</h4>
               
              </Modal.Body>
              <Modal.Footer>

                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>

              </Modal.Footer>
            </Modal>
          )
      }
      const DeletePopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 className='text-success'>User deleted successfully</h4>
               
              </Modal.Body>
              <Modal.Footer>

                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>

              </Modal.Footer>
            </Modal>
          )
      }


      let display =list.length >0 ? list.map((data ,index)=>{

        if(category==='individual_doctors'){
          const type='doctor';     
          let action = '';
          
            return (
              <div key={data.id}>
              <Link style={{ textDecoration: 'none' }} to={{pathname:`/doctor-details/${data.id}`, id:data.id}}>
              <div className='list-item'> 
                       
              {" "}
        <h5 className="each-list-link">
           {(currentPage-1)*10 + index+1}.{data.name}{" "} 
           {/*<OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
            Email: {data.email}<br/>
            Phn: {data.mobile_number}<br/>
            DOB: {data.dob}
            </Tooltip> }><i style={{color:'green'}} className="fas fa-info-circle"></i></OverlayTrigger>{" "}  */}
        </h5>
  
        <p className='detail-style'>ID : {data.id}</p>
        <div className="details"> 
          <button id='button-align' type="button" onClick={(e)=> { handleDisable(e ,data.id ,type ,action);  }} className={data.account_status==='ACTIVE'? 'btn btn-small btn-square btn-primary btn-col': data.account_status==='SUSPEND'? 'btn btn-small btn-square btn-secondary': 'btn btn-small btn-square btn-info'}>{data.account_status==='ACTIVE'?action='Disable'  :data.account_status==='SUSPEND'?action='Enable': null } </button>
          <button id='button-align' type="button" onClick={(e)=> handleDeleteModal(e, data.id, 'user')}  className="btn btn-small btn-square btn-danger">Delete</button>            
      </div>
      
          </div>
          </Link>
          </div>
            )
        }
        else if(category==='hospitals'){
          const type='hospital'
        let action = '';
        return (
          <div key={data.id}>
          <Link style={{ textDecoration: 'none' }} to={{pathname:`/hospital-details/${data.id}`, id:data.id, flag:true}}>
          <div className='list-item'>           
          {" "}
    <h5 className="each-list-link">
       {(currentPage-1)*10 + index+1}.{data.name}{" "}
       {/*<OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          Email: {data.email}<br/>
          Phn: {data.mobile_number}<br/>
          Location: {data.location}
        </Tooltip> }><i style={{color:'green'}} className="fas fa-info-circle"></i></OverlayTrigger>{" "}  */}
    </h5>
    
    <p className='detail-style'>ID : {data.id}</p>

    <div className="details">
      {
        data.account_status ? 
          <button id='button-align' type="button" onClick={(e)=>{ handleDisable(e, data.email ,type ,action); }} className={data.account_status==='ACTIVE'? 'btn btn-small btn-square btn-primary btn-col': data.account_status==='SUSPEND'? 'btn btn-small btn-square btn-secondary': 'btn btn-small btn-square btn-info'}>{data.account_status==='ACTIVE'?action='Disable'  :data.account_status==='SUSPEND'?action='Enable': null }</button>
          : null
      }
      
        <button id='button-align' type="button" onClick={(e)=> handleDeleteModal(e, data.email, 'hospital')}  className="btn btn-small btn-square btn-danger">Delete</button>           
  </div>
  
      </div>
      </Link>
      </div>
        )
      }

      else if(category==='patient'){
        const type='doctor'
        let action = '';
          return (
            <div key={data.id}>
            <Link style={{ textDecoration: 'none' }} to={{pathname:`/patient-details/${data.id}`, id:data.id}}>
            <div className='list-item'>           
            {" "}
      <h5 className="each-list-link">
         {(currentPage-1)*10 + index+1}.{data.name}{" "}
         {/*<OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
            Email: {data.email}<br/>
            Phn: {data.mobile_number}<br/>
            DOB: {data.dob}
          </Tooltip> }><i style={{color:'green'}} className="fas fa-info-circle"></i></OverlayTrigger>{" "} */}
      </h5>
  
      <p className='detail-style'>ID : {data.id}</p>
  
      <div className="details">
        <button id='button-align' type="button" onClick={(e)=> handleDisable(e, data.id ,type ,action)} className={data.account_status==='ACTIVE'? 'btn btn-small btn-square btn-primary btn-col': data.account_status==='SUSPEND'? 'btn btn-small btn-square btn-secondary': 'btn btn-small btn-square btn-info'}>{data.account_status==='ACTIVE'?action='Disable'  :data.account_status==='SUSPEND'?action='Enable': null }</button>
          <button id='button-align' type="button"  onClick={(e)=> handleDeleteModal(e, data.id, 'user')}  className="btn btn-small btn-square btn-danger">Delete</button>            
    </div>
    
        </div>
        </Link>
        </div>
          )

      }


      }): <h5 className='text-muted'>{load}</h5>

  //     let display1 =list1.length >0 ? list1.map((data ,index)=>{
  //       const type='hospital'
  //       let action = '';
  //       return (
  //         <div key={index}>
  //         <Link style={{ textDecoration: 'none' }} to={{pathname:`/hospital-details/${data.id}`, id:data.id, flag:true}}>
  //         <div className='list-item'>           
  //         {" "}
  //   <h5 className="each-list-link">
  //      {index + 1}.{data.name}{" "}
  //      {/*<OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
  //         Email: {data.email}<br/>
  //         Phn: {data.mobile_number}<br/>
  //         Location: {data.location}
  //       </Tooltip> }><i style={{color:'green'}} className="fas fa-info-circle"></i></OverlayTrigger>{" "}  */}
  //   </h5>
    
  //   <p className='detail-style'>ID : {data.id}</p>

  //   <div className="details">
  //     {
  //       data.account_status ? 
  //         <button id='button-align' type="button" onClick={(e)=>{ handleDisable(e, data.email ,type ,action); }} className={data.account_status==='ACTIVE'? 'btn btn-small btn-square btn-primary btn-col': data.account_status==='SUSPEND'? 'btn btn-small btn-square btn-secondary': 'btn btn-small btn-square btn-info'}>{data.account_status==='ACTIVE'?action='Disable'  :data.account_status==='SUSPEND'?action='Enable': null }</button>
  //         : null
  //     }
      
  //       <button id='button-align' type="button" onClick={(e)=> handleDeleteModal(e, data.email, 'hospital')}  className="btn btn-small btn-square btn-danger">Delete</button>           
  // </div>
  
  //     </div>
  //     </Link>
  //     </div>
  //       )
  //   }): null

  //   let display2 =list2.length >0 ? list2.map((data ,index)=>{
  //     const type='doctor'
  //     let action = '';
  //       return (
  //         <div key={index}>
  //         <Link style={{ textDecoration: 'none' }} to={{pathname:`/patient-details/${data.id}`, id:data.id}}>
  //         <div className='list-item'>           
  //         {" "}
  //   <h5 className="each-list-link">
  //      {index + 1}.{data.name}{" "}
  //      {/*<OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
  //         Email: {data.email}<br/>
  //         Phn: {data.mobile_number}<br/>
  //         DOB: {data.dob}
  //       </Tooltip> }><i style={{color:'green'}} className="fas fa-info-circle"></i></OverlayTrigger>{" "} */}
  //   </h5>

  //   <p className='detail-style'>ID : {data.id}</p>

  //   <div className="details">
  //     <button id='button-align' type="button" onClick={(e)=> handleDisable(e, data.id ,type ,action)} className={data.account_status==='ACTIVE'? 'btn btn-small btn-square btn-primary btn-col': data.account_status==='SUSPEND'? 'btn btn-small btn-square btn-secondary': 'btn btn-small btn-square btn-info'}>{data.account_status==='ACTIVE'?action='Disable'  :data.account_status==='SUSPEND'?action='Enable': null }</button>
  //       <button id='button-align' type="button"  onClick={(e)=> handleDeleteModal(e, data.id, 'user')}  className="btn btn-small btn-square btn-danger">Delete</button>            
  // </div>
  
  //     </div>
  //     </Link>
  //     </div>
  //       )
  //   }): null


   {/*  resultDisplay= <>{list.length>0 ?
                <div >
                <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing results in </p>{'  '}
                 <h3 style={{display:'inline-block'}} className=" additional-text">Doctors</h3>
                 </div> : null}
              
                {list.length>0 ?
                
                display : null}
    
              {category==='individual_doctors' ? searchTerm!==null && searchTerm!=="" && list.length <1 && <h6 style={{color:"red"}}>No results found in doctors</h6> :null} 
    
              {<br/>}
    
              {list1.length>0 ?
              <div>
                <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing results in </p>{'  '}
                <h3 style={{display:'inline-block'}} className="additional-text">Hospitals</h3></div> : null}
                
    
              {list1.length>0 ?
                display1 : null}
    
              {category==='hospitals' ? searchTerm!==null && searchTerm!=="" && list1.length <1 && <h6 style={{color:"red"}}>No results found in Hospitals</h6> : null} 
    
                {<br/>}
              {list2.length>0 ?
              <div>
                <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing results in </p>{'  '}
                <h3 style={{display:'inline-block'}} className="additional-text">Patients</h3></div> : null}
                
    
              {list2.length>0 ?
                display2 : null}
    
              {category==='patient' ? searchTerm!==null && searchTerm!=="" &&  list2.length <1 && <h6 style={{color:"red"}}>No results found in Patients</h6> : null}  
    
              {category===''? searchTerm!==null && searchTerm!=="" &&  list.length<1  &&  <h6 style={{color:"red"}}>No results found</h6> : null}
              </>*/}

    return (
        <div className="search-container">
      <div className="dashboard-navigation">
        <div>
          {" "}
          <Link to="/dashboard">
            {" "}
            <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
          </Link>
        </div>

        <div>
          {" "}
          <i className="fas fa-angle-double-right angle-icon"></i>{" "}
        </div>
        <div>
          {" "}
          <Link to="/search-hospitals"> Users List </Link>{" "}
        </div>
      </div>

      <div className="container failedlist-parentcontainer">
        <h2 className="title-of-page">Users List</h2>

        <form onSubmit={handleSearch}>
          <div className="search-div">
            <input
              className="form-control search-input"
              type="text"
              placeholder="Search a User"
              value={searchTerm}
              maxLength="30"
              onChange={handleSearchChange}
            />
            <button disabled={searchTerm===''} type="submit" className="btn btn-primary btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </button>
          </div>
        </form>
        <div className="header-display">

        <ToggleButtonGroup type="radio" name="options" defaultValue={category} onChange={handleChangeRadio}>
    <ToggleButton id="tbg-radio-1" value={'individual_doctors'} className="btn btn-light toggle-btns toggle-btn-1">
     Doctor
    </ToggleButton>
    <ToggleButton id="tbg-radio-2" value={'hospitals'} className="btn btn-light toggle-btns toggle-btn-2">
     Hospital
    </ToggleButton>
     <ToggleButton id="tbg-radio-2" value={'patient'} className="btn btn-light toggle-btns toggle-btn-4">
     Patient
    </ToggleButton>
   
    
    
  </ToggleButtonGroup>

          <button style={{marginLeft:'10px'}} className="btn btn-secondary" onClick={handleRefresh}>
            Refresh List <i className="fas fa-sync-alt"></i>
          </button>

          {/* <Dropdown>


<Dropdown.Toggle variant="secondary" id="dropdown-basic">
  {filter}
</Dropdown.Toggle>

<Dropdown.Menu>
  <Dropdown.Item onClick={()=>{handleFilter("") ; setFilter("All")}}>All</Dropdown.Item>
  <Dropdown.Item onClick={()=>{handleFilter("individual_doctors") ; setFilter("Doctors")}}>Doctor</Dropdown.Item>
  <Dropdown.Item  onClick={()=>{handleFilter("hospitals"); setFilter("Hospitals")}}>Hospital</Dropdown.Item>
  <Dropdown.Item  onClick={()=>{handleFilter("patient"); setFilter("Patient")}}>Patient</Dropdown.Item>

</Dropdown.Menu>
</Dropdown> */}

        </div>

        <div className="failed-list-section">
          <div className="container">
            <div className="test-reports-display-list">
              {display}
            </div>

          </div>
            
        </div>
      </div>

      {
        modalShow ? 
        <ConfirmPopUp 
            show={modalShow}
            onCall={() => { handleDelete();  setModalShow(false) }}
            onHide={() => setModalShow(false) }/> : null
    }

{enableShow? 
  <EnablePopUp 
        show={enableShow}
        onHide={() => { setEnableShow(false); setRefresh(!refresh)}}       
      />

: null }

{disableShow? 
  <DisablePopUp 
        show={disableShow}
        onHide={() => { setDisableShow(false); setRefresh(!refresh)}}        
      />

: null }

{deleteShow? 
  <DeletePopUp 
        show={deleteShow}
        onHide={() => { setDeleteShow(false); setRefresh(!refresh)}}        
      />

: null }

<br/>
            <br/>
            {totalPages > 0 ?
    <div className="pagn">

            <Pagination
          activePage={currentPage}
          itemsCountPerPage={10}
          totalItemsCount={totalRecords}
          pageRangeDisplayed={totalPages}
          onChange={handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null}

    </div>
    )
}

export default SearchHospital
