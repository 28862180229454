import React, {useState, useEffect,useContext} from 'react'
import {Link} from "react-router-dom";
import Pagination from "react-js-pagination";
import "./pagination.css";
import './userlist.css';
import "./subscriberslist.css"
import axios from "axios";
import {APIURL} from '../../../Global'
//import {Link as Scroll} from 'react-scroll'
import {SubscribersContext} from "../../../contexts/SubscribersContext";

//let prev =['']

//let j=0

//const menu = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z'.split(',')

function SubscribersList() {
    const [patientdata,setPatientdata]=useState([]);
     const [searchTerm,setSearchTerm]=useState('');
      const [currentPage,setCurrentPage]=useState(1);
         const [perPage,]=useState(2);
            const [loading,setLoading]=useState(true);
            const [refresh,]= useContext(SubscribersContext);
        

      
  useEffect(()=>{
        const tokenString = localStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */


        axios
        .get(`${APIURL}/api/v1/hospital/care-programs/subscribers/`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          setLoading(false);
          if (res.data.status === "success") {
            const data = res.data.results       
            setPatientdata(data)
        }
        else{

        }
    }).catch((error)=>{

    })
},[refresh])


 /*const handleBack=(e)=>{
    history.push('/careplans-db');
} 
*/
   const handleSearchChange = (event)=> {
     setCurrentPage(1)
        setSearchTerm(event.target.value)
    }

    const handlePageChange = (pageNumber)=> {
      setCurrentPage(pageNumber)
    }


  
          
     

        let data =   patientdata.sort((a,b)=>{
          if(a.full_name.toLowerCase() < b.full_name.toLowerCase()) return -1;
          if(a.full_name.toLowerCase() > b.full_name.toLowerCase()) return 1;
          return 0;
      })
      .filter((value)=>{
        if(searchTerm===''){
            return value
        } else if (value.full_name.toLowerCase().includes(searchTerm.toLowerCase())){
            return value
        }
    })


        const indexLast= currentPage * perPage;
        const indexFirst= indexLast - perPage;
        const current = data.slice(indexFirst ,indexLast);
        const total = data.length;

        const pageNumbers = []
        for(let i=0 ;i<= Math.ceil(total/perPage) ;i++){
          pageNumbers.push(i)
        }
        const totalPages=pageNumbers.length;
        return (<div className="subscribers">
          
            
        <div className="search-section">
  
       
            <div className="search-div">
              <input
                className="form-control search-input"
                type="text"
                maxLength="30"
                placeholder="Enter Name"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button  className="btn btn-primary btn-col search-btn">
                {" "}
                <i className="fas fa-search"></i>{" "}
              </button>        </div>
          <div className="container search-results">  
          {
              loading===true ?
            <h6 className="text-muted"> Loading...</h6>
            :
          current.length>0 ? current.map((value,index)=>{
            let link_url=`/subscriber/${value.id}`;
              return(



              <Link to={{pathname: link_url,
                         state: {name: value.full_name  ,
                                phn: value.mobile_number}}}>
                  <div key ={index} className='list-item'>               
                      <h5 className="each-list-link" id={value.id} key={value.id}>{(currentPage-1)*2 + index+1}.{value.full_name}</h5>  
                      <div className="details">
                        <p> Patient Id: {value.id} </p>
                        <p> Phn : {value.mobile_number} </p>
                      </div>                                                                 
                  </div>
                </Link>
              )          
            })
            : <h6 className="text-muted"> Subscribers list is empty!</h6>
          }
          
           
          </div>
              
            
            {totalPages > 1 ?
    <div className="pagn pagn-small">
 <br/>
    <br/>
            <Pagination
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={total}
          pageRangeDisplayed={totalPages}
          onChange={handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null}
            
        </div>
        </div>
        )
    
}

export default SubscribersList