import React ,{useState ,useEffect} from 'react'
import {Button ,Modal } from 'react-bootstrap';
import axios from "axios";
import {APIURL} from "../../Global";
import '../../css/bootstrap.min.css';
import '../../css/iofrm-style.css';
import '../../css/iofrm-theme4.css';
import "./popup.css";
import "./country_region_selector.css";
import "./dob.css";
import './profile.css'
import MailUpdate from './MailUpdate';
import AddressUpdate from './AddressUpdate';
import DobUpdate from './DobUpdate';
import GenderUpdate from './GenderUpdate';
import RegionUpdate from './RegionUpdate';
import CountryUpdate from './CountryUpdate';
import PhoneUpdate from './PhoneUpdate';

function EditProfile(props) {

    const [email,setEmail]=useState(null);
    const [img, setImg] =useState('');
    const [address,setAddress]=useState(null);
    const [region, setRegion]=useState('');
    const [country, setCountry]= useState('')
    const [phn, setPhn]= useState('')
    const [gender,setGender] = useState();
    const [dob, setDob] = useState();
    const [show, setShow]= useState(false);
    const [imgURL ,setImgURL]= useState("");
    const [previewOn , setPreviewOn]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);
    const [mailEdit, setMailEdit]= useState(false)
    const [addressEdit, setAddressEdit]= useState(false)
    const [dobEdit, setDobEdit]= useState(false)
    const [genderEdit, setGenderEdit]= useState(false)
    const [countryEdit, setCountryEdit]= useState(false)
    const [regionEdit, setRegionEdit]= useState(false)
    const [phoneEdit, setPhoneEdit]= useState(false)
    const [refresh ,setRefresh] =useState(false)
    const [flag, setFlag]= useState(false)
    const [sizeShow ,setSizeShow] =useState(false)
const [validShow ,setValidShow] =useState(false)
const [contentShow ,setContentShow] =useState(false)
const [uploadShow ,setUploadShow] =useState(false)
const [toggle, setToggle]= useState(false)

     const reader =new FileReader();

     useEffect(()=>{

      const tokenString= localStorage.getItem('usertoken');
       
       let str= tokenString.replace(/["]+/g, '')                           
        
       
        axios.get(`${APIURL}/api/v1/account/profile`, { headers: {
          
          "Authorization" : 'Token '+str  }})
        .then(res=>{
            
          if(res.data.status==="success"){
          
          setImg(res.data.data.photo)
          setAddress(res.data.data.address)
          setGender(res.data.data.gender)
          setDob(res.data.data.dob)
          setEmail(res.data.data.email)
          setCountry(res.data.data.country_name)
          setRegion(res.data.data.region)
          setPhn(res.data.data.mobile_number)
          }
    
    
        })
        .catch(err=>{
    
    })
    
    
    },[refresh]);
    

const removePicPreview=(e)=>{
  e.preventDefault();
  setFlag(true)
  setToggle(false)
  setPreviewOn(false);

 }

const editImage = () =>{
  const fileInput = document.getElementById('imageInput')
  fileInput.click();
}




// const selectCountry =(val)=> {
 // setCountry(val)
  
 
//}

// const selectRegion= (val)=> {
 // setRegion(val)
//}

const editMail = (e) => {
  e.preventDefault();
  setMailEdit(true)
}

const editAddress = (e) => {
  e.preventDefault();
  setAddressEdit(true)
}

const editDob = (e) => {
  e.preventDefault();
  setDobEdit(true)
}

const editGender = (e) => {
  e.preventDefault();
  setGenderEdit(true)
}

const editCountry = (e) => {
  e.preventDefault();
  setCountryEdit(true)
}

const editRegion = (e) => {
  e.preventDefault();
  setRegionEdit(true)
}

const editPhone = (e) => {
  e.preventDefault();
  setPhoneEdit(true)
}


const GenderPopUp = (props) => {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h4>Update Gender</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <GenderUpdate handle={GenderHandler} />
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Close</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}


const DobPopUp = (props) => {
  return(
    <Modal
        {...props}
        
        aria-labelledby="example-custom-modal-styling-title"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h4>Update Dob</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">

        <DobUpdate handle={DobHandler} />
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Close</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}


const AddressPopUp = (props) => {
  return(
    <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h4>Update Address</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">

        <AddressUpdate handle={AddressHandler} />
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Close</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}



const MailPopUp = (props) => {
  return(
    <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h4>Update Email</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">

        <MailUpdate handle={MailHandler} />
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Close</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const CountryPopUp = (props) => {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h4>Update Country</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <CountryUpdate handle={CountryHandler} />
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Close</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const RegionPopUp = (props) => {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h4>Update Region</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <RegionUpdate country={country} handle={RegionHandler} />
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Close</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const PhonePopUp = (props) => {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h4>Update Mobile Number</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <PhoneUpdate handle={PhoneHandler} />
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Close</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ConfirmPopUp = ({onHide}) => {
  return(
  <Modal
      show
      onHide
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="confirm-reject">Updated Successfully</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={onHide}>Ok</Button>          

      </Modal.Footer>
    </Modal>
  )
}


const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}



const uploadImage= e=>{

  const file=  e.target.files[0];
  
  if(!file){
    setUploadShow(!uploadShow)
    //setInvalidPic('Please Select an Image')
    return false
  }
  if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
    setValidShow(!validShow)
    //setInvalidPic('Please select valid image')
    return false;
  }
  if(file.size>2e6){
    setSizeShow(!sizeShow)
    return false
  }

  reader.onload = (e) => {
    const img1 = new Image();
    img1.onload = () => {
      setToggle(true)
      setImg(file)
    };
    img1.onerror = () => {
      setPreviewOn(false)
      setContentShow(!contentShow)
      
      return false;
    };
    img1.src = e.target.result;
  };
  reader.readAsDataURL(file);

  const url =URL.createObjectURL(file)
  setImgURL(url)
  setPreviewOn(true)
  
}


const SizePopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">You have Exceeded the size limit 2MB</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const LoadPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Please select an Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ValidPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Please select a valid Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ContentPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Invalid Image Content</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}



const submitHandle = async() => {

  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') 

   let fd= new FormData()

   //if(address!== null){
   //fd.append('address',address)
   //}

   if(img!== null){
    fd.append('photo',img)    
   }
   
  // if(email!== null){
   // fd.append('email',email)
  // }
   
   
  await axios.put(`${APIURL}/api/v1/account/profile/` ,fd, { headers: {

    "Authorization" : 'Token '+str  }})
  .then(res=>{
  
  
  
    if(res.data.status==="success"){
      setToggle(false)
      setFlag(false)
    setSubmitMsg("success");
     setShow(true)

    }else{

      setErrorMsg(res.data.message);
              setErrorSubmit(true);
     
    }
  
  })
  .catch(err=>{
    setErrorMsg("error");
  
              setErrorSubmit(true);

  
  })


}

const handleRemove= async()=> {

  const tokenString= localStorage.getItem('usertoken');

let str= tokenString.replace(/["]+/g, '') 

const data = {
  photo : ''
}



await axios.put(`${APIURL}/api/v1/account/profile/` ,data, { headers: {

"Authorization" : 'Token '+str  }})
.then(res=>{



if(res.data.status==="success"){

setSubmitMsg("success");
 setShow(true)

}else{

  setErrorMsg(res.data.message);
          setErrorSubmit(true);
 
}

})
.catch(err=>{
setErrorMsg("error");

          setErrorSubmit(true);

})
}

const DobHandler = ()=>{
  setDobEdit(false)
  setRefresh(!refresh)
}

const GenderHandler = ()=>{
  setGenderEdit(false)
  setRefresh(!refresh)
}

const MailHandler = ()=>{
  setMailEdit(false)
  setRefresh(!refresh)
}

const AddressHandler = ()=>{
  setAddressEdit(false)
  setRefresh(!refresh)
}

const CountryHandler = ()=>{
  setCountryEdit(false)
  setRefresh(!refresh)
}

const RegionHandler = ()=>{
  setRegionEdit(false)
  setRefresh(!refresh)
}

const PhoneHandler = ()=>{
  setPhoneEdit(false)
  setRefresh(!refresh)
}

    
    return (
      <div className="flex-col profile-holder">

            <h2 className="title-of-tasks"><i style={{marginRight:'4px'}} className="fas fa-user-circle"></i>Edit Profile</h2>
            {previewOn=== false ?
            <div>
            <img alt='Profile Picture' className={img!==''&& img!==null ? "profile-picture":"no-image"} src={flag===false? img: props.image } />
            <input type="file" id="imageInput" hidden="hidden" onChange={uploadImage}/>
             <i style={{cursor:'pointer'}}  onClick={editImage} id={img!==''?'cam-id':'icon-pos'} className ="fas fa-camera fa-lg"></i>         
            </div>

              :<div> <div onClick={removePicPreview}><i className="fas fa-times-circle close-img-btn"></i></div>
              <img alt='Profile Picture' src={imgURL} className="avatar"/>
              </div> }

              <br/>
              <div className='flex-row'>
              <button onClick={submitHandle} disabled={toggle===false} className="btn btn-primary btn-col" >Update Picture</button>
              <button style={{marginLeft:'10px'}} disabled={img===''||img===null}  onClick={handleRemove} className="btn btn-primary btn-col" >Remove Picture</button>
              </div>

        <div className='flex-col detail-holder'>
          <div className='flex-detail'>

              <div><label className='bold-label'>Email : </label><label className='bio-data'>{email===''?<span className='text-danger'>No Data</span>:email}</label>
                <label onClick={editMail}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>
              <div><label className='bold-label'>Address : </label><label className='bio-data'>{address===''?<span className='text-danger'>No Data</span>:address}</label>
                <label onClick={editAddress}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>

          </div>

          <div className='flex-detail'>

              <div ><label className='bold-label'>DOB : </label><label className='bio-data'>{dob===''?<span className='text-danger'>No Data</span>:dob}</label>
                <label onClick={editDob}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>
              <div ><label className='bold-label'>Gender : </label><label className='bio-data'>{gender===''?<span className='text-danger'>No Data</span>:gender}</label>
                <label onClick={editGender}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>
          </div>

          <div className='flex-detail'>

              <div ><label className='bold-label'>Country : </label><label className='bio-data'>{country}</label>
                <label onClick={editCountry}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>
              <div ><label className='bold-label'>Region : </label><label className='bio-data'>{region===''?<span className='text-danger'>No Data</span>:region}</label>
                <label onClick={editRegion}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>
          </div>

          <div className='flex-detail'>

              <div ><label className='bold-label'>Phone : </label><label className='bio-data'>{phn===''?<span className='text-danger'>No Data</span>:phn}</label>
                <label onClick={editPhone}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>

          </div>

        </div>
                   
      
          
                                        

      {
         submitMsg==="success" ?
        <ConfirmPopUp
            show={show}
            onHide= { ()=>{
              setShow(false)     
              setSubmitMsg('');
              setPreviewOn(false)
              setRefresh(!refresh)
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');
                       setPreviewOn(false)}}
       
      />: ''
    }

    {
      <MailPopUp 
      show={mailEdit}
      onHide={() => {
        setMailEdit(false)
      }} />

    }

    {
      <AddressPopUp
      show={addressEdit}
      onHide={()=>{
        setAddressEdit(false)
      }} />
    }

{
      <DobPopUp
      show={dobEdit}
      onHide={()=>{
        setDobEdit(false)
        
      }} />
    }

{
      <GenderPopUp
      show={genderEdit}
      onHide={()=>{
        setGenderEdit(false)
      }} />
    }

{
      <CountryPopUp
      show={countryEdit}
      onHide={()=>{
        setCountryEdit(false)
      }} />
    }

{
      <RegionPopUp
      show={regionEdit}
      onHide={()=>{
        setRegionEdit(false)
      }} />
    }

{
      <PhonePopUp
      show={phoneEdit}
      onHide={()=>{
        setPhoneEdit(false)
      }} />
    }

{
        sizeShow ? 
        <SizePopUp
        show={sizeShow}
        onHide={()=>{
          setSizeShow(false)
        }} /> : null
      } 

{
        uploadShow ? 
        <LoadPopUp
        show={uploadShow}
        onHide={()=>{
          setUploadShow(false)
        }} /> : null
      } 

{
        validShow ? 
        <ValidPopUp
        show={validShow}
        onHide={()=>{
          setValidShow(false)
        }} /> : null
      } 

{
        contentShow ? 
        <ContentPopUp
        show={contentShow}
        onHide={()=>{
          setContentShow(false)
        }} /> : null
      } 
        </div>
    )
}

export default EditProfile
