import React ,{useState} from 'react'

function ImagePopUp(props) {
    const [rotation,setRotation]=useState(0);

    const handleRotateLeft=()=>{
        let newRotation = rotation - 90;
        if(newRotation >= 360){
          newRotation =- 360;
        }if(newRotation === -360){
          newRotation =0;
        }
        setRotation(newRotation);
       
      }

    return (
        <div >
            <img  src={props.img} alt="Not available"   style={{display:'block', margin:'auto',  transform: `rotate(${rotation}deg)`}} className='img-popup'/>
            <br/>
              <button style={{display:'block' , margin:'auto'}} className="btn btn-primary btn-col" onClick={handleRotateLeft}> Rotate <i className="fa fa-undo" aria-hidden="true"></i> </button>
        </div>
    )
}

export default ImagePopUp
