import React from "react";

import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";

function HospDrSchedules() {
  return (
    <>
    <HospDocNavbar />
      <div className="container">
        <h2 className="title-of-page"> Schedules </h2>
        <p> This Page will be updated soon </p>
      </div>
    </>
  );
}
export default HospDrSchedules;
