import React from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./failedreport.css"
import axios from 'axios';
import {Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../../Global";
import ImageScale from '../DisplayDetails/ImageScale'
import Select from 'react-select';
function SubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data submitted sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/failedlist"><Button className='btn btn-primary btn-col' > Goto Failed Report List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}


function SaveDraft(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data saved sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/failedlist"><Button className='btn btn-primary btn-col' > Goto Failed Report List </Button></Link>
        <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
       
      </Modal.Footer>
    </Modal>
  );
}



class Report extends React.Component{
  constructor(props){
    super(props);
    this.state={
      tests:[],
      images:[],
      date: '',
      recordId:"",
      isLocked: "null",
      submitmsg: "",
      timer: '',
      dateMsg: '',
      modalShow:false,
       moveMsg:"",
      moveShow:false,
      moveErrorMsg:"",
      moveErrorShow:false,
      docType:"",
      savedraftmsg:"",
      modaldraftShow:false,
        rejectSuccessModalShow: false,
      rejectSuccessMsg:"",
      rejectConfirmMsg:"",
      rejectConfirmModalShow: false,
      rotation: 0,
      rotationArray:[],
      scaleValue: 1,
      zoomShow: false,
      pdf:'',
      isPdf: false,      
      keys : {
        'h': false,
        'd': false,
        'u': false,
        'r': false,
        'l': false,
        'i': false,
        'a': false,
        'p': false,
        'v': false,
        't': false,
        'n': false
       }
    }
  }

  
  componentDidMount(){
   
      const tokenString= localStorage.getItem('usertoken');
   
   let v2= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
  
   this.setState({recordId: this.props.match.params.id});
  
   
 axios.get(`${APIURL}/api/v1/staff/lab-reports/${this.props.match.params.id}`, {headers: {"Authorization" : 'Token '+v2
     }})
 .then(res=>{
   if(res.data.status === "success"){
   
    if(res.data.data.is_pdf===true){
      this.setState({
        pdf:res.data.data.images[0],
        isPdf: true
      })
    }

    let imgLength= res.data.data.images.length;
 
    let rotationArr=new Array(imgLength).fill(null).map(()=>(0));

      this.setState({
            images: res.data.data.images,
            tests: res.data.data.tests,
            date: res.data.data.date,
            rotationArray: rotationArr
            
          });

 
      //call lock
      axios.post(`${APIURL}/api/v1/staff/lab-reports/lock/`, {report_id: this.props.match.params.id} , { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+v2 }})
  .then(res => {
    if(res.data.status==="success"){

    this.setState({isLocked: "true"})
    this.setState({timer: 5});

    
   
  
   

  }
  else {

    this.setState({isLocked: "false"})
  }
}).catch(err=>{

})

//call unlock

setTimeout(()=>{
axios.post(`${APIURL}/api/v1/staff/lab-reports/unlock/`, {report_id: this.props.match.params.id} , { headers: {
       'Content-Type': 'application/json',
      "Authorization" : 'Token '+v2 }})
  .then(res => {
    if(res.data.status==="success"){

    this.setState({isLocked: "false"})

  }
  else {

    
  }
}).catch(err=>{

})

}, 300000);







      /* */

    }
    else {


    }

}
  )
    .catch(err =>{

    
    
  })

    
 
  //////* Shotcuts ********/ 

  window.onkeydown =(e) => {
    
    this.state.keys[e.key]=true

    if(e.altKey && this.state.keys['h']){
      this.props.history.push('/hospitallist')
    }
    if(e.altKey && this.state.keys['d']){
      this.props.history.push('/doctorslist')
    }
    if(e.altKey && this.state.keys['u']){
      this.props.history.push('/search-hospitals')
    }
    if(e.altKey && this.state.keys['r']){
      this.props.history.push('/rejectedlist')
    }
    if(e.altKey && this.state.keys['l']){
      this.props.history.push('/reportlist')
    }
    if(e.altKey && this.state.keys['i']){
      this.props.history.push('/addsynonyms')
    }
    if(e.altKey && this.state.keys['a']){
      this.props.history.push('/failedlist')
    }
    if(e.altKey && this.state.keys['p']){
      this.props.history.push('/prescriptionlist')
    }
    if(e.altKey && this.state.keys['v']){
      this.props.history.push('/written-prescriptionlist')
    }
    if(e.altKey && this.state.keys['t']){
      this.props.history.push('/testlist')
    }
    if(e.altKey && this.state.keys['n']){
      this.props.history.push('/nutrition')
    }


  }
  window.onkeyup=(ev)=> {
    
    this.state.keys[ev.key]= false
  }

    
}

componentWillUnmount(){ 
  window.onkeydown=null
  window.onkeyup=null
}

 render(){

 
   const scaleVal=this.state.scaleValue;
 const rotArray= this.state.rotationArray;
const moveOptions = [    
                           {value: 'lab_report' , label: 'Lab Report'},
                           
                           {value: 'prescription' , label: 'Prescription'},
                           {value: 'hand_written_prescription' , label: 'Handwritten Prescription'},
                          {value: 'discharge_summary' , label: 'Discharge Summary'},
                          {value: 'misc' , label: 'Miscellaneous'},
                          {value: null , label: <span className="text-muted">Cancel</span>}

      
       
    ];

   
   const tokenString= localStorage.getItem('usertoken');
   
   let v2= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
 

  const {recordId, images, isPdf, pdf}=this.state;


const MovePopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{this.state.moveMsg}</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        
        <Link to="/failedlist"><Button className='btn btn-primary btn-col' > Goto Failed Report List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}


const MoveErrorPopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 style={{color:"red"}}>{this.state.moveErrorMsg}</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        
        <Link to="/failedlist"><Button className='btn btn-primary btn-col' > Goto Failed Report List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}
        
          
  function ZoomPopUp(props) {
    return (
      <Modal
        {...props}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
         <ImageScale img={images}/>
  
        </Modal.Body>

        <Modal.Footer>
        <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }    

   const RejectConfirmPopup =(props)=>{
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="confirm-reject">Please confirm to reject the report</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

        <Button variant="danger" onClick={()=>{this.setState({rejectConfirmModalShow: false});
                                                               handleReject();}}> Confirm</Button>

       
      </Modal.Footer>
    </Modal>
  );
}

 const RejectSuccessPopup =(props)=>{
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Report rejected successfully!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/reportlist"><Button className='btn btn-primary btn-col' > Goto Report List</Button></Link>

       
      </Modal.Footer>
    </Modal>
  );

}

  const handleRotateLeft2=(i)=>{
    let newRotation = this.state.rotationArray[i] - 90;
   
    if(newRotation >= 360){
      newRotation =0;
    }if(newRotation === -360){
      newRotation =0;
    }
    let newArray=rotArray;
    newArray[i]=newRotation;
    this.setState({rotationArray:newArray});
   
  }

const handleSaveImage=(e)=>{
  e.preventDefault();
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') ;
const dataImage={
  image_id: recordId,
  rotate_angle: this.state.rotation,
  key: "lab_report"
}
       axios.post(`${APIURL}/api/v1/staff/image_rotate/`, dataImage , { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str }})
  .then(res => {
    if(res.data.status==="success"){
   
    
    
   
  
   

  }
  else {
  

    
  }
}).catch(err=>{

  })



}

const handleScale=()=>{
  if(this.state.scaleValue === 1){
    this.setState({scaleValue: 1.3});
  }
  else if (this.state.scaleValue === 1.3){
    this.setState({scaleValue: 1});
  }
  
 
}


const handleZoom=()=>{
  this.setState({zoomShow: true}); 
}

const handleRejectConfirm = (e)=>{
   e.preventDefault();
    this.setState({rejectConfirmMsg:'success'});
    this.setState({rejectConfirmModalShow: true});  
}
  
  const handleReject =() =>{
 
    

    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') ;

       axios.post(`${APIURL}/api/v1/staff/lab-reports/${this.props.match.params.id}/`, {status : "rejected"} , { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str }})
  .then(res => {
    if(res.data.status==="success"){
   
    this.setState({rejectSuccessMsg:'success'});
    this.setState({rejectSuccessModalShow: true});  
    
   
  
   

  }
  else {
  
     this.setState({rejectSuccessMsg:'error'});
    
  }
}).catch(err=>{

   this.setState({rejectSuccessMsg:'error'});
})


}






 
 const handleSaveDraft =(e)=>{
    e.preventDefault();
let dateUpdated= this.state.date;

const yourDate = new Date()
const current = yourDate.toISOString().split('T')[0]

if(dateUpdated!== '' &&  dateUpdated!== null && dateUpdated<=current){
  this.setState({dateMsg : "valid"});

    const dataToSend = {
  date: this.state.date,
  tests: this.state.tests,
  is_draft: true
  
}
var JSONData = JSON.stringify(dataToSend);

  axios.post(`${APIURL}/api/v1/staff/lab-reports/${recordId}/`, JSONData , { headers: {
     'Content-Type': 'application/json',
      "Authorization" : 'Token '+v2}})
  .then(res => {

    if(res.data.status==="success"){

    this.setState({savedraftmsg:'success'});
    this.setState({modaldraftShow: true});  
  }
  else{
    this.setState({savedraftmsg:'error'});
    
  }
}).catch(err=>{

this.setState({savedraftmsg:'error'});
})
}

else if(dateUpdated > current){
  this.setState({dateMsg:"not valid"});
}
else{
  this.setState({dateMsg: "invalid"});
}
}


 
const handleDate=(e)=>{
  e.preventDefault();
  if(e.target.value !== null){
      this.setState({date: e.target.value})
  }
}




const deleteRow=(index)=>{
  const newlist = this.state.tests.filter((test,i)=>{
    return i!== index
  })

  this.setState({tests:newlist})
}

const handleMove=(data)=>{
  this.setState({docType:data})

}

const handleMoveSubmit=async (e)=>{
  e.preventDefault();
  const tokenString= localStorage.getItem('usertoken');
   let dataToPost={
    key: this.state.docType.value,
    move_from: "failed_lab_report",
    report_id: parseInt(recordId)
   }
   let str= tokenString.replace(/["]+/g, '') ;

       axios.post(`${APIURL}/api/v1/patient/upload/`,dataToPost , { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str }})
  .then(res => {
    if(res.data.status==="success"){
  
  
    
   
  
    this.setState({moveMsg: (res.data.message? res.data.message : 'Document moved successfully!')});
   this.setState({moveShow: true});

  }
  else {
    let msg=res.data.message? res.data.message : "Error in submission";
 this.setState({moveErrorMsg: msg});
     this.setState({moveErrorShow: true});
    
  }
}).catch(err=>{
    this.setState({moveErrorMsg: "Error in submission"});
 this.setState({moveErrorShow: true});
})


}



const handleSubmit=(e)=>{
  e.preventDefault();
  
let dateUpdated= this.state.date;

const yourDate = new Date()
const current = yourDate.toISOString().split('T')[0]

if(dateUpdated!== '' &&  dateUpdated!== null && dateUpdated<=current){
  this.setState({dateMsg : "valid"});



const dataToSend = {
  date: this.state.date,
  tests: this.state.tests,
  
}
var JSONData = JSON.stringify(dataToSend);

  axios.post(`${APIURL}/api/v1/staff/lab-reports/${recordId}/`, JSONData , { headers: {
     'Content-Type': 'application/json',
      "Authorization" : 'Token '+v2}})
  .then(res => {
    if(res.data.status==="success"){

   
    this.setState({submitmsg:'success'});
     this.setState({modalShow: true});
  }
  else{
    this.setState({submitmsg:'error'});
   
  }
}).catch(err=>{

  this.setState({submitmsg:'error'})
})
}

else if(dateUpdated > current){
  this.setState({dateMsg:"not valid"});
}
else{
  this.setState({dateMsg: "invalid"});
}
}

const renderEditable=(cellInfo)=> {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const tests = [...this.state.tests];
          tests[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          
          this.setState({ tests});
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.tests[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  }

const columns = [
{
  Header: "TEST NAME",
  accessor: "test_name",
  Cell: renderEditable,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:250,
  minWidth:100,
   sortable:false

},
{
  Header: "UNITS",
  accessor: "units",
  Cell: renderEditable,
   sortable:false

},
{
  Header: "RESULT",
  accessor: "result",
  Cell: renderEditable,
   sortable:false

},
{
  Header: "REFERENCE RANGE",
  accessor: "reference_range",
  Cell: renderEditable,
  width:180,
  sortable:false,



},
{
  Header: "DELETE",
  Cell: props=>{
    return (
    <button className="delete-btn" 
       onClick={()=>{deleteRow(props.index)}}> X </button>
    )
  },
  sortable:false,
  filterable:false
}




]

const handleNewRow=(e)=>{
  e.preventDefault();
     const newTests = this.state.tests;
    newTests.push({test_name:""});
    this.setState({ tests: newTests });

}


  return (
  
   
   

   
  
    <div className="">
     
    <div className="dashboard-navigation">
        <div> <Link to="/dashboard"> <i className="fas fa-home dasboard-icon"> </i>Dashboard </Link></div>
       
       <div> <i className="fas fa-angle-double-right angle-icon"></i> </div>
        <div>  <Link to="/failedlist"> Failed Report List </Link> </div>
        <div> <i className="fas fa-angle-double-right angle-icon"></i> </div>
        <div>  Failed Lab Report </div>
        </div>


     {this.state.isLocked=== "true" ? 
     <span style={{backgroundColor: "red", color:"white", padding: "5px 10px", width:"100vw",borderRadius:"10px"}}> This file is currently locked by you (for 5 minutes)!! </span>
     :  this.state.isLocked=== "false" ?
     <span style={{backgroundColor: "orange", color:"white", padding: "5px 10px", width:"80vw",borderRadius:"10px"}}> This file is currently Unlocked !! </span>
     
:
      ''}

    <h2 className="title-of-page">Failed Lab Report {this.state.recordId} - Add Data</h2>
     
    <div className="flex-row btns-row">
      <button className="btn btn-danger  reject-btn" type="submit" onClick={handleRejectConfirm}>Reject Report</button>   
      <button className="btn btn-secondary  save-draft-btn" type="submit" onClick={handleSaveDraft}>Save Draft</button>
      
      <button className=" btn btn-primary btn-col submit-ver-btn" type="submit" onClick={handleSubmit}>Submit Verified data </button>
      <div className=" flex-row move-select"><span style={{color:"grey"}}>Move to : </span>
    <Select
        value={this.state.docType}
        onChange={(data)=>{handleMove(data)
            }}
        options={moveOptions}
        className="select-move"
      /> 
       <button className=" btn btn-primary btn-col " type="submit" onClick={handleMoveSubmit}>Move Document </button>
      
      </div><br/>
   </div>
     <br/>
     <div className="top">
       
         <div className="date-drawn-div form-group" id="date-color">
         <label id="datedrawn"> Verify Report Date * <br/>(YYYY-MM-DD) :  </label>
         <input type="text" maxLength="30" id="datedrawn" placeholder="YYYY-MM-DD" className="" value={this.state.date} onChange={handleDate}/>
         </div>

         

    </div>
    {this.state.dateMsg === "invalid" ?
      <div > <h5 style={{color: "red"}}> *Please enter Verify Date field! </h5> </div>
      : this.state.dateMsg === "not valid" ?
      <div > <h5 style={{color: "red"}}> *Please enter a Valid Verify Date field! </h5> </div> : ''}
      

     <div className="seperator"> </div> 

     <div className="dashboard-display">

         
         <div className="">
         <h5 className="title-of-page">Scanned Report </h5>
         {isPdf===false? images.map((pic,index)=>{
          return <div className="scanned-images">
                 <div className="img-holder-inside-labreport">
                 <img src={pic} width="500px" height="auto" alt="scanned images" onDoubleClick={handleScale} style={{transform:`rotate(${rotArray[index]}deg) scale(${scaleVal}` }}/>
                 </div>
                 <div className="btns-img-handle">
                 <button className="btn btn-primary btn-col btn-rotate" onClick={()=>handleRotateLeft2(index)}> Rotate <i className="fa fa-undo" aria-hidden="true"></i> </button>
                 <button className="btn btn-primary btn-col btn-rotate" onClick={handleZoom}> Zoom <i className="fas fa-search-plus"></i> </button>
                  <button className="btn btn-primary btn-col" onClick={handleSaveImage}> Save Image  </button>
                  </div>
                  </div>
         }): <div style={{width:'700px', height:'650px'}}>
         <object data={pdf} type="application/pdf" width="100%" height="100%">
  </object></div> }
         
         </div>
         <div className="">
         <h5 className="title-of-page"> Table to verify and Edit Data </h5>
         <ReactTable 
         columns={columns}
         data={this.state.tests}
         defaultPageSize = {10}
         filterable
         resizable
         className='report-table'
         noDataText={"Please click on 'Click to Add New Row' button to add data..."}
         >

         </ReactTable>
   

      <button type="button" className="btn btn-primary btn-col add-row-btn" onClick={handleNewRow}> Click to Add New Row </button>
      

      </div>
     
       
       <div className="bottom-section">

        <br/>
         {/*<div className="seperator"> </div> */}
         
           


  <div className="flex-row btns-row">
      <button className="btn btn-danger  reject-btn" type="submit" onClick={handleRejectConfirm}>Reject Report</button>   
      <button className="btn btn-secondary  save-draft-btn" type="submit" onClick={handleSaveDraft}>Save Draft</button>
      
      <button className=" btn btn-primary btn-col " type="submit" onClick={handleSubmit}>Submit Verified data </button>
      <div className=" flex-row move-select"><span style={{color:"grey"}}>Move to : </span>
    <Select
        value={this.state.docType}
        onChange={(data)=>{handleMove(data)
            }}
        options={moveOptions}
        className="select-move"
      /> 
       <button className=" btn btn-primary btn-col " type="submit" onClick={handleMoveSubmit}>Move Document </button>
      
      </div><br/>
   </div>
     <br/>
       
<br/>
        {this.state.savedraftmsg==="success" ? 
          <SaveDraft
        show={this.state.modaldraftShow}
        onHide={() =>  this.setState({modaldraftShow: false})}
      />
         :  this.state.savedraftmsg==="error"? <h5 style={{color: "red"}}> Error in data saving! <br/>
         **Test and Result values are mandatory for draft saving. </h5>
            : ''
          }

     
         {this.state.submitmsg==="success" ? 
          <SubmitPopUp
        show={this.state.modalShow}
        onHide={() =>  this.setState({modalShow: false})}
      />
         :  this.state.submitmsg==="error"? <h5 style={{color: "red"}}> Error in Data submission.Please check again! </h5>
            : ''
          }
       
        {this.state.rejectConfirmMsg==="success" ? 

          <RejectConfirmPopup
        show={this.state.rejectConfirmModalShow}
        onHide={() =>  this.setState({rejectConfirmModalShow: false})}
      />

           : 
             '' }

      

       {this.state.rejectSuccessMsg==="success" ? 

          <RejectSuccessPopup
        show={this.state.rejectSuccessModalShow}
        onHide={() =>  this.setState({rejectSuccessModalShow: false})}
      />

           :   this.state.rejectSuccessMsg==="error" ? 
             (<div>
                <br/>
              <h5 style={{color: "red"}}> Error in Report rejection.Please check again! </h5>
              </div>) 
               : ''}

{ this.state.zoomShow? 
<ZoomPopUp 
      show={this.state.zoomShow}
      onHide={()=> this.setState({zoomShow:false})}
      
    />

: null  }

{ this.state.moveShow? 
<MovePopUp 
      show={this.state.moveShow}
      onHide={()=> this.setState({moveShow:false})}
      
    />

: null  }

{ this.state.moveErrorShow? 
<MoveErrorPopUp 
      show={this.state.moveErrorShow}
      onHide={()=> this.setState({moveErrorShow:false})}
      
    />

: null  }

   </div>
    </div>

     

     </div>


    
          

      
      
   
  );
}
}
export default Report;