import React,{useState,useEffect} from 'react'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function ImageUpdate(props) {

    const [imgData, setImgData]= useState()
    const [previewOn, setPreviewOn]=useState(false)
    const [imgUrl, setImgUrl]= useState('')
    const [sizeShow ,setSizeShow] =useState(false)
    const [validShow ,setValidShow] =useState(false)
    const [contentShow ,setContentShow] =useState(false)
    const [uploadShow ,setUploadShow] =useState(false)  
    const [url, setUrl]=useState('')

    if(imgData !==undefined){
        let data = imgData
       props.imgUpdate(data)
    }

    const reader =new FileReader();

    useEffect(()=> {
        if(props.img instanceof File === true){
            const url =URL.createObjectURL(props.img)
            setUrl(url)
        }
    },[])

    /*const handleUpdate =()=> {
        props.update();
    }*/

    const removePicPreview=(e)=>{
        e.preventDefault();
        setPreviewOn(false);
        setImgData(null)
       }

    const handleClick= ()=> {
        const fileInput = document.getElementById('imageInput')
        fileInput.click();
    }

    const uploadImage= e=>{

        const file=  e.target.files[0];
        
        if(!file){
          setUploadShow(!uploadShow)
          //setInvalidPic('Please Select an Image')
          return false
        }
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
          setValidShow(!validShow)
          //setInvalidPic('Please select valid image')
          return false;
        }
        if(file.size>2e6){
          setSizeShow(!sizeShow)
          return false
        }
      
        reader.onload = (e) => {
          const img1 = new Image();
          img1.onload = () => {
            setImgData(file)
          };
          img1.onerror = () => {
            setPreviewOn(false)
            setContentShow(!contentShow)
            
            return false;
          };
          img1.src = e.target.result;
        };
        reader.readAsDataURL(file);
      
        const url =URL.createObjectURL(file)
        setImgUrl(url)
        setPreviewOn(true)
        
      }

    const SizePopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h5 id="confirm-reject">You have exceeded image size limit 2MB</h5>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }
      
      const LoadPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Please select an Image</h4>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }
      
      const ValidPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Please select a valid Image</h4>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }
      
      const ContentPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Invalid Image Content</h4>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

    return (
        <div className='image-edit'>
            {
               props.img instanceof File ? previewOn===false ?
                    <>
                        <p className='text-right'>
                            <input type='file' id='imageInput' onChange={uploadImage} hidden='hidden' ></input>
                            <i onClick={handleClick} className="fas fa-camera cam"></i>
                        </p>
                        <p className='text-center'>
                            <img  className='update-class' alt='Scanned Image' src={props.img instanceof File ? url : props.img} width='300' height='300' ></img>
                        </p>                       
                    </>
                    :
                    <>
                        <p className='text-right'>
                            <div onClick={removePicPreview}><i className="fas fa-times-circle close-prev"></i></div>
                        </p>
                        <p className='text-center'>
                            <img className='update-class' src={imgUrl} width='300' height='300' alt='Scanned Image'/>
                        </p>
                        {/*<button onClick={handleUpdate} className='btn btn-primary btn-col'>Update</button>*/}
                    </>   
        :
        <p className='text-center'>
        <img  className='update-class' alt='Scanned Image' src={props.img instanceof File ? url : props.img} width='300' height='300' ></img>
    </p> 
    }

{
        sizeShow ? 
        <SizePopUp
        show={sizeShow}
        onHide={()=>{
          setSizeShow(false)
        }} /> : null
      } 

{
        uploadShow ? 
        <LoadPopUp
        show={uploadShow}
        onHide={()=>{
          setUploadShow(false)
        }} /> : null
      } 

{
        validShow ? 
        <ValidPopUp
        show={validShow}
        onHide={()=>{
          setValidShow(false)
        }} /> : null
      } 

{
        contentShow ? 
        <ContentPopUp
        show={contentShow}
        onHide={()=>{
          setContentShow(false)
        }} /> : null
      }

        </div>
    )
}

export default ImageUpdate
