import React, {useState, useEffect} from "react";

import "./patientpresclist.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";

import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import PatientPrescription from "../HospPatientPresc/HospPatientPresc";
import Pagination from "react-js-pagination";
import "./pagination.css";
import { Dropdown } from 'react-bootstrap';
import {APIURL} from "../../Global";


function PatientPrescList(props){
  const [prescList,setPrescList] = useState([]);
   const [patientId,] =useState(props.match.params.id);
   const [prescShow,setPrescShow]=useState(false)
   const [prescId,setPrescId] = useState();
   const [activePage,setActivePage] = useState(1);
      const [order,setOrder]=useState("desc")
      const [dropDownOpen,setDropDownOpen]=useState(false);
      const [refresh,setRefresh]= useState(false);
      const [totalPages,setTotalPages]=useState(1);
      
      const storedId = sessionStorage.getItem('hospId')

      const history=useHistory()

      const [keys,] = useState({'p': false,'a': false,'n': false,'m': false,'l': false,'s': false,});


      useEffect(()=>{
      
        window.onkeydown =(e) => {
          
          keys[e.key]=true
      
          if(e.altKey && keys['p']){
            history.push('/patients-directory')
          }
          if(e.altKey && keys['a']){
            history.push('/appointment')
          }
          if(e.altKey && keys['n']){
            history.push('/doctor/notifications')
          }
          if(e.altKey && keys['m']){
            history.push('/message')
          }
          if(e.altKey && keys['l']){
            history.push('/doctor/analytics')
          }
          if(e.altKey && keys['s']){
            history.push('/service-requestlist')
          }
      
      
        }
        window.onkeyup=(ev)=> {
          
          keys[ev.key]= false
        }
      
        return()=>{
          window.onkeydown=null
          window.onkeyup=null
        }
      },[]);


useEffect(()=>{

  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
    
     
  
    axios.get(`${APIURL}/api/v1/patient/prescriptions/?src=website&patient_id=${patientId}&sort_by=${order}&per_page=10&page=${activePage}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{


   
      if(res.data.status==="success"){
     setPrescList(res.data.results);
     setTotalPages(res.data.total_pages);
      
/*
         history.push({
  pathname: `/patient-record/${searchTerm}`,
 
  state: res.data 
})*/
      }else{
       
      }


    })
    .catch(err=>{
 
})
 

 },[activePage,order,refresh]);

const handlePrescOverlay=(e,prescId)=>{
 e.preventDefault();
 setPrescShow(true);
 
 setPrescId(prescId);


}

const PrescPopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          
         {/* <h4 className="title-of-tasks"> Prescription {props.prescid}  </h4> */}
         <h5 className="text-muted"> Felixa ID {props.patientid}</h5>
         <h5 className="text-muted">{storedId!==''? 'Hospital ID ' + storedId: null}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PatientPrescription names={prescList} {...props} />
       
     </Modal.Body>
     <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );

}
//const toggle = () => setDropDownOpen(!dropDownOpen);
    const handlePageChange=(pageNumber)=> {
   
    setActivePage(pageNumber);
    setRefresh(!refresh);
    }

    const onSort = async order=>{
  await setOrder(order);
  
  await setRefresh(!refresh);
}
const display = prescList.map((presc,index)=>{
             
                  return (<>

                       <div key={index} className="links-div thumbnail-div">

                       {/*
                         <Link to={url} {...props}>  <div className="list-item"> */}

                       <div className="list-item">
                     {/*   <Link to={{
                    pathname: `/patient-prescription/`,
                    patientId: patientId,
                    prescId: presc.id
                            }} >  
          */}
          <span className="history-link" onClick={(e)=>{handlePrescOverlay(e,presc.id)}} >
          <div className="row-table-title">
          <h5 className="each-list-link">
            {index + 1}. Prescription {presc.id}  
          </h5>
          
           <p> Date: {presc.date} </p>
           
          </div>

          <table className="thumbnail-table ">
                      <tr className="table-header-small">
                        <th> Id</th>
                        <th> Drug</th>
                        <th> Strength</th>
                        <th> Form</th>
                        <th> Route</th>
                        <th> Dosage</th>
                        <th> Frequency</th>
                        <th> Duration (Days)</th>
                        <th> Till Date</th>
                        <th> Intake Count</th>
                         <th> Intake Time</th>
                          <th> Remarks</th>
                        
                      </tr>
                      
                    {presc.medicines? presc.medicines.map(item=>{
                     return  <tr className="table-row-small">
                               <td> {item.id} </td>
                               <td> {item.drug} </td>
                               <td> {item.strength} </td>
                               <td> {item.form} </td>
                               <td> {item.route} </td>
                               <td> {item.dosage} </td>
                               <td> {item.frequency} </td>
                               <td> {item.duration} </td>
                               <td> {item.till_date} </td>
                               <td> {item.intake_count} </td>
                               <td> {item.intake_time} </td>
                                 <td> {item.remarks} </td>

                              
                            </tr>
                      }) 
                      : null}
                   </table>

        {/* </Link>*/}
        </span></div>
                   
                       </div>
                       {prescShow && prescId===presc.id? 
                       <PrescPopup 
        show={prescShow}
        onHide={() =>  setPrescShow(false)}
        prescid ={prescId}
        patientid= {patientId}
        
      />

: null }



{ }


                       </>
                    )

                });
  return(<>
    <HospDocNavbar />

    <div className="container"><br/>
     <div className="text-left">
    <Link to={`/patient-record/${patientId}`}><span className="btn btn-info" >  <i className="fas fa-angle-double-left"></i> Go to Patient Record</span></Link>
    </div>

    <h2 className="title-of-tasks"> Prescriptions List </h2>
    <div className='flex-row'>
     <h5 className="title-of-tasks text-center hospId-display"> Felixa ID {patientId}</h5>
     <h5 className="title-of-tasks text-center">{storedId!==''? 'Hospital ID ' + storedId: null}</h5>
    </div>     
   
<div className="buttons-wrapper-presc">
       
          <button className="btn btn-secondary" onClick={()=>{setRefresh(!refresh)}}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button>

<Dropdown>
  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
    Sort 
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item onClick={()=>{onSort("asc")}}>Ascending</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{onSort("desc")}}>Descending</Dropdown.Item>
  
  </Dropdown.Menu>
</Dropdown>

</div>

   
<div className="columns-holder">
    {prescList.length>0 ? display : <h6 style={{color: "red"}}> Sorry, No prescriptions to show! </h6> }
    </div>
    <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalPages*10}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          disabledClass = "disabled-class"
        />

</div>
    </>)
}


export default PatientPrescList;