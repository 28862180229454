import React, { useState, useEffect } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./procedurelist.css";
import Select from "react-select";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import { APIURL } from "../../Global";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import ProcedureList from "./ProcedureList";
// import EditRemark from "./EditRemark";
// import AddRemark from "./AddRemark";

function ProcedureRequests() {
  const [render, setRender] = useState(true);
  const [reRender, setRerender] = useState(false);
  const [, setGetError] = useState("");
  const [tableData, setTableData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState("");
  const [, setCurrentColumn] = useState("");
  const [display, setDisplay] = useState(false);

  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [, setOptionsDisplay] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  const [submitMsg, setSubmitMsg] = useState("");
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [remarkData, setRemarkData] = useState("");
  const [remarkList, setRemarkList] = useState([]);
  const [cell, setCell] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [backup, setBackup] = useState("");
  const [procedureData, setProcedureData] = useState([]);
  const [procedureShow, setProcedureShow] = useState(false);
  const [status, setStatus] = useState("");
  const [dateData, setDateData]= useState();
  const [pId, setPId] = useState("");
  


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let history = useHistory();

  const [keys] = useState({
    u: false,
    c: false,
    n: false,
    a: false,
    q: false,
    l: false,
  });

  useEffect(() => {
    window.onkeydown = (e) => {
      keys[e.key] = true;

      if (e.altKey && keys["u"]) {
        history.push("/directory");
      }
      if (e.altKey && keys["c"]) {
        history.push("/careplans-db");
      }
      if (e.altKey && keys["n"]) {
        history.push("/notifications");
      }
      if (e.altKey && keys["a"]) {
        history.push("/analytics");
      }
      if (e.altKey && keys["q"]) {
        history.push("/qrcode");
      }
      if (e.altKey && keys["l"]) {
        history.push("/call-log");
      }
    };
    window.onkeyup = (ev) => {
      keys[ev.key] = false;
    };

    return () => {
      window.onkeydown = null;
      window.onkeyup = null;
    };
  }, []);

  useEffect(() => {
    debugger;
    const tokenString = localStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/hospital/procedure-fulfillment/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        debugger;
        if (res.status==200) {
          let dataList = res.data.data;
           

          let updatedList = [];
          //  let filteredList=dataList.filter((item,index)=>{
          //   return !(item.status.toLowerCase()==="completed" || item.status.toLowerCase()==="rejected")
          // }).sort((a,b) => {
          //   return new Date(b.order_date) - new Date(a.order_date)
          // })


          updatedList = dataList.map((item, index) => {
            let status;
            let statusObj;
            if (item.status.toLowerCase() === "assigned") {
              status = "new";
              statusObj = { value: "assigned", label: "Assigned" };
            } else if (item.status.toLowerCase() === "ongoing") {
              status = "ongoing";
              statusObj = { value: "ongoing", label: "Ongoing" };
            } else if (item.status.toLowerCase() === "rejected") {
              status = "rejected";
              statusObj = { value: "rejected", label: "Rejected" };
            } else if (item.status.toLowerCase() === "completed") {
              status = "completed";
              statusObj = { value: "completed", label: "Completed" };
            }
            else if (item.status.toLowerCase() === "pending") {
              status = "pending";
              statusObj = { value: "pending", label: "pending" };
            }

            return {
              status: status,
              statusObj: statusObj,
              patient_id: item.patient_id,
              date: item.date,
              patient_name: item.patient_name,
              assign_name: item.assign_name,
              all_procedures: item.all_procedures,
            };
          });
              
        // let dataList= res.data.details;

        // var arr = updatedList;
        // var arrsort = [];
        // arr.forEach(function (el){
        //   if(el.status!="completed")
        //   {
        //     arrsort.push(el);
        //   }
        //  });

        //  setTableData(arrsort);
         setTableData(updatedList);
        } else {
          setGetError(res.data.message);
        }
      })
      .catch((err) => {
        setGetError("Error in fetching data");
      });
  }, [reRender]);

  useEffect(() => {
    const tokenString = localStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/hospital/procedure-assignee/?q=${search}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        const assignee_List = [];
        if (res.data.status === "success") {
          const assigneeList = res.data.details;
          assigneeList.map((person, index) => assignee_List.push(person["id"]));

          setOptions(assigneeList);
          setOptionsDisplay(assignee_List);
        }
      })
      .catch((err) => {});
  }, [search]);

  useEffect(() => {
    setTableData(tableData);
  }, [render, tableData]);

  const statusOptions = [
    { value: "assigned", label: "Assigned" },
    { value: "ongoing", label: "Ongoing" },
    { value: "completed", label: "Completed" },
    { value: "rejected", label: "Rejected" },
    { value: "pending", label: "Pending" },
  ];

  const SuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>{props.msg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            {errorMsg !== ""
              ? errorMsg
              : "Error in Data Submission. Please try again!"}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  var sendData = null;

  function handleSend(childData) {
    sendData = childData;
  }

  const addHandle = () => {
    setModalShow(false);
    //setRender(!render);
    //saveChanges(cell)
    const data = tableData;
    data[cell].remarks = sendData;
    setUpdateSuccess(true);
    setBackup(sendData);
  };

  const AddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {/* <AddRemark update={handleSend} save={addHandle}/> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <EditRemark data={remarkData} list={remarkList} update={handleSend}/> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const UpdatePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>Please save to complete changes </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleAdd = (index) => {
    setCell(index);
    setModalShow(true);
  };

  const handleEdit = (data, list, index) => {
    setRemarkData(data);
    setRemarkList(list);
    setCell(index);
    setEditShow(true);
  };
  const renderRemarks3 = (cellInfo) => {
    if (
      tableData[cellInfo.index][cellInfo.column.id] === "" ||
      tableData[cellInfo.index][cellInfo.column.id] === null
    ) {
      return (
        <button
          onClick={() => handleAdd(cellInfo.index)}
          className="btn btn-small add-remark-btn"
        >
          <i className="fas fa-plus"></i>
        </button>
      );
    } else {
      return (
        <button
          onClick={() =>
            handleEdit(
              tableData[cellInfo.index][cellInfo.column.id],
              tableData[cellInfo.index].remark_list,
              cellInfo.index
            )
          }
          className="btn btn-primary btn-col btn-square btn-small"
        >
          View/Edit
        </button>
      );
      /*return (<textarea 
      type="text" 
      className="form-group" 
      value= {tableData[cellInfo.index][cellInfo.column.id]}
      name="remarks" 
       onChange={(e)=>{

        e.preventDefault();
        const list = tableData;
       list[cellInfo.index].remarks = e.target.value;
       
       
       setTableData(list);
       setRender(!render);
} }
  autoComplete="off"
      /> )*/
    }
  };

  const renderAssigned2 = (cellInfo) => {
    return (
      <div>
        <input
          style={{ backgroundColor: "#fafafa" }}
          value={tableData[cellInfo.index].assign_name}
          className="form-control"
          name="assignee"
          onChange={(e) => {
            e.preventDefault();

            const list = tableData;
            list[cellInfo.index].assign_name = e.target.value;

            setTableData(list);

            setCurrentIndex(cellInfo.index);
            setCurrentColumn(cellInfo.column.id);

            setDisplay(true);

            setSearch(e.target.value);
          }}
        />

        {currentIndex === cellInfo.index &&
          cellInfo.column.id === "assign_name" &&
          display && (
            <div className="suggestions-box-holder">
              <button
                className="btn-suggestion btn btn-secondary btn-small"
                title="close"
                onClick={() => setDisplay(false)}
              >
                {" "}
                X{" "}
              </button>

              <div className="suggestions-box suggestions-assignee-box">
                {options.map((item, i) => {
                  return (
                    <div
                      className="scan-item"
                      key={i}
                      onClick={(e) => {
                        e.preventDefault();

                        setRender(!render);

                        const List = tableData;
                        List[cellInfo.index].assign_id = item.id;
                        List[cellInfo.index].assign_name = item.full_name;

                        setTableData(List);
                        setDisplay(false);
                      }}
                    >
                      <div className="search-item each-option-suggestion">
                        <h6 className="">ID: {item.id} </h6>
                        <h6 className=""> {item.full_name}</h6>
                        <h6 className="">{item.email} </h6>
                        <h6 className=""> {item.mobile_number}</h6>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    );
  };

  /**********
const renderAssigned=(cellInfo)=> {

 if(tableData[cellInfo.index].status==="new"){
  return (<input type="text" className="form-group" /> )
 }
 else{
  return ( <div > {tableData[cellInfo.index][cellInfo.column.id]}</div>)
 }
}
***********/
  /*const renderAssigned=(cellInfo)=> {

 if(tableData[cellInfo.index].assignee==="" || tableData[cellInfo.index].assignee===null){
  return (<input type="text" className="form-group" /> )
 }
 else{
  return ( <div > {tableData[cellInfo.index][cellInfo.column.id]}</div>)
 }
}*/

  /*const renderRemarks2=(cellInfo)=> {

 if(!tableData[cellInfo.index][cellInfo.column.id]){
  return (<textarea type="text" className="form-group " /> )
 }
 else{
  return ( <div > {tableData[cellInfo.index][cellInfo.column.id]}</div>)
 }
}*/
  /*const renderInput=(cellInfo)=> {


  return (<input type="text" className="form-group" /> )

}
const renderRemarks=(cellInfo)=> {

 
  return (<textarea type="text" className="form-group " /> )
 
}*/
  const renderTable = (cellInfo) => {
    return (
      <div className="fixed-co">
        {" "}
        {tableData[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  };
  const renderId = (cellInfo) => {
    if (tableData[cellInfo.index][cellInfo.column.id]) {
      return (
        <div className="fixed-col">
          {" "}
          {tableData[cellInfo.index][cellInfo.column.id]}
        </div>
      );
    } else {
      return null;
    }
  };
  const renderService = (cellInfo) => {
    return (
      <div>
        {" "}
        <span style={{ margin: "10px" }}>
          {tableData[cellInfo.index][cellInfo.column.id]}
        </span>
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={
            <Tooltip className="my-tooltip" id="button-tooltip">
              Details:{" "}
              {tableData[cellInfo.index].details !== null
                ? tableData[cellInfo.index].details
                : "Not Available"}{" "}
              <br />
            </Tooltip>
          }
        >
          <i
            style={{ color: "var(--theme-blue)" }}
            className="fas fa-info-circle"
          ></i>
        </OverlayTrigger>
      </div>
    );
  };
  const renderContact = (cellInfo) => {
    return (
      <div>
        {" "}
        <span style={{ margin: "10px" }}>
          {tableData[cellInfo.index][cellInfo.column.id]}
        </span>
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={
            <Tooltip className="my-tooltip" id="button-tooltip">
              {tableData[cellInfo.index].address !== null
                ? tableData[cellInfo.index].address
                : "Not Available"}{" "}
              <br />
            </Tooltip>
          }
        >
          <h6 className="overlay-address">Address</h6>
        </OverlayTrigger>
      </div>
    );
  };
  /*const renderDetails=(cellInfo)=> {
  
    return (

     <div > <p > {tableData[cellInfo.index][cellInfo.column.id]}</p></div>
      
    );
  }*/

  const renderStatus2 = (cellInfo) => {
    return (
      <>
        <Select
          value={tableData[cellInfo.index].statusObj}
          onChange={(data) => {
            const List = tableData;

            List[cellInfo.index].statusObj = data;
            List[cellInfo.index].status = data.value;
            setTableData(List);
            setRender(!render);
          }}
          options={statusOptions}
          className={`status-select ${
            tableData[cellInfo.index].status === "new"
              ? "new-status"
              : tableData[cellInfo.index].status === "assigned"
              ? "accepted-status"
              : tableData[cellInfo.index].status === "ongoing"
              ? "ongoing-status"
              : tableData[cellInfo.index].status === "pending"
              ? "pending-status"
              : tableData[cellInfo.index].status === "cancelled"
              ? "cancelled-status"
              : tableData[cellInfo.index].status === "completed"
              ? "completed-status"
              : tableData[cellInfo.index].status === "delivered"
              ? "delivered-status"
              : tableData[cellInfo.index].status === "rejected"
              ? "rejected-status"
              : ""
          }`}
        />
      </>
    );
  };

  /*const renderStatus=(cellInfo)=> {
  if(tableData[cellInfo.index][cellInfo.column.id]==="new"){
   return (

      <div ><span className="status new-status caps"> {tableData[cellInfo.index][cellInfo.column.id] }</span></div>
      
    );
  }
  else if(tableData[cellInfo.index][cellInfo.column.id]==="ongoing"){
    return (

      <div ><span className="status ongoing-status caps"> {tableData[cellInfo.index][cellInfo.column.id] }</span></div>
      
    );
  }
  if(tableData[cellInfo.index][cellInfo.column.id]==="accepted"){
    return (

      <div ><span className="status accepted-status caps"> {tableData[cellInfo.index][cellInfo.column.id]}</span></div>
      
    );
  }
  if(tableData[cellInfo.index][cellInfo.column.id]==="completed"){
    return (

      <div ><span className="status completed-status caps"> {tableData[cellInfo.index][cellInfo.column.id]}</span></div>
      
    );
  }
  if(tableData[cellInfo.index][cellInfo.column.id]==="rejected"){
    return (

      <div ><span className="status rejected-status caps"> {tableData[cellInfo.index][cellInfo.column.id]}</span></div>
      
    );
  }
  }




const renderPriority=(cellInfo)=> {
  if(tableData[cellInfo.index][cellInfo.column.id]==="critical"){
    return (

      <div > <div className="priority critical-priority caps">{tableData[cellInfo.index][cellInfo.column.id] }</div></div>
      
    );
  }
  else if(tableData[cellInfo.index][cellInfo.column.id]==="very high"){
    return (

      <div ><div className="priority very-high-priority caps"> {tableData[cellInfo.index][cellInfo.column.id] }</div></div>
      
    );
  }
  else if(tableData[cellInfo.index][cellInfo.column.id]==="high"){
    return (

      <div ><div className="priority high-priority caps"> {tableData[cellInfo.index][cellInfo.column.id] }</div></div>
      
    );
  }
  if(tableData[cellInfo.index][cellInfo.column.id]==="medium"){
    return (

      <div ><div className="priority medium-priority caps"> {tableData[cellInfo.index][cellInfo.column.id]}</div></div>
      
    );
  }
  if(tableData[cellInfo.index][cellInfo.column.id]==="low"){
    return (

      <div ><div className="priority low-priority caps"> {tableData[cellInfo.index][cellInfo.column.id]}</div></div>
      
    );
  }
  

  }*/

  /**************removed***details column*******************
{
  Header: "Details",
  accessor: "details",
  Cell: renderDetails,
   sortable:true,
    filterable:true,
   width:200

},



********************************/

  const saveChanges = (index) => {
    debugger
    const rowToUpdate = tableData.filter((elt, i) => {
      return i === index;
    });

    callSave(rowToUpdate[0]);
  };
  const validate = (rowToValidate) => {
    let isValid = true;

    {
      /********************************************
  if((rowToValidate.assigned_to===null || rowToValidate.assigned_to==="" || rowToValidate.assigned_to==="undefined" || !rowToValidate.assigned_to) && (rowToValidate.assigned_name && (rowToValidate.assigned_name!==null || rowToValidate.assigned_name!==""))){

isValid=false;
 setErrorMsg("Error in Assignee field. Please select assignee from the suggestion list.")
  setErrorSubmit(true);
  }

********************************removed assignee validation *****/
    }
    if (!rowToValidate.status && !rowToValidate.assign_id) {
      isValid = false;

      setErrorMsg("Please enter status or assignee");

      setErrorSubmit(true);
    }
    //   if(!rowToValidate.assign_id){
    //     isValid=false;

    //  setErrorMsg("Please enter assignee")

    //   setErrorSubmit(true);
    //   }

    return isValid;
  };

  const callSave = (rowToUpdate) => {

    debugger;
    if (validate(rowToUpdate)) {
      const id = rowToUpdate.all_procedures.map((item) => {
        return item.procedure_details.procedure_id;
      });

      const tokenString = localStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");
      const procedure_id = id;
      const assign = rowToUpdate.assign_id;
      const status = rowToUpdate.status;
      const patient_id = rowToUpdate.patient_id;

      const dataToUpdate = {
        procedure_id,
        assign,
        status,
      };

      axios
        .put(
          `${APIURL}/api/v1/hospital/procedure-fulfillment/?patient_id=${patient_id}`,
          dataToUpdate,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          debugger;
          if (res.status==200) {
            setSubmitMsg(
              res.data.message ? res.data.message : "Updated successfully!"
            );
            setBackup("");
            setSuccessSubmit(true);
            setTableData([]);
          } else {
            setErrorMsg(
              res.data.message ? res.data.message : "Error in submission"
            );
            setErrorSubmit(true);
          }
        })
        .catch((err) => {
          setErrorMsg("Error in submission");
          setErrorSubmit(true);
        });
    }
  };

  /*const callSaveRemarks=(rowToUpdate)=>{

  if(validate(rowToUpdate)){

  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
      const order_id=rowToUpdate.ref;
      const remarks= sendData
      const status= rowToUpdate.status;
      const assignee = rowToUpdate.assigned_to;
      const priority= rowToUpdate.priority;
   const dataToUpdate={
    order_id,
    remarks,
    status,
    assignee,
    priority
   }
   
    axios.post(`${APIURL}/api/v1/hospital/service-request-order/`,dataToUpdate, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        
      if(res.data.status==="success"){
    
      setSubmitMsg(res.data.message ? res.data.message : "Updated successfully!");

          setSuccessSubmit(true);
          setTableData([]);
   
   }
       else{
       

         setErrorMsg(res.data.message ? res.data.message : "Error in submission");
              setErrorSubmit(true);
              
      }
    


    })
    .catch(err=>{
  
   setErrorMsg( "Error in submission");
              setErrorSubmit(true);

})
}
}*/

  const renderDate = (cellInfo) => {
    let dateString = tableData[cellInfo.index].date;

    // const theDate = new Date(Date.parse(`${dateString} UTC`));

    // let finalDate = theDate.toString();
    // console.log(finalDate)

    let dateObject = new Date(dateString);
    console.log(dateObject)
    const updatedDate = dateObject.toString();
console.log(updatedDate)
    return (
      <div>
        <h6> {updatedDate.slice(4, 16)} </h6>
      </div>
    );
  };

  const handleClose = () => {
    setProcedureShow(false);
    setRerender(!reRender);
  };
  const ProcedurePopup = (props) => {

  
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <ProcedureList
            data={procedureData}
            modalHandle={handleClose}
            toggle="doctor"
            status={status}
            date={dateData}
            patient_id = {pId}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const viewProcedures = (data, allData) => {
    debugger;
    setProcedureData(data);
    setProcedureShow(true);
    setStatus(allData.status);
    setDateData(allData.date)
    setPId(allData.patient_id)
  };

  const renderProcedures = (cellInfo) => {
    if (tableData[cellInfo.index].all_procedures.length > 0) {
      return (
        <button
          onClick={() =>
            viewProcedures(
              tableData[cellInfo.index].all_procedures,
              tableData[cellInfo.index]
            )
          }
          className="btn btn-primary btn-col btn-square btn-small"
        >
          View
        </button>
      );
    }
  };

  const columns = [
    {
      Header: "Id",
      accessor: "patient_id",
      Cell: renderId,

      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().startsWith(filter.value.toLowerCase())
          : true;
      },
      width: 50,
    },
    {
      Header: (
        <>
          <h6>Patient Name</h6>
        </>
      ),
      accessor: "patient_name",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 150,
    },

    {
      Header: "Date",
      accessor: "date",
      Cell: renderDate,
      // style:{
      //   backgroundColor:"rgba(0,0,0,0.1)"
      // },

      width: 120,
      filterable: true,
      sortable: true,
    },
    {
      Header: "Procedures",
      accessor: "all_procedures",
      Cell: renderProcedures,

      width: 120,
    },

    // {
    //   Header: "Contact",
    //   accessor: "contact_number",
    //   Cell: renderContact,
    //    sortable:false,
    //     filterable:true,
    //    width:150

    // },

    {
      Header: (
        <>
          <h6>*Status</h6>
        </>
      ),
      accessor: "statusObj",
      Cell: renderStatus2,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value.toLowerCase() === "assigned") {
          return row[filter.id].value === "assigned";
        }
        if (filter.value.toLowerCase() === "ongoing") {
          return row[filter.id].value === "ongoing";
        }
        if (filter.value.toLowerCase() === "completed") {
          return row[filter.id].value === "completed";
        }
        if (filter.value.toLowerCase() === "rejected") {
          return row[filter.id].value === "rejected";
        }
        if (filter.value.toLowerCase() === "pending") {
          return row[filter.id].value === "pending";
        }
        
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="assigned">Assigned</option>
          <option value="ongoing">Ongoing</option>
          <option value="completed">Completed</option>
          <option value="rejected">Rejected</option>
          <option value="pending">Pending</option>
        </select>
      ),

      width: 160,
    },
    {
      Header: (
        <>
          <h6>Assignee</h6>
        </>
      ),
      accessor: "assign_name",
      Cell: renderAssigned2,

      sortable: true,
      filterable: true,

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 160,
    },

    // {
    //   Header: <><h6>Notes/Remarks</h6>
    //           </>,
    //    accessor: "remarks",
    //   Cell: renderRemarks3,
    //    sortable:false,
    //    filterable:true,
    //    filterMethod:(filter, row, column) => {
    //     const id = filter.pivotId || filter.id
    //     return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
    //   },
    //    width:120

    // },
    {
      Header: "Save Changes",
      Cell: (props) => {
        return (
          <button
            className="btn btn-primary btn-col btn-square btn-small"
            onClick={() => {
              saveChanges(props.index);
            }}
          >
            Save{" "}
          </button>
        );
      },
      sortable: false,
      filterable: false,
      minWidth: 50,
      width: 80,
    },
  ];

  const displayTable = (
    <ReactTable
      columns={columns}
      data={tableData}
      defaultPageSize={20}
      className="service-req-table procedure-table"
      resizable
    ></ReactTable>
  );

  const handleBack = (e) => {
    history.goBack();
  };
  return (
    <>
      <HospDocNavbar />
      <br />
      <div className="flex-head">
        <button
          className="btn btn-info topleft-single-btn"
          onClick={handleBack}
        >
          {" "}
          <i className="fas fa-chevron-left back-btn-icon"></i>Back
        </button>
        <h2 className="title-of-tasks title-mg"> Procedure Fulfillment</h2>
      </div>
      <br />
      <div className="">
        <div className="text-center">
          <div className="service-table-holder flex-col"> {displayTable}</div>
        </div>
      </div>
      <br />
      <br />

      <br />
      <br />
      <div className="info-notes-sr flex-row">
        <div className="info-div italics-text">
          <h6 className="">
            {" "}
            - Status: assigned, ongoing, completed and rejected requests are
            listed here{" "}
          </h6>
          <h6 className="">
            {" "}
            - Click on column header/title to sort the respective column in
            table{" "}
          </h6>
          <h6 className="">
            {" "}
            - Filter the result by searching in the input field at each column
            header{" "}
          </h6>
          <h6 className="">
            {" "}
            - Multisorting and multifiltering can be done at multiple columns{" "}
          </h6>
        </div>
      </div>

      {errorMsg !== "" ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => {
            setErrorSubmit(false);
            setErrorMsg("");
            setTableData([]);
            setRerender(!reRender);
          }}
        />
      ) : (
        ""
      )}

      {procedureShow ? (
        <ProcedurePopup
          show={procedureShow}
          onHide={() => {
            setProcedureShow(false);
          }}
        />
      ) : null}

      {modalShow ? (
        <AddPopup
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
      ) : null}

      {editShow ? (
        <EditPopup
          show={editShow}
          onHide={() => {
            setEditShow(false);
          }}
          onSave={() => {
            if (sendData !== tableData[cell].remarks) {
              const data = tableData;
              data[cell].remarks = sendData;
              setBackup(sendData);
              setTableData(data);
              setEditShow(false);
              setRender(!render);
              setUpdateSuccess(true);
              //saveChanges(cell)
            }
          }}
        />
      ) : null}

      {submitMsg !== "" ? (
        <SuccessPopup
          show={successSubmit}
          msg={submitMsg}
          onHide={() => {
            setSuccessSubmit(false);
            setSubmitMsg("");
            setTableData([]);
            setRerender(!reRender);
          }}
        />
      ) : (
        ""
      )}

      {updateSuccess ? (
        <UpdatePopup
          show={updateSuccess}
          onHide={() => {
            setUpdateSuccess(false);
          }}
        />
      ) : null}
    </>
  );
}

export default ProcedureRequests;
