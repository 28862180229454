import React, {useState, useEffect} from "react";
import WebNotifications from "./WebNotifications"
import {useHistory} from "react-router-dom";
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";

function WebNotificationsDr() {
	 let history=useHistory();
  let link=`${/patient-record/}`;
 const handleBack=(e)=>{
  history.goBack();
}

const [keys,] = useState({'p': false,'a': false,'m': false,'l': false,'s': false,});

useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



useEffect(()=>{

  window.onkeydown =(e) => {
	
	keys[e.key]=true

	if(e.altKey && keys['p']){
	  history.push('/patients-directory')
	}
	if(e.altKey && keys['a']){
	  history.push('/appointment')
	}
	if(e.altKey && keys['m']){
	  history.push('/message')
	}
	if(e.altKey && keys['l']){
	  history.push('/doctor/analytics')
	}
	if(e.altKey && keys['s']){
	  history.push('/service-requestlist')
	}


  }
  window.onkeyup=(ev)=> {
	
	keys[ev.key]= false
  }

  return()=>{
	window.onkeydown=null
	window.onkeyup=null
  }
},[]);
	return(<>
		<HospDocNavbar />
		<div className=" text-left">
		 		<br/>
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> 
		<WebNotifications baselink={link}/>
		</>
		)
}
export default WebNotificationsDr;