import React from "react";

import "./home.css";
import home1 from "../../images/home1.JPG";


function Home() {
  return (
    <div className="container page-container outer-home">
      <img src={home1} alt="home" />
    </div>
  );
}

export default Home;
