import React, {useState} from 'react'

function ProofImage(props) {

    const [rotation,setRotation]=useState(0);

    const handleRotateLeft=()=>{
        let newRotation = rotation - 90;
        if(newRotation >= 360){
          newRotation =- 360;
        }if(newRotation === -360){
          newRotation =0;
        }
        setRotation(newRotation);
       
      }

    return (
        <div>
            <img src={props.image} alt="id proof"  style={{display:'block', transform: `rotate(${rotation}deg)`}} className='img-popup'/>
            <br/>
              <button style={{display:'block'}} className="btn btn-primary btn-col" onClick={handleRotateLeft}> Rotate <i className="fa fa-undo" aria-hidden="true"></i> </button>
        </div>
    )
}

export default ProofImage
