import React,{useState} from 'react';
import { useHistory} from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import axios from "axios";
import {APIURL} from "../../Global";

function  InputOtp2(props){
	const [otpValue,setOtpValue]=useState('');
	const [user,]=useState(props.location.state.username);

 const [msg,]=useState(props.location.state.responseMessage);


  const [errorMsg,setErrorMsg]=useState(null);
 const [errorSubmit,setErrorSubmit]=useState(false);
const history=useHistory();



const ErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg} </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}


	const handleValidateOTP=(e)=>{
    e.preventDefault();
		 const dataToSend={
      username: user,
      otp: otpValue
    }
    axios.post(`${APIURL}/api/v1/account/forgot-password/`, dataToSend, { headers: {
      
     
      'Content-Type': 'application/json'  }


    })

    .then(res=>{
     
      if(res.data.status=== "success"){
           if(res.data.is_valid===true ){
 


    history.push({
  pathname: '/reset-password',
   state: { username: user,
            otp: otpValue 
            }
})


     
      
        }
        else{
          setErrorMsg("Invalid OTP");
              setErrorSubmit(true);
        }
      }
      else{

            setErrorMsg(res.data.message? res.data.message: "Error in OTP validation");
              setErrorSubmit(true);

            }
                
            })
     .catch(err =>{
      setErrorMsg("Error in OTP validation");
              setErrorSubmit(true);
    
  })

	
	}

	
	

return(<>
<div className="wrapper-content">
    <div className="forgot-password-page">
           
           
    
              
             


      
    
      <form className="login-form forgot-password-form" onSubmit={handleValidateOTP}> 
          <h4 className="form-heading title-of-tasks"> Enter OTP </h4>
        <h6 style={{color:"grey"}}> {msg} </h6><br/>
       {/* < div className=" " style={{color:"grey"}}> {user}</div>*/}
        <input type="text" maxLength='30' className="form-control" placeholder="Enter OTP here" value={otpValue} onChange={(e)=>{ 
                                                                                               e.preventDefault();
                                                                                                              setOtpValue(e.target.value);
                                                                                                              }} required/>
       


         <br/>
          <button type="submit" className="btn btn-primary btn-col"  >Validate OTP</button> 
          </form>

      
       
        
      
       
       
   



    {errorMsg!==null ?
    <ErrorPopup
        show={errorSubmit}
        onHide={() =>  setErrorSubmit(false)}
       
      />: null}
             

   </div>
   </div>

  </>


       )


   }
 export default InputOtp2;