import React, {useState,useEffect} from 'react'
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./service.css";
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";
import axios from "axios";
import {APIURL} from "../../Global";
import {useHistory} from "react-router-dom";
import {Tooltip} from 'react-bootstrap';
import {OverlayTrigger} from 'react-bootstrap';

function RentalOrderDetails() {

const [reRender,setRerender]=useState(false);
  const [,setGetError]=useState('');
const [tableData,setTableData]=useState([]);


    useEffect(() => { window.scrollTo(0, 0) }, [])
    let history=useHistory();
    
    const [keys,] = useState({'u': false,'c': false,'n': false,'a': false,'q': false,'l': false,});
    
    
    useEffect(()=>{
    
      window.onkeydown =(e) => {
        
        keys[e.key]=true
    
        if(e.altKey && keys['u']){
          history.push('/directory')
        }
        if(e.altKey && keys['c']){
          history.push('/careplans-db')
        }
        if(e.altKey && keys['n']){
          history.push('/notifications')
        }
        if(e.altKey && keys['a']){
          history.push('/analytics')
        }
        if(e.altKey && keys['q']){
          history.push('/qrcode')
        }
        if(e.altKey && keys['l']){
          history.push('/call-log')
        }
    
    
      }
      window.onkeyup=(ev)=> {
        
        keys[ev.key]= false
      }
    
      return()=>{
        window.onkeydown=null
        window.onkeyup=null
      }
    },[]);


    useEffect(()=>{
        const tokenString= localStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '')  ;
                
        
        
          axios.get(`${APIURL}/api/v1/hospital/rental-request-order/`, { headers: {
            
            "Authorization" : 'Token '+str  }})
          .then(res=>{
           
      
         
         
            if(res.data.status==="success"){
              
              let dataList= res.data.rental_data;
      
             
              let updatedList=[];
               let filteredList=dataList.filter((item,index)=>{
                return !(item.status.toLowerCase()==="completed" || item.status.toLowerCase()==="rejected")
              }).sort((a,b) => {
                return new Date(b.order_date) - new Date(a.order_date)
              })
              
              updatedList= filteredList.map((item)=>{
      
                        return({
                        name: item.patient_name,
                        patient_id:item.patient_id,
                        ref:item.order_id,
                        order: item.service_title,
                        rental_from:item.rental_from,
                        rental_to:item.rental_to,
                        amount: item.amount,
                        patient_side_status:item.patient_side_status,
                        over_due:item.over_due,
                        due_amount:item.due_amount,
                        extra_used_days:item.extra_used_days,
                        is_readed:item.is_readed,
                        rental_device_status:item.rental_device_status
                      }
                    )
              })
            
               setTableData(updatedList);
      
            }
       
              else{
              setGetError(res.data.message);
            }
            
      
      
          })
          .catch(err=>{
      
       setGetError("Error in fetching data");
      })
      
      
      },[reRender]);


      const renderId=(cellInfo)=> {
        if(tableData[cellInfo.index][cellInfo.column.id]){
          return (
      
            <div  className="fixed-col"> {tableData[cellInfo.index][cellInfo.column.id]}</div>
            
          );
        }
        else{
          return null
        }
        }
        const renderService=(cellInfo)=> {
        
          return (
           
            <div > <span style={{margin:"10px"}}> 
                {tableData[cellInfo.index][cellInfo.column.id]}
             </span>  
             <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
                Details: {tableData[cellInfo.index].details!== null? tableData[cellInfo.index].details: 'Not Available'} <br/>
                 
             
                </Tooltip> }><i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger> 
             </div>
            
          );
        }

        const renderName=(cellInfo)=> {
  
            return (<>
        
              <div  className="fixed-co"> 
                {tableData[cellInfo.index][cellInfo.column.id]}</div>
                <h6> (id : {tableData[cellInfo.index].patient_id} )</h6>
                </>
              
            );
          }

    const renderTable=(cellInfo)=> {
  
        return (
    
          <div  className="fixed-co"> {tableData[cellInfo.index][cellInfo.column.id]}</div>
          
        );
      }
    
      const renderOverdue=(cellInfo)=> {
      
        return (
    
          <div  className="fixed-co"> {tableData[cellInfo.index][cellInfo.column.id]===true ? "Yes" : "No"}</div>
          
        );
      }

      const renderDate=(cellInfo)=> {

        let dateString = tableData[cellInfo.index][cellInfo.column.id];
    
    let dateObject = new Date(dateString);
    
    const updatedDate = dateObject.toString();
        return (<div >
          <h6> {updatedDate.slice(4,16)}  </h6>
           <h6> {updatedDate.slice(16,24)}</h6>
         </div>)
    
       }


    const columns = [

        {
            Header: "Id",
            accessor: "ref",
            Cell: renderId,
            
             sortable:true,
              filterable:true,
              filterMethod:(filter, row, column) => {
              const id = filter.pivotId || filter.id
              return row[id] !== undefined ? String(row[id]).toLowerCase().startsWith(filter.value.toLowerCase()) : true
            },     
             width:50
          
          },
          {
            Header: "Equipment request",
            accessor: "order",
            Cell: renderService,
             sortable:true,
              filterable:true,
              filterMethod:(filter, row, column) => {
              const id = filter.pivotId || filter.id
              return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
            },     
             width:200
          
          },
          {
            Header: <><h6>Patient Name</h6>
                     </>,
            accessor: "name",
            Cell: renderName,
              sortable:true,
              filterable:true,
              filterMethod:(filter, row, column) => {
              const id = filter.pivotId || filter.id
              return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
            },         
             width:150
          
          },
        {
            Header: <><h6>Patient</h6>
                       <h6>side Status</h6>
                    </>,
            accessor: "patient_side_status",
            Cell: renderTable,
             sortable:true,
              filterable:true,
             filterMethod: (filter, row) => {
                              if (filter.value === "all") {
                                return true;
                              }
                              if (filter.value === "requested") {
                                return row[filter.id] === "requested";
                              }
                              if (filter.value === "received") {
                                return row[filter.id]=== "received";
                              }
                              
                               if (filter.value === "returned") {
                                return row[filter.id] === "returned";
                              }
                               if (filter.value === "lost") {
                                return row[filter.id] === "lost";
                              }
                              
                            },
                            Filter: ({ filter, onChange }) =>
                              <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: "100%" }}
                                value={filter ? filter.value : "all"}
                              >
                                <option value="all">All</option>
                                 <option value="requested">Requested</option>
                                <option value="received">Received</option>
                                <option value="returned">Returned</option>
                                <option value="lost">Lost</option>
                                
                              </select>,
                             
                        
             width:120
          
          },
          {
            Header: "Amount",
            accessor: "amount",
            Cell: renderTable,
             sortable:true,
                
             width:100
          
          },
          {
            Header: <><h6>Due </h6>
                       <h6>Amount</h6>
                    </>,
            accessor: "due_amount",
            Cell: renderTable,
             sortable:true,
                
             width:100
          
          },
          {
            Header: <><h6>Is Overdue?</h6>
                   
                    </>,
            accessor: "over_due",
            Cell: renderOverdue,
             sortable:true,
               filterable:true,
             filterMethod: (filter, row) => {
                              if (filter.value === "all") {
                                return true;
                              }
                              if (filter.value === "true") {
                                return row[filter.id] === true;
                              }
                              if (filter.value === "false") {
                                return row[filter.id]=== false;
                              }
                              
                            },
                            Filter: ({ filter, onChange }) =>
                              <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: "100%" }}
                                value={filter ? filter.value : "all"}
                              >
                                <option value="all">All</option>
                                 <option value="true">Yes</option>
                                <option value="false">No</option>
                                
                              </select>,
                             
             width:100
          
          },
          {
            Header: <><h6>Additional </h6>
                       <h6>days used</h6>
                    </>,
            accessor: "extra_used_days",
            Cell: renderTable,
             sortable:true,
                
             width:100
          
          },
          {
            Header: <><h6>Rental </h6>
                       <h6>From</h6>
                    </>,
            accessor: "rental_from",
            Cell: renderDate,
             sortable:true,
                
             width:100
          
          },
          {
            Header: <><h6>Rental </h6>
                       <h6>To</h6>
                    </>,
            accessor: "rental_to",
            Cell: renderDate,
             sortable:true,
                
             width:100
          
          },
          {
            Header: <><h6>Rental </h6>
                       <h6>Status</h6>
                    </>,
            accessor: "rental_device_status",
            Cell: renderTable,
             sortable:true,
              filterable:true,
             filterMethod: (filter, row) => {
                              if (filter.value === "all") {
                                return true;
                              }
                              if (filter.value === "pending") {
                                return row[filter.id] === "pending";
                              }
                              if (filter.value === "packing") {
                                return row[filter.id]=== "packing";
                              }
                              
                               if (filter.value === "dispatched") {
                                return row[filter.id] === "dispatched";
                              }
                               if (filter.value === "delivered") {
                                return row[filter.id] === "delivered";
                              }
                              if (filter.value === "u_returned") {
                                return row[filter.id] === "u_returned";
                              }
                               if (filter.value === "u_lost") {
                                return row[filter.id] === "u_lost";
                              }
                              
                            },
                            Filter: ({ filter, onChange }) =>
                              <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: "100%" }}
                                value={filter ? filter.value : "all"}
                              >
                                <option value="all">All</option>
                                 <option value="pending">Pending</option>
                                <option value="packing">Packing</option>
                                <option value="dispatched">Dispatched</option>
                                <option value="delivered">Delivered</option>
                                <option value="u_returned">User-Returned</option>
                                <option value="u_lost">User-Lost</option>
                                
                              </select>,
                             
                        
             width:140
          
          }

    ]

    const displayTable=<ReactTable 
    columns={columns}
    data={tableData}
    defaultPageSize = {25}
    className="service-req-table"
    resizable
 
           
    >

    </ReactTable> 

const handleBack=(e)=>{
history.goBack();
}


    return (
        <>
          <HospBdoNavbar />
          <br/>
  <div className="flex-head">
        
          <button className="btn btn-info topleft-single-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <h2 className="title-of-tasks title-mg"> Equipment Tracking</h2>
    </div> 
    <br/>
    <div className="">
    



      <div className="text-center">
        <div className="service-table-holder flex-col"> {displayTable}
        </div>
    
     
    </div>
    </div>
    <br/>
    <br/>
    
    <br/>
    <br/>
    <div className="info-notes-sr flex-row">
      <div className="info-div italics-text">
     <h6 className="" > - Click on column header/title to sort the respective column in table </h6>
     <h6 className="" > - Filter the result by searching in the input field at each column header </h6>
     <h6 className="" > - Multisorting and multifiltering can be done at multiple columns   </h6>
     </div>

</div>
        </>
    )
}

export default RentalOrderDetails
