import React, { useState, createContext } from "react";

export const BookingContext = createContext();

export const BookingProvider = (props) => {
  const [refresh,setRefresh] = useState(false);
   
  return (
    <BookingContext.Provider value={[refresh,setRefresh]}>
      {props.children}
    </BookingContext.Provider>
  );
};