import React, {useState, useEffect} from "react";



import {useHistory} from "react-router-dom";
import "./analytics.css";
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";


import Analytics from "./Analytics"
function AnalyticsBdo() {
  
  let history=useHistory();

   
  const handleBack=(e)=>{
  history.goBack();
}

const [keys,] = useState({'u': false,'c': false,'n': false,'s': false,'q': false,'l': false,});

useEffect(() => {
  window.scrollTo(0, 0);
}, []);

useEffect(()=>{

  window.onkeydown =(e) => {
    
    keys[e.key]=true

    if(e.altKey && keys['u']){
      history.push('/directory')
    }
    if(e.altKey && keys['c']){
      history.push('/careplans-db')
    }
    if(e.altKey && keys['n']){
      history.push('/notifications')
    }

    if(e.altKey && keys['s']){
      history.push('/service-requests')
    }
    if(e.altKey && keys['q']){
      history.push('/qrcode')
    }
    if(e.altKey && keys['l']){
      history.push('/call-log')
    }


  }
  window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
  }

  return()=>{
    window.onkeydown=null
    window.onkeyup=null
  }
},[]);

  return (
   <> 
   <HospBdoNavbar/>
   <div className=" text-left">
        <br/>
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> 


   <Analytics />
   </>
  );
}

export default AnalyticsBdo;
