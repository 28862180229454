import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./addsda.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./dob.css";
import DatePicker from "react-date-picker";
import { APIURL } from "../../Global";

function AddSda() {
  const [sdaDatas, setSdaDatas] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    code: "",
    email: "",
    country: "",
    state: "",
    location: "",
    //specializations: '',
    //qualification : [],
    //department:"",
    dob: "",
    gender: "",
  });

  const [error, setError] = useState({
    email: "",
    phone: "",
  });

  const [dobValue, setDobValue] = useState();

  const [errorMsg, setErrorMsg] = useState("");

  const [, setFormattedDob] = useState("");

  const [submitMsg, setSubmitMsg] = useState("");
  const [validateRawPhoneMsg, setValidateRawPhoneMsg] = useState("");
  const [rawPhone, setRawPhone] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);

  const [errorShow, setErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const history = useHistory();

  const [keys] = useState({
    d: false,
    a: false,
    b: false,
    n: false,
    s: false,
    l: false,
    p: false,
    c: false,
    h: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.onkeydown = (e) => {
      keys[e.key] = true;

      if (e.altKey && keys["d"]) {
        history.push("/doctors-list");
      }
      if (e.altKey && keys["a"]) {
        history.push("/hospital/add");
      }
      if (e.altKey && keys["b"]) {
        history.push("/sda-list");
      }
      if (e.altKey && keys["n"]) {
        history.push("/emergency-number");
      }
      if (e.altKey && keys["s"]) {
        history.push("/service-request-list");
      }
      if (e.altKey && keys["l"]) {
        history.push("/license-details");
      }
      if (e.altKey && keys["p"]) {
        history.push("/license-payment");
      }
      if (e.altKey && keys["c"]) {
        history.push("/hosp-calllog");
      }
      if (e.altKey && keys["h"]) {
        history.push("/payment-history");
      }
    };
    window.onkeyup = (ev) => {
      keys[ev.key] = false;
    };

    return () => {
      window.onkeydown = null;
      window.onkeyup = null;
    };
  }, []);

  function SubmitPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps">{submitMsg}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/sda-list">
            <Button className="btn btn-primary btn-col"> Goto SDA List </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    );
  }

  function ErrorPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps" style={{ color: "red" }}>
            {errorMsg}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleDOB = (date) => {
    setDobValue(date);

    let dateUpdated = date;
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      /*let dateString= DateExtracted.toString(); */
      setFormattedDob(DateExtracted);

      setSdaDatas((currentstate) => ({
        ...currentstate,
        dob: DateExtracted,
      }));
    }
  };

  const handleGenderRadioButton = (e) => {
    let genderOpted = e.target.value;
    setSdaDatas((currentstate) => ({
      ...currentstate,
      gender: genderOpted,
    }));
  };

  const handleSubmit = () => {
    if (rawPhone !== null && rawPhone !== "" && rawPhone !== " ") {
      setValidateRawPhoneMsg("success");
      const tokenStr = localStorage.getItem("usertoken");

      if (tokenStr) {
        const v = tokenStr;
        let v2 = v.replace(/["]+/g, "");

        const dataToSend = {
          name: sdaDatas.firstname + " " + sdaDatas.lastname,
          location: sdaDatas.location,
          state: sdaDatas.state,
          country: sdaDatas.country,
          //specializations : sdaDatas.specializations,
          email: sdaDatas.email,
          //qualification: sdaDatas.qualification,
          phone: sdaDatas.code + sdaDatas.phone,
          code: sdaDatas.code,
          //department : sdaDatas.department,
          dob: sdaDatas.dob,
          gender: sdaDatas.gender,
        };

        axios
          .post(`${APIURL}/api/v1/hospital/sda/`, dataToSend, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + v2,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              setSubmitMsg(
                res.data.message ? res.data.message : "Successfully added"
              );

              setModalShow(true);
            } else {
              setErrorMsg(
                res.data.message ? res.data.message : "Error in submission"
              );

              setErrorShow(true);
            }
          })
          .catch((err) => {
            setErrorMsg("Error in submission");
          });
      }
    } else {
      setValidateRawPhoneMsg("error");
    }
  };

  const handleCheckEmail = (e) => {
    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        email: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        [e.target.name]: e.target.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                [key]: `This ${key} is already registered!`,
              }));
              setIsValidEmail(false);
            } else {
              setIsValidEmail(true);
            }
          } else {
            setIsValidEmail(true);
          }
        })
        .catch((err) => {});
    }
  };

  const handleCheckExistingPhone = (e) => {
    setIsValidPhone(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        phone: "",
      }));

      const data = {
        code: dialCode,
        mobile_number: rawPhone,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                phone: "This Phone Number is already registered!",
              }));
              setIsValidPhone(false);
            } else {
              setIsValidPhone(true);
            }
          } else {
            setIsValidPhone(true);
          }
        })
        .catch((err) => {});
    }
  };

  const handleContinue = async (e) => {
    e.preventDefault();

    if (validate() && isValidEmail && isValidPhone) {
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    setSdaDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
  };

  // const handleQualification=e=>{
  //   e.preventDefault();
  //   const val=e.target.value;

  //       const key=e.target.name;
  //       let arr=[];
  //       if(val && val.includes(',')){
  //         arr = val.split(',');
  //       }
  //       else{
  //       arr.push(val);
  //       }
  //       setSdaDatas(currentstate=>({
  //         ...currentstate,
  //         [key]: arr
  //       }));

  // }

  const handleCountry = (val) => {
    setSdaDatas((currentstate) => ({
      ...currentstate,
      country: val,
    }));
  };

  const handleRegion = (val) => {
    setSdaDatas((currentstate) => ({
      ...currentstate,
      state: val,
    }));
  };

  const handlePhoneInput = (value, data, event, formattedValue) => {
    setRawPhone(value.slice(data.dialCode.length));
    setDialCode(value.slice(0, data.dialCode.length));
    setSdaDatas((currentstate) => ({
      ...currentstate,
      phone: value.slice(data.dialCode.length),
      code: value.slice(0, data.dialCode.length),
    }));
  };

  const validate = () => {
    let input = sdaDatas;
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["firstname"] || input["firstname"] === "") {
      isValid = false;
      errors["firstname"] = "Please enter first name";
    }

    if (!input["lastname"] || input["lastname"] === "") {
      isValid = false;
      errors["lastname"] = "Please enter last name";
    }

    // if (!input["specializations"] || input["specializations"]==="") {
    //   isValid = false;
    //   errors["specializations"] = "Please enter specializations";
    // }

    if (!input["location"] || input["location"] === "") {
      isValid = false;
      errors["location"] = "Please enter location";
    }

    if (!input["country"] || input["country"] === "") {
      isValid = false;
      errors["country"] = "Please select a country";
    }

    if (!input["state"] || input["state"] === "") {
      isValid = false;
      errors["state"] = "Please select a region";
    }

    // if (!input['qualification'] || input['qualification'].length===0 || input['qualification'][0]==="") {
    //   isValid = false;
    //   errors["qualification"] = "Please enter qualification";
    // }

    if (!dialCode || dialCode === "" || !rawPhone || rawPhone === "") {
      isValid = false;
      errors["phone"] = "Please enter phone number";
    }

    if (
      typeof dobValue == "undefined" ||
      dobValue === "" ||
      dobValue === null
    ) {
      isValid = false;
      errors["dob"] = "Please enter your date of birth";
    }
    if (
      typeof sdaDatas.gender == "undefined" ||
      sdaDatas.gender === "" ||
      sdaDatas.gender === null
    ) {
      isValid = false;
      errors["gender"] = "Please select gender";
    }

    if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
      }
    }

    if (typeof input["firstname"] !== "undefined") {
      var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern1.test(input["firstname"])) {
        isValid = false;
        errors["firstname"] = "Special Characters are not allowed";
      }
    }
    if (input["firstname"] && typeof input["firstname"] !== "undefined") {
      if (input["firstname"].length > 0 && input["firstname"].length < 3) {
        isValid = false;
        errors["firstname"] = "Must contain atleast 3 characters";
      }
    }

    if (typeof input["lastname"] !== "undefined") {
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern2.test(input["lastname"])) {
        isValid = false;
        errors["lastname"] = "Special Characters are not allowed";
      }
    }
    if (rawPhone.length > 0 && rawPhone.length < 10) {
      isValid = false;
      errors["phone"] = "Please check numbers ";
    }

    if (!input["email"]) {
      isValid = false;

      errors["email"] = "Please enter email Address.";
    }

    if (isValidPhone === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }
    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
    }

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };

  return (
    <div className="add-dr-page">
      <HospitalNavbar />

      <div className="add-dr-form-holder">
        <div className="quick-links">
          <Link to="/sda-list">
            <button type="button" className="btn btn-primary btn-col dr-btn">
              {" "}
              Goto SDA List{" "}
            </button>
          </Link>
        </div>
        <form className="add-dr-form" onSubmit={handleContinue}>
          <h4 className="title-of-page"> Add SDA Details</h4>
          <div className="add-dr-div">
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* First Name: </label>
              <input
                className="form-control"
                type="text"
                maxLength="30"
                placeholder=""
                name="firstname"
                onChange={handleChange}
              />
              {error.firstname ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.firstname}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Last Name: </label>

              <input
                className="form-control"
                type="text"
                maxLength="30"
                placeholder=""
                name="lastname"
                onChange={handleChange}
              />
              {error.lastname ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.lastname}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Email Address: </label>

              <input
                className="form-control "
                type="email"
                maxLength="50"
                placeholder=""
                name="email"
                onChange={handleChange}
                onBlur={handleCheckEmail}
              />
              {error.email ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.email}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Phone: </label>

              <PhoneInput
                inputProps={{}}
                country={"in"}
                onChange={handlePhoneInput}
                onBlur={handleCheckExistingPhone}
              />
              {error.phone ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.phone}
                </div>
              ) : null}

              {submitMsg !== null && submitMsg !== "" ? (
                <p style={{ color: "red" }}>{submitMsg}</p>
              ) : (
                ""
              )}
            </div>

            {/* <div className="form-group col-lg-6 item-left-row">


         <label className="">* Qualifications: </label>


          
          <input className="form-control" type="text" maxLength='30' placeholder="" name="qualification"  onChange={handleQualification} />
          {error.qualification ? <div style={{zIndex:'auto'}} className="error-validation-msg">{error.qualification}</div> : null}
        </div>
        <div className="form-group col-lg-6 item-left-row">

         <label className="">* Specializations: </label>

          <input className="form-control" type="text" maxLength='30' placeholder="" name="specializations"  onChange={handleChange} />
          {error.specializations ? <div style={{zIndex:'auto'}} className="error-validation-msg">{error.specializations}</div> : null}
        </div>
        
        
       <div className="form-group col-lg-6 item-left-row">
       <label className=""> Department: </label>
      
          <input className="form-control" type="text" maxLength='30' placeholder="" name="department"  onChange={handleChange}/>
        </div> */}

            <div className="form-group dob-check col-lg-6">
              <div className="dob-container form-control">
                <span>Date of Birth * : </span>
                <DatePicker
                  onChange={(date) => {
                    handleDOB(date);
                  }}
                  maxDate={new Date()}
                  value={dobValue}
                />
              </div>
              {error.dob ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.dob}
                </div>
              ) : null}
            </div>

            <div className="form-group gender-radio-check col-lg-6">
              <div className="form-control">
                <span>*Gender:</span>
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="male"
                  checked={sdaDatas.gender === "male"}
                  onChange={handleGenderRadioButton}
                />
                <label htmlFor="male">Male</label>

                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="female"
                  checked={sdaDatas.gender === "female"}
                  onChange={handleGenderRadioButton}
                />

                <label htmlFor="female">Female</label>

                <input
                  type="radio"
                  id="other"
                  name="gender"
                  value="other"
                  checked={sdaDatas.gender === "other"}
                  onChange={handleGenderRadioButton}
                />
                <label htmlFor="other">Other</label>
              </div>
              {error.gender ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.gender}
                </div>
              ) : null}
            </div>

            <div className="form-group col-lg-6 country-selector-dr">
              <label className="">* Country: </label>

              <CountryDropdown
                value={sdaDatas.country}
                onChange={(val) => handleCountry(val)}
              />
              {error.country ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.country}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 region-selector-dr">
              <label className="">* Region: </label>

              <RegionDropdown
                country={sdaDatas.country}
                value={sdaDatas.state}
                onChange={(val) => handleRegion(val)}
              />
              {error.state ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.state}
                </div>
              ) : null}
              <small id="emailHelp" className="form-text text-muted">
                *Select Region - (Enabled once you select Country)
              </small>
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className=""> * Location/ City : </label>

              <input
                className="form-control"
                type="text"
                maxLength="30"
                placeholder=""
                name="location"
                onChange={handleChange}
              />
              {error.location ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.location}
                </div>
              ) : null}
            </div>
          </div>
          <button type="submit" className="btn btn-primary btn-col">
            {" "}
            Submit Data
          </button>{" "}
          <br />
          <br />
          <h6 className="text-muted">All fields marked * are mandatory</h6>
        </form>

        {submitMsg !== "" ? (
          <SubmitPopUp
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setSubmitMsg("");
            }}
          />
        ) : null}

        {errorMsg !== "" ? (
          <ErrorPopUp
            show={errorShow}
            onHide={() => {
              setErrorShow(false);
              setErrorMsg("");
            }}
          />
        ) : null}

        {/************************Unused***************
    {(() => {
      switch(submitMsg){
      case "success":
      return   (
          <div>
            <h5 style={{ color: "green" }}> Data submitted sucessfully !!</h5>{" "}

            


            <br />
            <br />
           
          
            
          </div>
        );

        case "error": 
        return (
          <h5 style={{ color: "red" }}>
            
            Error in data submission.Please check again!
          </h5>
        );
        default: 
        return "";
           }
      })()}

    *************************/}

        {validateRawPhoneMsg === "error" ? (
          <h5 style={{ color: "red" }}>Phone number field is mandatory!</h5>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AddSda;
