import React ,{useState} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import './profile.css'
import DatePicker from 'react-date-picker';
import {Button ,Modal } from 'react-bootstrap';

function DobUpdate(props) {
    const [dob, setDob] = useState('');
    const [formattedDob, setFormattedDob] = useState("");
    const [show, setShow]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);
    const [error,setError]=useState({
        dob:''
       });

    const handleDOB=(date)=>{

        setDob(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
      /*let dateString= DateExtracted.toString(); */
      setFormattedDob(DateExtracted);
        }
      }

      const  validate = () => {
        //let input = email;
        let errors = {};
        let isValid = true;
  
        if (dob==="" ) {
          isValid = false;
          errors["dob"] = "Please select a date of birth";
        }
    
        setError(currentstate=>({
          ...currentstate,...errors
        }))
    
        return isValid;
    }

    const handleContinue =async(e) => {
      e.preventDefault();
  
      if( validate() ){
        submitDob()
      }
    }

      const submitDob = (e) =>{
        const tokenString= localStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '') 
      
         const data = {
           dob : formattedDob
         }
      
         axios.put(`${APIURL}/api/v1/account/profile/` ,data, { headers: {
      
          "Authorization" : 'Token '+str  }})
        .then(res=>{
        
              
          if(res.data.status==="success"){
      
            setSubmitMsg("success");
            setShow(true)
           setDob()
           
        
          }else{
            setErrorMsg(res.data.message);
            setErrorSubmit(true);
          
           
          }
        
        })
        .catch(err=>{
          setErrorMsg("error");
  
          setErrorSubmit(true);

        
        })
      
      }


      const ConfirmPopUp = ({show,onHide}) => {
        return(
        <Modal
            show
            onHide
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 id="confirm-reject">Updated Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={onHide}>Ok</Button>          
      
            </Modal.Footer>
          </Modal>
        )
      }
      
      
      const SubmitErrorPopup =(props)=>{
          
      
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }




    return (
        <div>
        <div style={{marginBottom:'180px'}} className="form-control dob item">
            <span >Date of Birth : </span>
                    <DatePicker
                          onChange={date=>{handleDOB(date)}}

                          maxDate = {new Date()}
                          value={dob}
                          
                     /> 
                        {error.dob ? <div className="error-validation-msg"> {error.dob}</div> : null}
                      
            </div>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update DOB</button>
        </div>

        {
         submitMsg==="success" ?
        <ConfirmPopUp
            show={show}
            onHide= { ()=>{
              setShow(false) 
              props.handle()
              setSubmitMsg('');
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }


        </div>
    )
}

export default DobUpdate
