import React, { useEffect,useState} from "react";


import axios from "axios";
import 'react-table-v6/react-table.css';
import {useHistory} from "react-router-dom";
import {APIURL} from "../../Global";
import "./comparisonreport.css";
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";

function ComparisonReport(props) {

   const [,setErrorMsg]=useState();
   const [selectedIDs,]=useState(props.location.state.sel);
   const [selectedReports,setSelectedReports]=useState([]);
   const [headerList,setHeaderList]=useState([]);
let tableDisplay=null;
 let newList=[];
   
   let counter=headerList.length;

   const [newData,setNewData]= useState([]);
   const [reportIdRequest,setReportIdRequest]=useState();

useEffect(()=>{
 let str=null;
 selectedIDs.forEach((ID,index)=>{
  if(index===0){
  str=`report_id=${ID}`;
   }
   else{
  str= str+`&report_id=${ID}`;
   }
 });
   setReportIdRequest(str);
  

},[selectedIDs]);

/*useEffect(()=>{
  let hdrList=[];
hdrList.push({id:null,label:"Test Name"});
  selectedIDs.map((ID,index)=>{
    hdrList.push({
      id: ID,
      label: `Report ${ID}`});
  });
  setHeaderList(hdrList);
  
},[selectedIDs]);*/

useEffect(()=>{
  let hdrList=[];
hdrList.push({id:null,label:"Test Name",date:null});
  selectedReports.map((Report,index)=>{
    let rep_id=Report.report_id;
    let rep_date=Report.date;
    hdrList.push({
      id: rep_id,
      label: `Report ID ${rep_id}`,
      date: rep_date});
  });
  setHeaderList(hdrList);
  
},[selectedReports]);
useEffect(()=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
    
     
  
    axios.get(`${APIURL}/api/v1/patient/labtest-comparison/?${reportIdRequest}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     
      if(res.data.status==="success"){
        
        setNewData(res.data.data);
        setSelectedReports(res.data.reports);
       
     

      }else{
        setErrorMsg(res.data.message? res.data.msg : "Error in fetching data");
      }


    })
    .catch(err=>{
 
  setErrorMsg("Error in fetching data");
})
 
},[reportIdRequest]);


   useEffect(()=>{
     tableDisplay= <table>
        <tr className="table-header">
        {headerList.map(header=>{
           return(<th>{header.label} {header.date}</th>)
        })}

       </tr>
       {newList.length>0 ? newList.map((rowData,i)=>{
        return (<tr className="table-row">
           <td className="table-test-name">{rowData.name}</td>
           {rowData.reps.map((reportValue,index)=>{
            return <td>{reportValue}</td>
           })}
        </tr>)
       })
       : null}

        


        </table>

      },[newList,newData])
  
    let history=useHistory();



const handleBack=(e)=>{
  history.goBack();
}




Object.entries(newData).map((item,i) => {

  
  let dataObj={};
  let repsArray=[];
  for(let ctr=1;ctr<counter;ctr++){
   repsArray.push(null);
  }
 
  

  dataObj.name=item[0];
  item[1].map((itemData,index)=>{

  

  for(let ctr=1; ctr<counter;ctr++){
   
    if(itemData.report_id === headerList[ctr].id){
    
    if(itemData.unit!==null){
     repsArray[ctr-1]=`${itemData.value} ${itemData.unit}`;
   }else{
    repsArray[ctr-1]=`${itemData.value}`;
   }


    }
  }
   
   
  })


  dataObj.reps=repsArray;

  newList.push(dataObj);

  
})

 tableDisplay=
        <table className="comparison-table">
        <tr className="table-header">
        {headerList.map((header,index)=>{
           return(<th><div className={index===0 ? "" : "table-report-id box-3"}><h5> {header.label} </h5></div>
            {header.date ? <h6>  {header.date.slice(0,10)} {header.date.slice(11,16)}  </h6>
            : null}
            </th>)
        })}

       </tr>
      

       {newList.length>0 ? newList.map((rowData,i)=>{
       return(<tr className="table-row">
               <td className="table-test-name">{rowData.name} </td>
           {rowData.reps.map((reportValue,index)=>{
            return(<td>{reportValue}</td>)
           })}
        </tr>)
       })
       : null}

        


        </table>

  return (
 

    <>
    <HospDocNavbar/>
     <div className="comparison-page">
     <div className=""><br/>
    <h2 className="title-of-tasks"> Lab Reports : Comparison Report</h2>
    <div className=" text-left">
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> 
         {/* <h3 className=""> Patient  ID : {patientId} </h3><br/><br/> */}


</div>

<div className="food-habits-sections">

  

     <div className="food-table">
    
<br/>
    
   <div className="comparison-table-container">
    {tableDisplay}
    </div>
    <br/>
     </div>
   
     </div>
     </div>
     </>
  );
}

export default ComparisonReport;