import React, {useState, useEffect, useContext} from 'react'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import axios from "axios";
import {APIURL} from "../../../Global";
import "./connectedpatients.css";
import GenerateInvoice from "../GenerateInvoice/GenerateInvoice"
import {SubscribersContext} from "../../../contexts/SubscribersContext";
import CareProgram from "../../CareProgram_Details/CareProgramDetails";

function AddPatient(props) {

    const [careList,setCareList]=useState([]);
    const [careId ,setCareId]= useState('');
    const [careProgramId ,setCareProgramId]= useState('');
    const [name,]=useState(props.name);
    const [phn,]=useState(props.phn);
    const [patientId,]=useState(props.id);
    const [careName, setCareName]= useState('');
    const [successShow ,setSuccessShow]= useState(false);
    const [errorShow ,setErrorShow]= useState(false);
    const [errorMsg ,setErrorMsg]= useState('');
      const [successMsg ,setSuccessMsg]= useState('')
    const [show ,setShow]= useState(false)
    const [careShow ,setCareShow]= useState(true)
    const [tenure ,setTenure]= useState(1)
    const [backShow ,setBackShow]= useState(false)
    const [headingToggle ,setHeadingToggle]= useState(true)
    const [invoiceShow,setInvoiceShow]=useState(false);
     const [refresh,setRefresh]= useContext(SubscribersContext);
       const [,setTitle]=useState();
        const [price,setPrice]=useState();
   const [discount,setDiscount]=useState();
 const [currency,setCurrency]=useState();
  const [,setIsActive]=useState();
  const [taxAmount,setTaxAmount]=useState();
   const [pricingType,setPricingType]=useState();
 const [,setOfferText]=useState();
 const [,setGetError]=useState('');
 const [detailsShow,setDetailsShow]=useState(false);
 const [detailsMsg,setDetailsMsg]=useState("");

    useEffect(()=>{
        const tokenString= localStorage.getItem('usertoken');
       
       let str= tokenString.replace(/["]+/g, '');
    
       
        
       
        axios.get(`${APIURL}/api/v1/hospital/care-programs/`, 
        { headers: {
          'Content-Type': 'application/json',
          "Authorization" : 'Token '+str  }})
           .then(res=>{
        
    
       
    
          if(res.data.status==="success"){
          
         setCareList(res.data.care_programs);
    
        
    
       
          
    
          }else{
          
          }
    
    
        })
        .catch(err=>{
      
     
    })
     
    
     
    },[])


useEffect(()=>{

    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;


  
    axios.get(`${APIURL}/api/v1/hospital/care-programs/${careId}/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){

       setTitle(res.data.data.title);
       setDiscount(res.data.data.discounted_price);
       setPrice(res.data.data.price);
       setCurrency(res.data.data.currency);
       setIsActive(res.data.data.is_active);

       let priceType=null;

  if(res.data.data.pricing_type==="one_month"){
    priceType="1 month (Monthly)";
  }
   else if(res.data.data.pricing_type==="three_months"){
    priceType="3 months (Qaurterly)";
  }
  else if(res.data.data.pricing_type==="six_months"){
    priceType="6 months (Half-yearly)";
  }
  else if(res.data.data.pricing_type==="one_year"){
    priceType="1 year (Yearly)";
  }
  else if(res.data.data.pricing_type==="two_year"){
    priceType="Life time(2 years)";
  }
  setPricingType(priceType);

       setTaxAmount(res.data.data.tax_percentage)
       setOfferText(res.data.data.description);
     

     
         

      }

        else{
        setGetError(res.data.message);
      }
      


    })
    .catch(err=>{

 setGetError("Error in fetching data");
})


},[careId]);
    const handleSelect = () => {

        const tokenString= localStorage.getItem('usertoken');
       
       let str= tokenString.replace(/["]+/g, '');

       const dataToSend={
        patient_id: props.id,
        care_program_id: careId,
        tenure:tenure
       }
     
        axios.post(`${APIURL}/api/v1/hospital/care-programs/subscribers/manage/`,dataToSend, { headers: {
          
          "Authorization" : 'Token '+str,
          'Content-Type': 'application/json'  }
    
    
        })
    
        .then(res=>{
         
          if(res.data.status=== "success"){
         
            setSuccessShow(true);
            setRefresh(!refresh);
            setSuccessMsg(res.data.message? res.data.message : "" );

            
          }
          else{
           setErrorShow(true)
           setErrorMsg(res.data.message)
          }
        })
        .catch(err=>{
            setErrorShow(true)
        
    
    })


    }

    const SuccessPopup =(props)=> {
        return(
            <Modal
                {...props}
               
                      aria-labelledby="contained-modal-title-vcenter"
                centered
                 backdrop="static"
                  keyboard={false}
                   dialogClassName='modal-90w'
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
        
              
                 <div className="tenure-content">

           {invoiceShow?
           

   <div className="invoice-holder">
                  <GenerateInvoice careId={careId} careName={careName} tenure={tenure} patientId={patientId} name={name} phn={phn}/>
          <br/>
                            <div className="divider"></div>
                            <div className="text-right"><button  className="btn btn-primary btn-col btn-small text-right" onClick={()=>setInvoiceShow(false)}>X Close</button>
                </div>
                <br/>
    
                
               </div>
               : 
              <div>

              <h4 className="text-success">{successMsg!==''?successMsg : <h4 className="text-danger">Added Patient Successfully!</h4>}</h4>

                  <button style={{}} className="btn btn-danger btn-small btn-square" onClick={()=>setInvoiceShow(true)}>Generate Invoice</button>
          </div>
           }
                 </div> 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
          
                </Modal.Footer>
              </Modal>
            )
    }

    const ErrorPopup =(props)=> {
        return(
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                 backdrop="static"
                  keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
        
               <h5 className="text-danger">{errorMsg!==''?errorMsg : <h5 className="text-danger">Error in Data Submission. Please try again!</h5>}</h5>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
          
                </Modal.Footer>
              </Modal>
            )
    }

    const DetailsPopup =(props)=> {
        return(
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                 backdrop="static"
                  keyboard={false}
                   dialogClassName='modal-90w'
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
          
              <CareProgram careId={careProgramId} />  
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Close</Button>          
          
                </Modal.Footer>
              </Modal>
            )
    }

    const handleSuccess = ()=> {
        setSuccessShow(false)
        let modal =false
        props.modalStatus(modal)
        let toggle = !props.refresh
        props.modalToggle(toggle)
    }

    const handleChange =(e)=> {
      const val =e.target.value
      if(val.length<=2){
        setTenure(val)
      } else{
        return false;
    } 
  }

    const handleBack= ()=> {
      setBackShow(false)
      setShow(false)
      setCareShow(true)
      setHeadingToggle(true)
    }

    const handleDetails2= (id)=> {

      setDetailsMsg("show");
          setDetailsShow(true);
          setCareProgramId(id);

    }


    const display= careList.length> 0 ? careList.map((care,index)=>{
  if(care.is_active && care.is_obselete===false){
      return(<div key={index} className='box-display'><span className="outer-box-name">
       <div  className={care.is_active ? "each-list-title box-care-pgm box-care-pgm-lg" : " inactive-box"}>
              
               
                <h5 className="each-title">
    
    
                  {" "}
                 <span className="content-inside">
                 <h3 style={{fontSize:'24px', fontWeight:'bold'}}>{care.title} </h3>
                <h6 style={{fontWeight:'bold'}}> ID: {care.id} {care.is_active ? null: ", Inactive" }</h6>
                      </span>
                  
                    
                </h5>

                <div className="flex-row"><button onClick={()=>handleDetails2(care.id)} className='btn btn-primary btn-col btn-small btn-square'>Details</button>
                { care.is_active ? 
                  <button style={{marginLeft:'10px'}} onClick={()=> {setShow(true); setCareShow(false); setCareId(care.id); setCareName(care.title); setBackShow(true); setHeadingToggle(false)}} className='btn btn-primary btn-col btn-small btn-square'>Subscribe</button>: null}

                </div>
                </div></span>
                  </div>
    )}
    })
    : null 
    return (
      <>
    {
      backShow?
    
      <div className=" text-left">
        <br/>
          <button className="btn btn-info back-btn" onClick={handleBack} > <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> : null }
        <div className="hospital-dashboard-container hosp-bdo-page"> <br/>        
       <h2 style={{textAlign:'center'}} className="title-bdo"> Subscribe patient to Care Plan </h2>
       {
         headingToggle?
         <>
                
               <h6 style={{textAlign:'center'}} className="text-muted">Select Care Plan from the list to add subscription</h6>
            </>   : null
       }
       

            <br/>

     <div className="row-jumbo-content">
                     
            <div className=" jumbo-text-content bdo-tasks">
                  
            <div className="container flex-center ">


            { careList.length>0 ? careShow?
                 display: null :  <h6 style={{color:"red"}}>No Care Plans Found</h6>}
              
            {show ? 
             <div className='tenure-content'>
              {/* <h3 className=''><span className='span-color'>{careName}</span></h3><br/>**/}

                <div className="pgm-details-box">
    <div className="pgm-details-header box-theme">
                               

                      <h6 className="sub-section-title pgm-header-title"> Care Plan: {careName}  </h6>
                      </div>
   <br/>
     {/*****Details***********/}
   <div className="care-pgm-details-body">
   
    <h6 className="features-form text-left">Standard Price: <span className="price-box"><span className="currency-uppercase"> {currency}</span> {price}</span></h6>
    <br/>
      <h6 className="features-form text-left">**Price after Discount : <span className="price-box"><span className="currency-uppercase"> {currency}</span> {discount} </span></h6>
      <br/>

      <h6 className="features-form text-left"> Tax : <span className="price-box"> {taxAmount ? taxAmount +" %" : "nil"} </span> </h6>
      <br/>
      <h6 className="features-form text-left"> Price plan : <span className="price-box">{pricingType} </span></h6>
      <br/>
        
    </div>

   <div className='flex-row'>
    <h5 style={{alignSelf:'center'}} className="text-muted font-weight-bold">Select Period : </h5>
    <input type='number' style={{height:'30px', width:'60px'}} min='1'max='20' value={tenure} onChange={handleChange}></input>
    <h5 className="care-period">{pricingType==='1 month (Monthly)'? 'Month': pricingType==='3 months (Qaurterly)'? 'Quarter': pricingType==='6 months (Half-yearly)'? 'Half Year': pricingType==='1 year (Yearly)'? 'Year': pricingType==='Life time(2 years)'? 'Year': 'Month' }</h5>
   </div>
    <br/>
    {/* <button style={{}} className="btn btn-primary btn-col " onClick={handleSelect}>Add Subscription</button> */}

   </div>
  {/****************/}
   {/* <br/>
    <br/>
              
  <div className="flex-col">
                <span className="flex-row"><h5 className="text-muted font-weight-bold">Select Tenure : </h5>
               <input type='number' style={{height:'35px', width:'60px'}} min='1'max='20' value={tenure} onChange={handleChange}></input>
               </span><br/>

              </div> */}

              <br/>
                <button className="btn btn-primary btn-col " onClick={handleSelect}>Add Subscription</button>
              
               </div> : null
          }
            

            </div>
</div>

</div> 


            {
        successShow ? 
        <SuccessPopup
        show={successShow}
        onHide={handleSuccess} /> : null
      } 

{
        errorShow ? 
        <ErrorPopup
        show={errorShow}
        onHide={()=> setErrorShow(false)} /> : null
      } 

                  {
        detailsMsg==="show" ? 
        <DetailsPopup
        show={detailsShow}
        onHide={() =>  {setDetailsShow(false);
                         setDetailsMsg("")}}
         /> : null
      } 
        </div>
      </>

        
    )

    
}

export default AddPatient
