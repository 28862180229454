import React,{useEffect,useState} from "react";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import "./nutrition.css";
import Select from 'react-select';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";
import {ButtonGroup} from 'react-bootstrap';
import {ToggleButton} from 'react-bootstrap';
import {ToggleButtonGroup} from 'react-bootstrap';
function Nutrition() {
  const [dataList,setDataList]=useState([]);
	const [currentRow,] = useState();
 
  const [pages,setPages]=useState(-1);
  const [activePage,setActivePage]=useState(0);
  const [pageSize,setPageSize]=useState(10);

  const [loading,setLoading]=useState(true);
  const [newFood,setNewFood]=useState({
    name: "",
    synonyms:[],
    calories:"",
    carbohydrates:"",
    fat: "",
    protein:"",
    food_unit: "",
    food_quantity:"",
    side_dish:[],
    sodium:"",
    potassium:"",
    fiber:""


  });
  const [units,setUnits]=useState();
  	const [refresh,setRefresh]=useState();
	const [render,setRender]= useState(true);
 const [searchTerm, setSearchTerm] = useState("");
 const [errorMsg,setErrorMsg]=useState(null);
 const [errorSubmit,setErrorSubmit]=useState(false);
  const [submitMsg,setSubmitMsg]=useState("");
  const [modalShow,setModalShow] =useState(false);
  const [refreshed,setRefreshed]= useState(false);

  const history =useHistory();

  const [keys,] = useState({'h': false,'d': false,'u': false,'r': false,'l': false,'i': false,'a': false,'p': false,'v': false,'t': false});
  const [selected,setSelected]=useState(1);
   const [filterValue,setFilterValue]=useState("incomplete");
  useEffect(()=>{
  
  window.onkeydown =(e) => {
   
   keys[e.key]=true
  
   if(e.altKey && keys['h']){
     history.push('/hospitallist')
   }
   if(e.altKey && keys['d']){
     history.push('/doctorslist')
   }
   if(e.altKey && keys['u']){
     history.push('/search-hospitals')
   }
   if(e.altKey && keys['r']){
     history.push('/rejectedlist')
   }
   if(e.altKey && keys['l']){
     history.push('/reportlist')
   }
   if(e.altKey && keys['i']){
     history.push('/addsynonyms')
   }
   if(e.altKey && keys['a']){
     history.push('/failedlist')
   }
   if(e.altKey && keys['p']){
     history.push('/prescriptionlist')
   }
   if(e.altKey && keys['v']){
     history.push('/written-prescriptionlist')
   }
   if(e.altKey && keys['t']){
     history.push('/testlist')
   }
  
}
  window.onkeyup=(ev)=> {
   
   keys[ev.key]= false
  }
  
  return()=>{
   window.onkeydown=null
   window.onkeyup=null
  }
  },[]);

useEffect(()=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, ''); 

if(searchTerm!=="" && searchTerm!== null){
 axios.get(`${APIURL}/api/v1/staff/nutrient-addition/?filter=${filterValue}&per_page=${pageSize}&page=${activePage+1}&q=${searchTerm}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
    


   
      if(res.data.status==="success"){

       
 

setPages(res.data.total_pages);
setDataList(res.data.data);
setLoading(false);

      }


    })
    .catch(err=>{

})

  }


  else{
    axios.get(`${APIURL}/api/v1/staff/nutrient-addition/?filter=${filterValue}&per_page=${pageSize}&page=${activePage+1}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
    


   
      if(res.data.status==="success"){

       
 

setPages(res.data.total_pages);
setDataList(res.data.data);
setLoading(false);
      }


    })
    .catch(err=>{

})


  }

},[pageSize,activePage,render,searchTerm,filterValue])


useEffect(()=>{
  setSearchTerm(searchTerm);
},[searchTerm]);


useEffect(()=>{
  setDataList(dataList);
},[dataList,refreshed])
  
const unitsOptions = [
      {value: "bowl" , label: 'Bowl'},
       { value: "piece" , label: 'Piece'},
        {value: "serving" , label: 'Serving'},
         {value: "tea spoon" , label: 'Teaspoon'},
          {value: "slice" , label: 'Slice'},
                {value: "cup" , label: 'Cup'},
    ];

const saveChanges=(index)=>{
 
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, ''); 
          
 
  
  const dataToSend= dataList[index];
    axios.put(`${APIURL}/api/v1/staff/nutrient-addition/`,dataToSend, { headers: {
      
      "Authorization" : 'Token '+str,
      'Content-Type': 'application/json'  }})
    .then(res=>{
 


      if(res.data.status==="success"){
       
     
       setSubmitMsg("success");
      setModalShow(true);
   
      }
      else{
             setErrorMsg(res.data.message);
              setErrorSubmit(true);
      }


    })
    .catch(err=>{

   setErrorMsg("There was some error in data submission. Please check again.");
              setErrorSubmit(true);
})

}

const deleteRow=(index)=>{
  const newlist = dataList.length>0 ? dataList.filter((test,i)=>{
    return i=== index
  })
  : null;
 



  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, ''); 
          
 
  
  const dataToDelete= {
    food_name: newlist[0].name
  };

 
    axios.delete(`${APIURL}/api/v1/staff/nutrient-addition/`, { headers: {
      
      "Authorization" : 'Token '+str,
      'Content-Type': 'application/json'  }, data: dataToDelete })
    .then(res=>{
    

      if(res.data.status==="success"){
       
      setRender(!render);
      setRefresh(!refresh);

      }


    })
    .catch(err=>{

})
}


const delSyn= (itemIndex,cellInfo)=>{
   const itemsList = dataList[cellInfo.index][cellInfo.column.id];
        const newSyn= itemsList.filter((synItem,i)=>{
          return i!== itemIndex
        })

         const updatedArray= dataList;
          updatedArray[cellInfo.index][cellInfo.column.id]= newSyn;
 
          setDataList(updatedArray);
          setRefreshed(!refreshed);

      }

      const addSyn= (itemIndex,cellInfo)=>{

   setRefreshed(!refreshed);

      }
const renderEditable2=(cellInfo)=> {

  if(!(currentRow === cellInfo.index)){

  if(cellInfo.column.id === "synonyms" || cellInfo.column.id === "side_dish"){
    const itemsList = [...dataList[cellInfo.index][cellInfo.column.id], ''];

    
   



    const list= itemsList ? itemsList.map((item,itemIndex)=>{
      if(item!=="" && itemIndex< itemsList.length-1){
         return (
                   <div className="row-syn-item">  
                   <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const tests = itemsList;
          tests[itemIndex] = e.target.innerHTML;
         
          const updatedArray= dataList;
          updatedArray[cellInfo.index][cellInfo.column.id][itemIndex] = e.target.innerHTML;
        
          setDataList(updatedArray);
        }}
        dangerouslySetInnerHTML={{
          __html: dataList[cellInfo.index][cellInfo.column.id][itemIndex]
        }}
      />
      <button className="btn btn-smallest btn-secondary" onClick={()=>{delSyn(itemIndex,cellInfo)}}> X </button>
        </div>

        
         );
       }
       else{
         return (
                   <div className="row-syn-item"> 
                   <input className="input-new-syn" type="text" value={dataList[cellInfo.index][cellInfo.column.id][itemIndex]} onChange={(e)=>{
                                    const tests = itemsList;
                        tests[itemIndex] = e.target.value;
                       
                        const updatedArray= dataList;
                      
                        updatedArray[cellInfo.index][cellInfo.column.id][itemIndex] = e.target.value;
                      
                        setDataList(updatedArray);
                   }}/>
                   
      <button className="btn btn-smallest btn-secondary" onClick={()=>{addSyn(itemIndex,cellInfo)}}> + </button>
</div>)


       }

}) 
: "";
      return list;
      }



   else if(cellInfo.column.id === "name"){

          return <div style={{ backgroundColor: "#fafafa" }}>{dataList[cellInfo.index] ? dataList[cellInfo.index].name : null} </div>

        

   }
   else{
    return (
         <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const list = dataList;
          list[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          setDataList(list)
        }}
        dangerouslySetInnerHTML={{
          __html: dataList[cellInfo.index][cellInfo.column.id]
        }}
      />




     
    );
  }
}
else if(currentRow === cellInfo.index){
  return (
      <input
        style={{ backgroundColor: "#fafafa" }}
        
         className="form-control"   
        onChange={e => {
          const list = [...dataList];
          list[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          setDataList(list);
        }}
        
      />
    );
}
}



const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg} </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}


const SubmitPopUp=(props)=> {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data submitted sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button> 
       
      
      </Modal.Footer>
    </Modal>
  );
}


const columns2 = [
{
  Header: "FOOD NAME",
  accessor: "name",
  Cell: renderEditable2,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
 
  minWidth:100,
   sortable:false
 

},
{
  Header: "SYNONYMS",
  accessor: "synonyms",
  Cell: renderEditable2,
   sortable:false,
   filterable:false,
   width:200

},
{
  Header: "CAL.",
  accessor: "calories",
  Cell: renderEditable2,
  minWidth:60,
   sortable:false,
   filterable:false

},
{
  Header: "CARB",
  accessor: "carbohydrates",
  Cell: renderEditable2,
    minWidth:60,
   sortable:false,
   filterable:false

},
{
  Header: "FAT",
  accessor: "fat",
  Cell: renderEditable2,
  minWidth:60,
  sortable:false,
  filterable:false



},
{
  Header: "PROT.",
  accessor: "protein",
  Cell: renderEditable2,
  minWidth:60,
  sortable:false,
  filterable:false



},
{
  Header: "SODIUM.",
  accessor: "sodium",
  Cell: renderEditable2,
  minWidth:60,
  sortable:false,
  filterable:false



},
{
  Header: "POT.",
  accessor: "potassium",
  Cell: renderEditable2,
  minWidth:60,
  sortable:false,
  filterable:false



},
{
  Header: "FIBRE.",
  accessor: "fiber",
  Cell: renderEditable2,
  minWidth:60,
  sortable:false,
  filterable:false



},
{
  Header: "UNIT",
  accessor: "food_unit",
  Cell: renderEditable2,
  minWidth:80,
  sortable:false,
  filterable:false



},
{
  Header: "QTY.",
  accessor: "food_quantity",
  Cell: renderEditable2,
  minWidth:60,
  sortable:false,
filterable:false


},
{
  Header: "SIDE DISH",
  accessor: "side_dish",
  Cell: renderEditable2,
  width:180,
  sortable:false,
  filterable:false



},
{
  Header: "DEL",
  Cell: props=>{
    return (
    <button className="btn btn-danger btn-small btn-square" 
       onClick={()=>{deleteRow(props.index)}}> <i className="fa fa-trash-alt" aria-hidden="true"></i> </button>
    )
  },
 width:60,
  sortable:false,
  filterable:false
},
{
  Header: "SAVE",
  Cell: props=>{
    return (
    <button className="btn btn-primary btn-col btn-small btn-square" 
       onClick={()=>{saveChanges(props.index)}}> Save</button>
    )
  },
  width:60,
  sortable:false,
  filterable:false
}




]

const handleSubmitNewFood=e=>{
  e.preventDefault();

  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, ''); 
          
 
  
  const dataToSend= newFood;
    axios.post(`${APIURL}/api/v1/staff/nutrient-addition/`,dataToSend, { headers: {
      
      "Authorization" : 'Token '+str,
      'Content-Type': 'application/json'  }})
    .then(res=>{
    


      if(res.data.status==="success"){
       
     
       setSubmitMsg("success");
      
      setModalShow(true);
      setRender(!render);
     
      }
      else{
             setErrorMsg(res.data.message);
              setErrorSubmit(true);
      }


    })
    .catch(err=>{

   setErrorMsg("There was some error in data submission. Please check again.");
              setErrorSubmit(true);
})


}
const handleNewFood=e=>{
  e.preventDefault();
   const val=e.target.value;
      const key=e.target.name;

    
  setNewFood(currentstate=>({
            ...currentstate,
            [key]: val
          }));

}

const handleNewFoodArrays=e=>{
  e.preventDefault();
   const val=e.target.value;
      const key=e.target.name;
      let newArray= val.split(',');

    
  setNewFood(currentstate=>({
            ...currentstate,
            [key]: newArray
          }));

}

const handleChangeRadio=(val)=>{
   
    setSelected(val);
    if(val===1){
       setFilterValue("completed")
     } 
     else  if(val===2){
       setFilterValue("incomplete")
     } 
     else  if(val===4){
       setFilterValue("partial")
     } 
     
   

   }
  return (
    <div className="container">
     <div className="dashboard-navigation">
          <div>
            {" "}
            <Link to="/dashboard">
              {" "}
              <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
            </Link>
          </div>

          <div>
            {" "}
            <i className="fas fa-angle-double-right angle-icon"></i>{" "}
          </div>
          <div>
            {" "}
            <Link to="/nutrition"> Food Nutrients </Link>{" "}
          </div>
        </div>
      <div className="nutrients-page">
        <h2 className="title-of-page"> Food Nutrients Table </h2>

        <form >
          <div className="search-div">
            <input
              className="form-control search-input"
              type="text"
              placeholder="Search Food Name"
              maxLength="150"
              value={searchTerm}
              onChange={(e)=>{
               
                setSearchTerm(e.target.value)
              }}
            />
            <div className="btn btn-primary btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </div>
          </div>
        </form>


        <div className="buttons-wrapper refresh-nutrients">


 {/************toggle btns*******/}
        <div className="flex-col">            
   
 <ToggleButtonGroup type="radio" name="options" defaultValue={2} onChange={handleChangeRadio}>
    <ToggleButton id="tbg-radio-1" value={1} className="btn btn-light toggle-btns toggle-btn-1">
     Completed
    </ToggleButton>
    <ToggleButton id="tbg-radio-2" value={2} className="btn btn-light toggle-btns toggle-btn-2">
     No data/Incomplete 
    </ToggleButton>
    
    <ToggleButton id="tbg-radio-2" value={4} className="btn btn-light toggle-btns toggle-btn-4">
     Partially complete
    </ToggleButton>
    
  </ToggleButtonGroup>
  </div>

  {/************ends toggle btns*******/}
        <button className="btn btn-secondary" onClick={()=>{setRender(!render)}}>
            Refresh Table <i className="fas fa-sync-alt"></i>
          </button>
     
    </div>
         


      
        <ReactTable
        
        columns={columns2}
  pages={pages} 
  

  loading={loading}
  data={dataList}
  manual
  pageSizeOptions= {[10, 20, 25, 50]}
onPageChange={pageIndex => {
 
        setActivePage(pageIndex);
    }}
    onPageSizeChange={(pageSize, pageIndex) => {
        setActivePage(pageIndex);
        setPageSize(pageSize);
  }}
  defaultPageSize= "10"
  className='report-table'
/>
    
         <br/>
         <div className="divider"> </div>
   
       <form className="newfood-form">
       <h5 className="new-food-table-header"> Add New Food </h5>
       <div className="newfood-table">
         <div className="form-group col-3">
          <label >Food Name* </label>
          <input type="text" maxLength="150" name="name" value={newFood.name} className="form-control" onChange={handleNewFood}/>
         </div>
          
          <div className="form-group col-1">
          <label > Calories* </label>
          <input type="text" maxLength="150" name="calories" value={newFood.calories} className="form-control" onChange={handleNewFood}/>
         </div>
          <div className="form-group col-2">
          <label > Carbohydrates* </label>
          <input type="text" maxLength="150" name="carbohydrates" value={newFood.carbohydrates} className="form-control" onChange={handleNewFood}/>
         </div>
          <div className="form-group col-1">
          <label >Fat*</label>
          <input type="text" maxLength="150" name="fat" value={newFood.fat} className="form-control" onChange={handleNewFood}/>
         </div>
          <div className="form-group col-1">
          <label >Protein*</label>
          <input type="text" maxLength="150" name="protein" value={newFood.protein} className="form-control" onChange={handleNewFood}/>
         </div>
         <div className="form-group col-2">
          <label >Sodium*</label>
          <input type="text" maxLength="150" name="sodium" value={newFood.sodium} className="form-control" onChange={handleNewFood}/>
         </div>
         <div className="form-group col-1">
          <label >Potassium*</label>
          <input type="text" maxLength="150" name="potassium" value={newFood.potassium} className="form-control" onChange={handleNewFood}/>
         </div>
         <div className="form-group col-1">
          <label >Fiber*</label>
          <input type="text" maxLength="150" name="fiber" value={newFood.fiber} className="form-control" onChange={handleNewFood}/>
         </div>

          <div className="form-group col-2 col-md-6">
          <label >Units* </label>
         
            <Select
        value={units}
        onChange={(data)=>{
             const list = newFood;
             list.food_unit = data.value;
             setNewFood(list);
             setUnits(data)
            
             
             
              
            
            }}
        options={unitsOptions}
       
      />
         </div>
         <div className="form-group col-1 col-md-6">
          <label >Quantity* </label>
          <input type="text" maxLength="150" name="food_quantity" value={newFood.food_quantity} className="form-control" onChange={handleNewFood}/>
         </div>

         <div className="form-group col-12">
          <label > Synonyms </label>
          <span className="text-muted"> -(Add as many with comma seperated)</span>
          <input type="text" maxLength="150" placeholder="Eg: roti,naan" name="synonyms" value={newFood.synonyms} className="form-control" onChange={handleNewFoodArrays}/>
         </div>
         <div className="form-group col-12">
          <label > Side Dishes </label>
          <span className="text-muted"> - (Add as many with comma seperated)</span>
          <input type="text" maxLength="150" placeholder="Eg: curry, veg curry" name="side_dish" value={newFood.side_dish} className="form-control" onChange={handleNewFoodArrays}/>
         </div>

         </div>
         <button type="submit" className="btn btn-primary btn-col" onClick={handleSubmitNewFood}> Submit </button>
       </form>
        <span className="text-muted">* : Fields are mandatory </span>

      {errorMsg!==null ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  setErrorSubmit(false)}
       
      />: null}
    
   

   {submitMsg==="success" ? 

          <SubmitPopUp
        show={modalShow}
        onHide={() =>  setModalShow(false)}
      /> 
      : null}

      </div>
    </div>
  );
}
export default Nutrition;
