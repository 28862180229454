import React, { useState, createContext } from "react";

export const HospBdoLoginContext = createContext();

export const HospBdoLoginProvider = (props) => {
  const [hospBdoData,setHospBdoData] = useState({
  	bdo_department: "Cardiac",
  	bdo_name: "Ajith Kumar",
  	hospital_name: "Victoria",
  	location: "Ernakulam",
  	user_id: 101
  });
   
  return (
    <HospBdoLoginContext.Provider value={[hospBdoData,setHospBdoData]}>
      {props.children}
    </HospBdoLoginContext.Provider>
  );
};


 