import React, { useEffect, useState} from "react";
import axios from "axios";
import "./testheadingslist.css";
import { Link,useHistory } from "react-router-dom";
import {APIURL} from "../../../Global";
import Pagination from "react-js-pagination";
import "./pagination.css";

function TestHeadingsList() {
  const [labTestList, setLabTestList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activePage,setActivePage] = useState(1);
  const [order,]=useState("asc")
  const [refresh,setRefresh]= useState(false);

  const history =useHistory();

const [keys,] = useState({'h': false,'d': false,'u': false,'r': false,'l': false,'i': false,'a': false,'p': false,'v': false,'n': false});


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['h']){
   history.push('/hospitallist')
 }
 if(e.altKey && keys['d']){
   history.push('/doctorslist')
 }
 if(e.altKey && keys['u']){
   history.push('/search-hospitals')
 }
 if(e.altKey && keys['r']){
   history.push('/rejectedlist')
 }
 if(e.altKey && keys['l']){
   history.push('/reportlist')
 }
 if(e.altKey && keys['i']){
   history.push('/addsynonyms')
 }
 if(e.altKey && keys['a']){
   history.push('/failedlist')
 }
 if(e.altKey && keys['p']){
   history.push('/prescriptionlist')
 }
 if(e.altKey && keys['v']){
   history.push('/written-prescriptionlist')
 }
 if(e.altKey && keys['n']){
  history.push('/nutrition')
}

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);

  //const [dropDownOpen,setDropDownOpen]=useState(false);

  const [nextStartID,] = useState(0);
  const [totalPages,setTotalPages]=useState(1);


  useEffect(() => {
    const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

   
    
    axios
      .get(
        `${APIURL}/api/v1/staff/lab-tests/?per_page=10&page=${activePage}&test_id=${nextStartID}`,
        { headers: { Authorization: "Token " + v2} }
      )
      .then((res) => {
        if (res.data.status === "success") {
          const labTests = res.data.lab_tests;



          setTotalPages(res.data.total_pages);
          setLabTestList(labTests);

        }else{
       
        }
  
  
      })
      .catch(err=>{
   
  })
   
  
   },[activePage,order,refresh]);

  const handleRefresh = (e) => {
    e.preventDefault();

    const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */


    axios
      .get(`${APIURL}/api/v1/staff/lab-tests/`, {
        headers: { Authorization:  "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const data = res.data;
          const labTests = data.lab_tests;
          setLabTestList(labTests);
        } else {

        }
      })
      .catch((err) => {

      });
  };
  //const toggle = () => setDropDownOpen(!dropDownOpen);
    const handlePageChange=(pageNumber)=> {
   
    setActivePage(pageNumber);
    setRefresh(!refresh);
    }
    {/*const onSort = async order=>{
      await setOrder(order);
      
      await setRefresh(!refresh);
    }
  */}
  const handleSearchChange = (e) => {
    e.preventDefault();

    setSearchTerm(e.target.value);
    //this.setState({searchTerm: e.target.value});
  };

 

  let dataDisplay = labTestList.map((data, index) => {
    const url = `test/${data.id}`;
    return (
      <div className="row-testid-1">
        <div className="each-row-1 row">
          <div className="syn-headings-title syn-id">
            <Link to={url} className="link-to-details">
              {" "}
              <h6> ID {data.id} </h6>
            </Link>
          </div>
          <div className="syn-headings-title">
            <Link to={url} className="link-to-details">
              <h6 className="syn-title"> {data.test_name} </h6>
            </Link>
          </div>

          {data.synonyms.map((syn, index) => {
            if(index!== -0){
            return (
              <>
                <div className="synonyms">
                  <h6> {syn} </h6>
                </div>
              </>
            );
          } 
          else {
            return null;
          }
          })}
        </div>
      </div>
    );
  });

  const handleSearch = (e) => {
    e.preventDefault();

    const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

   

    axios
      .get(`${APIURL}/api/v1/staff/lab-tests/?q=${searchTerm}`, {
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
          Authorization:  "Token " + v2,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const data = res.data;
          const labTests = data.lab_tests;
          setLabTestList(labTests);
        } else {

        }
      })
      .catch((err) => {

      });

    setSearchTerm("");
  };

  return (
    <div className="container">
      <div className="dashboard-navigation">
        <div>
          {" "}
          <Link to="/dashboard">
            {" "}
            <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
          </Link>
        </div>

        <div>
          {" "}
          <i className="fas fa-angle-double-right angle-icon"></i>{" "}
        </div>
        <div>
          {" "}
          <Link to="/testlist"> Test Names List </Link>{" "}
        </div>
      </div>

      <div className="container failedlist-parentcontainer">
        <h2 className="title-of-page">Test Names and Synonyms</h2>

        <form onSubmit={handleSearch}>
          <div className="search-div">
            <input
              className="form-control search-input"
              type="text"
              placeholder="Search Test Name"
              value={searchTerm}
              maxLength="150"
              onChange={handleSearchChange}
            />
            <button type="submit" className="btn btn-primary btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </button>
          </div>
        </form>
        <div className="header-display">
          <button className="btn btn-secondary" onClick={handleRefresh}>
            Refresh List <i className="fas fa-sync-alt"></i>
          </button>
        </div>

        <div className="failed-list-section">
          <div className="container">
            <div className="test-reports-display-list">
              {dataDisplay.length ? (
                dataDisplay
              ) : (
                <span style={{ color: "grey", fontSize: "25px" }}>
                  Please wait while the data is loading!!
                </span>
              )}
            </div>

            {/*
      
      
                                <button type="submit" className="next-btn">Next</button> */}
          </div>

              
          <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalPages*10}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          disabledClass = "disabled-class"
        />

                

              
               
        </div>
      </div>
    </div>
  );
}
export default TestHeadingsList;
