import React, {useState, useEffect} from "react";
import Analytics from "./Analytics"

import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import {useHistory} from "react-router-dom";
function AnalyticsDr() {
let history=useHistory();

const [keys,] = useState({'p': false,'a': false,'n': false,'m': false,'s': false,});


useEffect(()=>{

  window.onkeydown =(e) => {
    
    keys[e.key]=true

    if(e.altKey && keys['p']){
      history.push('/patients-directory')
    }
    if(e.altKey && keys['a']){
      history.push('/appointment')
    }
    if(e.altKey && keys['n']){
      history.push('/doctor/notifications')
    }
    if(e.altKey && keys['m']){
      history.push('/message')
    }
    if(e.altKey && keys['s']){
      history.push('/service-requestlist')
    }


  }
  window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
  }

  return()=>{
    window.onkeydown=null
    window.onkeyup=null
  }
},[]);

  const handleBack=(e)=>{
  history.goBack();
}
	return(<>
		<HospDocNavbar />
		<div className=" text-left">
        <br/>
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> 
		<Analytics />
		</>
		)
}
export default AnalyticsDr;