import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import { Link } from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import './featurelist.css'

import AddTarget from './AddTarget';
import EditTarget from './EditTarget';

import {ToggleButton} from 'react-bootstrap';
import {ToggleButtonGroup} from 'react-bootstrap';
import { expand } from 'urijs';


function Target() {

    const [featureList, setFeatureList]= useState([])
    const [rentalList, setRentalList]= useState([])
    const [editShow, setEditShow]= useState(false)
    const [current, setCurrent]= useState() 
    const [refresh, setRefresh]= useState(false)
    const [addShow, setAddShow]= useState(false)
    const [addRentalShow, setAddRentalShow]= useState(false)
    const [editRentalShow, setEditRentalShow]= useState(false)
    const [editInventoryShow, setEditInventoryShow]= useState(false)
    const [category, setCategory]= useState(2)
    const [expand, setExpand]= useState()
    const [modalShow, setModalShow]= useState(false)
    const [flag, setFlag]= useState(false)

   
    useEffect(()=> {
        window.scrollTo(0,0)
    },[])

    useEffect(() => {
       bindTarget();
      }, []);

      const bindTarget=()=>{
      const tokenString = localStorage.getItem("usertoken");
    
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
  
   
  
      axios
        .get(`${APIURL}/api/v1/staff/master-targets/`, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          
          //console.log(res);
          if (res.status == 200) {
            
            const datas = res.data;
           console.log(datas);
            setFeatureList(datas);
            //console.log(featureList)
            }

            // const filteredRentalData = datas.filter(item => {
            //   return item.service_type === 'rental'
            // })
            // setFeatureList(filteredData);
            //setRentalList(filteredRentalData);
            
            // if(flag){
            //   const idVal = filteredRentalData.length-1
            //   sessionStorage.setItem('rentalId',filteredRentalData[idVal].id)
            //   setModalShow(true)
            // }
          else {
            
          }
        })
        .catch((err) => {
         
        });
      }

      const handleChangeRadio=(val)=>{
        setCategory(val);
       }

    const modalClose = () => {
      setEditShow(false)
      setRefresh(!refresh)
    }

    const addModalClose = () => {
      setAddShow(false)
      setRefresh(!refresh)
    }

    const rentalModalClose = () => {
      setAddRentalShow(false)
      setRefresh(!refresh)
      setFlag(true)
    }

    const notProceedClose = () => {
      setAddRentalShow(false)
      setRefresh(!refresh)
    }

    const rentalEditModalClose = () => {
      setEditRentalShow(false)
      setRefresh(!refresh)
    }

    const inventoryModalClose = () => {
      setModalShow(false)
      setFlag(false)
      setRefresh(!refresh)
    }

    const editInventoryModalClose = () => {
      setEditInventoryShow(false)
      setRefresh(!refresh)
    }

 
//     const AddPopUp = (props) => {
//       return(
//         <Modal
//         {...props}
//         aria-labelledby="example-custom-modal-styling-title"
//    backdrop="static"
//     keyboard={false}
//     size='lg'
//   >
//   <Modal.Header closeButton>
//     <Modal.Title id="contained-modal-title-vcenter">
    
     
//     </Modal.Title>
//   </Modal.Header>
//   <Modal.Body>

//    <Addprocedure modalClose={addModalClose} />
   
//   </Modal.Body>
//   <Modal.Footer>
   
//      <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>   

//   </Modal.Footer>
// </Modal>
//       )
//   }
const handleClose = () =>
{
  bindTarget();
  setEditShow(false);
  setAddRentalShow(false);
}

  const AddRentalPopUp = (props) => {
    return(
      <Modal
      {...props}
      aria-labelledby="example-custom-modal-styling-title"
 backdrop="static"
  keyboard={false}
  dialogClassName="modal-80w"
>
<Modal.Header>
  <Modal.Title id="contained-modal-title-vcenter">
  
   
  </Modal.Title>
</Modal.Header>
<Modal.Body>

 <AddTarget modalClose={rentalModalClose} notProceedClose={notProceedClose} />
 
</Modal.Body>
<Modal.Footer>
 
   <Button className='btn btn-primary btn-col' onClick={handleClose}>Close</Button>   

</Modal.Footer>
</Modal>
    )
}

      const EditPopUp = (props) => {
        return(
          <Modal
          {...props}
          aria-labelledby="example-custom-modal-styling-title"
     backdrop="static"
      keyboard={false}
      size='lg'
    >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
      
       
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>

     <EditTarget data={current} modalClose={modalClose} />
     
    </Modal.Body>
    <Modal.Footer>
     
       <Button className='btn btn-primary btn-col' onClick={handleClose}>Close</Button>   

    </Modal.Footer>
  </Modal>
        )
    }

//     const EditRentalPopUp = (props) => {
//       return(
//         <Modal
//         {...props}
//         aria-labelledby="example-custom-modal-styling-title"
//    backdrop="static"
//     keyboard={false}
//     dialogClassName="modal-80w"
//   >
//   <Modal.Header closeButton>
//     <Modal.Title id="contained-modal-title-vcenter">
    
     
//     </Modal.Title>
//   </Modal.Header>
//   <Modal.Body>

//    <EditProcedure data={current} modalClose={rentalEditModalClose} />
   
//   </Modal.Body>
//   <Modal.Footer>
   
//      <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>   

//   </Modal.Footer>
// </Modal>
//       )
//   }

//   const InventoryPopUp = (props) => {
//     return(
//       <Modal
//       {...props}
//       aria-labelledby="example-custom-modal-styling-title"
//  backdrop="static"
//   keyboard={false}
//   size='lg'
// >
// <Modal.Header closeButton>
//   <Modal.Title id="contained-modal-title-vcenter">
  
   
//   </Modal.Title>
// </Modal.Header>
// <Modal.Body>

//  <AddInventory modalClose={inventoryModalClose} />
 
// </Modal.Body>
// <Modal.Footer>
 
//    <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>   

// </Modal.Footer>
// </Modal>
//     )
// }

// const EditInventoryPopUp = (props) => {
//   return(
//     <Modal
//     {...props}
//     aria-labelledby="example-custom-modal-styling-title"
// backdrop="static"
// keyboard={false}
// size='lg'
// >
// <Modal.Header closeButton>
// <Modal.Title id="contained-modal-title-vcenter">

 
// </Modal.Title>
// </Modal.Header>
// <Modal.Body>

// <EditInventory data={current} modalClose={editInventoryModalClose} />

// </Modal.Body>
// <Modal.Footer>

//  <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>   

// </Modal.Footer>
// </Modal>
//   )
// }

    const handleEdit = (index) => {
      const list = featureList[index]
      setCurrent(list)
      setEditShow(true)
    }

    // const handleRentalEdit = (index) => {
    //   const list = rentalList[index]
    //   setCurrent(list)
    //   setEditRentalShow(true)
    // }

    // const handleEditInventory = (index) => {
    //   const list = rentalList[index]
    //   setCurrent(list)
    //   setEditInventoryShow(true)
    // }

    const handleDetail = (index ) => {
      if(expand !== index){
        setExpand(index)
      }
      else{
        setExpand()
      }
    }

    //   const dataDisplay = category === 1 ? featureList.length >0 ? featureList.map((item,index) => {
    //       return (
    //         <div className='care-features' key={item.id}>
    //           <div className='feature-data'>
    //             <h5 className='feature-title'>{index+1}.{item.title2}</h5>
    //             <h5 style={{marginLeft:'auto', marginRight:'auto'}} className='feature-title'>{item.service_type.replace("_"," ")}</h5>
    //           </div>
    //             <div className='seperate-content'/>
    //             <div className='feature-data'>
    //             <h6 className='feature-request'>On Request Service : {item.on_request_service=== true? 'Yes' : item.on_request_service=== false? 'No' : 'Nil'}</h6>
    //             <h6 className='feature-service'>Service Unit : {item.service_unit}</h6>
    //             <h6 className='feature-service'>Active : {item.is_active === true? 'Yes': item.is_active === false? 'No' : 'Nil'}</h6>
    //             <button onClick={()=> handleEdit(index)} className='btn btn-primary btn-col'>Edit</button>
    //           </div>
    //         </div>
    //       )
          
    //   }) : <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
    //           <h4>No Features to show!!</h4>
    //        </div>

      const dataDisplay  = category === 2 ? featureList.length >0 ? featureList.map((item,index) => {
         

        // "id": 1,
        // "created_name": "admin",
        // "age_group_name": "infants",
        // "target_name": "target1",
        // "min_value": 1.6,
        // "max_value": 2.2,
        // "tolerance": 3.0,
        // "comparison": "greater",
        // "unit": "mg/DL",
        // "description": "asdndkfdmf,",
        // "created_at": "2021-12-24T11:14:41.108243Z",
        // "modified_at": "2021-12-24T11:14:41.108243Z",
        // "age_group": 1
        
          return (
            <div className='care-features' key={item.id}>
              
              <div className='feature-data'>
                <h5 className='feature-title'>{index+1}.{item.target_name}</h5>
                
                
                {/* <button onClick={()=> handleRentalEdit(index)} disable = {true} className='btn btn-primary btn-col'>Edit</button> */}
                <button style={{marginLeft:'auto', marginRight:'100px'}} onClick={()=> handleEdit(index)} disable = {true} className='btn btn-primary btn-col'>Edit</button>


              </div>
              {
                expand !==index ?
                  <div className='seperate-content'/>
                  : null
              }
                

                <div style={{cursor:'pointer'}} onClick={()=> handleDetail(index)}  className={ expand === index ?'feature-data mt-3' : 'feature-data'}>
                  <h6 className='feature-request'>Age Group: {item.age_group_name}</h6>
                  
                  {/* <h6 className='feature-service'>Active : {item.is_active === true? 'Yes': item.is_active === false? 'No' : 'Nil'}</h6> */}
                  {/* <h6 className='feature-service'><i className={expand===index ? "fas fa-sort-up detail-open" : "fas fa-sort-down detail-open"}></i>Inventory Details</h6> */}
              </div>
              {
                expand === index ? <>
                    <div className='seperate-content'/>
                      <div className='row-of-features'>
                        <div className='col-of-features'>
                          <h6>Target Name : {item.target_name}</h6>
                          <h6>Age Group Name: {item.age_group_name}</h6>
                          <h6>Minimum Value: {item.min_value}</h6>
                          <h6>Maximum Value: {item.max_value}</h6>
                          <h6>Tolerance: {item.tolerance}</h6>
                          <h6>Comparison: {item.comparison}</h6>
                          <h6>Unit: {item.unit}</h6>
                          <h6>Description: {item.description}</h6>
                          <h6>Created_at: {item.created_at}</h6>
                          <h6>Age Group: {item.age_group}</h6>
                        </div>

                       
                      </div>
                      {/* <button onClick={()=>handleEditInventory(index)} style={{alignSelf:'flex-end'}} className='btn btn-primary btn-col'>Edit Inventory</button> */}
                  </>
                  : null
              }
              
            </div>
          )
          
      }) : <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
              <h4>No Data to show!!</h4>
           </div>

        : null


    return (
             <div className="container displaylist-container ">
        <div className="dashboard-navigation">
          <div>
            {" "}
            <Link to="/dashboard">
              {" "}
              <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
            </Link>
          </div>

          <div>
            {" "}
            <i className="fas fa-angle-double-right angle-icon"></i>{" "}
          </div>
          
        </div>
        <div className="header-display">
          <h2 className="title-of-page"> Targets</h2>


         <div className="buttons-wrapper">
          {/* <button className="btn btn-secondary" onClick={()=> setRefresh(!refresh)}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button> */}
          {
            // category === 1 ?
            //   <button className="btn btn-primary btn-col" onClick={()=> setAddShow(true)}>
            //     Add Feature <i class="fas fa-plus"></i>
            //   </button> 
            //   :
              category === 2 ?
              <button className="btn btn-primary btn-col" onClick={()=> setAddRentalShow(true)}>
                Add Targets <i class="fas fa-plus"></i>
              </button> 
              : null
          }
          
</div>  


        </div>

        <div className='mb-5'>
      <ToggleButtonGroup type="radio" name="options" defaultValue={category} onChange={handleChangeRadio}>
    {/* <ToggleButton id="tbg-radio-1" value={1} className="btn btn-light toggle-btns toggle-btn-1">
    Feature
    </ToggleButton> */}
    <ToggleButton id="tbg-radio-2" value={2} className="btn btn-light toggle-btns toggle-btn-2">
   Targets 
    </ToggleButton>
  
      </ToggleButtonGroup>
    </div>

        <div className="test-reports-display-list">

          <div className='feature-container'>
            {dataDisplay}
          </div>
          
        </div>


    {
      editShow ?
      <EditPopUp
        show={editShow}
        onHide={() => setEditShow(false)}
        /> : null
    }

{/* {
      editRentalShow ?
      <EditRentalPopUp
        show={editRentalShow}
        onHide={() => setEditRentalShow(false)}
        /> : null
    } */}

{/* {
      addShow ?
      <AddPopUp
        show={addShow}
        onHide={() => setAddShow(false)}
        /> : null
    } */}

{
      addRentalShow ?
      <AddRentalPopUp
        show={addRentalShow}
        onHide={() => setAddRentalShow(false)}
        /> : null
    }

{/* {modalShow ? 
<InventoryPopUp
show={modalShow}
onHide={() =>  {setModalShow(false); setFlag(false) }}
/> 
: null}

{editInventoryShow ? 
<EditInventoryPopUp
show={editInventoryShow}
onHide={() =>  {setEditInventoryShow(false) }}
/> 
: null} */}

      </div>
    )


}

export default Target
