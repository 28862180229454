import React from "react";

import "./Appointment.css";
import { Link} from "react-router-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import "./pagination.css";
import {APIURL} from "../../Global";
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import Calender from "../Calender/Calender";



class MessageList extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        datalist: [],
         activePage: 1,
        order: "asc",
        dropdownOpen : false,
        total_page:1,
        sort: "sort",              
        keys : {
          'p': false,
          'n': false,
          'm': false,
          'l': false,
          's': false
         }
      };
    }
    
  
    componentDidMount() {
      

    //////* Shortcuts ********/ 

    window.onkeydown =(e) => {
    
      this.state.keys[e.key]=true

      if(e.altKey && this.state.keys['p']){
        this.props.history.push('/patients-directory')
      }
      if(e.altKey && this.state.keys['n']){
        this.props.history.push('/doctor/notifications')
      }
      if(e.altKey && this.state.keys['m']){
        this.props.history.push('/message')
      }
      if(e.altKey && this.state.keys['l']){
        this.props.history.push('/doctor/analytics')
      }
      if(e.altKey && this.state.keys['s']){
        this.props.history.push('/service-requestlist')
      }
  
  
    }
    window.onkeyup=(ev)=> {
      
      this.state.keys[ev.key]= false
    }

  }

  componentWillUnmount(){ 
    window.onkeydown=null
    window.onkeyup=null
}

   handleBack=(e)=>{
    this.props.history.goBack();
}
  
    render() {  
      return (<>
      <HospDocNavbar />
       <div className=" text-left">
        <br/>
          <button className="btn btn-info back-btn" onClick={this.handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div>
          
        
        <div className="container displaylist-container ">
         
          
          <div>
          <h2 className="title-of-tasks"> Appointments</h2>
             </div>
         
         

          <Calender  dataList={this.state.datalist}/>
       
         
        </div>
        </>
      );
    }
  }
  
  export default MessageList;
  