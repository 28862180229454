import React, {useState, useEffect} from "react";
import WebNotifications from "./WebNotifications"
import {useHistory} from "react-router-dom";
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";

function WebNotificationsBdo() {
	 let history=useHistory();
  let link=`${/record/}`;
	  
	const handleBack=(e)=>{
  history.goBack();	
}

const [keys,] = useState({'u': false,'c': false,'a': false,'s': false,'q': false,'l': false,});


useEffect(()=>{

  window.onkeydown =(e) => {
    
    keys[e.key]=true

    if(e.altKey && keys['u']){
      history.push('/directory')
    }
	if(e.altKey && keys['c']){
		history.push('/careplans-db')
	  }
    if(e.altKey && keys['a']){
      history.push('/analytics')
    }
    if(e.altKey && keys['s']){
      history.push('/service-requests')
    }
    if(e.altKey && keys['q']){
      history.push('/qrcode')
    }
    if(e.altKey && keys['l']){
      history.push('/call-log')
    }


  }
  window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
  }

  return()=>{
    window.onkeydown=null
    window.onkeyup=null
  }
},[]);

	return(<>
		<HospBdoNavbar />
		<div className=" text-left">
		 		<br/>
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> 
		<WebNotifications baselink={link}/>
		</>
		)
}
export default WebNotificationsBdo;