import React from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./displaywritten.css"
import axios from 'axios';
import {Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../../Global";
import Select from 'react-select';
import ImageScale from '../DisplayDetails/ImageScale'
import DatePicker from 'react-date-picker';

function SubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data submitted sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/written-prescriptionlist"><Button className='btn btn-primary btn-col' > Goto Handwritten Prescription List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}

function SaveDraft(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data saved sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/written-prescriptionlist"><Button className='btn btn-primary btn-col' > Goto Handwritten Prescription List </Button></Link>
        <Button className='btn btn-primary btn-col'>Continue</Button>
       
      </Modal.Footer>
    </Modal>
  );
}

function ErrorSubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> Error in data submission!</h5>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
      </Modal.Footer>
    </Modal>
  );
}


function ValidateErrorPopup (props){

  
  return (

    <Modal
     {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}>{props.error}</h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>


       
      </Modal.Footer>
    </Modal>
  );
}

function SaveDraftError(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="error" >Error in Draft saving!! </h4> <br/>
         
       
      </Modal.Body>
      <Modal.Footer>
       
        

       
        <Button className='btn btn-primary btn-col' onClick={props.onHide}> Close </Button>
       
      </Modal.Footer>
    </Modal>
  );
}



class DisplayWrittenPresc extends React.Component{
  constructor(props){
    super(props);
    this.state={
      med:[],
      images:[],
      date: '',
      prescId:"",
      isLocked: "null",
      submitmsg: "",
      timer: '',
      dateMsg: '',
       modalShow:false,
       doctor_name: "",
       savedraftmsg:"",
      modaldraftShow:false,
      rejectSuccessModalShow: false,
      rejectSuccessMsg:"",
      rejectConfirmMsg:"",
      rejectConfirmModalShow: false,
      submitErrorMsg: "",
      modalErrorShow: false,
      validateError: "",
      validateErrorMsgShow: false,
      modalDraftError: false,
      validateErrorMsg: false,
      moveMsg:"",
      moveShow:false,
      moveErrorMsg:"",
      moveErrorShow:false,
      docType:"",
      zoomShow: false,
      rotation: 0,
      rotationArray:[],
      scaleValue: 1,
      pdf:'',
      isPdf: false,
      keys : {
        'h': false,
        'd': false,
        'u': false,
        'r': false,
        'l': false,
        'i': false,
        'a': false,
        'p': false,
        'v': false,
        't': false,
        'n': false
       }
    }
  }
  
  
  componentDidMount(){
     const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
    
     
   this.setState({prescId: this.props.match.params.id});
    axios.get(`${APIURL}/api/v1/staff/prescriptions/${this.props.match.params.id}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        if(res.data.status==="success"){

          if(res.data.data.is_pdf===true){
            this.setState({
              pdf:res.data.data.images[0],
              isPdf: true
            })
          }
          let imgLength= res.data.data.images.length;
 
    let rotationArr=new Array(imgLength).fill(null).map(()=>(0));

      this.setState({
            images: res.data.data.images,
            med: res.data.data.medicines,
            date: res.data.data.date,
            doctor_name: res.data.data.doctor_name,
            rotationArray: rotationArr
            
          });

       

      //call lock
      axios.post(`${APIURL}/api/v1/staff/prescriptions/lock/`, {prescription_id: this.props.match.params.id} , { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
  .then(res => {
    if(res.data.status==="success"){
    
    this.setState({isLocked: "true"})
    this.setState({timer: 5});

    
   
 
   

  }
  else {
    
    this.setState({isLocked: "false"})
  }
}).catch(err=>{

})

//call unlock

setTimeout(()=>{
axios.post(`${APIURL}/api/v1/staff/prescriptions/unlock/`, {prescription_id: this.props.match.params.id} , { headers: {
       'Content-Type': 'application/json',
      "Authorization" : 'Token '+str}})
  .then(res => {
    if(res.data.status==="success"){
   
    this.setState({isLocked: "false"})

  }
  else {

    
  }
}).catch(err=>{

})

}, 300000);







      /* */

    }
    else {


    }

}
  )
    .catch(err =>{

    
    
  })

    
   //////* Shotcuts ********/ 

   window.onkeydown =(e) => {
    
    this.state.keys[e.key]=true

    if(e.altKey && this.state.keys['h']){
      this.props.history.push('/hospitallist')
    }
    if(e.altKey && this.state.keys['d']){
      this.props.history.push('/doctorslist')
    }
    if(e.altKey && this.state.keys['u']){
      this.props.history.push('/search-hospitals')
    }
    if(e.altKey && this.state.keys['r']){
      this.props.history.push('/rejectedlist')
    }
    if(e.altKey && this.state.keys['l']){
      this.props.history.push('/reportlist')
    }
    if(e.altKey && this.state.keys['i']){
      this.props.history.push('/addsynonyms')
    }
    if(e.altKey && this.state.keys['a']){
      this.props.history.push('/failedlist')
    }
    if(e.altKey && this.state.keys['p']){
      this.props.history.push('/prescriptionlist')
    }
    if(e.altKey && this.state.keys['v']){
      this.props.history.push('/written-prescriptionlist')
    }
    if(e.altKey && this.state.keys['t']){
      this.props.history.push('/testlist')
    }
    if(e.altKey && this.state.keys['n']){
      this.props.history.push('/nutrition')
    }


  }
  window.onkeyup=(ev)=> {
    
    this.state.keys[ev.key]= false
  }


    
}

componentWillUnmount(){ 
  window.onkeydown=null
  window.onkeyup=null
}

 render(){

  const scaleVal=this.state.scaleValue;
  const rotArray= this.state.rotationArray;

  const {prescId, med, images, isPdf, pdf}=this.state;

  const moveOptions = [      {value: 'lab_report' , label: 'Lab Report'},
                          
                           {value: 'prescription' , label: 'Prescription'},
                           {value: 'discharge_summary' , label: 'Discharge Summary'},
                          {value: 'misc' , label: 'Miscellaneous'},
                          {value: null , label: <span className="text-muted">Cancel</span>}
      
    ];
const MovePopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{this.state.moveMsg}</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/written-prescriptionlist"><Button className='btn btn-primary btn-col' >  Goto Handwritten Prescription List</Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}


const MoveErrorPopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 style={{color:"red"}}>{this.state.moveErrorMsg}</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/written-prescriptionlist"><Button className='btn btn-primary btn-col' > Goto Handwritten Prescription List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}
 
  function ZoomPopUp(props) {
    const presc = true;
    return (
      <Modal
        {...props}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
         <ImageScale img={images} presc={presc}/>
  
        </Modal.Body>

        <Modal.Footer>
        <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  } 

  const handleRotateLeft2=(i)=>{
    let newRotation = this.state.rotationArray[i] - 90;
   
    if(newRotation >= 360){
      newRotation =0;
    }if(newRotation === -360){
      newRotation =0;
    }
    let newArray=rotArray;
    newArray[i]=newRotation;
    this.setState({rotationArray:newArray});
   
  }

  const handleZoom=()=>{
    this.setState({zoomShow: true}); 
}

  const frequencyOptions = [
    {value: 1 , label: '1-Daily'},
     { value: 2 , label: '2-Weekly'},
      {value: 3 , label: '3-Monthly'},
       {value: 4 , label: '4-Alternate days'},
        {value: 5 , label: '5-Weekly twice'},
         {value: 6 , label: '6-Weekly Thrice'}
  ];
const formOptions = [

    {value: 'Tablets' , label: 'Tablets'},
     { value: 'Capsules' , label: 'Capsules'},
      {value: 'Chewable tablets' , label: 'Chewable tablets'},
      {value: 'Powders',label:'Powders'},
       {value: 'Solutions',label:'Solutions'},
        {value: 'Emulsions',label:'Emulsions'},
         {value: 'Suspensions',label:'Suspensions'},
          {value: 'Lotions',label:'Lotions'},
       {value: 'Creams',label:'Creams'},
        {value: 'Ointments',label:'Ointments'},
         {value: 'Effervescent granules',label:'Effervescent granules'},
         {value:'Aerosols', label: 'Aerosols'},
          {value:'Gases', label: 'Gases'},
           {value:'Suppositories', label: 'Suppositories'},
             {value:'Injections', label: 'Injections'},

  ];


const durationOptions=[

       {value: 1 , label: '1 day'},
       {value: 2 , label: '2 days'},
       {value: 3 , label: '3 days'},
       {value: 4 , label: '4 days'},
       {value: 5 , label: '5 days'},
       {value: 6 , label: '6 days'},
       {value: 7 , label: '7 days'},
       {value: 8 , label: '8 days'},
       {value: 9 , label: '9 days'},
       {value: 10 , label: '10 days'},
       {value: 11 , label: '11 days'},
       {value: 12 , label: '12 days'},
       {value: 13 , label: '13 days'},
       {value: 14 , label: '14 days'},
          
      {value: 7, label: '1 week'},
        {value: 14, label: '2 weeks'},
        {value: 21, label: '3 weeks'},
        {value: 28, label: '4 weeks'},
        {value: 35, label: '5 weeks'},
        {value: 42, label: '6 weeks'},
        {value: 49, label: '7 weeks'},
        {value: 56, label: '8 weeks'},
        {value: 63, label: '9 weeks'},
        {value: 70, label: '10 weeks'},
        {value: 77, label: '11 weeks'},
         {value: 84, label: '12 weeks'},
        {value: 91, label: '13 weeks'},
        {value: 98, label: '14 weeks'},
        {value: 105, label: '15 weeks'},
         {value: 112, label: '16 weeks'},
        {value: 119, label: '17 weeks'},
         {value: 126, label: '18 weeks'},
        {value: 133, label: '19 weeks'},
         {value: 140, label: '20 weeks'},
         {value: 147, label: '21 weeks'},
         {value: 154, label: '22 weeks'},
         {value: 161, label: '23 weeks'},
         {value: 168, label: '24 weeks'}
];
const routeOptions= [
     {value: 'Oral',label:'Oral'},
     {value: 'Topical-Skin',label:'Topical-skin'},
     {value: 'Topical-Eyes',label:'Topical-Eyes'},
     {value: 'Topical-Ears',label:'Topical-Ears'},
     {value: 'Inhalation',label:'Inhalation'},
     {value: 'Injections',label:'Injections'},
     {value: 'Vaginal',label:'Vaginal'},
     {value: 'Anal',label:'Anal'},
     {value: 'Sub-lingual', label: 'Sub-lingual'}

]

const intakeTimeOptions = [
    {value: 0 , label: '0-N/A'},
    {value: 1 , label: '1-Before food'},
    {value: 2 , label: '2-After food'}
   
]
const intakeCountOptions = [
    {value: [0,0,0,1] , label: '0-0-0-1'},
    {value: [0,0,1,0] , label: '0-0-1-0'},
     {value: [0,0,1,1] , label: '0-0-1-1'},
      {value: [0,1,0,0] , label: '0-1-0-0'},
       {value: [0,1,0,1] , label: '0-1-0-1'},
        {value: [0,1,1,0] , label: '0-1-1-0'},
         {value: [0,1,1,1] , label: '0-1-1-1'},
          {value: [1,0,0,0] , label: '1-0-0-0'},
         {value: [1,0,0,1] , label: '1-0-0-1'},
    {value: [1,0,1,0] , label: '1-0-1-0'},
     {value: [1,0,1,1] , label: '1-0-1-1'},
      {value: [1,1,0,0] , label: '1-1-0-0'},
       {value: [1,1,0,1] , label: '1-1-0-1'},
        {value: [1,1,1,0] , label: '1-1-1-0'},
         {value: [1,1,1,1] , label: '1-1-1-1'}
         
  ];


   /****validate******/

const validate=(medUpdated)=>{
  let isValid=true;
if(medUpdated.length>0){

    medUpdated.map((item,index)=>{




  if(!item){
     isValid=false;

     this.setState({validateErrorMsg:`Empty data. Please check again`})
     this.setState({validateError: 'error'})
     this.setState({validateErrorMsgShow: true})
 
  }
 else if((item.duration==="" || item.duration===null) && (item.intake_count==="" || item.intake_count===null) && (item.strength==="" || item.strength===null) && 
  (item.drug===null || item.drug==="")&& (item.frequency===null || item.frequency==="")&&(item.form===""|| item.form===null)
&& (item.route===null || item.route==="")&& (item.dosage===null || item.dosage==="") &&
(item.till_date===null || item.till_date==="")&& (item.intake_time===null || item.intake_time==="")
&& (item.remarks===null || item.remarks==="")){
   isValid=false;
 


   this.setState({validateErrorMsg:`Empty data. Please check again`})
   this.setState({validateError: 'error'})
   this.setState({validateErrorMsgShow: true})
 
}


else if((item.drug==="" || item.drug===null)&& (item.frequency==="" || item.frequency===null)&& 
       (item.intake_count==="" || item.intake_count===null)&&(item.duration==="" || item.duration==null)){
         isValid=false;
       

   this.setState({validateErrorMsg:`Mandatory fields: *Drug, Frequency, Count, Duration- can't be empty at Row ${index+1}`})
     this.setState({validateError: 'error'})
     this.setState({validateErrorMsgShow: true})
       }

    
else if(item.drug==="" || item.drug===null){
   isValid=false;

   this.setState({validateErrorMsg:`Drug name can't be empty at Row ${index+1}`})
   this.setState({validateError: 'error'})
   this.setState({validateErrorMsgShow: true})



}

else if(item.frequency==="" || item.frequency===null){
   isValid=false;

   this.setState({validateErrorMsg:`Frequency can't be empty at Row ${index+1}`})
   this.setState({validateError: 'error'})
   this.setState({validateErrorMsgShow: true})


 
}
else if(item.intake_count==="" || item.intake_count===null){
   isValid=false;

   this.setState({validateErrorMsg:`Count can't be empty at Row ${index+1}`})
   this.setState({validateError: 'error'})
   this.setState({validateErrorMsgShow: true})


 

}

else if(item.duration==="" || item.duration===null){
  isValid=false;
  this.setState({validateErrorMsg:`Duration can't be empty at Row ${index+1}`})
  this.setState({validateError: 'error'})
  this.setState({validateErrorMsgShow: true})

  

}
if(item.frequency!=="" && item.frequency!== null){
   if((item.frequency!== 1 && item.frequency!== 2 && item.frequency!== 3 && item.frequency!== 4)){
   isValid=false;

   this.setState({validateErrorMsg:`Invalid Frequency at Row ${index+1}`})
   this.setState({validateError: 'error'})
   this.setState({validateErrorMsgShow: true})


  
}
}
if(item.duration!=="" && item.duration!== null){
   if(!(item.duration>0 && item.duration<10000)){
   isValid=false;

   this.setState({validateErrorMsg:`Invalid Duration at Row ${index+1}`})
   this.setState({validateError: 'error'})
   this.setState({validateErrorMsgShow: true})

 
}
}
 if(item.intake_time!=="" && item.intake_time!== null){
 
   if((item.intake_time!== 0 && item.intake_time!== 1 && item.intake_time!== 2)){
   isValid=false;

   this.setState({validateErrorMsg:`Invalid Intake Time at Row ${index+1}`})
   this.setState({validateError: 'error'})
   this.setState({validateErrorMsgShow: true})

 
}
}

if(item.intake_count!=="" && item.intake_count!== null){
  if(item.intake_count.length===4){
     /*const arrayIntake=item.intake_count.map((eachItem,i)=>{
          if(eachItem!==1 && eachItem!==0){
                 isValid=false;

                 this.setState({validateErrorMsg:`Invalid (Intake) Count at Row ${index+1}`})
                 this.setState({validateError: 'error'})
                 this.setState({validateErrorMsgShow: true})

     
          }
     }) */
   }
   else{
     isValid=false;

      this.setState({validateErrorMsg:`Invalid (Intake) Count at Row ${index+1}`})
      this.setState({validateError: 'error'})
      this.setState({validateErrorMsgShow: true})


   }
}



if(item.till_date!=="" && item.till_date!== null){
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  const dateString=item.till_date;
  if(!dateString.match(regEx)) {
       isValid=false;

       this.setState({validateErrorMsg:`Invalid Till Date at Row ${index+1}`})
       this.setState({validateError: 'error'})
       this.setState({validateErrorMsgShow: true})


 
    }
  else{
          const d = new Date(dateString);
        const dNum = d.getTime();
        if(!dNum && dNum !== 0) {
           isValid=false;

           this.setState({validateErrorMsg:`Invalid Till Date at Row ${index+1}`})
           this.setState({validateError: 'error'})
           this.setState({validateErrorMsgShow: true})


        
        }
        else{

          
          }
     }

}

/*if(item.intake_count!=="" && item.intake_count!== null){
  let pattern= new RegExp(/^(([[01][,]\s?[0|1][,]\s?[0|1][,]\s?[0|1]]))$/);
      if (!pattern.test(item.intake_count)) {
          isValid=false;

           setValidateErrorMsg(`Invalid Intake Count at Row ${index+1}`);
 setValidateError("error");
 setValidateErrorMsgShow(true);


 
        }
}

  */




})



return isValid;
}

else{
  return false;
}



}
/******validate ends*****/


  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  
          
   

   const RejectConfirmPopup =(props)=>{
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="confirm-reject">Please confirm to reject the prescription</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

        <Button variant="danger" onClick={()=>{this.setState({rejectConfirmModalShow: false});
                                                               handleReject();}}> Confirm</Button>

       
      </Modal.Footer>
    </Modal>
  );
}

 const RejectSuccessPopup =(props)=>{
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Prescription rejected successfully!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/written-prescriptionlist"><Button className='btn btn-primary btn-col' > Goto Handwritten PrescriptionList</Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}



const handleRejectConfirm = (e)=>{
   e.preventDefault();
    this.setState({rejectConfirmMsg:'success'});
    this.setState({rejectConfirmModalShow: true});  
}
  
  const handleReject =() =>{
 
    

    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') ;

       axios.post(`${APIURL}/api/v1/staff/prescriptions/${this.props.match.params.id}/`, {status : "rejected"} , { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str }})
  .then(res => {
    if(res.data.status==="success"){
   
    this.setState({rejectSuccessMsg:'success'});
    this.setState({rejectSuccessModalShow: true});  
    
   
  
   

  }
  else {
   
     this.setState({rejectSuccessMsg:'error'});
    
  }
}).catch(err=>{
 
   this.setState({rejectSuccessMsg:'error'});
})


}
 

const handleMove=(data)=>{
  this.setState({docType:data})

}

const handleMoveSubmit=async (e)=>{
  e.preventDefault();
  const tokenString= localStorage.getItem('usertoken');
   let dataToPost={
    key: this.state.docType.value,
    move_from: "hand_written_prescription",
    report_id: parseInt(prescId)
   }
   let str= tokenString.replace(/["]+/g, '') ;

       axios.post(`${APIURL}/api/v1/patient/upload/`,dataToPost , { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str }})
  .then(res => {
    if(res.data.status==="success"){
  
  
    
   
  
    this.setState({moveMsg: (res.data.message? res.data.message : 'Document moved successfully!')});
   this.setState({moveShow: true});

  }
  else {
    let msg=res.data.message? res.data.message : "Error in submission";
 this.setState({moveErrorMsg: msg});
     this.setState({moveErrorShow: true});
    
  }
}).catch(err=>{
    this.setState({moveErrorMsg: "Error in submission"});
 this.setState({moveErrorShow: true});
})


}


const handleSaveDraft =(e)=>{
 


    e.preventDefault();
let dateUpdated= this.state.date;

const yourDate = new Date()
const current = yourDate.toISOString().split('T')[0]

if(dateUpdated!== '' &&  dateUpdated!== null && dateUpdated<=current){
  this.setState({dateMsg : "valid"});



const dataToSend = {
  date: this.state.date,
  tests: this.state.med,
  doctor_name: this.state.doctor_name,
   is_draft: true
}
var JSONData = JSON.stringify(dataToSend);


 

axios.post(`${APIURL}/api/v1/staff/prescriptions/${prescId}/`, JSONData , 
{ headers: {
       'Content-Type': 'application/json',
      "Authorization" : 'Token '+str }})
  .then(res => {
    if(res.data.status==="success"){

   
    this.setState({savedraftmsg:'success'});
    this.setState({modaldraftShow: true});  
  }
  else{
    this.setState({savedraftmsg:'error'});
    this.setState({modalDraftError: true})
  }
}).catch(err=>{
 
this.setState({savedraftmsg:'error'});
this.setState({modalDraftError: true})
})
}
else if(dateUpdated > current){
  this.setState({dateMsg:"not valid"});
}
else{
  this.setState({dateMsg:"invalid"});
}
}
  



const handleDr =(e)=>{
  e.preventDefault();
  if(e.target.value !== null){
      this.setState({doctor_name: e.target.value});
  }
}
const handleDate=(e)=>{
  e.preventDefault();
  if(e.target.value !== null){
      this.setState({date: e.target.value})
  }
}



const deleteRow=(index)=>{
  const newlist = this.state.med.filter((elt,i)=>{
    return i!== index
  })

  this.setState({med:newlist})
}

const handleSubmit=async(e)=>{
  e.preventDefault();
  
let dateUpdated= this.state.date;

const md= med

const medUpdated= await  md.length>0 ? md.map((item,index)=>{

  if(item.intake_count){
     let intakeArray=[];
  if(item.intake_count.includes('-')){
      const intake = item.intake_count;
    const s= intake.split('-');
   
    s.forEach(i=>{
      intakeArray.push(parseInt(i));
    })
    }

    return {
      duration: item.duration,
      intake_count:  intakeArray,
      drug: item.drug,
      frequency:item.frequency,
      form:item.form,
      route:item.route,
      id:item.id,
      dosage:item.dosage,
      strength:item.strength,
      till_date:item.till_date,
       intake_time: item.intake_time,
       remarks: item.remarks
    }
  }

  else{
    return {
    duration: item.duration,
    intake_count:  item.intake_count,
    drug: item.drug,
    strength:item.strength,
    frequency:item.frequency,
    dosage:item.dosage,
    form:item.form,
    id:item.id,
    route:item.route,
    till_date:item.till_date,
     intake_time: item.intake_time,
     remarks: item.remarks
          }
  }



}) : "";

if(await validate(medUpdated)){

const yourDate = new Date()
const current = yourDate.toISOString().split('T')[0]

if(dateUpdated!== '' &&  dateUpdated!== null && dateUpdated<=current){
  this.setState({dateMsg : "valid"});



const dataToSend = {
  date: this.state.date,
  tests: medUpdated,
  doctor_name: this.state.doctor_name
}
var JSONData = JSON.stringify(dataToSend);



  axios.post(`${APIURL}/api/v1/staff/prescriptions/${prescId}/`, JSONData , { headers: {
       'Content-Type': 'application/json',
      "Authorization" : 'Token '+str }})
  .then(res => {
    if(res.data.status==="success"){

   
    this.setState({submitmsg:'success'})
     this.setState({modalShow: true});
  }
  else{
    this.setState({submitErrorMsg:'error'})
    this.setState({modalErrorShow: true})
  }
}).catch(err=>{
  
  this.setState({submitErrorMsg:'error'})
  this.setState({modalErrorShow: true})
})

}
else if(dateUpdated > current){
  this.setState({dateMsg:"not valid"});
}
else{
  this.setState({dateMsg:"invalid"});
   }
 }
}


const handleSelectChange=async (data,cellInfo)=>{

  const med2 = [...med];
  med2[cellInfo.index][cellInfo.column.id] = data.value;
  await this.setState({med:med2})




 

}

const handleSelectCountChange=async (data,cellInfo)=>{

  const med2 = [...med];
  med2[cellInfo.index][cellInfo.column.id] = data.label;
  await this.setState({med:med2})




 

}
const renderEditable=(cellInfo)=> {
return (<>
<div 
    
    onBlur={e=>{

      let med2 = [...med];

  med2[cellInfo.index][cellInfo.column.id] = e.target.innerHTML.trim().replace(/&nbsp;/g, '')
.replace(/&amp;/g, '&')
.replace(/&gt;/g, '>')
.replace(/&lt;/g, '<');
this.setState({med:med2})

}
}
    contentEditable
    dangerouslySetInnerHTML={{__html: med[cellInfo.index][cellInfo.column.id]}}
    onPaste={ evt => {
          evt.preventDefault()

          const text = evt.clipboardData.getData('text/plain')
          document.execCommand('insertHTML', false, text)
  }}
    onKeyPress={evt => {
      const keyCode = evt.keyCode || evt.which;

      if (keyCode === 13) {
        evt.returnValue = false;
        if (evt.preventDefault) evt.preventDefault()
      } }}
     ></div>





</>
);
}

const renderDate = (cellInfo)=> {
  console.log(med[cellInfo.index][cellInfo.column.id])
  return (
    <>
          <DatePicker
       minDate = {new Date()}
       value={med[cellInfo.index][cellInfo.column.id] ? new Date(med[cellInfo.index][cellInfo.column.id]) : null}
       onChange={ date=> {

        let presc = med
        let dateUpdated= date;
          if(date!==null){
        const offset = date.getTimezoneOffset();
        
        dateUpdated = new Date(date.getTime() - (offset*60*1000))
        
        let DateExtracted = dateUpdated.toISOString().split('T')[0];

        presc[cellInfo.index][cellInfo.column.id]= DateExtracted
        this.setState({med:presc})

        }
       } }
                          
                     /> 
    </>
  )
  
}

const renderRouteEditable=(cellInfo)=> {
return (<>

<div 
    
    onBlur={e=>{

      let med2 = [...med];

  med2[cellInfo.index][cellInfo.column.id] = e.target.innerHTML.trim().replace(/&nbsp;/g, '')
.replace(/&amp;/g, '&')
.replace(/&gt;/g, '>')
.replace(/&lt;/g, '<');
this.setState({med:med2})

}
}
    contentEditable
    dangerouslySetInnerHTML={{__html: med[cellInfo.index][cellInfo.column.id]}}
                    onPaste={ evt => {
          evt.preventDefault()

          const text = evt.clipboardData.getData('text/plain')
          document.execCommand('insertHTML', false, text)
        }}
    onKeyPress={evt => {
      const keyCode = evt.keyCode || evt.which;

      if (keyCode === 13) {
        evt.returnValue = false;
        if (evt.preventDefault) evt.preventDefault()
      } }}
     ></div>




<Select
value={"select"}
onChange={(data)=>handleSelectChange(data,cellInfo)}
options={routeOptions}
className="select-intake"
/>

</>
);
}

const renderFormEditable=(cellInfo)=> {
return (<>

<div 
    
    onBlur={e=>{

      let med2 = [...med];

  med2[cellInfo.index][cellInfo.column.id] = e.target.innerHTML.trim().replace(/&nbsp;/g, '')
.replace(/&amp;/g, '&')
.replace(/&gt;/g, '>')
.replace(/&lt;/g, '<');
this.setState({med:med2})

}
}
    contentEditable
    dangerouslySetInnerHTML={{__html: med[cellInfo.index][cellInfo.column.id]}}

                  onPaste={ evt => {
        evt.preventDefault()

        const text = evt.clipboardData.getData('text/plain')
        document.execCommand('insertHTML', false, text)
      }}
    onKeyPress={evt => {
      const keyCode = evt.keyCode || evt.which;

      if (keyCode === 13) {
        evt.returnValue = false;
        if (evt.preventDefault) evt.preventDefault()
      } }}
     ></div>




<Select
value={"select"}
onChange={(data)=>handleSelectChange(data,cellInfo)}
options={formOptions}
className="select-intake"
/>

</>
);
}

const renderFrequencyEditable=(cellInfo)=> {
return (<>




<div 
    
    onBlur={e=>{

      let med2 = [...med];

  med2[cellInfo.index][cellInfo.column.id] = e.target.innerHTML.trim().replace(/&nbsp;/g, '')
.replace(/&amp;/g, '&')
.replace(/&gt;/g, '>')
.replace(/&lt;/g, '<');
this.setState({med:med2})

}
}
    contentEditable
    dangerouslySetInnerHTML={{__html: med[cellInfo.index][cellInfo.column.id]}}
              onPaste={ evt => {
    evt.preventDefault()

    const text = evt.clipboardData.getData('text/plain')
    document.execCommand('insertHTML', false, text)
  }}
    onKeyPress={evt => {
      const keyCode = evt.keyCode || evt.which;

      if (keyCode === 13) {
        evt.returnValue = false;
        if (evt.preventDefault) evt.preventDefault()
      } }}
     ></div>

<Select
value={"select"}
onChange={(data)=>handleSelectChange(data,cellInfo)}
options={frequencyOptions}
className="select-intake"
/>

</>
);
}


const renderIntakeCountEditable=(cellInfo)=> {
return (<>


<div 
    
    onBlur={e=>{

      let med2 = [...med];

  med2[cellInfo.index][cellInfo.column.id] = e.target.innerHTML.trim().replace(/&nbsp;/g, '')
.replace(/&amp;/g, '&')
.replace(/&gt;/g, '>')
.replace(/&lt;/g, '<');
this.setState({med:med2})

}
}
    contentEditable
    dangerouslySetInnerHTML={{__html: med[cellInfo.index][cellInfo.column.id]}}
                  onPaste={ evt => {
        evt.preventDefault()

        const text = evt.clipboardData.getData('text/plain')
        document.execCommand('insertHTML', false, text)
      }}
    onKeyPress={evt => {
      const keyCode = evt.keyCode || evt.which;

      if (keyCode === 13) {
        evt.returnValue = false;
        if (evt.preventDefault) evt.preventDefault()
      } }}
     ></div>



<Select
value={"select"}
onChange={(data)=>handleSelectCountChange(data,cellInfo)}

options={intakeCountOptions}
className="select-intake"
/>

</>
);
}


const renderDurationEditable=(cellInfo)=> {
return (<>

<div 
    
    onBlur={e=>{

      let med2 = [...med];

  med2[cellInfo.index][cellInfo.column.id] = e.target.innerHTML.trim().replace(/&nbsp;/g, '')
.replace(/&amp;/g, '&')
.replace(/&gt;/g, '>')
.replace(/&lt;/g, '<');
this.setState({med:med2})
  
}
}
    contentEditable
    dangerouslySetInnerHTML={{__html: med[cellInfo.index][cellInfo.column.id]}}
                onPaste={ evt => {
      evt.preventDefault()

      const text = evt.clipboardData.getData('text/plain')
      document.execCommand('insertHTML', false, text)
    }}
    onKeyPress={evt => {
      const keyCode = evt.keyCode || evt.which;

      if (keyCode === 13) {
        evt.returnValue = false;
        if (evt.preventDefault) evt.preventDefault()
      } }}
     ></div>


<Select
value={"select"}
onChange={(data)=>handleSelectChange(data,cellInfo)}
options={durationOptions}
className="select-intake"
/>

</>
);
}

const renderIntakeTimeEditable=(cellInfo)=> {
return (<>
<div 
    
    onBlur={e=>{

      let med2 = [...med];

  med2[cellInfo.index][cellInfo.column.id] = e.target.innerHTML.trim().replace(/&nbsp;/g, '')
.replace(/&amp;/g, '&')
.replace(/&gt;/g, '>')
.replace(/&lt;/g, '<');
this.setState({med:med2})
 
}
}
    contentEditable
    dangerouslySetInnerHTML={{__html: med[cellInfo.index][cellInfo.column.id]}}
                  onPaste={ evt => {
        evt.preventDefault()

        const text = evt.clipboardData.getData('text/plain')
        document.execCommand('insertHTML', false, text)
      }}
    onKeyPress={evt => {
      const keyCode = evt.keyCode || evt.which;

      if (keyCode === 13) {
        evt.returnValue = false;
        if (evt.preventDefault) evt.preventDefault()
      } }}
     ></div>





<Select
value={"select"}
onChange={(data)=>handleSelectChange(data,cellInfo)}
options={intakeTimeOptions}
className="select-intake"
/>

</>
);
}

const columns = [
  {
    Header: <span> 
            <span className="drug-header all-header">*DRUG</span>
            <h6 className="header-eg">Eg: Paracetamol </h6>
            </span>,
    accessor: "drug",
    Cell: renderEditable,
    style:{
      backgroundColor:"rgba(0,0,0,0.1)"
    },
    
    minWidth:100,
     sortable:false,
     filterable:false
  
  },
  {
    Header: <span>
              <span className="table-header-tooltip str-header all-header" data-toggle = "tooltip" 
                 data-placement = "top" title = "Strength">STR.</span>
                 <h6 className="header-eg">Eg: 500mg </h6>
            </span>,
    accessor: "strength",
    minWidth:100,
    Cell: renderEditable,
     sortable:false,
      filterable:false
  
  },
  {
    Header: <span><span className="form-header all-header">FORM</span>
            <h6 className="header-eg"> </h6>
            </span>,
    accessor: "form",
    Cell: renderFormEditable,
     sortable:false,
      filterable:false,
     minWidth:100
  
  },
  {
    Header: <span>
            <span className="route-header all-header">ROUTE</span>
            <h6 className="header-eg"> </h6>
            </span>,
    accessor: "route",
    Cell: renderRouteEditable,
    sortable:false,
     filterable:false,
    minWidth:100
  
  
  },
  {
    Header: <span>
           <span className="dosage-header all-header">DOSAGE</span>
           <h6 className="header-eg">Eg: 1 </h6>
            </span>,
    accessor: "dosage",
    Cell: renderEditable,
    sortable:false,
     filterable:false,
    width:50
  
  
  },{
    Header: <span><span className="frequency-header all-header">*FREQUENCY </span>
       <h6 className="header-eg">Eg: 2 </h6>
            </span>,
    accessor: "frequency",
    Cell: renderFrequencyEditable,
    sortable:false,
     filterable:false,
    minWidth:100
  
  
  },
  {
    Header: <span>
                <span className="tilldate-header all-header">TILL DATE </span>,
  
                 <h6 className="header-eg">YYYY-MM-DD </h6>
            </span>,
    accessor: "till_date",
    Cell: renderDate,
    sortable:false,
     filterable:false,
    minWidth:135
  
  
  },
  {
    Header: <span> 
       <span className="intake-header all-header">*COUNT </span>
  
                                                 <h6 className="header-eg"> 4 times/per day format. Eg: 1-0-0-1 </h6>
            </span>,
    accessor: "intake_count",
    Cell: renderIntakeCountEditable,
    sortable:false,
     filterable:false,
    minWidth:100
  
  
  },
  {
    Header: <span><span className="duration-header all-header">*DURATION </span>
                 <h6 className="header-eg">No.of days Eg: 23 </h6>
            </span>,
  
  
    accessor: "duration",
    Cell: renderDurationEditable,
    sortable:false,
     filterable:false,
    minWidth:100
  
  
  },
  {
    Header: <span> <span className="intaketime-header all-header"><span className="table-header-tooltip" data-toggle = "tooltip" 
                 data-placement = "top" title = "INTAKE TIME">TIME</span></span>
                  <h6 className="header-eg"> Eg:1 </h6>
            </span>,
    accessor: "intake_time",
    Cell: renderIntakeTimeEditable,
    sortable:false,
    
    minWidth:100,
    filterable:false
  
  
  
  
  },
  {
    Header: <span className="remarks-header all-header">REMARKS</span>,
    accessor: "remarks",
    Cell: renderEditable,
    sortable:false,
   
    minWidth:150,
    filterable:false
  
  
  
  
  },
  {
    Header: <span className="table-header-tooltip del-header" data-toggle = "tooltip" 
                 data-placement = "top" title = "Delete Row"><i className="fas fa-trash-alt"></i></span>,
    Cell: props=>{
      return (
      <button className="delete-btn" 
         onClick={()=>{deleteRow(props.index)}}> <i className="fas fa-trash-alt"></i> </button>
      )
    },
    sortable:false,
    filterable:false,
    width:50
  }




]

const handleNewRow=(e)=>{
  e.preventDefault();
     const newMed = this.state.med;
    newMed.push({drug:"",
    strength:null,
    id:null,
     form:null,
     route:null,
     dosage:null,
      frequency:null,
      duration: null,
     till_date:null,
      intake_time: null,
      intake_count:  null,
      remarks: null});

    this.setState({ med: newMed });

}
  return (
  
   
   

     
  
    <div className="">


      <div className="dashboard-navigation">
        <div> <Link to="/dashboard"> <i className="fas fa-home dasboard-icon"> </i>Dashboard </Link></div>
       
       <div> <i className="fas fa-angle-double-right angle-icon"></i> </div>
        <div> <Link to="/written-prescriptionlist"> Written-PrescriptionList </Link> </div>
        <div> <i className="fas fa-angle-double-right angle-icon"></i> </div>
        <div>  Written Prescription </div>
        </div>


    
     {this.state.isLocked=== "true" ? 
     <span style={{backgroundColor: "red", color:"white", padding: "5px 10px", width:"80vw",borderRadius:"10px"}}> This file is currently locked by you (for 5 minutes)!! </span>
     
     :  this.state.isLocked=== "false" ?
     <span style={{backgroundColor: "orange", color:"white", padding: "5px 10px", width:"80vw",borderRadius:"10px"}}> This file is currently Unlocked !! </span>
:
      ''}

    <h2 className="title-of-page"> Prescription ID-{this.state.prescId} Verification</h2>
   
    <div className="flex-row btns-row">  
    <button className="btn btn-danger  reject-btn" type="submit" onClick={handleRejectConfirm}>Reject Prescription</button>   
       <button className="btn btn-secondary " type="submit" onClick={handleSaveDraft}>Save Draft</button>
       <button className="btn btn-primary btn-col submit-ver-btn" type="submit" onClick={handleSubmit}>Submit Verified data </button>
      <div className=" flex-row move-select"><span style={{color:"grey"}}>Move to : </span>
    <Select
        value={this.state.docType}
        onChange={(data)=>{handleMove(data)
            }}
        options={moveOptions}
        className="select-move"
      /> 
       <button className=" btn btn-primary btn-col " type="submit" onClick={handleMoveSubmit}>Move Document </button>
      
      </div><br/>
</div>
    <br/>  
    <div className="top">
       <div className="date-drawn-div form-group">
         <label id="datedrawn"> Verify Date * <br/>(YYYY-MM-DD) :  </label>
         <input type="text" maxLength="30" id="datedrawn" placeholder="YYYY-MM-DD" className="" value={this.state.date} onChange={handleDate}/>
         </div>

   


         <div className=" date-drawn-div form-group" >
          <label id="verify-doctor" > Verify Doctor </label>
          <input type="text" maxLength="150" className="dr-field" value={this.state.doctor_name} onChange={handleDr} required/>
        </div>
    </div>
    {this.state.dateMsg === "invalid" ?
      <div > <h5 style={{color: "red"}}> *Please enter Verify Date field! </h5> </div>
      : this.state.dateMsg === "not valid" ?
      <div > <h5 style={{color: "red"}}> *Please enter a Valid Verify Date field! </h5> </div> : ''}
           

<div className="seperator"> </div>

     <div className="dashboard-display">
          


         <div className="">
         <h5 className="title-of-page">Scanned prescription </h5>

         {isPdf===false? images.map((pic, index)=>{
          return <div className="scanned-images-presc">
                  <img src={pic} width="500px" height="auto" alt="scanned prescription" style={{transform:`rotate(${rotArray[index]}deg) scale(${scaleVal}`}}/>
                  <div className="btns-img-handle">
                 <button className="btn btn-primary btn-col btn-rotate" onClick={()=>handleRotateLeft2(index)}> Rotate <i className="fa fa-undo" aria-hidden="true"></i> </button>
                 <button className="btn btn-primary btn-col btn-rotate" onClick={handleZoom}> Zoom <i className="fas fa-search-plus"></i> </button>
                  </div>
                   
                  </div>
         }): <div style={{width:'600px', height:'600px'}}>
         <object data={pdf} type="application/pdf" width="100%" height="100%">
  </object></div>  }
         
         
         </div>
         <div className="">
         <h5 className="title-of-page"> Table to verify and Edit Data </h5>
         <ReactTable 
         columns={columns}
         data={this.state.med}
         defaultPageSize = {10}
         filterable
         resizable
         noDataText={"Please wait while the data is loading..."}
         >

         </ReactTable>

         <button type="button" className="btn btn-primary btn-col add-row-btn" onClick={handleNewRow}> Click to Add New Row </button>
      
      </div>
     
       
       <div className="bottom-section">

      <br/>
         
    <div className="second-set">

       
        
         
      


           </div>

        <h6 className="text-muted">   **Drug ,Duration, Frequency and Intake count are mandatory fields</h6>
          <h6 className="text-muted"> **Enter Till Date in YYYY-MM-DD format </h6>    



  <div className="flex-row btns-row">   
     <button className="btn btn-danger reject-btn" type="submit" onClick={handleRejectConfirm}>Reject Prescription</button>   
      
      <button className="btn btn-secondary save-draft-btn" type="submit" onClick={handleSaveDraft}>Save Draft</button>
      
      <button className=" btn btn-primary btn-col " type="submit" onClick={handleSubmit}>Submit Verified data </button>
     <div className=" flex-row move-select"><span style={{color:"grey"}}>Move to : </span>
    <Select
        value={this.state.docType}
        onChange={(data)=>{handleMove(data)
            }}
        options={moveOptions}
        className="select-move"
      /> 
       <button className=" btn btn-primary btn-col " type="submit" onClick={handleMoveSubmit}>Move Document </button>
      
      </div><br/>
   </div>
     <br/>       
<br/>
        {this.state.savedraftmsg==="success" ? 
          <SaveDraft
        show={this.state.modaldraftShow}
        onHide={() =>  this.setState({modaldraftShow: false})}
      />
         :  this.state.savedraftmsg==="error"? <SaveDraftError
         show={this.state.modalDraftError}
         onHide={() => this.setState({modalDraftError:false})}
       />
          
             : ''
          }

     {this.state.dateMsg === "invalid" ?
      <div > <h5 style={{color: "red"}}> *Please enter Verify Date field! </h5> </div>
      : this.state.dateMsg === "not valid" ?
      <div > <h5 style={{color: "red"}}> *Please enter a Valid Verify Date field! </h5> </div> : ''}
     
            {this.state.submitmsg==="success" ? 

          <SubmitPopUp
        show={this.state.modalShow}
        onHide={() =>  this.setState({modalShow: false})}
      />

           : null }

{this.state.submitErrorMsg==="error" ? 

<ErrorSubmitPopUp
show={this.state.modalErrorShow}
onHide={() =>  this.setState({modalErrorShow: false})}
/>
: null}

{this.state.validateError==="error" ? 

          <ValidateErrorPopup
        show={this.state.validateErrorMsgShow}
        onHide={() => this.setState({validateErrorMsgShow: false}) }
        error={this.state.validateErrorMsg}
      />

           : 
             '' }

      {this.state.rejectConfirmMsg==="success" ? 

          <RejectConfirmPopup
        show={this.state.rejectConfirmModalShow}
        onHide={() =>  this.setState({rejectConfirmModalShow: false})}
      />

           : 
             '' }

      

       {this.state.rejectSuccessMsg==="success" ? 

          <RejectSuccessPopup
        show={this.state.rejectSuccessModalShow}
        onHide={() =>  this.setState({rejectSuccessModalShow: false})}
      />

           :   this.state.rejectSuccessMsg==="error" ? 
             (<div>
                <br/>
              <h5 style={{color: "red"}}> Error in Prescription rejection.Please check again! </h5>
              </div>) 
               : ''}

{ this.state.zoomShow? 
<ZoomPopUp 
      show={this.state.zoomShow}
      onHide={()=> this.setState({zoomShow:false})}
      
    />

: null  }

{ this.state.moveShow? 
<MovePopUp 
      show={this.state.moveShow}
      onHide={()=> this.setState({moveShow:false})}
      
    />

: null  }

{ this.state.moveErrorShow? 
<MoveErrorPopUp 
      show={this.state.moveErrorShow}
      onHide={()=> this.setState({moveErrorShow:false})}
      
    />

: null  }


   </div>
    </div>

     

     </div>



    
          

      
      
   
  );
}
}
export default DisplayWrittenPresc;