import React from "react";
import "./patientpresc.css";
import pic1 from "../../images/prescription-sample.jpg";
import pic2 from "../../images/prescription-sample2.jpg";

function PatientPresc() {
  return (
    <div className="container">
      <div >
        <h3 className="title-of-page"> Prescriptions History</h3>
       
        <br/>
        
       <div className="images-div">
        <img src={pic1} width="600px" height="auto" alt="report"/>
        <img src={pic2} width="600px" height="auto" alt="report"/>
      </div>
        <br/>
      </div>
    </div>
  );
}

export default PatientPresc;
