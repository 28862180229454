import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function EditAgeGroup(props) {

    const[data, setData]= useState(props.data);

    // min_age:                                           
    // max_age:                               
    // country:                             
    // age_name: 

    const[error,setError]=useState({
      allergyname:'',
      min_age:'',
      max_age:'',                                                        
      country:'',
      age_name:''
     
       });
       const countryoptions = [{ value:'US', label:'UNITED STATES' },
       { value:'India', label:'INDIA' }
      
   ]
   const agegroupoptions = [{ value:'infants', label:'INFANTS' },
   { value:'kids', label:'KIDS' },
   { value:'teen', label:'TEENAGER' },
   { value:'young', label:'YOUTH' },
   { value:'adult', label:'ADULT' },
   { value:'elderly', label:'ELDERLY' }
]
    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const[completeShow,setCompleteShow]=useState(false)
    const[errorValidate,setErrorValidate]=useState(false)

//     const serviceOptions = [{ value:'general', label:'General' },
//     { value:'service_request', label:'Service Request' },
//     { value:'rental', label:'Equipment Request' }
// ]

const CompletePopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-success'> Successfully Saved</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
       <Button className='btn btn-secondary' onClick={props.onHide}>Cancel</Button> 
       
       
      </Modal.Footer>
    </Modal>
  );
}

    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'> Click Proceed to Edit AgeGroup.</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
             <Button className='btn btn-secondary' onClick={props.onHide}>Not Now</Button> 
             <Button className='btn btn-primary btn-col' onClick={saveChanges}>Proceed</Button> 
             
            </Modal.Footer>
          </Modal>
        );
      }

      const handleError = () =>
  {
    setErrorValidate(false);
    setSuccessShow(false)
  }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>check data duplication or not</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
      const ErorValidatePopup=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>All feilds are mandatory</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={handleError}>Ok</Button> 
             
             
            
            </Modal.Footer>
          </Modal>
        );
      }
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


      const handleSave=() =>
{
  setSuccessShow(true);
 
}
      const handleServiceChange= (data) => {

        setData( current => ({
            ...current , age_name: data
        }))
   }

   const handleUnitChange= (data) => {

    setData( current => ({
        ...current , country: data
    }))
  }

  // const handleRequest = (e)=> {
  //  const val = e.target.value
  //  const key = e.target.name

  //  setData((current) => ({
  //      ...current , [key] : val==='true'? true : val==='false'? false : null
  //  }))

  // }

  // const handleYear = (date)=> {
  //   setYear(date);
      
  //     let dateUpdated= date;
  //       if(date!==null){
  //     const offset = date.getTimezoneOffset();
      
  //     dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
  //     let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
  //     /*let dateString= DateExtracted.toString(); */
  //     setYearData(DateExtracted);
      
  //     }
  // }

  // const handleExpiry = (date)=> {
  //   setExpiry(date);
      
  //     let dateUpdated= date;
  //       if(date!==null){
  //     const offset = date.getTimezoneOffset();
      
  //     dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
  //     let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
  //     /*let dateString= DateExtracted.toString(); */
  //     setExpiryData(DateExtracted);
      
  //     }
  // }

  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});
  
  // min_age:'',
  //     max_age:'',                                                        
  //     country:'',
  //     age_name:''

    if (!input["min_age"] || input["min_age"]==="" ) {
      isValid = false;
      errors["min_age"] = "Enter minimum age";
    }
    if (!input["max_age"] || input["max_age"]==="" ) {
      isValid = false;
      errors["max_age"] = "Enter maximum age";
    }
    if (!input["country"] || input["country"]==="" ) {
      isValid = false;
      errors["country"] = "Please enter country name";
    }
    if (!input["age_name"] || input["age_name"]==="" ) {
      isValid = false;
      errors["age_name"] = "Please enter Age Group";
    }
    
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();
 ;
 console.log(validate())
    if(validate()){
        const tokenString = localStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */

  //    min_age:'',
  //    max_age:'',                                                        
  //    country:'',
  //    age_name:''
    
       

 
  const dataToSend = {
    min_age: data.min_age,                               
    max_age : data.max_age,
    country: data.country.value,                     
    age_name: data.age_name.value
        

  }
 
     axios
       .put(`${APIURL}/api/v1/staff/age-group-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          setCompleteShow(true);
          setData({
            age_name : ''
         });}
          else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
    }

    else {
      setErrorValidate(true)
   }

}

    return (
        <>
    <h2 style={{marginTop:'0'}} className="title-of-page">Edit Age Group</h2>
        <div  className='config-box fit-content'>

        {/* //    min_age:'',
  //    max_age:'',                                                        
  //    country:'',
  //    age_name:'' */}
    
            
    <div className='feature-data pad-features'>
            <div className='flex-row name-content'>
                <span className='align-rental'>Age Group : </span>
                <Select
                            value={data.age_name}
                            onChange={(data,e)=>{handleServiceChange(data,e)
                            }}
                            options={agegroupoptions}
                            name='age_name'
                            className="select-currency select-flex"
                        />
                        {error.age_name ? <div className="error-validation-msg error-feature">{error.age_name}</div> : null}
            </div>
            </div>
            <div className='feature-data pad-features'>
            <div className='flex-col justify-feature'>
                    <span className='align-rental'>Minimum Age : </span>
                    <input type='number' className='form-control title-flex' name='min_age' min="0" max="100" value={data.min_age} onChange={handleTitle} />
                    {error.min_age ? <div className="error-validation-msg error-feature-inventory">{error.min_age}</div> : null}
                 </div>

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Maximum Age : </span>
                    <input type='number' className='form-control title-flex' name='max_age' min="0" max="100" value={data.max_age} onChange={handleTitle} />
                    {error.max_age ? <div className="error-validation-msg error-feature-inventory">{error.max_age}</div> : null}
                </div>
                </div>
                <div className='feature-data pad-features'>
                <div className='flex-row name-content'>
                <span>Country : </span>
                <Select
                            value={data.country}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={countryoptions}
                            name='country'
                            className="select-currency select-flex"
                        />
                        {error.country ? <div className="error-validation-msg error-feature">{error.country}</div> : null}
            </div>
            </div>
                
            

            

            

           

            {/* <div className='flex-row name-content'>
                <span>Equipment Unit : </span>
                <Select
                            value={data.unit}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={unitOptions}
                            name='service_unit'
                            className="select-currency serv-width"
                        />
            </div>

            <div className='flex-row name-content'>
                <span>On Request Service : </span>

                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-input">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='request'  value= {true} checked={data.request === true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='request'  value= {false} checked={data.request === false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
            </div>

            <div className='flex-row name-content'>
                <span>Active Feature : </span>
                
                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-input">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='active'  value= {true} checked={data.active === true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='active'  value= {false} checked={data.active === false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
            </div> */}

            <div className='text-center'>
                <button onClick={handleSave} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>
            {successShow ? 
<SuccessPopUp
show={successShow}
onProceed={() =>  {setSuccessShow(false); props.modalClose();}}
onHide={() =>  {setSuccessShow(false); props.notProceedClose(); }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
{errorValidate ? 

<ErorValidatePopup
show={errorValidate}
onHide={() =>  {setErrorValidate(false)}}
/> 
: null}


{completeShow ? 

<CompletePopUp
show={completeShow}
onHide={() =>  {setCompleteShow(false)}}
/> 
: null}
            
        </div>
        </>
    )
}

export default EditAgeGroup;
