import React, {useState,useEffect} from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "../ServiceRequests/service.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import ActiveUserList from "./ActiveUserList";

function LicenseTableBasic(props) {

const [modalShow, setModalShow]= useState(false)
const [activeUsers, setActiveUsers]= useState([])

const licenseData= props.data


const id = props.id

useEffect(()=> {
    if(id!==undefined){
        sessionStorage.setItem('id',id)
    }       
},[])



    const handleUsers= (cellInfo)=> {
        setActiveUsers(licenseData[cellInfo].active_users)
        setModalShow(true)
    }

    const UserPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ActiveUserList users={activeUsers} />
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

const renderTable= (cellInfo)=> {
    return(
        <div>{licenseData[cellInfo.index][cellInfo.column.id]}</div>
    )
}

const renderDate= (cellInfo)=> {
    const data =licenseData[cellInfo.index][cellInfo.column.id]
    const date = data.slice(0,10)
    return(
        <div>{date}</div>
    )
}


const columns = [
{
  Header: "ID",
  accessor: "license_id",
  Cell: renderTable,
    width:110,
    style:{
        backgroundColor:"rgba(0,0,0,0.1)"
      },
},
{
    Header: "Amount Paid",
    accessor: "amount_paid",
    Cell: renderTable,

      width:130
  },
  {
    Header: "Currency",
    accessor: "currency",
    Cell: renderTable,

      width:130
  },
  {
    Header: "Valid From",
    accessor: "valid_from",
    Cell: renderDate,
      width:120,
      style:{
          backgroundColor:"rgba(0,0,0,0.1)"
        },
  },
  {
    Header: "Valid Till",
    accessor: "valid_till",
    Cell: renderDate,
      width:120,
      style:{
          backgroundColor:"rgba(0,0,0,0.1)"
        },
  },
{
    Header: "Tax",
    accessor: "tax_percentage",
    Cell: renderTable,

      width:100
  },
  {
    Header: "Tenure",
    accessor: "tenure",
    Cell: renderTable,

      width:100
  },
  {
    Header: "User Count",
    accessor: "user_count_limit",
    Cell: renderTable,
      width:110,
      style:{
          backgroundColor:"rgba(0,0,0,0.1)"
        },
  },
  {
    Header: "Active Users",
    accessor: "active_user_count",
    Cell: renderTable,
      width:110,
  },
  {
    Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
                 data-placement = "top" title = "View Active Users">View Active Users</span>,
    Cell: props=>{
      return (
      <button className="btn btn-success btn-square btn-small" 
         onClick={()=>{handleUsers(props.index)}}>View Active Users</button>
      )
    },
    minWidth:140
  
  },
]

  const displayTable=<ReactTable 
         columns={columns}
         data={licenseData}
         defaultPageSize = {5}
         className="service-req-table SR-basic-table"
         resizable
                  
                
         >

         </ReactTable> 
  return (<>
  
      <div className="text-center">
        <div className="service-table-holder flex-col"> {displayTable}
        </div>
    
     
    
    </div>
    <br/>
    <br/>

    {modalShow ? 

<UserPopUp
show={modalShow}
onHide={() =>  {setModalShow(false);}}
/> 
: null}
    </>
  );
}

export default LicenseTableBasic;
