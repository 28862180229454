import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import "../../App.css";
import axios from "axios";
import { APIURL } from "../../Global";

function EquipmentDetails(props) {
  const [eqID, setEqID] = useState(props.data.equipment);

  const [equipmentData, setEquipementdata] = useState([]);

  useEffect(() => {
    ;

    const tokenString = localStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/hospital/equipment-detail/${eqID}/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          debugger
          let tempDate=[];
          tempDate.push({ id: res.data.id ,  equipment_name: res.data.equipment_name, serial_number:res.data.serial_number,price:res.data.price,model:res.data.model,category:res.data.category })
          const datavalue = tempDate;

           

        
          setEquipementdata(datavalue);
        }
      });
    }, []);

  const renderEditable = (cellInfo) => {
    debugger
    if (equipmentData[cellInfo.index][cellInfo.column.id]) {
      return (
        <div className="fixed-co">
          {" "}
          {equipmentData[cellInfo.index][cellInfo.column.id]}
        </div>
      );
    } else {
      return <span className="text-danger">NIL</span>;
    }
  
  };

  const columnsEquipments = [
    {
      Header: "Equipment ID",
      accessor: "id",
      Cell: renderEditable,
      //   style:{
      //     backgroundColor:"rgba(0,0,0,0.1)"
      //   },
      width: 240,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Equipment Name",
      accessor: "equipment_name",
      Cell: renderEditable,
      //   style:{
      //     backgroundColor:"rgba(0,0,0,0.1)"
      //   },
      width: 240,
      minWidth: 100,
      sortable: false,
    },
    {
      Header:"Serial Number",
      accessor: "serial_number",
      Cell: renderEditable,
      //   style:{
      //     backgroundColor:"rgba(0,0,0,0.1)"
      //   },
      width: 240,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Price",
      accessor: "price",
      Cell: renderEditable,
      //   style:{
      //     backgroundColor:"rgba(0,0,0,0.1)"
      //   },
      width: 240,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Model",
      accessor: "model",
      Cell: renderEditable,
      //   style:{
      //     backgroundColor:"rgba(0,0,0,0.1)"
      //   },
      width: 240,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Category",
      accessor: "category",
      Cell: renderEditable,
      //   style:{
      //     backgroundColor:"rgba(0,0,0,0.1)"
      //   },
      width: 240,
      minWidth: 100,
      sortable: false,
    },
  ];

  const displayTable = (
    <ReactTable
      columns={columnsEquipments}
      data={equipmentData}
      showPaginationBottom={false}
      //defaultPageSize={5}
      minRows={1}
      className="procedure-table"
     // resizable
    ></ReactTable>
  );
  return (
    <>
      <div id="procedure-table-list">
        <div className="flex-row"></div>
        <h2 style={{ width: "95%" }} className="title-of-tasks">
          EquipmentDetails
        </h2>
        {displayTable}
      </div>
    </>
  );
}

export default EquipmentDetails;
