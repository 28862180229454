import React, {useState} from 'react'

function EditRemark(props) {

    const [remarkData, setRemarkData]= useState(props.data)
    const [show, setShow]= useState(false)

    const handleShow =()=> {
        if(show===false){
            setShow(!show)
        }
        else{
            setRemarkData(props.data)
            setShow(!show)
        }
    }

    let data = remarkData
       props.update(data)
    return (
        <div className='flex-col'>        
            {
                show ? 
                <textarea 
                type="text" 
                className="form-group text-area remark-edit"
                value= {remarkData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setRemarkData(e.target.value)
                  //props.update(data)
          } }
            autoComplete="off"
                /> : <p className='initial-remarks'>{remarkData}</p>
            }

<button onClick={handleShow} className={show===false?'btn btn-primary btn-square btn-small':'btn btn-danger btn-small btn-square'}>{show===false?'Edit':'Discard'}</button>
            
        </div>
    )
}

export default EditRemark
