import React, {useState} from 'react'
import Pagination from "react-js-pagination";


function ActiveUserList(props) {
    const [activeUsers]=useState(props.users);
     const [searchTerm,setSearchTerm]=useState('');
      const [currentPage,setCurrentPage]=useState(1);
         const [perPage]=useState(5);


   const handleSearchChange = (event)=> {
        setSearchTerm(event.target.value)
    }

    const handlePageChange = (pageNumber)=> {
      setCurrentPage(pageNumber)
    }


  
          
     

        let data =   activeUsers.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
      })
      .filter((value)=>{
        if(searchTerm===''){
            return value
        } else if (value.name.toLowerCase().includes(searchTerm.toLowerCase())){
            return value
        }
    })


        const indexLast= currentPage * perPage;
        const indexFirst= indexLast - perPage;
        const current = data.slice(indexFirst ,indexLast);
        const total = data.length;

        const pageNumbers = []
        for(let i=0 ;i<= Math.ceil(total/perPage) ;i++){
          pageNumbers.push(i)
        }
        const totalPages=pageNumbers.length;
        return (<div className="subscribers">
          
            <h3 className='title-of-tasks'>Active Users List</h3>
            <br/>
        <div className="search-section">
  
       
            <div className="search-div">
              <input
                className="form-control search-input"
                type="text"
                maxLength="30"
                placeholder="Enter Name"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button  className="btn btn-primary btn-col search-btn">
                {" "}
                <i className="fas fa-search"></i>{" "}
              </button>        </div>
          <div className="container search-results">  
          {
          current.length>0 ? current.map((value,index)=>{
              return(             
                  <div key ={index} className='list-detail'>               
                      <h5 className="each-list-link" id={value.id} key={value.id}>{(currentPage-1)*2 + index+1}.{value.name}</h5>  
                      <div className='flex-row'>
                        <p className='each-detail'>{value.type==='hospital_doctor'? 'Doctor ID:': value.type==='hospital'? 'Bdo ID:': 'ID'}  {value.id} </p>
                        <p className='each-detail'> User Type : {value.type} </p>     
                      </div>                                                        
                  </div>
              )          
            })
            : <h6 className="text-danger text-center"> No Active Users Found</h6>
          }
          
           
          </div>
              
            
            {totalPages > 1 ?
    <div className="pagn pagn-small">
 <br/>
    <br/>
            <Pagination
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={total}
          pageRangeDisplayed={totalPages}
          onChange={handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null}
            
        </div>
        </div>
        )
    
}

export default ActiveUserList