import React, {useState ,useEffect}  from 'react';
import {Link , useHistory } from 'react-router-dom';
import axios from "axios";
import "./patientinfo.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";
function PatientInfo(props){
  const [height,setHeight]= useState();
  const [weight,setWeight]=useState();
 
    const [isDiabetes,setIsDiabetes]=useState(false);
    const [isAllergic,setIsAllergic] = useState("allergic-no");
    const [allergyValue,setAllergyValue]=useState("");
    const [hasHereditaryDisease,setHasHereditaryDisease]=useState();
    const [hereditaryValue,setHereditaryValue]=useState("");
    const [isHospitalized,setIsHospitalized]=useState();
     const [hospitalized,setHospitalized]=useState();
    const [exercise,setExercise]=useState();
    const [foodstyle,setFoodStyle]=useState();
    const [,setSelectedOption]=useState("option1");
    const [maritalstatus,setMaritalStatus]=useState("");
    const [married,setMarried]=useState();
    const [extraDatas,setExtraDatas]=useState("");
    const [renderMed,setRenderMed]=useState(false);

 const [errorSubmitMsg,setErrorSubmitMsg]=useState(null);
 const [errorSubmit,setErrorSubmit]=useState(false);


 const [successSubmitMsg,setSuccessSubmitMsg]=useState(null);
 const [successSubmit,setSuccessSubmit]=useState(false);
    const [medicalConditions,setMedicalConditions]=useState({
        diabetes: false,
        cardiac: false,
        neural_disease:false,
        hypertension: false,
        kidney_disease: false,
        liver_disease: false,
        asthma: false,
        stroke: false

    });
    const [othersMedicalValue,setOthersMedicalValue]= useState('');
    const [habits,setHabits]=useState({
      drinking: false,
      smoking: false,
      chewing: false,
      habits_others: false
    });
 const [medications,setMedications]=useState([]);
 const [medicineValue,setMedicineValue]=useState();
 useEffect(()=>{
  setMedications(medications);
 },[renderMed]);


    let history = useHistory();


const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorSubmitMsg} </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}
const SubmitSuccessPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h4>Health data added successfully !! </h4>
        <h6>
         We are in process of implementing Web app with all functionalities.
         Please use our mobile app to use our services until then.  
          <br />
        </h6>
        <h6> Thanks for your understanding! </h6>
        
        <a href='https://play.google.com/store/apps/details?id=intp.purpc&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank"><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className="google-play-text"/></a>
                              
       
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>

         <Link to="/login"><Button className='btn btn-primary btn-col'>Goto Login</Button></Link>


       
       

      </Modal.Footer>
    </Modal>
  );
}

    const handleAllergyValue=e=>{
     
      setAllergyValue(e.target.value);
    }
const handleHeight=e=>{
  e.preventDefault();
  setHeight(e.target.value);
}
const handleWeight=e=>{
  e.preventDefault();
  setWeight(e.target.value);
}
const handleHereditaryValue=e=>{
  setHereditaryValue(e.target.value);
}
const handleMedOthers=e=>{
  setOthersMedicalValue(e.target.value);
}

/*const onChangeDiabetes=e=>{
  setIsDiabetes(!isDiabetes);


}*/
const handleSubmit=(e)=>{
  e.preventDefault();


  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
  

let medConditions = [];
['diabetes','cardiac','neural_disease','kidney_disease','liver_disease','asthma','hypertension','stroke'].map(item=>{
if(medicalConditions[item]){
  medConditions.push(item);

}
});

if(othersMedicalValue!=="" && othersMedicalValue!== null){
  medConditions.push(othersMedicalValue);
}

let habitsArray = [];
['smoking','chewing','drinking'].map(item=>{
if(habits[item]){
  habitsArray.push(item);

}
});


let allergyArray= [];
if(allergyValue){
allergyArray.push(allergyValue);
}



const dataToPost={
  
  weight: weight,
  height: height,
  comorbidities:medConditions.length>0 ? medConditions: [],
  medication: medications.length>0 ? medications: [],
  allergic_conditions: allergyArray.length>0 ? allergyArray : [],
  exercise_level: exercise,
  food_habits: foodstyle,
  hereditary_disease :hereditaryValue ,
  other_medical_information: extraDatas,
  habits: habitsArray,
  anytime_hospitalized: hospitalized,
  married: married
}

    axios.post(`${APIURL}/api/v1/patient/user-details/`, dataToPost, { headers: {
      
      "Authorization" : 'Token '+str ,
      'Content-Type': 'application/json' }})
    .then(res=>{
  
      if(res.data.status==="success"){
        setSuccessSubmitMsg("success");
          setSuccessSubmit(true);
      

   
   
      }else{
          setErrorSubmitMsg(res.data.message);
          setErrorSubmit(true);
       

      }


    })
    .catch(err=>{
       setErrorSubmitMsg("Error in health data submission!");
          setErrorSubmit(true);
       
}
 
  
)
  
}
const handleSkip=(e)=>{


    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
        axios.post(`${APIURL}/api/v1/patient/patient-health-details-skip/`,null,
        { headers: 
          {
      
      "Authorization" : 'Token '+str}})
    .then(res =>{
            
            if(res.data.status === "success"){
                   
                  
                     
                }
                
            })
     .catch(err =>{

  })
  history.push('/login');
  
}
const handleMaritalStatus=(e)=>{
   setMaritalStatus(e.target.value);
if(e.target.value==="yes"){
 setMarried(true);
 
}
else if(e.target.value==="no"){

  setMarried(false);
}
}
const handleAddMedicine =e=>{
  e.preventDefault();
  let newArray=medications;
  newArray.push(medicineValue);
  setMedications(newArray);
  setRenderMed(!renderMed);
  setMedicineValue('');
}
const handleIsAllergic=e=>{
  
  setIsAllergic(e.target.value);

}

const handleExtraDatas=(e)=>{
  e.preventDefault();

  setExtraDatas(e.target.value);
}
 
const handleHospitalized=e=>{
  setIsHospitalized(e.target.value);
  if(e.target.value==='hospitalized-yes'){
  setHospitalized(true);
}
else if(e.target.value==='hospitalized-no'){
  setHospitalized(false);
}
}
const handleHereditary=e=>{
  setHasHereditaryDisease(e.target.value);
}
const handleExercise=e=>{
  setExercise(e.target.value);
}
const handleFoodStyle=e=>{
  setFoodStyle(e.target.value);
}
const handleMedicalCondition=e=>{
  
      const key=e.target.name;
    
         setMedicalConditions(currentstate=>({
            ...currentstate,
            [key]: !(medicalConditions[key])
          }));
      
}

const handleHabits=e=>{
      
      const key=e.target.name;
    
         setHabits(currentstate=>({
            ...currentstate,
            [key]: !(habits[key])
          }));
      
}
const handleMedicine=e=>{
  setMedicineValue(e.target.value);
}
/*const handleOptionChange=(e)=>{
  setSelectedOption(e.target.value)
}*/
const displayMedications = medications.length>0 ? medications.map((item,i)=>{
  return (<div key={i} className="medicine-item">
           <h6> {item} </h6>
           </div>)
}) : null ; 
 return (
                  <div className="form-body">
                   
                    <div className="row">
                        <div className="img-holder">
                            <div className="bg"></div>
                           <div className="info-holder holder-sticky"> 
                                 <div className="captions-holder">
                                 <div className="s-wrap">

                                  <div className="s-move">
                                   <div className="slide">
                                      <h2 className="captions">
                                         Digitize Health Documents. Get Continuum Care. </h2>
                                         
                                       
                                                                           
                                       
                                        <h2 className="captions">Register as Patient and install <span className="felixacare-logo-color">FelixaCare app</span>.
                                     </h2> 
                                     
                                     <a href='https://play.google.com/store/apps/details?id=intp.purpc&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer"><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className="google-play-text"/></a>
                                  </div>

                                  <div className="slide">
                                      <h2 className="captions">
                                         Take better care of your parents, spouse and relatives. </h2>
                                      <h2 className="captions">
                                         Volunteer to care and assist someone . </h2>
                                         
                                       
                                                                           
                                       
                                        <h2 className="captions">Register as Caregiver and install <span className="felixacare-logo-color">FelixaCare app</span>.
                                     </h2> 
                                     
                                     <a href='https://play.google.com/store/apps/details?id=intp.purpc&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer"><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className="google-play-text"/></a>
                                  </div>
                                </div>

                              </div>
                              </div>
                            </div>
                        </div>
                        <div className="form-holder form-container">
                            <div className="info-form-content form-content">
                                <div className="form-items">
                                   {/*  <h3>Get access to the innovative AI platform for people's wellbeing.</h3> */}
                                    <p></p>
                                    <div className="page-links">
                                        <Link to="/login" >Login</Link><Link to="register" className="active">Register</Link>
                                       
                                    </div>

                                    

                                         <form className="reg-form" onSubmit={handleSubmit}>
                                       
                                    
                                    <h3 className="title-of-tasks text-center"> Health Details </h3>
                                       <h6 className="thank-you-note">  Thank you for choosing our service! Please fill below health details ,so that we can serve you better .</h6>
                                      <div className="divider"></div>
                                      
                                       {/**** parameters****/}
                                        
                                          <h6 className="questions" > Please enter Height(in cm) and Weight(in kg) </h6>

                                     <div className="container">
                                 

                                 {/*********row 1*******/}
                                      <div className="row">
                                        <div  className="col">
                                             
                                             <input className="form-control" id="height" type="text" name="height" placeholder="Height (in cm)" onChange={handleHeight} maxLength='30'/>
                                           

                                        </div>
                                       <div  className="col">

                                      
                                           <input className="form-control" type="text" name="Weight" placeholder="Weight (in Kg)" onChange={handleWeight} maxLength='30'
                                        /> 
                                        </div>
                                    </div>
                                    </div>

                                  


                                       {/*********row 2*******/}
                  




                                    <div>
                                   
                                             <div className="divider"></div>
                                       {/**** medical conditions****/}
                                        
                                          <h6 className="questions" > Any Medical Conditions ? (Select all that apply) </h6>
                                         <div className="row"> 
                                          <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="diabetes" checked={medicalConditions.diabetes}
                                      onChange={handleMedicalCondition} name="diabetes"/>
  <label className="form-check-label" htmlFor="diabetes">
    Diabetes
  </label>
</div>

 <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="cardiac"  name="cardiac" checked={medicalConditions.cardiac}
                                      onChange={handleMedicalCondition}/>
  <label className="form-check-label" htmlFor="cardiac">
    Cardiac
  </label>
</div>

<div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="neural_disease"  name="neural_disease" checked={medicalConditions.neural_disease}
                                      onChange={handleMedicalCondition}/>
  <label className="form-check-label" htmlFor="neural_disease">
    Neural Disease
  </label>
</div>


<div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="hypertension"  name="hypertension" checked={medicalConditions.hypertension}
                                      onChange={handleMedicalCondition}/>
  <label className="form-check-label" htmlFor="hypertension">
    Hypertension
  </label>
</div>




<div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="kidney_disease"  name="kidney_disease" checked={medicalConditions.kidney_disease}
                                      onChange={handleMedicalCondition}/>
  <label className="form-check-label" htmlFor="kidney_disease">
    Kidney disease
  </label>
</div>
<div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="asthma"  name="asthma" checked={medicalConditions.asthma}
                                      onChange={handleMedicalCondition}/>
  <label className="form-check-label" htmlFor="asthma">
    Asthma
  </label>
</div>

<div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="liver_disease"  name="liver_disease" checked={medicalConditions.liver_disease}
                                      onChange={handleMedicalCondition}/>
  <label className="form-check-label" htmlFor="liver_disease">
    Liver disease
  </label>
</div>
<div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="stroke"  name="stroke" checked={medicalConditions.stroke}
                                      onChange={handleMedicalCondition}/>
  <label className="form-check-label" htmlFor="stroke">
   Stroke
  </label>
</div>

<div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="others"  name="others" checked={medicalConditions.others}
                                      onChange={handleMedicalCondition}/>
  <label className="form-check-label" htmlFor="others">
   Others
  </label>
</div>

 </div>
<br/>
{medicalConditions.others? (<><div className="additional-box">
                      <input type="text" placeholder="" value={othersMedicalValue} onChange={handleMedOthers} maxLength='150' className="form-control"/>  
                     
             </div></>) : null}






                      
                        

                                             <div className="divider"></div>
                        

                                                              

                                       {/*************radio button****/}

                                        
                                       <div className="row">
                                         <h6 className="questions"> Are you Married ? </h6>
                                       <div> 
                                          <input 
                                             type="radio" 
                                             id="yes" 
                                             name="maritalstatus" 
                                             value="yes"
                                             checked={maritalstatus === "yes"}
                                             onChange={handleMaritalStatus}
                                            
                                                 />
                                          <label htmlFor="yes">Yes</label>
                                          </div>
                                          <div>
                                          <input 
                                             type="radio" 
                                             id="no" 
                                             name="maritalstatus" 
                                             value="no"
                                             checked={maritalstatus === "no"}
                                             onChange={handleMaritalStatus}
                                            
                                                 />
                                          <label htmlFor="no">No</label>
                                        </div>


                                        </div>


                                           <div className="divider"></div>

                                        {/**********Allergy? ******/}
                                        <div className="row">
                                                            <h6 className="questions">Do you have any Allergies? </h6>
                      <input type="radio" id="allergy-yes" name="is-Allergic" 
                      value="allergy-yes" checked={isAllergic === "allergy-yes"} 
                  onChange={handleIsAllergic} />
                            
                      <label htmlFor="allergy-yes">Yes</label>

                         <input type="radio" id="allergy-no" name="is-Allergic" value="allergy-no" checked={isAllergic ==="allergy-no"} 
                  onChange={handleIsAllergic} />
                            
                      <label htmlFor="allergy-no">No</label>
                     
                     </div>
                     <br/>
                     {isAllergic==="allergy-yes"? (<><div className="additional-box">
                      <input type="text" maxLength='150' placeholder="Add here" value={allergyValue} onChange={handleAllergyValue} className="form-control"/>  
                     
             </div></>) : null}
 

                       <div className="divider"></div>
                     {/************Medications******/}
                   <div className="row">
                  <h6 className="questions"> If you are on any medications , please add medicine name below.  </h6>
                  <br/></div> 
                 <div className="medicines-row"> {medications.length>0 ? displayMedications : null} </div>
                  <div className="comorbidity-add-row">
                      <input type="text" placeholder="Add Medicine name" value={medicineValue} onChange={handleMedicine} maxLength='30' className="form-control additional-box" id="med"/>  
                      <button type="submit" className="btn btn-smallest btn-square btn-primary btn-col btn-add-med" onClick={handleAddMedicine}> + Add </button> 
             </div>
                 
                       <div className="divider"></div>
                  {/**********Hospitalized? ******/}
                                        <div className="row">
                                                            <h6 className="questions">Have you been hospitalized recently(in the past 6 weeks)? </h6>
                      <input type="radio" id="hospitalized-yes" name="isHospitalized" 
                      value="hospitalized-yes" checked={isHospitalized === "hospitalized-yes"} 
                  onChange={handleHospitalized} />
                            
                      <label htmlFor="hospitalized-yes">Yes</label>

                          <input type="radio" id="hospitalized-no" name="isHospitalized" 
                      value="hospitalized-no" checked={isHospitalized === "hospitalized-no"} 
                  onChange={handleHospitalized} />
                            
                      <label htmlFor="hospitalized-no">No</label>
                     </div>
                       <div className="divider"></div>
                   {/***********Hereditary disease******/}
                  

                         <div className="row">
                                                            <h6 className="questions">Do you have any hereditary diseases ? </h6>
                      <input type="radio" id="hereditary-yes" name="hereditary-yes" 
                      value="hereditary-yes" checked={hasHereditaryDisease === "hereditary-yes"} 
                  onChange={handleHereditary} />
                            
                      <label htmlFor="hereditary-yes">Yes</label>

                        <input type="radio" id="hereditary-no" name="hereditary-no" 
                      value="hereditary-no" checked={hasHereditaryDisease === "hereditary-no"} 
                  onChange={handleHereditary} />
                            
                      <label htmlFor="hereditary-no">No</label>

                   </div> 
                   {hasHereditaryDisease==="hereditary-yes"? (<><div className="additional-box">
                      <input type="text" placeholder="Add here" value={hereditaryValue} maxLength='150' onChange={handleHereditaryValue} className="form-control"/>  
                     
             </div></>) : null}

                       <div className="divider"></div>
                     {/**************Exercise*****/}
                    
                    <div className="row">
                                                            <h6 className="questions">Exercise routine </h6>
                      <input type="radio" id="regular" name="regular" 
                      value="regular" checked={exercise === "regular"} 
                  onChange={handleExercise} />
                            
                      <label htmlFor="regular">Regular</label>

                         <input type="radio" id="occasional" name="occasional" value="occasional" checked={exercise === "occasional"} 
                  onChange={handleExercise} />
                 
                            
                      <label htmlFor="occasional">Occasional</label>
                     
                     
                       <input type="radio" id="never" name="never" value="never" checked={exercise === "never"} 
                  onChange={handleExercise} />
                 
                            
                      <label htmlFor="never"> Never</label>
                     
                     </div> 
                      <div className="divider"></div>
                   {/**************Food style*****/}
                    
                    <div className="row">
                                                            <h6 className="questions">Food Style </h6>
                      <input type="radio" id="veg" name="veg" 
                      value="veg" checked={foodstyle === "veg"} 
                  onChange={handleFoodStyle} />
                            
                      <label htmlFor="veg">Vegetarian</label>
                       
                        <input type="radio" id="non_veg" name="non_veg" 
                      value="non_veg" checked={foodstyle === "non_veg"} 
                  onChange={handleFoodStyle} />
                            
                      <label htmlFor="non_veg">Non-vegetarian</label>

                        
                     
                     </div> 

                       <div className="divider"></div>

                        {/**** Any Habits****/}
                                        
                                          <h6 className="questions"> Do you have any of the following Habits? </h6>
                                         <div className="row"> 
                                          <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="smoking" checked={habits.smoking}
                                      onChange={handleHabits} name="smoking"/>
  <label className="form-check-label" htmlFor="smoking">
    Smoking
  </label>
</div>

 <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="drinking" checked={habits.drinking}
                                      onChange={handleHabits} name="drinking"/>
  <label className="form-check-label" htmlFor="drinking">
    Drinking
  </label>
</div>

 <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="chewing" checked={habits.chewing}
                                      onChange={handleHabits} name="chewing"/>
  <label className="form-check-label" htmlFor="chewing">
    Chewing
  </label>
</div>
{/*
 <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="habits_others" checked={habits.habits_others}
                                      onChange={handleHabits} name="habits_others"/>
  <label className="form-check-label" htmlFor="habits_others">
    Other
  </label>
</div>  */}
</div>

  <div className="divider"></div>
{/**********Any other Details*****/}

                                         <h6 className="questions"> Any other health info would you like to tell us? </h6>
<div className="row">
 

  <textarea className=""  maxlength="300" value={extraDatas} onChange={handleExtraDatas}> </textarea>

    
         </div>




                                      </div>

                                         <button className="btn btn-secondary" type="submit" onClick={handleSkip}> Skip </button>
                                       <button className="btn btn-primary btn-col" type="submit" onClick={handleSubmit}> Submit </button>
       
                                    </form>
                                 </div>
                            </div>
                      </div>

              </div>

             

               {errorSubmitMsg!==null ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  setErrorSubmit(false)}
       
      />: null}

       {successSubmitMsg === "success" ?
    <SubmitSuccessPopup
        show={successSubmit}
        onHide={() =>  setSuccessSubmit(false)}
       
      />: null}

              </div>
              )

}

export default  PatientInfo;