import React from "react";

import "./missingheader.css";
import { Link} from "react-router-dom";
import axios from "axios";
import {APIURL} from "../../../Global";
class MissingHeaderList extends React.Component {
  constructor() {
    super();
    this.state = {
      datalist: [],
    };
  }

  componentDidMount() {
    const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

   

    axios
      .get(`${APIURL}/api/v1/staff/lab-reports/?status=failed&reason=no_header&sort_by=asc&per_page=10`, {
        headers: { Authorization: "Token "+ v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.pending_verifications;

          this.setState({ datalist: datas });
        } else {

        }
      })
      .catch((err) => {

      });
  }

  render() {
    const tokenStr = localStorage.getItem("usertoken");

    let v2 = tokenStr.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

   
    let dataDisplay = this.state.datalist.map((data, index) => {
      let idval = data.report.id;
      let url = `/no-header/${idval}`;
      return (
        <div className="list-item">
          <h5 className="each-list-link">
            {index + 1}. <Link to={url}>{data.patient.name} - Lab Report ID_{data.report.id} </Link>
          </h5>
          <div className="details">
            <p> Patient Id: {data.patient.id} </p>
            <p> Date : {data.uploaded_at} </p>
          </div>
        </div>
      );
    });
    const refresh = () => {
      axios
        .get(`${APIURL}/api/v1/staff/lab-reports/?status=failed&reason=no_header&sort_by=asc&per_page=10`, {
          headers: { Authorization:  "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            const datas = res.data.pending_verifications;

            this.setState({ datalist: datas });
          } else {

          }
        })
        .catch((err) => {

        });
    };

    return (
      <div className="container displaylist-container ">
        <div className="dashboard-navigation">
          <div>
            {" "}
            <Link to="/dashboard">
              {" "}
              <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
            </Link>
          </div>

          <div>
            {" "}
            <i className="fas fa-angle-double-right angle-icon"></i>{" "}
          </div>
          <div>
            {" "}
            <Link to="/noheader-list"> Missing-Header List </Link>{" "}
          </div>
        </div>
        <div className="header-display">
          <h2 className="title-of-page"> Missing Header- Lab Report List</h2>

          <button className="btn btn-secondary" onClick={refresh}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button>
        </div>

        <div className="test-reports-display-list">
          {dataDisplay.length ? (
            dataDisplay
          ) : (
            <span style={{ color: "red", fontSize: "25px" }}>
              No more Pending list to show!!
            </span>
          )}
        </div>

        {/*
      
      
                                <button type="submit" className="next-btn">Next</button> */}
      </div>
    );
  }
}

export default MissingHeaderList;
