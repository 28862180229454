import React,{useState,useEffect,useContext} from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./presc.css";
import "./presctable.css";
import axios from 'axios';

import {APIURL} from "../../Global";
import {MedContext} from "../../contexts/MedContext";


function CurrentMedications_Bdo(props){

const [medRender,]=useContext(MedContext);
	const [medList,setMedList]=useState([{


                 drug: "",
                 
                 from: "",
                 to:"",
                
                 dosage: "",

                 intake_count: [],
                 intake_time: '',
                 remarks: ""
                 	}]);
	const [,setErrorMsg]=useState('');

const [,setErrorSubmitModalShow]=useState(false);

 const id = props.id;
 

	
  useEffect(()=>{

    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

   
    
   
    axios.get(`${APIURL}/api/v1/doctor/patient-current-medication/?key=current_medication&patient_id=${id}`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   

      if(res.data.status==="success"){
       
     setMedList(res.data.medicines);


   
      

      }else{
        setErrorMsg(res.data.message? res.data.message : "Error in fetching data");
        setErrorSubmitModalShow(true);
      }


    })
    .catch(err=>{
  
  setErrorMsg("Error in fetching data");
  setErrorSubmitModalShow(true);
})
 

 
},[medRender,id])











const renderEditable=(cellInfo)=>{

  return(
    <div style={{ backgroundColor: "#fafafa" }}
        className="form-control"
      > {medList[cellInfo.index][cellInfo.column.id]}</div> )
}

const renderIntakeTime=(cellInfo)=>{
  const food_time=medList[cellInfo.index][cellInfo.column.id];
  let food_label="";
  if(food_time=== "0"){
    food_label= "N/A";
  }
  else if(food_time=== "1"){
    food_label= "Before food";
  }
  else if(food_time=== "2"){
    food_label= "After food";
  } 
   return(

    <div style={{ backgroundColor: "#fafafa" }}
        className="form-control"
      > {food_label}</div> 
    

)
}


const columns = [
{
  Header: "DRUG",
  accessor: "drug",
  Cell: renderEditable,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:100,
   sortable:false,
   filterable:false,
   width:200

},
{
  Header: "DOSAGE",
  accessor: "dosage",
  minWidth:80,
  width:100,
  Cell: renderEditable,
  sortable:false,
  filterable:false

 


},
{
  Header: "FROM",
  accessor: "from",
  minWidth:80,
  width:120,
  Cell: renderEditable,
  sortable:false,
  filterable:false

 


},
{
  Header: "TO",
  accessor: "to",
  minWidth:80,
  width:120,
  Cell: renderEditable,
  sortable:false,
  filterable:false

 


},
{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "INTAKE COUNT">COUNT</span>,
  accessor: "intake_count",
  Cell: renderEditable,
  sortable:false,
  width:110,
  minWidth:100,
  filterable:false




},
{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "INTAKE TIME">TIME</span>,
  accessor: "intake_time",
  Cell: renderIntakeTime,
  sortable:false,
  width:130,
  minWidth:100,
  filterable:false




},
{
  Header: "REMARKS",
  accessor: "remarks",
  Cell: renderEditable,
  sortable:false,
  width:300,
  minWidth:100,
  filterable:false




}



]




	return(
    <div className="overlay-body">


    <div className="add-presc-page"><br/>
   
   
    {/*<h4> Patient : ID {id} </h4><br/> */}

<div className="current-table-holder">
		<ReactTable 
         columns={columns}
         data={medList}
         defaultPageSize = {5}
         filterable
         resizable
         noDataText={"No data"}
         className='report-table'
         >

         </ReactTable>
         <br/>
  </div>
       
          
        
        
      </div>
     







  
</div>		
)
}

export default CurrentMedications_Bdo;