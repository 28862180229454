import React, { useEffect, useState } from "react";
import axios from "axios";
import Chart from "chart.js";
import Carousel from "react-grid-carousel";
import ReactTable from "react-table-v6";
import { APIURL } from "../../Global";

import "./TargetAnalysis.css";
import "react-table-v6/react-table.css";

let myChart;

function TargetAnalysis(props) {
  const [unit, setUnit] = useState(props.targetUnit);
  const [limit, setLimit] = useState(props.targetValue);
  const [load, setLoad] = useState("");
  const [targetValueArray, setTargetValueArray] = useState([]);
  const [targetLimitArray, setTargetLimitArray] = useState([]);
  const [targetValueList, setTargetValueList] = useState([]);
  const [analysisDatesArray, setAnalysisDatesArray] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const ctx = document.getElementById("chart-analysis").getContext("2d");

    if (myChart) {
      myChart.destroy();
    }
    myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: analysisDatesArray,
        datasets: [
          {
            label: props.targetName,
            data: targetValueArray,
            backgroundColor: "#00667C",
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            fill: false,
            borderColor: "#00667C",
          },
          {
            label: "Target Value",
            data: targetLimitArray,
            backgroundColor: 'purple',
            pointBorderWidth:0,
            pointHoverRadius: 0,
            fill: false,
            borderColor: 'purple',
          },
        ],
        tooltips: {
          backgroundColor: "purple",
        },
      },

      options: {
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString:
                props.targetName + `  ${unit ? "(" + unit + ")" : null} `,
              },

              ticks: {
                beginAtZero: true,
                stepSize: 20,
                padding: 20,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Date",
              },
            },
          ],
        },
        legend: {
          position: "right",
         labels: {
            fontColor: "#000",
          },
        },
      },
    });
  }, [targetValueArray, analysisDatesArray, unit]);

  useEffect(() => {
    setModalShow(false);
    handleGraph();
  }, []);

  const renderVal = (cellInfo) => {
    const data = targetValueList;
    const pulseData = [...data].reverse();

    return <h6> {pulseData[cellInfo.index].current_target_values} </h6>;
  };
  const renderDate = (cellInfo) => {
    const date4 = targetValueList;
    const date5 = [...date4].reverse();

    return <h6> {date5[cellInfo.index].target_added_date.slice(0, 10)} </h6>;
  };

  const columnsTarget = [
    {
      Header: "Date",
      sortable: false,
      accessor: "date",
      Cell: renderDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: "50%",
      minWidth: "50%",
      sortable: true,
    },
    {
      Header: (
        <>
          <h6>{props.targetName}({props.targetUnit})</h6>
          
        </>
      ),
      sortable: false,
      accessor: "target",
      Cell: renderVal,
      width: "50%",
      minWidth: "50%",
    },
  ];

  const Target_Table = (
    <ReactTable
      columns={columnsTarget}
      data={targetValueList}
      defaultPageSize={10}
    ></ReactTable>
  );
  

  const handleGraph = () => {
    const tokenString = localStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    setLoad("Loading Data...");

    axios
      .get(
        `${APIURL}/api/v1/patient/current-target-values-history/?patient_target_id=${props.targetId}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setLoad("");
          let arr = res.data.current_target_values_history;
          let targetValue = [];
          let targetLimit = [];

          arr.reverse().map((result) => {
            targetValue.push(result.current_target_values);
            targetLimit.push(limit);
          });
          setTargetValueArray(targetValue);
          setTargetLimitArray(targetLimit);

          let newArrayDates = [];
          arr.map((result) => {
            newArrayDates.push(result.target_added_date.slice(0, 10));
          });
          setAnalysisDatesArray(newArrayDates);

          setTargetValueList(res.data.current_target_values_history);
        } else {
          setLoad("Error:" + res.data.message);
        }
      })
      .catch((err) => {
        setLoad("Error in Fetching Data");
      });
  };

  return (
    <>
      <h4 className="title-of-tasks">TARGET ANALYSIS</h4>
      <h5 className="target-name"> {props.targetName} </h5>
      <Carousel cols={1} rows={1} gap={20} showDots={true} loop>
        <Carousel.Item>
          <div style={{ width: "90%" , marginLeft:"5%"}}>       
            <h6 style={{ color: "red" }}>{load}</h6>
            <div className="chart-div-glucose">
              <canvas id="chart-analysis" width="90%" height="50%"></canvas>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div style={{ width: "90%",maxHeight:"380px",overflowY:"auto", marginLeft:"5%" }}>
            {load === "" ? (
              targetValueList.length > 0 ? (
                <>{Target_Table}</>
              ) : (
                <h6 style={{ color: "red" }}> No data to show! </h6>
              )
            ) : load === "Error in Fetching Data" ? (
              <h6 style={{ color: "red" }}>{load}</h6>
            ) : (
              <h6 style={{ color: "red" }}>{load}</h6>
            )}
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default TargetAnalysis;
