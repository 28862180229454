import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import {Button ,Modal} from 'react-bootstrap'

function ConfigPayment(props) {

    const [freeCount, setFreeCount]= useState('')
    const [freeCountNew, setFreeCountNew]= useState('')
    const [currency, setCurrency]= useState('')
    const [currency1, setCurrency1]= useState('')
    const [currencyNew, setCurrencyNew]= useState('')
    const [price, setPrice]= useState('')
    const [priceNew, setPriceNew]= useState('')
    const [tax, setTax]= useState('')
    const [taxNew, setTaxNew]= useState('')
    const [period, setPeriod]= useState(null)
    const [periodNew, setPeriodNew]= useState('')
    const [formattedPeriod, setFormattedPeriod]= useState()
    const [show, setShow]= useState(false)
    const [toggle1, setToggle1]= useState(false)
    const [toggle2, setToggle2]= useState(false)
    const [toggle3, setToggle3]= useState(false)
    const [toggle4, setToggle4]= useState(false)
    const [toggle5, setToggle5]= useState(false)
    const [successShow, setSuccessShow]= useState(false)
    const [failShow, setFailShow]= useState(false)
    const [errorMsg, setErrorMsg]= useState('')
    const [msg, setMsg]= useState('')
    const [errorShow, setErrorShow]= useState(false)
    const [deleteConfirm, setDeleteConfirm]= useState(false)
    const [deleteShow, setDeleteShow]= useState(false)
    const [infoShow ,setInfoShow]= useState(false)

    const currencyOptions = [{value: 'inr' , label: 'INR'},
       { value: 'usd' , label: 'USD'},
       {value: 'aed', label: 'AED'}            
    ];

    useEffect(()=>{

        const tokenString= localStorage.getItem('usertoken');
       
       let str= tokenString.replace(/["]+/g, '')  ;
    
    
      
        axios.get(`${APIURL}/api/v1/staff/hospital-user-price/?hospital_id=${props.id}`, { headers: {
          
          "Authorization" : 'Token '+str  }})
        .then(res=>{
         
    
       
       
          if(res.data.status==="success"){ 

            const data =res.data.data
            if(res.data.message==='Hospital User Pricing Details Not Yet Added'){
                setShow(true)
            }
            else{

                if(data.free_usage_till_date!==null){
                    let date = data.free_usage_till_date.slice(0,10)
                    setPeriod(date)
                }
                
                
                let upper = data.currency.toUpperCase()
                setFreeCount(data.free_user_count)
                setPrice(data.one_month_user_price)               
                setCurrency(upper)
                setCurrency1(data.currency)
                setTax(data.tax_percentage)
                
            }

          }
            else{
    
          }
          
    
    
        })
        .catch(err=>{
    
    })
    
    
    },[]);

    const validate = ()=> {

        let valid= true
        if(freeCount===null&& freeCountNew===''){
            setErrorMsg('Please fill the free users field')
            valid= false
        }
        if(price===null && priceNew===''){
            setErrorMsg('Please fill the price field')
            valid= false
        }
        if(tax===null&& taxNew===''){
            setErrorMsg('Please fill the tax field')
            valid= false
        }
        if(currency===null && currencyNew===''){
            setErrorMsg('Please fill the currency field')
            valid= false
        }
        if(priceNew === '0'){
            setErrorMsg('Price should be greater than 0')
            valid= false
        }
        return valid
    }

    const handleContinue = (e)=> {
        e.preventDefault();
        if(validate()){
            handleSubmit()
        }
        else{
            setErrorShow(true)
        }
    }

    const handleSubmit = ()=> {



        const tokenString = localStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
          const data ={
              hospital_id : props.id,
              free_user_count : freeCountNew===''? freeCount : freeCountNew ,
              one_month_user_price : priceNew===''? price : priceNew ,
              tax_percentage : taxNew===''? tax : taxNew ,
              currency : currencyNew.value===undefined ? currency1 : currencyNew.value ,
              free_usage_till_date : periodNew!==''? formattedPeriod : period
          }
          axios.post(`${APIURL}/api/v1/staff/hospital-user-price/`, data, {
            headers: { Authorization: "Token " + v2 },
       })
       .then((res) =>{
           if(res.data.status==='success'){
               setSuccessShow(true)
           }
           else{
               setMsg(res.data.message)
               setFailShow(true)
           }
       })
       .catch((err) => {
           setMsg('Error in data submission.Please check again!')
        setFailShow(true)
       })
    }

    const handleCount= (e)=> {
        e.preventDefault();
        const val =e.target.value
      
        if(val.length<=3){
          setFreeCountNew(val)
        } else{
          return false;
      }
    }

    const handlePrice= (e)=> {
        e.preventDefault();
        const val= e.target.value

        if(val.length<=4){
            setPriceNew(val)
        }
        else{
            return false;
        }
    }

    const handleTax= (e)=> {
        e.preventDefault();
        const val= e.target.value

        if(val.length<=3){
            setTaxNew(val)
        }
        else{
            return false;
        }
    }

    const handlePeriod=(date)=>{
        
        setPeriodNew(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
      /*let dateString= DateExtracted.toString(); */
      setFormattedPeriod(DateExtracted);
        }
      }

    const handleCurrencyChange=(data)=>{

        setCurrencyNew(data);
      }

      const handleToggle1 = ()=> {
        setToggle1(!toggle1)

        if(toggle1===true){
            setFreeCountNew('')
        }
    }
    const handleToggle2 = ()=> {
        setToggle2(!toggle2)

        if(toggle2===true){
            setPriceNew('')
        }
    }
    const handleToggle3 = ()=> {
        setToggle3(!toggle3)

        if(toggle3===true){
            setTaxNew('')
        }
    }
    const handleToggle4 = ()=> {
        setToggle4(!toggle4)

        if(toggle4===true){
            setCurrencyNew('')
        }
    }

    const handleToggle5 = ()=> {
        setToggle5(!toggle5)

        if(toggle5===true){
            setPeriodNew('')
        }
    }

    const SuccessPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Payment Configured Successfully</h4>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const FailPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h5 style={{color: "red"}}>{msg}</h5>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const ErrorPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h5 style={{color: "red"}}>{errorMsg}</h5>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const DeleteConfirmPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 id="confirm-reject">Please confirm to delete Free Usage Date!</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
      
              <Button variant="danger" onClick={()=>{setDeleteShow(true); setPeriod(null); setPeriodNew(''); 
                                                     setDeleteConfirm(false); setToggle5(false); setInfoShow(true) }}> Confirm</Button>
      
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const DeletePopUp=(props)=> {
 
 
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Successfully Deleted Free Usage Date.</h4>
            
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
             
             
            </Modal.Footer>
          </Modal>
        );
      }

    return (<>
    <div className='flex-col'>
    <h3 className='title-of-tasks header'>Configure Hospital Payment</h3><br/>
        
            
            
            {
                show? 
                
            <div className='config-box'>
                <br/>
                <div className='flex-row align-count'>
                        <h5 className='text-type'>Free Users :</h5>
                        <input type='number' className='num-width form-control' min='0' value={freeCount} onChange={handleCount} ></input> 
                    </div>
                <br/>
                    <div className='flex-row align-count'>
                        <h5 className='text-type'>Monthly Price :</h5>
                        <input type='number' className='num-width form-control' min='0' value={price} onChange={handlePrice} ></input> 
                    </div>
                <br/>
                    <div className='flex-row align-count'>
                        <h5 className='text-type'>Tax Percentage :</h5>
                        <input type='number' className='num-width form-control' min='0' value={tax} onChange={handleTax} ></input> 
                    </div>
                <br/>
                    <div className='flex-row align-count'>
                        <h5 className='text-type'>Currency :</h5>  
                        <Select
                            value={currency}
                            onChange={(data)=>{handleCurrencyChange(data)
                            }}
                            options={currencyOptions}
                            className="select-currency cur-width"
                        />
                    </div>
                    <br/>
                    <div className='flex-row align-count'>
                        <h5 className='text-type'>Free Usage Date :</h5>
                        <DatePicker
                          onChange={date=>{handlePeriod(date)}}

                          minDate = {new Date()}
                          value={periodNew}
                          
                     /> 
                    </div>
                <br/>
        </div> : show===false? <div className='config-box'>
                <br/>
                <div className='flex-row align-count'>
                        <h5 className='text-type'>Free Users :</h5>
                        {
                            toggle1?
                            <input type='number' className='num-width form-control' min='0' value={freeCountNew} onChange={handleCount} ></input>
                            :
                            <span className='text-config'>{freeCount===null? <span className='text-danger'>No Data</span> : freeCount}</span>
                        }
                         
                         <button className='btn btn-success btn-square btn-small btn-align' onClick={handleToggle1}>{toggle1?'Discard':'Change'}</button>
                    </div>
                <br/>
                    <div className='flex-row align-count'>
                        <h5 className='text-type'>Monthly Price :</h5>
                        {
                            toggle2?
                            <input type='number' className='num-width form-control' min='0' value={priceNew} onChange={handlePrice} ></input>
                            :
                            <span className='text-config'>{price===null? <span className='text-danger'>No Data</span>: price}</span>
                        }
                         
                        <button className='btn btn-success btn-square btn-small btn-align' onClick={handleToggle2}>{toggle2?'Discard':'Change'}</button>
                    </div>
                <br/>
                    <div className='flex-row align-count'>
                        <h5 className='text-type'>Tax Percentage :</h5>
                        {
                            toggle3?
                            <input type='number' className='num-width form-control' min='0' value={taxNew} onChange={handleTax} ></input> 
                            :
                            <span className='text-config'>{tax===null?<span className='text-danger'>No Data</span>:tax}</span>
                        }
                        
                        <button className='btn btn-success btn-square btn-small btn-align' onClick={handleToggle3}>{toggle3?'Discard':'Change'}</button>
                    </div>
                <br/>
                    <div className='flex-row align-count'>
                        <h5 className='text-type'>Currency :</h5>  
                        {
                            toggle4?
                            <Select
                                value={currencyNew}
                                onChange={(data)=>{handleCurrencyChange(data)
                                }}
                                options={currencyOptions}
                                className="select-currency cur-width"
                            />
                            :
                            <span className='text-config'>{currency===null? <span className='text-danger'>No Data</span>: currency}</span>
                        }
                        
                        <button className='btn btn-success btn-square btn-small btn-align' onClick={handleToggle4}>{toggle4?'Discard':'Change'}</button>
                    </div>
                    <br/>
                    <div className='flex-row align-count'>
                        <h5 className='text-type'>Free Usage Date :</h5>
                        {
                            toggle5?
                            <div>
                            <DatePicker
                          onChange={date=>{handlePeriod(date)}}

                          minDate = {new Date()}
                          value={periodNew}
                          
                     />{' '}
                     <button className="delete-btn btn-smallest" onClick={()=> setDeleteConfirm(true)}> X </button>
                     </div> : <span className='text-config'>{period===null?<span className='text-danger'>No Free Usage</span>: period}</span>
                        }
                        
                        <button className='btn btn-success btn-square btn-small btn-align' onClick={handleToggle5}>{toggle5?'Discard':'Change'}</button>  
                    </div>
                <br/>
        </div> : null
            }
            {
                infoShow?
                <span className='text-muted'><i className="fas fa-info-circle info-icon"></i>Please Submit to Save Changes</span>
                :null
            }
            
        <br/>
        <button className='btn btn-primary btn-col' onClick={handleContinue}>Submit</button>
        </div>

        {
        successShow ? 
        <SuccessPopUp 
            show={successShow}
            onHide= {() => {setSuccessShow(false); props.handle();}}/> : null
    }

{
        deleteConfirm ? 
        <DeleteConfirmPopup 
            show={deleteConfirm}
            onHide= {() => {setDeleteConfirm(false);}}/> : null
    }

{
        deleteShow ? 
        <DeletePopUp 
            show={deleteShow}
            onHide= {() => {setDeleteShow(false);}}/> : null
    }

{
        msg!=='' ? 
        <FailPopUp 
            show={failShow}
            onHide={() => {setFailShow(false)}}/> : null
    }

{
        errorMsg!=='' ? 
        <ErrorPopUp 
            show={errorShow}
            onHide= {() => {setErrorShow(false); setErrorMsg('')}}/> : null
    }

      </>
    )
}

export default ConfigPayment
