import React,{useState} from 'react'
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function AddSchedule(props) {
    const [startDate, setStartDate]=useState('')
    const [endDate, setEndDate]=useState('')
    const [fromDate,setFromDate] = useState('');  
    const [toDate,setToDate] = useState('');

    const [slot,setSlot]= useState([])
    const [frequency,setFrequency]= useState({value:"daily", label:"Weekly"})

    const [monthDay, setMonthDay]= useState('')
    const [week,setWeek]= useState({value:"", label:""})
    const [weekDay,setWeekDay]=useState({value:"", label:""})

    const [copy,setCopy]= useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    })
    const [refresh,setRefresh]=useState(false)
    const [successShow,setSuccessShow]= useState(false)
    const [errorShow,setErrorShow]= useState(false)
    const [errorMsg, setErrorMsg]= useState('')
    const [msg, setMsg]= useState('')
    const [validateErrorShow,setValidateErrorShow]=useState(false)
    const [flag,setFlag]= useState('')
    const [radioCheck,setRadioCheck]=useState('')

    const timeOptions = [ {value: '07:30:00' ,label: '07:30:00'},
    {value: '08:00:00' ,label: '08:00:00'},
    {value: '08:30:00' ,label: '08:30:00'},
    {value: '09:00:00' ,label: '09:00:00'},
    {value: '09:30:00' ,label: '09:30:00'},
    {value: '10:00:00' ,label: '10:00:00'},
    {value: '10:30:00' ,label: '10:30:00'},
    {value: '11:00:00' ,label: '11:00:00'},
    {value: '11:30:00' ,label: '11:30:00'},
    {value: '12:00:00' ,label: '12:00:00'},
    {value: '12:30:00' ,label: '12:30:00'},
    {value: '13:00:00' ,label: '13:00:00'},
    {value: '13:30:00' ,label: '13:30:00'},
    {value: '14:00:00' ,label: '14:00:00'},
    {value: '14:30:00' ,label: '14:30:00'},
    {value: '15:00:00' ,label: '15:00:00'},
    {value: '15:30:00' ,label: '15:30:00'},
    {value: '16:00:00' ,label: '16:00:00'},
    {value: '16:30:00' ,label: '16:30:00'},
    {value: '17:00:00' ,label: '17:00:00'},
    {value: '17:30:00' ,label: '17:30:00'},
    {value: '18:00:00' ,label: '18:00:00'},
    {value: '18:30:00' ,label: '18:30:00'},
    {value: '19:00:00' ,label: '19:00:00'},
    {value: '19:30:00' ,label: '19:30:00'},
    {value: '20:00:00' ,label: '20:00:00'},
    {value: '20:30:00' ,label: '20:30:00'},
    {value: '21:00:00' ,label: '21:00:00'},
    {value: '21:30:00' ,label: '21:30:00'},
    {value: '22:00:00' ,label: '22:00:00'},
    {value: '22:30:00' ,label: '22:30:00'},  
    
 ];

 const frequencyOptions= [{value: "daily", label:"Weekly"},
    {value: "monthly", label:"Monthly"},

]

const weekOptions = [ {value: 'first' ,label: 'First'},
{ value: 'second' , label: 'Second'},
{value: 'third' ,label: 'Third'},
{value: 'fourth' ,label: 'Fourth'},
{value: 'last' ,label: 'Last'},

];

const dayOptions = [ {value: '0' ,label: 'Monday'},
{ value: '1' , label: 'tuesday'},
{ value: '2' , label: 'wednesday'},
{ value: '3' , label: 'thursday'},
{ value: '4' , label: 'friday'},
{ value: '5' , label: 'saturday'},
{ value: '6' , label: 'sunday'},

];

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const validate= async()=> {

    let isValid =true;
    const list= slot

    if(startDate > endDate){
        isValid = false
        setMsg('Consultation Start date should be Less than End date!')
    }
    if(addDays(startDate,30) < endDate){
        isValid = false
        setMsg('Schedule Days cannot exceed 30 days')
    }
    if(radioCheck===""){
        isValid = false
        setMsg('Please select the days for Consultation')
    }

    if(startDate===''){
        isValid = false
        setMsg('Please enter schedule start date')
    }
    if(endDate===''){
        isValid = false
        setMsg('Please enter schedule end date')
    }
    if(list.length < 1){
        isValid = false
        setMsg('Please add a consultation slot')
    }
    if(frequency.value==='monthly'){
        if(monthDay==='' && week.value==='' && weekDay.value==='' ){
            isValid = false
            setMsg('Please enter a month day or week of schedule')
        }
        if(monthDay==='' && week.value==='' && weekDay.value!=='' ){
            isValid = false
            setMsg('Please enter the week of schedule')
        }
        if(monthDay==='' && week.value!=='' && weekDay.value==='' ){
            isValid = false
            setMsg('Please enter the weekday of schedule')
        }
    }
    



    list.map((item)=> {
        
        if(item.from_time.value === item.to_time.value){
            isValid= false
            setMsg('Please check Slot Time')
        }
        if(item.from_time.value > item.to_time.value){
            isValid= false
            setMsg('Please check Slot Time')
        }
        if(item.token===""){
            isValid= false
            setMsg('Please enter the Token Number')
        }
    })

    for(let i=0; i<list.length; i++){

        for(let j=1; j< list.length; j++){

            if(i != j){
                if(list[i].from_time.value === list[j].from_time.value){
                    isValid= false
                    setMsg('Time Slot Clash')
                }
                if(list[i].to_time.value === list[j].to_time.value){
                    isValid= false
                    setMsg('Time Slot Clash')
                }
                if(list[i].from_time.value < list[j].from_time.value && list[i].to_time.value > list[j].to_time.value ){
                    isValid= false
                    setMsg('Time Slot Clash')
                }
                if(list[i].from_time.value > list[j].from_time.value && list[i].to_time.value < list[j].to_time.value ){
                    isValid= false
                    setMsg('Time Slot Clash')
                }
            }

            
        }
    }


    return isValid
}

const handleSchedule= async()=> {

    if( await validate()){
        const tokenString= localStorage.getItem('usertoken');
         
        let str= tokenString.replace(/["]+/g, '') 

        const data= {
          from_date: fromDate,
          to_date: toDate,
          slots: await processSlots(),
          frequency:frequency.value,
          days: frequency.value==='daily'? await processDays() : [],
          day_of_month: flag==='month'? monthDay : null,
          week_of_month: flag==='week'? week.value : null,
          weekday_of_month: flag==='week'? weekDay.value : null

        }

       axios.post(`${APIURL}/api/v1/doctor/schedule/`, data, { headers: {
           
         "Authorization" : 'Token '+str  }})
       .then(res=>{
           
         if(res.data.status==="success"){

             setSuccessShow(true)
         }
         else{
            setErrorMsg(res.data.message)
            setErrorShow(true)
         }
   
       })
       .catch(err=>{
          setErrorShow(true)
          setErrorMsg('Error in data submission.Please try again!')
   })
    }

    else {
        setValidateErrorShow(true)
    }
    
}

const processSlots= async()=> {
    const arr = slot
    const data =arr.map(item=> {
        return(
            { from_time: item.from_time.value,
              to_time: item.to_time.value,
              token: item.token,       
            }
        )
    })

    return data
}

const processDays= async()=> {
    let list= []
    const data= copy

    if(data.monday===true){
        list.push('0')
    }
    if(data.tuesday===true){
        list.push('1')
    }
    if(data.wednesday===true){
        list.push('2')
    }
    if(data.thursday===true){
        list.push('3')
    }
    if(data.friday===true){
        list.push('4')
    }
    if(data.saturday===true){
        list.push('5')
    }
    if(data.sunday===true){
        list.push('6')
    }

    return list
}

 /*useEffect(()=> {
    let list =slot
    const arr= []
    list.map(item=>{

        arr.push({id:item.id, from_time:{value:item.from_time, label: item.from_time},
                  to_time:{value:item.to_time, label: item.to_time} , token: item.token  })        
        })
    setSlot(arr)

    const data= days

    data.map(item=> {
        if(item==="0"){
            setCopy(day=> ({...day, 'monday':true}))
        }
        else if(item==="1"){
            setCopy(day=> ({...day, 'tuesday':true}))
        }
        else if(item==="2"){
            setCopy(day=> ({...day, 'wednesday':true}))
        }
        else if(item==="3"){
            setCopy(day=> ({...day, 'thursday':true}))
        }
        else if(item==="4"){
            setCopy(day=> ({...day, 'friday':true}))
        }
        else if(item==="5"){
            setCopy(day=> ({...day, 'saturday':true}))        }
        else if(item==="6"){
            setCopy(day=> ({...day, 'sunday':true}))        }
    })


 },[])*/

 const SubmitPopUp=(props)=> {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-success'>Schedule Added Successfully</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
         
        
        </Modal.Footer>
      </Modal>
    );
  }

  const ErrorPopUp=(props)=> {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h5 style={{color: "red"}}>{errorMsg!==''? errorMsg : 'Error in data submission.Please try again!'}</h5>
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
         
        
        </Modal.Footer>
      </Modal>
    );
  }

  const ValidateErrorPopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
          <h5 style={{color: "red"}}>{msg}</h5>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

    const handleStartDate=(date)=>{

        setStartDate(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
      /*let dateString= DateExtracted.toString(); */
      setFromDate(DateExtracted);
      
      }
    }

    const handleToDate=(date)=>{

        setEndDate(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
      /*let dateString= DateExtracted.toString(); */
      setToDate(DateExtracted);
      
      }
    }

    const handleFromChange=(time,index)=>{
        let list = slot
        list[index].from_time= time
        setSlot(list)
        setRefresh(!refresh)
    }

    const handleToChange=(time,index)=>{
        let list = slot
        list[index].to_time= time
        setSlot(list)
        setRefresh(!refresh)
    }

    const handleToken=(e,index)=> {
        let list = slot
        list[index].token= e.target.value
        setSlot(list)
        setRefresh(!refresh)
    }

    const handleClick= (index)=> {
        let list =slot
        const data= list.filter((item,i)=>{
            return i!==index
        })
        setSlot(data)
        setRefresh(!refresh)
    }

    const handleAdd= ()=> {
        const list =slot
        list.push({
            from_time : {value:"",label:""},
            to_time : {value:"",label:""},
            token : ""
        })
        setSlot(list)
        setRefresh(!refresh)
    }

    const handleFrequency= (data)=> {
        if(data.value==='daily'||'weekly'){
            setFlag('')
        }
        setFrequency(data)
        setRefresh(!refresh)
        setRadioCheck('')
    }

    const handleDays= (e)=> {
        setRadioCheck('Validated')
        const val= e.target.name

        setCopy(data=> ({...data, [val] : !(copy[val]) }))       
    }

    const handleConsult= (e)=> {
        const val=e.target.value
        setFlag(val)
        setRadioCheck('Validated')
        setWeek({value:'', label:''})
        setWeekDay({value:'', label:''})
        setMonthDay('')
    }

    const handleMonthDay= (e)=> {
        e.preventDefault();
        const val =e.target.value
      
        if(val.length<=2){
            setMonthDay(e.target.value)
            
        } else{
          return false;
      }
        
    }

    const handleWeek= (data)=> {
        setWeek(data)
    }

    const handleWeekDay= (data)=> {
        setWeekDay(data)
    }

    return (<>
        <h3 className='sub-title'>Add Schedule</h3>
        <div className='resch-date'>
            <span className='date-content'><i className="fas fa-calendar-alt cal-col"></i> Consultation Date</span>
            <div className='consult-flex check-consult'>
                <div className='detail-flex start-margin'>

                    <span className='text-muted font-weight-bold date-align'>Start</span>
                    <div className='from-date'>
                        <DatePicker
                          value={startDate}
                          onChange={date=>{handleStartDate(date)}}                         
                          minDate={new Date()}
                          
                     />
                     </div>
                </div>
            <div className='detail-flex'>
                <span className='text-muted font-weight-bold date-align'>End</span>
                <div className='from-date'>
                    <DatePicker           
                          value={endDate}
                          onChange={date=>{handleToDate(date)}}  
                          minDate={new Date()}                       
                          
                     />
                </div>
            </div>
            </div>
            
            <span className='date-content'><i className="fas fa-stethoscope walk-col"></i> Consultation Slot <i onClick={handleAdd} className="fas fa-plus plus-slot"></i></span>
            <div className='check-consult'>
            {
                slot.length>0 ?
                <div className='consult-flex month-align'>
                    <span className='text-muted font-weight-bold head-section'>From</span>
                    <span className='text-muted font-weight-bold head-section'>To</span>
                    <span className='text-muted font-weight-bold head-token'>Tokens</span>
                </div>
                : null
            }
                
                    {
                        slot.map((item,index)=> {
                            return(<div className='consult-flex date-align start-margin'>
                                <div className='detail-flex sort-margin'>
                        <Select
                    value={item.from_time}
                    onChange={(data)=>{handleFromChange(data,index)
                        }}
                    options={timeOptions}
                    className="select-time"
                        /> 
                        </div>
                        
                        <div className='detail-flex sort-margin'>
                       
                        <Select
                    value={item.to_time}
                    onChange={(data)=>{handleToChange(data,index)
                        }}
                    options={timeOptions}
                    className="select-time"
                        /> 
                        </div> 
                        
                        <div className='detail-flex sort-margin'>
                        
                        <input type='text' className='form-control token-align' value={item.token}
                            onChange={(data)=>handleToken(data,index)}
                                />
                        
                        </div>
                        <i onClick={()=>handleClick(index)} className="fas fa-times align-close"></i>  
                        </div>
                            )
                        })
                    }
                    </div>

                    <div className='consult-flex date-content month-align'>
                        <span><i className="fas fa-redo-alt rep-icon"></i> Repeat</span>
                        <Select
                    value={frequency}
                    onChange={(data)=>{handleFrequency(data)
                        }}
                    options={frequencyOptions}
                    className="schedule-frequency"
                        /> 
                </div>
                
                <span className='date-content month-align'><i class="fas fa-user-md doc-icon"></i> Consultation Days</span>
                <div className='check-consult'>
                {
                    frequency.value==='daily'|| frequency.value==='weekly'?

                    <div className='consult-flex month-align'>

                            <input className='checkbox-class' type='checkbox' id='monday' name='monday' checked={copy.monday===true} onChange={handleDays} />
                            <label className='days-class' htmlFor='Monday'>Monday</label> 

                            <input className='checkbox-class' type='checkbox' id='tuesday' name='tuesday' checked={copy.tuesday===true} onChange={handleDays} />
                            <label className='days-class' htmlFor='Tuesday'>Tuesday</label> 

                            <input className='checkbox-class' type='checkbox' id='wednesday' name='wednesday' checked={copy.wednesday===true} onChange={handleDays} />
                            <label className='days-class' htmlFor='Wednesday'>Wednesday</label> 

                            <input className='checkbox-class' type='checkbox' id='thursday' name='thursday' checked={copy.thursday===true} onChange={handleDays} />
                            <label className='days-class' htmlFor='Thursday'>Thursday</label>    

                            <input className='checkbox-class' type='checkbox' id='friday' name='friday' checked={copy.friday===true} onChange={handleDays} />
                            <label className='days-class' htmlFor='Friday'>Friday</label>  

                            <input className='checkbox-class' type='checkbox' id='saturday' name='saturday' checked={copy.saturday===true} onChange={handleDays} />
                            <label className='days-class' htmlFor='Saturday'>Saturday</label> 

                            <input className='checkbox-class' type='checkbox' id='sunday' name='sunday' checked={copy.sunday===true} onChange={handleDays} />
                            <label className='days-class' htmlFor='Sunday'>Sunday</label> 

                </div>
                :
                <>
                <div className='consult-flex month-align'>
                <input className='checkbox-class1' type="radio" id="day" name='day' value='month' onChange={handleConsult} />
                <label htmlFor='day'>On day</label>
                    
                    <input disabled={flag!=='month'} type='number' placeholder='Month Day' value={monthDay} onChange={handleMonthDay} className='month-width form-control' min='1' max='31'/>
                </div>
                    <br/>
                <div className='consult-flex'>

                <input className='checkbox-class1' type="radio" id="week" name='day' value="week" onChange={handleConsult}/>
                <label htmlFor='week'>On the</label>
                <Select 
                value={week}
                onChange={(data)=>{handleWeek(data)
                    }}
                    isDisabled={flag!=="week"}
                options={weekOptions}
                className="select-monthly"
                    />

                <Select
                value={weekDay}
                onChange={(data)=>{handleWeekDay(data)
                    }}
                options={dayOptions}
                isDisabled={flag!=="week"}
                className="select-monthly"
                    />
                    
                </div>
                </>
                }
                </div>
                    
                <br/>
                <br/>
                <p className='btn-center'>
                <button onClick={handleSchedule} className='btn btn-primary btn-col'>Add Schedule</button>
                </p>
                </div>

{successShow ? 
<SubmitPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false); props.refresh();}}
/> 
: null}

{errorMsg!=='' ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}

{
        msg!=='' ? 
        <ValidateErrorPopUp 
            show={validateErrorShow}
            onHide= {() => {setValidateErrorShow(false); setMsg('')}}/> : null
    }
            
        </>
    )
}

export default AddSchedule
