import React, { useState, createContext } from "react";

export const MedContext = createContext();

export const MedProvider = (props) => {
  const [medRender,setMedRender] = useState(false);
   
  return (
    <MedContext.Provider value={[medRender,setMedRender]}>
      {props.children}
    </MedContext.Provider>
  );
};
