import React,{useEffect, useState} from 'react';
import axios from 'axios';
import "../DisplayReportAndHeadings/displayreportandheadings.css";
import "./synonymstable.css";

import {APIURL} from "../../../Global";
function SynonymsTable() {
  
    const [headingsList,setHeadingsList]=useState({
      test: [],
      result:[],
      units:[],
      reference_range:[]
    });
     const [showSynonymsTable,setShowSynonymsTable] = useState(false);
  
  useEffect(()=>{

    const tokenString= localStorage.getItem('usertoken');
   
   let v2= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
  

  
 
 axios.get(`${APIURL}/api/v1/staff/lab-reports/headings/`,{ headers: {"Authorization" : 'Token '+v2  }})
    .then(res =>{
            
           if(res.data.status === "success"){
                    
                    setHeadingsList({
                      test: res.data.test,
                      result:res.data.result,
                      units: res.data.units,
                      reference_range: res.data.reference_range
                    })
                    }
           else{
                             
            } 
                
            }) 
    .catch(err =>{
 
}
);


  },[]);


  const displayTest= headingsList.test.length>0 ? headingsList.test.map((val,index)=>{
  return <div key={index} className="display-item"> 
           <h6>{val} </h6>
         </div>
}) : null;

const displayUnits= headingsList.units.length>0 ? headingsList.units.map((val,index)=>{
return <div key={index} className="display-item "> 
           <h6>{val} </h6>
         </div>

}) : null;


const displayResult= headingsList.result.length>0 ? headingsList.result.map((val,index)=>{
  return <div key={index} className="display-item "> 
           <h6>{val} </h6>
         </div>
}) : null;


const displayRef= headingsList.reference_range.length>0 ? headingsList.reference_range.map((val,index)=>{
  return <div key={index} className="display-item "> 
           <h6>{val} </h6>
         </div>
}) : null;
 const handleDisplaySynonymsTable =()=>{
  setShowSynonymsTable(!showSynonymsTable);
 }
  

return (            
  

<div className="show-synonyms-section">


   {showSynonymsTable ? 
    <>
 <h5 className="title-of-page"> Synonyms Table </h5>
  <div className="addheadings-section">
   
    <div className="parent-headings-container">
      

      <div className="each-col">
          <div className="syn-headings form-control"><h5> Test Name  </h5></div>
    {displayTest}
     
  </div>
     
  <div className="each-col">
       <div className="syn-headings form-control"><h5> Units </h5></div>
    {displayUnits}
   
  </div>

  <div className="each-col">
    <div className="syn-headings form-control"><h5> Result  </h5></div>
    {displayResult}
 
  </div>



    <div className="each-col">
    <div className="syn-headings form-control"><h5> Reference Range</h5></div>
    {displayRef}
   
    </div>

    

    

    </div>
  
   </div>
   <br/>
   <button className="btn btn-danger " onClick={handleDisplaySynonymsTable}> <i className="fa fa-angle-up down-up-icon" aria-hidden="true"></i> Hide SynonymsTable  </button>
</>
:  
 <button className="btn btn-primary btn-col " onClick={handleDisplaySynonymsTable}> <i className="fa fa-angle-down down-up-icon" aria-hidden="true"></i>Show Synonyms Table </button>
      }

  
   </div>
  
   
    
  );
  
}

export default SynonymsTable;