import React, { useState, createContext } from "react";

export const HospDrLoginContext = createContext();

export const HospDrLoginProvider = (props) => {
  const [hospDrData,setHospDrData] = useState();
   
  return (
    <HospDrLoginContext.Provider value={[hospDrData,setHospDrData]}>
      {props.children}
    </HospDrLoginContext.Provider>
  );
};
