import React, { useState, createContext } from "react";

export const ObservationsContext = createContext();

export const ObservationsProvider = (props) => {
  const [reRender,setReRender] = useState(false);
   
  return (
    <ObservationsContext.Provider value={[reRender,setReRender]}>
      {props.children}
    </ObservationsContext.Provider>
  );
};
