import React from "react";

import "./staffdashboard.css";

function OtherDashboard() {
  return (
    <>
      <div className="container">
        <h2 className="title-of-lists"> Welcome to Your Dashboard</h2>
        <h4 style={{color:"grey"}}>
         We are in process of implementing Web app with all functionalities.
         Please use our mobile app to use our services till then.  


          <br />
        </h4>

              <h4 style={{color:"grey"}}> Thanks for understanding! </h4>

              <a href='https://play.google.com/store/apps/details?id=intp.purpc&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer"><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className="google-play-text"/></a>
             
      </div>
    </>
  );
}
export default OtherDashboard;
