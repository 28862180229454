import React, { useState } from "react";
import Select from "react-select";
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";

function AddTest(props) {
  const [data, setData] = useState({
    testname: "",
    testsample: "",
    testmethod: "",
    unit: "",
    minrefrange: "",
    maxrefrange: "",
    group: { value: "", label: "" },
    country: { value: "", label: "" },
    cptcode: "",
    description: "",
    disease: "",
    //is_verified : ""
  });

  const [error, setError] = useState({
    testname: "",
    testsample: "",
    testmethod: "",
    unit: "",
    minrefrange: "",
    maxrefrange: "",
    group: "",
    country: "",
    //is_verified:""
  });

  const group1 = [
    { value: "male", label: "MALE" },
    { value: "female", label: "FEMALE" },
    { value: "kids", label: "KIDS" },
    { value: "pregnancy", label: "PREGNANCY" },
    { value: "foetal", label: "FOETAL" },
    { value: "others", label: "OTHERS" },
    { value: "na", label: "NA" },
  ];

  const country = [
    { value: "India", label: "INDIA" },
    { value: "US", label: "USA" },
  ];

  const [successShow, setSuccessShow] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [completeShow, setCompleteShow] = useState(false);
  const [errorValidate, setErrorValidate] = useState(false);

  //     const serviceOptions = [{ value:'general', label:'General' },
  //     { value:'service_request', label:'Service Request' },
  //     { value:'rental', label:'Equipment Request' }
  // ]

  const CompletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-success"> Successfully Saved</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={props.onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SuccessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-success"> Click Proceed to add lab test</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={props.onHide}>
            Not Now
          </Button>
          <Button className="btn btn-primary btn-col" onClick={saveChanges}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleError = () => {
    setErrorValidate(false);
    setSuccessShow(false);
  };

  const ErrorPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            Please check data correct or duplicated
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const ErorValidatePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>Please fill required fields </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={handleError}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const handleTitle = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setData((current) => ({
      ...current,
      [key]: val,
    }));
  };

  const handleSave = () => {
    setSuccessShow(true);
  };
  const handleServiceChange = (data) => {
    setData((current) => ({
      ...current,
      group: data,
    }));
  };

  const handleUnitChange = (data) => {
    setData((current) => ({
      ...current,
      country: data,
    }));
  };



 
  const validate = () => {
    ;
    let input = data;
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["testname"] || input["testname"] === "") {
      isValid = false;
      errors["testname"] = "Please enter Test Name";
    }
    if (!input["testsample"] || input["testsample"] === "") {
      isValid = false;
      errors["testsample"] = "Please enter a sample value";
    }
    if (!input["testmethod"] || input["testmethod"] === "") {
      isValid = false;
      errors["testmethod"] = "Please enter test method";
    }

    if (!input["minrefrange"] || input["minrefrange"] === "") {
      isValid = false;
      errors["minrefrange"] = "Please enter minimum refer range";
    }
    if (!input["maxrefrange"] || input["maxrefrange"] === "") {
      isValid = false;
      errors["maxrefrange"] = "Please enter maximum refer range";
    }
    if (!input["unit"] || input["unit"] === "") {
      isValid = false;
      errors["unit"] = "Please enter unit";
    }
    if (!input["group"] || input["group"] === "") {
      isValid = false;
      errors["group"] = "Please select age gorup";
    }
    if (!input["country"] || input["country"] === "") {
      isValid = false;
      errors["country"] = "Please select country";
    }
  

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };

  const saveChanges = (e) => {
    e.preventDefault();
    ;
    console.log(validate());
    if (validate()) {
      const tokenString = localStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      const dataToSend = {
        test_name: data.testname,
        test_sample: data.testsample,
        test_method: data.testmethod,
        unit: data.unit,
        min_ref_range: data.minrefrange,
        max_ref_range: data.maxrefrange,
        group: data.group.value,
        country: data.country.value,
        is_verified : false,
        cpt_code: data.cptcode,
        description: data.description,
       asso_disease: data.disease,
      };

      axios
        .post(`${APIURL}/api/v1/staff/master-labtests/`, dataToSend, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          ;
          if (res.status == 200) {
            setCompleteShow(true);
            setData({
              testname: "",
              testsample: "",
              testmethod: "",
              unit: "",
              minrefrange: "",
              maxrefrange: "",
              group: { value: "", label: "" },
              country: { value: "", label: "" },
              cptcode: "",
              description: "",
              disease: "",
            });
          } else {
            setErrorShow(true);
          }
        })
        .catch((err) => {
          setErrorShow(true);
        });
      setSuccessShow(false);
    } else {
      setErrorValidate(true);
    }
  };

  return (
    <>
    
      <h2 style={{ marginTop: "0" }} className="title-of-page">
        Add Lab Test
      </h2>
      <div style={{ maxHeight: "1000px" }} className="config-box fit-content">
        <div className="feature-data pad-features">
          <div className="flex-col justify-feature">
            <span className="align-rental">Test Name: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="testname"
              value={data.testname}
              onChange={handleTitle}
            />
            {error.testname ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.testname}
              </div>
            ) : null}
          </div>

          <div className="flex-col justify-feature">
            <span className="align-rental">Test Method : </span>
            <input
              type="text"
              className="form-control title-flex"
              name="testmethod"
             
              value={data.testmethod}
              onChange={handleTitle}
            />
            {error.testmethod ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.testmethod}
              </div>
            ) : null}
          </div>

          <div className="flex-col justify-feature">
            <span className="align-rental">Test Sample : </span>
            <input
              type="text"
              className="form-control title-flex"
              name="testsample"
             
              value={data.testsample}
              onChange={handleTitle}
            />
            {error.testsample ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.testsample}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Unit in rg/gh: </span>
            <input
              type="number"
              className="form-control title-flex"
              name="unit"
              min="0"
              max="100"
              value={data.unit}
              onChange={handleTitle}
            />
            {error.unit ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.unit}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Min Range: </span>
            <input
              type="number"
              className="form-control title-flex"
              name="minrefrange"
              min = "0"
              max = "100"
              value={data.minrefrange}
              onChange={handleTitle}
            />
            {error.minrefrange ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.minrefrange}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Max Range: </span>
            <input
              type="number"
              className="form-control title-flex"
              name="maxrefrange"
              min = "0"
              max = "100"
              value={data.maxrefrange}
              onChange={handleTitle}
            />
            {error.maxrefrange ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.maxrefrange}
              </div>
            ) : null}
          </div>
         <div className="flex-col justify-feature">
            <span className="align-rental">CPT Code: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="cptcode"
              value={data.cptcode}
              onChange={handleTitle}
            />
           
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Associate Disease: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="disease"
              value={data.disease}
              onChange={handleTitle}
            />
             
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Description: </span>
            <textarea
              type="text"
              className="form-control title-flex"
              name="description"
              value={data.description}
              onChange={handleTitle}
            />
            
          </div>

          <div className="flex-row name-content">
            <span className="align-rental">Group: </span>
            <Select
              value={data.group}
              onChange={(data, e) => {
                handleServiceChange(data, e);
              }}
              options={group1}
              name="group"
              className="select-currency select-flex"
            />
            {  error.group ? (
              <div className="error-validation-msg error-feature">
                {error.group}
              </div>
            ) : null}
          </div>

           <div className="flex-row name-content">
            <span className="align-rental">Country: </span>
            <Select
              value={data.country}
              onChange={(data, e) => {
                handleUnitChange(data, e);
              }}
              options={country}
              name="country"
              className="select-currency select-flex"
            />
            {error.country ? (
              <div className="error-validation-msg error-feature">
                {error.country}
              </div>
            ) : null}
          </div>
        
       
        </div>

        <div className="text-center">
          <button onClick={handleSave} className="btn btn-primary btn-col mb-2">
            Save
          </button>
        </div>
        {successShow ? (
          <SuccessPopUp
            show={successShow}
            onProceed={() => {
              setSuccessShow(false);
              props.modalClose();
            }}
            onHide={() => {
              setSuccessShow(false);
              props.notProceedClose();
            }}
          />
        ) : null}

        {errorShow ? (
          <ErrorPopUp
            show={errorShow}
            onHide={() => {
              setErrorShow(false);
            }}
          />
        ) : null}
        {errorValidate ? (
          <ErorValidatePopup
            show={errorValidate}
            onHide={() => {
              setErrorValidate(false);
            }}
          />
        ) : null}

        {completeShow ? (
          <CompletePopUp
            show={completeShow}
            onHide={() => {
              setCompleteShow(false);
            }}
          />
        ) : null}
      </div>
    </>
  );
}

export default AddTest;
