import React, {useState,useEffect} from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./service.css";
//import Select from 'react-select';
import {Tooltip} from 'react-bootstrap';
import {OverlayTrigger} from 'react-bootstrap';
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import ViewRemark from "./ViewRemark";
function ServiceHistory() {

const [render,]=useState(true);
  const [,setGetError]=useState('');
const [tableData,setTableData]=useState([]);
 //const [currentIndex,setCurrentIndex]=useState('');
  //const [,setCurrentColumn] = useState('');
  //const [display,setDisplay]=useState(false);

  const [search,]=useState("");
  const [,setOptions] = useState([]);
 const [,setOptionsDisplay] = useState([]);
 const [modalShow, setModalShow]= useState(false);
 const [remarkList, setRemarkList]= useState([])



useEffect(()=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
  
  
    axios.get(`${APIURL}/api/v1/hospital/service-request-order/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
       
        let dataList= res.data.data;

         
        let updatedList=[];
        let filteredList=dataList.filter((item,index)=>{
          return (item.status.toLowerCase()==="completed" || item.status.toLowerCase()==="rejected" || item.status.toLowerCase()==="cancelled")
        })
        updatedList= filteredList.map((item,index)=>{
          let status;
          let statusObj;
          if(item.status.toLowerCase()==="completed"){
            status="completed";
            statusObj={value: "completed", label: "Completed"}
          }
          else if(item.status.toLowerCase()==="rejected"){
            status="rejected";
            statusObj={value: "rejected", label: "Rejected"}
          }
          else if(item.status.toLowerCase()==="cancelled"){
            status="cancelled";
            statusObj={value: "cancelled", label: "Cancelled"}
          }
         
   let priority;
   let priorityObj;
       if(item.priority.toLowerCase()==="critical"){
            priority="critical";
            priorityObj= {value: 'critical' , label: 'Cr'}
          }
          else if(item.priority.toLowerCase()==="very high"){
            priority="very high";
            priorityObj= { value: 'very high' , label: 'V.h'}
          }
          else if(item.priority.toLowerCase()==="high"){
            priority="high";
            priorityObj= { value: 'high' , label: 'Hi'}
          }          
          else  if(item.priority.toLowerCase()==="medium"){
            priority="medium";
            priorityObj=  { value: 'medium' , label: 'Md'}
          }
          else  if(item.priority.toLowerCase()==="low"){
            priority="low";
            priorityObj=  { value: 'low' , label: 'Lw'}
          }

                  return({
                  name: item.patient_name,
                  date: item.order_date,
                  order: item.service_title,
                  priority: priority,
                  priorityObj: priorityObj,
                  ref:item.order_id,
                  contact_number: item.contact_number,
                  status:status,
                  statusObj: statusObj ,
                  patient_id:item.patient_id,
                  address: item.address,
                  details: item.details,
                  required_date: item.due_date,
                  assigned_to: item.assignee!==null ? item.assignee : null,
                  remarks: item.remarks? item.remarks :null,
                  remarks_list: item.remarks_list
                }
              )
        })
        
         setTableData(updatedList);
      }
 
        else{
        setGetError(res.data.message);
      }
      


    })
    .catch(err=>{

 setGetError("Error in fetching data");
})


},[]);


useEffect(()=>{

    const tokenString= localStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '')  
            
      
      
      axios.get(`${APIURL}/api/v1/hospital/service-request-order/search/?q=${search}`, { headers: {
        
        "Authorization" : 'Token '+str  }})
      .then(res=>{
       const assignee_List=[]
        if(res.data.status==="success"){
        
          const assigneeList=res.data.data
          assigneeList.map((person, index) => (assignee_List.push(person["id"])))
        
        setOptions(assigneeList);
        setOptionsDisplay(assignee_List)
        
  
        }
  
  
      })
      .catch(err=>{
  
  })
  
  
  },[search]);

useEffect(()=>{
    setTableData(tableData)
  },[render,tableData]);
 /*const statusOptions = [ {value: 'new' , label: 'Nw'},
       { value: 'ongoing' , label: 'On'},
      { value: 'accepted' , label: 'Ac'},

       
    ];
    const priorityOptions = [ {value: 'critical' , label: 'Cr'},
    { value: 'very high' , label: 'V.h'},
       { value: 'high' , label: 'Hi'},
      { value: 'medium' , label: 'Md'},
       { value: 'low' , label: 'Lw'},

       
    ];*/

    
/*const renderRemarks3=(cellInfo)=> {

  return (<textarea 
         type="text" 
         className="form-group" 
         value= {tableData[cellInfo.index][cellInfo.column.id]}
         name="remarks" 
          onChange={(e)=>{

           e.preventDefault();
           const list = tableData;
          list[cellInfo.index].remarks = e.target.value;
          
          
          setTableData(list);
          setRender(!render);
   } }
     autoComplete="off"
         /> )
 
}*/
/*const renderAssigned2=(cellInfo)=> {
 return (<div  >
      
      <input
        style={{ backgroundColor: "#fafafa" }}
        value= {tableData[cellInfo.index][cellInfo.column.id]}
        className="form-control"        
       name="assignee"

        onChange={(e)=>{

           e.preventDefault();


                setCurrentIndex(cellInfo.index);
                             setCurrentColumn(cellInfo.column.id);
                               
                               setDisplay(true);
 

 

  setSearch(e.target.value);

  


 
  const list = tableData;
          list[cellInfo.index].assigned_to = e.target.value;
          
          
          setTableData(list);
   } }
     autoComplete="off"
               
      />

        {currentIndex === cellInfo.index && cellInfo.column.id=== "assigned_to" && display && (<div className="suggestions-box suggestions-assignee-box">
             {options.map((item,i)=>{
         
    return(
      <div className="scan-item" 
          
            key={i} 
            onClick={(e)=>{ e.preventDefault();
             
              setRender(!render);
              
                                      const List = tableData;
                                      List[cellInfo.index].assigned_to= item.id;
                                       
                                 
                                      setTableData(List);
                                      setDisplay(false);

                                      }}
            >
            <div className="search-item each-option-suggestion">
      <h6 className="">ID: {item.id} /  {item.name}</h6>
       <h6 className="">{item.email} </h6>
       <h6 className=""> {item.mobile_number}</h6>
       </div>

      </div>
      )
  }) 
           }

        </div>)
      }
      

      </div>
        

    )

      }*/
    
    
const renderAssigned=(cellInfo)=> {


  return ( <div > {tableData[cellInfo.index][cellInfo.column.id]}</div>)
 
}
/*const renderRemarks2=(cellInfo)=> {

 if(!tableData[cellInfo.index][cellInfo.column.id]){
  return (<textarea type="text" className="form-group " /> )
 }
 else{
  return ( <div > {tableData[cellInfo.index][cellInfo.column.id]}</div>)
 }
}*/
/*const renderInput=(cellInfo)=> {


  return (<input type="text" className="form-group" /> )

}*/

const ViewPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <ViewRemark data={remarkList}/>

      </Modal.Body>
      <Modal.Footer>
         
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
       

      </Modal.Footer>
    </Modal>
  );
}

const handleView =(data,index) => {
  setRemarkList(data)
  setModalShow(true)
}

const renderRemarks=(cellInfo)=> {

  if(tableData[cellInfo.index][cellInfo.column.id].length < 1 || tableData[cellInfo.index][cellInfo.column.id]===null){
    return null
   }
   else{
   
    return (
      <button onClick={()=> handleView(tableData[cellInfo.index].remarks_list, cellInfo.index)} className='btn btn-primary btn-col btn-square btn-small'>View</button>
      /*<textarea type="text" className="text-area-remarks " value= {tableData[cellInfo.index][cellInfo.column.id]} />*/)
   }

/*if(tableData[cellInfo.index][cellInfo.column.id]==="" || tableData[cellInfo.index][cellInfo.column.id]===null){
  return null
 }
 else{
 
  return (<textarea type="text" className="text-area-remarks " value= {tableData[cellInfo.index][cellInfo.column.id]} />)
 }*/
}
const renderTable=(cellInfo)=> {
  
    return (

      <div > {tableData[cellInfo.index][cellInfo.column.id]}</div>
      
    );
  }
   const renderId=(cellInfo)=> {
  if(tableData[cellInfo.index][cellInfo.column.id]){
    return (

      <div  className="fixed-col"> {tableData[cellInfo.index][cellInfo.column.id]}</div>
      
    );
  }
  else{
    return null
  }
  }
  const renderService=(cellInfo)=> {
  
    return (
     
      <div > <span style={{margin:"10px"}}> 
          {tableData[cellInfo.index][cellInfo.column.id]}
       </span>  
       <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          Details: {tableData[cellInfo.index].details} <br/>
           
       
          </Tooltip> }><i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger> 
       </div>
      
    );
  }
  const renderContact=(cellInfo)=> {
  
    return (
     
      <div > <span style={{margin:"10px"}}> 
          {tableData[cellInfo.index][cellInfo.column.id]}
       </span>  
       <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          
          
          Address: {tableData[cellInfo.index].address} <br/>
          </Tooltip> }><i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger> 
       </div>
      
    );
  }
/*const renderDetails=(cellInfo)=> {
  
    return (

     <div > <p > {tableData[cellInfo.index][cellInfo.column.id]}</p></div>
      
    );
  }*/






/*const renderStatus2=(cellInfo)=> {
  
    return (
<>
       <Select
       value={tableData[cellInfo.index].statusObj}
       onChange={(data)=>{
        const List = tableData;
       

             List[cellInfo.index].statusObj = data;
            List[cellInfo.index].status=data.value;
             setTableData(List);
             setRender(!render);
             
      }}
        options={statusOptions}
        className= {`status-select ${tableData[cellInfo.index].status ==="new" ? "new-status" : 
                         tableData[cellInfo.index].status ==="accepted" ? "accepted-status" : 
                         tableData[cellInfo.index].status ==="ongoing" ? "ongoing-status" :
                         ""}`}  
      />
      </>
    );
  }*/

  /*const renderPriority2=(cellInfo)=> {
  
    return (
<>
       <Select
       value={tableData[cellInfo.index].priorityObj}
       onChange={(data)=>{
        const List = tableData;
       

             List[cellInfo.index].priorityObj = data;
            List[cellInfo.index].priority = data.value;
             setTableData(List);
             setRender(!render);
             
      }}
        options={priorityOptions}
        className="status-select"
        className= {`status-select ${tableData[cellInfo.index].priority ==="critical" ? "critical-priority" : 
                         tableData[cellInfo.index].priority ==="very high" ? "very-high-priority" : 
                         tableData[cellInfo.index].priority ==="high" ? "high-priority" : 
                         tableData[cellInfo.index].priority ==="medium" ? "medium-priority" :
                         tableData[cellInfo.index].priority ==="low" ? "low-priority" :
                         ""}`}  
        
      />
      </>
    );
  }*/

const renderStatus=(cellInfo)=> {
  if(tableData[cellInfo.index].status==="completed"){
   return (

      <div ><div className=" completed-status-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value }</div></div>
      
    );
  }
  else if(tableData[cellInfo.index].status==="rejected"){
    return (

      <div ><div className="rejected-status-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value }</div></div>
      
    );
  }
  else if(tableData[cellInfo.index].status==="cancelled"){
    return (

      <div ><div className="cancelled-status-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value }</div></div>
      
    );
  }
  
  }




const renderPriority=(cellInfo)=> {
  if(tableData[cellInfo.index].priority==="critical"){
    return (

      <div > <div className="priority critical-priority-2 caps">{tableData[cellInfo.index].priorityObj.label  }</div></div>
      
    );
  }
  else if(tableData[cellInfo.index].priority==="very high"){
    return (

      <div ><div className="priority very-high-priority-2 caps"> {tableData[cellInfo.index].priorityObj.label  }</div></div>
      
    );
  }
  else if(tableData[cellInfo.index].priority==="high"){
    return (

      <div ><div className="priority high-priority-2 caps"> {tableData[cellInfo.index].priorityObj.label  }</div></div>
      
    );
  }
  if(tableData[cellInfo.index].priority==="medium"){
    return (

      <div ><div className="priority medium-priority-2 caps"> {tableData[cellInfo.index].priorityObj.label }</div></div>
      
    );
  }
  if(tableData[cellInfo.index].priority ==="low"){
    return (

      <div ><div className="priority low-priority-2 caps"> {tableData[cellInfo.index].priorityObj.label }</div></div>
      
    );
  }
  

  }


/**************removed***details column*******************
{
  Header: "Details",
  accessor: "details",
  Cell: renderDetails,
   sortable:true,
    filterable:true,
   width:200

},



********************************/


/*const saveChanges=(index)=>{
  const rowToUpdate = tableData.filter((elt,i)=>{
    return i=== index
  })
 

callSave(rowToUpdate[0]);

}*/
/*const callSave=(rowToUpdate)=>{

  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
        const order_id=rowToUpdate.ref;
        const assignee=rowToUpdate.assigned_to;
        const remarks=rowToUpdate.remarks;
        const status= rowToUpdate.status;
       const priority= rowToUpdate.priority;
   const dataToUpdate={
    order_id,
    assignee,
    remarks,
    status,
    priority

   }
   
    axios.post(`${APIURL}/api/v1/hospital/service-request-order/`,dataToUpdate, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        
      if(res.data.status==="success"){
    
      //setSubmitMsg("Successfully updated!");
      setModalShow(true);  
   }
       else{
        // setGetError(res.data.message);
        setErrorSubmit(true); 
      }
    


    })
    .catch(err=>{
  
   setGetError("Error in fetching data");
})

}*/


const renderDate=(cellInfo)=> {

    let dateString = tableData[cellInfo.index][cellInfo.column.id];

let dateObject = new Date(dateString);

const updatedDate = dateObject.toString();
    return (<div >
      <h6> {updatedDate.slice(4,16)} </h6>
      <h6>{updatedDate.slice(16,24)}</h6>
     </div>)

   }


// Define a default UI for filtering
/***********************
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}
*******************/
const columns = [
{
  Header: "Id",
  accessor: "ref",
  Cell: renderId,
    sortable:true,
    filterable:true,

    filterMethod:(filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().startsWith(filter.value.toLowerCase()) : true
  },     
   width:60

},

{
  Header: "Service request",
  accessor: "order",
  Cell: renderService,
   sortable:true,
    filterable:true,
    filterMethod:(filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
  },     
   width:200

},

{
  Header: "Requested Date",
  accessor: "date",
  Cell: renderDate,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },

  width:120,
 filterable:true,
   sortable:true

},
{
  Header: "Due date",
  accessor: "required_date",
  Cell: renderDate,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },

  width:100,
 filterable:true,
   sortable:true

},
{
  Header: <><h6>Patient Name</h6>
           </>,
  accessor: "name",
  Cell: renderTable,
    sortable:true,
    filterable:true,
     filterMethod:(filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
  },         
   width:160

},
{
  Header: "Contact",
  accessor: "contact_number",
  Cell: renderContact,
   sortable:false,
    filterable:true,
   width:160

},
{
  Header: "Priority",
  accessor: "priorityObj",
  Cell: renderPriority,
   sortable:true,
   filterable:true,
   filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "very high") {
                      return row[filter.id].value === "very high";
                    }
                    if (filter.value === "high") {
                      return row[filter.id].value === "high";
                    }
                    
                     if (filter.value === "critical") {
                      return row[filter.id].value === "critical";
                    }
                     if (filter.value === "medium") {
                      return row[filter.id].value === "medium";
                    }
                     if (filter.value === "low") {
                      return row[filter.id].value === "low";
                    }
                  },
                  Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                       <option value="critical">Cr</option>
                      <option value="very high">V.h</option>
                      <option value="high">Hi</option>
                      <option value="medium">Md</option>
                      <option value="low">Lw</option>
                    </select>,
                   
              
   width:70

},


{
  Header: <><h6>Status</h6>
            </>,
  accessor: "statusObj",
  Cell: renderStatus,
   sortable:true,
    filterable:true,
   filterMethod: (filter, row) => {
    
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value.toLowerCase() === "completed") {
                      return row[filter.id].value ==="completed";
                    }
                     if (filter.value.toLowerCase() === "rejected") {
                      return row[filter.id].value === "rejected";
                    }
                    if (filter.value.toLowerCase() === "cancelled") {
                      return row[filter.id].value === "cancelled";
                    }
                     
                   
                     
                  },
                  Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">Show All</option>
                       <option value="completed">Completed</option>
                       <option value="rejected">Rejected</option>
                       <option value="cancelled">Cancelled</option>
                      
                                          
                    </select>,
                     
   width:100

},
{
  Header: <><h6>Assigned to</h6>
         </>,
  accessor: "assigned_to",
  Cell: renderAssigned,
   style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },

   sortable:true,
   filterable:true,
   filterMethod:(filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
  },     
   width:150

},

{
  Header: <><h6>Notes/Remarks</h6>
          </>,
   accessor: "remarks_list",
  Cell: renderRemarks,
   sortable:false,
   filterable:true,
   filterMethod:(filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
  },     
   width:200

}






]

  const displayTable=<ReactTable 
         columns={columns}
         data={tableData}
         defaultPageSize = {25}
         className="service-req-table"
         resizable
                  
                
         >

         </ReactTable> 
  return (<>
  
      <div className="text-center">
        <div className="service-table-holder flex-col"> {displayTable}
        </div>
    
     
    
    </div>
    <br/>
    <br/>
    
    <br/>
    <br/>
    <div className="info-notes-sr flex-row">
      <div className="info-div">
      <h6 className="bold-text" > Notes:  </h6>
     <h6 className="" > - Click on column header to sort the respective column </h6>
     <h6 className="" > - Filter the result by searching in the input field at each column header </h6>
     <h6 className="" > - Multisorting and multifiltering can be done at multiple columns   </h6>
     </div>
     {/*********
     <div className="info-div">

     <h6 className="bold-text">Status: </h6>
    <span> <span className="completed-status-2">Com  </span> : Completed</span>
     <span>   <span className="rejected-status-2">>Rej </span> : New </span>
       <span>  <span className="accepted-status-2"> </span> : Accepted </span>

     </div>
*********/}
    <div className="info-div">

     <h6 className="bold-text" > Priority : </h6>
    <span> <h6><span className="critical-priority-2"> Cr  </span> : Critical </h6></span>
     <span> <h6>  <span className="very-high-priority-2">V.h  </span> : Very High </h6> </span>
       <span> <h6> <span className="high-priority-2"> Hi  </span> : High </h6></span>
        <span> <h6> <span className="medium-priority-2"> Md </span> : Medium</h6> </span>
         <span> <h6> <span className="low-priority-2"> Lw  </span> : Low </h6></span>

     </div>
</div>


{
      modalShow ? 
      <ViewPopup
        show={modalShow}
        onHide= {() => { setModalShow(false)     
        }}
      /> : null
    }

    </>
  );
}

export default ServiceHistory;
