import React,{useState,useEffect,useRef} from "react";
import {Link,useHistory} from "react-router-dom";
import "./caredetails.css";
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";
import axios from "axios";
import {APIURL} from "../../Global";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';

import Select from 'react-select';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
function CareProgramEdit(props) {
	
    //const [bdoData,] =useContext(BdoLoginContext);
  
     /*  const [bdo_name,]= useState(bdoData ? bdoData.name : null);
    const doctor_specializations = hospBdoData ? hospBdoData.doctor_specializations : null; 
   const [department,]  = useState(bdoData ? bdoData.department : null);
   const [Id,] = useState(bdoData ? bdoData.user_id : null);
   const [hospitalName,]= useState(bdoData ? bdoData.hospital_username: null);
   const specializations = hospBdoData ? hospBdoData.specializations : null;
   const [location,] = useState(bdoData ? bdoData.location: null);*/
   const [title,setTitle]=useState();
   const [price,setPrice]=useState();
   const [discount,setDiscount]=useState();
     const [tax,setTax]=useState();
 const [pricingType,setPricingType]=useState();
 const [,setPricingVal]=useState();
 const [currency,setCurrency]=useState();
 const [,setCurrencyValue]=useState();
 const [offerText,setOfferText]=useState();
      const [,setGetError]=useState('');
   const [commonFeatures,]=useState([]);
   //const [commonFeaturesActive,setCommonFeaturesActive]=useState([]);
   const [featuresList,setFeaturesList]=useState([]);
   const [finalFeatures,setFinalFeatures]=useState([]);
   const [msg, setMsg]= useState('')
   const [show, setShow]= useState(false)
   const [refresh,setRefresh]=useState(false);
   const [customFeatures,]=useState([...new Array(5)].map(()=>({
      title: "",
      limit: "",
      is_default:false,
      extra_cost:""
   })));
   //const [count,setCount]=useState(20);
const [careProgramId,]=useState(props.match.params.id);
 const [error,setError]=useState({
    title:"",
    price: '',
    discount:"",
    currency: "",
    pricingType:"",
    tax:"",
    submit:"",
    features:""
   });
    const [errorMsg,setErrorMsg]=useState("");
   const [submitMsg,setSubmitMsg] = useState("");
   const [confirmMsg,setConfirmMsg] = useState("");
 const [successSubmit,setSuccessSubmit]=useState(false);
    const [errorSubmit,setErrorSubmit]=useState(false);
    const [modalShow,setModalShow]=useState(false);
    const [reRender,setReRender]=useState(false);
    const [index, setIndex]= useState([])
    
const [errorLog,setErrorLog]=useState("");
 const [errorStatus,setErrorStatus]=useState(false);


   let history=useHistory();
   
   const [keys,] = useState({'u': false,'c': false,'n': false,'a': false,'s': false,'q': false,'l': false,});


   useEffect(()=>{
   
     window.onkeydown =(e) => {
       
       keys[e.key]=true
   
       if(e.altKey && keys['u']){
         history.push('/directory')
       }
       if(e.altKey && keys['c']){
         history.push('/careplans-db')
       }
       if(e.altKey && keys['n']){
         history.push('/notifications')
       }
       if(e.altKey && keys['a']){
         history.push('/analytics')
       }
       if(e.altKey && keys['s']){
         history.push('/service-requests')
       }
       if(e.altKey && keys['q']){
         history.push('/qrcode')
       }
       if(e.altKey && keys['l']){
         history.push('/call-log')
       }
   
   
     }
     window.onkeyup=(ev)=> {
       
       keys[ev.key]= false
     }
   
     return()=>{
       window.onkeydown=null
       window.onkeyup=null
     }
   },[]);

   
   let featuresTable=null;

useEffect(() => { window.scrollTo(0, 0) }, [])
   useEffect(()=>{
    
    featuresTable=<ReactTable 
         columns={columnsFeaturesList}
         data={featuresList}
          pageSize={featuresList.length>0 ? featuresList.length : 10}
         resizable
        className="features-reacttable"    
             
            showPagination={false}          
                          
                 
         >

         </ReactTable> 

   },[commonFeatures,customFeatures,featuresList])

   useEffect(()=>{

    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;


  
    axios.get(`${APIURL}/api/v1/hospital/care-programs/${careProgramId}/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){

        const pricing = res.data.data.price
        const disc = res.data.data.discounted_price
        sessionStorage.setItem('price',pricing)
        sessionStorage.setItem('discount',disc)

       setTitle(res.data.data.title);
       setDiscount(res.data.data.discounted_price);
       setPrice(res.data.data.price);
       
       setOfferText(res.data.data.description);
       setTax(res.data.data.tax_percentage);
      
        setCurrencyValue(res.data.data.currency);

        if(res.data.data.currency==="inr"){
           setCurrency( {value: 'inr' , label: 'INR'});
        }
        else  if(res.data.data.currency==="usd"){
           setCurrency( { value: 'usd' , label: 'USD'});
        }
        setPricingVal(res.data.data.pricing_type);
        if(res.data.data.pricing_type==="one_month"){
           setPricingType( {value: 'one_month' , label: '1 month (Monthly)'});
        }
        else   if(res.data.data.pricing_type==="three_months"){
           setPricingType(  {value: 'three_months' , label: '3 months (Qaurterly)'});
        }
        else   if(res.data.data.pricing_type==="six_months"){
           setPricingType(  {value: 'six_months' , label: '6 months (Half-yearly)'});
        }
          else   if(res.data.data.pricing_type==="one_year"){
           setPricingType(  {value: 'one_year' , label: '1 year (Yearly)'});
        }
          else   if(res.data.data.pricing_type==="life_time"){
           setPricingType(  {value: 'life_time' , label: 'Life time'});
        }
     /***********************************
       let fList=res.data.data.features;
       const customList= fList.filter((item)=>{
        return item.is_default===false
       })

      const commonList=fList.filter((item)=>{
        return item.is_default===true
       })


       setCustomFeatures(customList);
       setCommonFeatures(commonList);
         setFeaturesList(commonList.concat(customList));

      }
    *************************/

     let fList=res.data.data.features;
      /* const activeList= fList.filter((item)=>{
        return item.is_active===true
       })*/
       const data1 = JSON.stringify(fList)
       sessionStorage.setItem('data',data1)
     
         setFeaturesList(fList);

      }

        else{
        setGetError(res.data.message);
      }
      


    })
    .catch(err=>{

 setGetError("Error in fetching data");
})


},[]);

/*function usePrevious(value){
  const ref= useRef()

  useEffect(()=> {
    ref.current =value
  },[value])

  return ref.current
}*/

const SuccessPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"green"}}>{submitMsg} </h5>
        
        
     
       
      </Modal.Body>
      <Modal.Footer>
         
         <Link to="/care-programs"><Button className='btn btn-primary btn-col'>Go to Care Plans list</Button></Link>

         

       
       

      </Modal.Footer>
    </Modal>
  );
}
const ConfirmPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"red"}}>Features cannot be disabled after submission. Please confirm to update Care Plan!</h5>
        
        
     
       
      </Modal.Body>
      <Modal.Footer>
           <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
            <Button className='btn btn-primary btn-col' onClick={()=>{setModalShow(false);
                                             handleSubmit()}}>Confirm</Button>
        

         

       
       

      </Modal.Footer>
    </Modal>
  );
}
const ErrorPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
         <span style={{color: "orange"}}> Please note that only <span style={{color:"var(--theme-blue)"}}> INR</span> is supported as currency at the moment.<br/> <br/>
        Kindly contact us at 
               <a href="mailto:Contact@IntPurple.com" target="_blank" rel="noopener noreferrer" aria-label="mail id"> Contact@IntPurple.com </a> 
     if you need any support. </span>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}
const SubmitErrorPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Submission. Please try again!" } </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}

const FeaturePopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"red"}}>{msg} </h5>
        <br/>
        <span className='text-muted new-text'><i className="fas fa-info-circle feature-info"></i>Decreasing the service count may cause issues for the Patients.Please create a new care plan to meet your requirements</span>
     
       
      </Modal.Body>
      <Modal.Footer>
         
         <Button onClick={props.onHide} className='btn btn-primary btn-col'>Ok</Button>

         

       
       

      </Modal.Footer>
    </Modal>
  );
}

const handleCurrencyChange=(data)=>{
if(data.value!=="inr"){
  setErrorLog("errorLog");
  setErrorStatus(true)
  setCurrency(currency)
}
else{
setCurrency(data);
}
}
    const currencyOptions = [ {value: 'inr' , label: 'INR'},
       { value: 'usd' , label: 'USD'}
      
       
    ];
      const pricingOptions = [ {value: 'one_month' , label: '1 month (Monthly)'},
                           {value: 'three_months' , label: '3 months (Qaurterly)'},
                          {value: 'six_months' , label: '6 months (Half-yearly)'},
                          {value: 'one_year' , label: '1 year (Yearly)'},
                          {value: 'life_time' , label: 'Life time'}
      
       
    ];







/*const renderEditable=(cellInfo)=> {
    return (<>
      <div 
            
            onBlur={e=>{

              let list = [...featuresList];
        
          list[cellInfo.index][cellInfo.column.id] = e.target.innerHTML.trim().replace(/&nbsp;/g, '')
      .replace(/&amp;/g, '&')
      .replace(/&gt;/g, '>')
      .replace(/&lt;/g, '<');
           setFeaturesList(list);
           
        }
        }
            contentEditable
            dangerouslySetInnerHTML={{__html: featuresList[cellInfo.index][cellInfo.column.id]}}
            onPaste={ evt => {
                  evt.preventDefault()

                  const text = evt.clipboardData.getData('text/plain')
                  document.execCommand('insertHTML', false, text)
          }}
            onKeyPress={evt => {
              const keyCode = evt.keyCode || evt.which;

              if (keyCode === 13) {
                evt.returnValue = false;
                if (evt.preventDefault) evt.preventDefault()
              } }}
             ></div>


     
      
    
      </>
    );
  }*/



const renderLimit=(cellInfo)=> {
 
     if(featuresList[cellInfo.index].is_active===true){
     
      return (<div className="flex-row flex-row-start flex-row-align-center">

     <div > {featuresList[cellInfo.index][cellInfo.column.id] ? <h6 className="feature-rt-td">
      <div 
            className="edit-table-field form-control free-usage-input"
            onBlur={e=>{

              let list = [...featuresList];
        
          list[cellInfo.index][cellInfo.column.id] = e.target.innerHTML.trim().replace(/&nbsp;/g, '')
      .replace(/&amp;/g, '&')
      .replace(/&gt;/g, '>')
      .replace(/&lt;/g, '<');
           setFeaturesList(list);
           
        }
        }
            contentEditable
            dangerouslySetInnerHTML={{__html: featuresList[cellInfo.index][cellInfo.column.id]}}
            onPaste={ evt => {
                  evt.preventDefault()

                  const text = evt.clipboardData.getData('text/plain')
                  document.execCommand('insertHTML', false, text)
          }}
            onKeyPress={evt => {
              const keyCode = evt.keyCode || evt.which;

              if (keyCode === 13) {
                evt.returnValue = false;
                if (evt.preventDefault) evt.preventDefault()
              } }}
             ></div>

          </h6> : 

<input className="form-control" type="number" min={0}  name={cellInfo.column.id} 
 
    onChange={(e)=>{
       e.preventDefault();
      let list= featuresList;

  list[cellInfo.index].limit =e.target.value;
  setFeaturesList(list);
 
    }
  }
  />


      }</div>
      
<h6 className="caps feature-rt-td" >{featuresList[cellInfo.index].service_unit!=="count" ? featuresList[cellInfo.index].service_unit : null}
    </h6>
    </div>
    )
  }
  else{
     return (<>

    <div className="bkgrnd-grey"><h6> N/A </h6></div>
      </>
    )
  }
  }

const renderExtraCost=(cellInfo)=> {
 
     if(featuresList[cellInfo.index].is_active===true){
     
      return (<>

     <div > {featuresList[cellInfo.index][cellInfo.column.id] ? <h6 className="feature-rt-td">


     <div 
            className="edit-table-field"
            onBlur={e=>{

              let list = [...featuresList];
        
          list[cellInfo.index][cellInfo.column.id] = e.target.innerHTML.trim().replace(/&nbsp;/g, '')
      .replace(/&amp;/g, '&')
      .replace(/&gt;/g, '>')
      .replace(/&lt;/g, '<');
           setFeaturesList(list);
           
        }
        }
            contentEditable
            dangerouslySetInnerHTML={{__html: featuresList[cellInfo.index][cellInfo.column.id]}}
            onPaste={ evt => {
                  evt.preventDefault()

                  const text = evt.clipboardData.getData('text/plain')
                  document.execCommand('insertHTML', false, text)
          }}
            onKeyPress={evt => {
              const keyCode = evt.keyCode || evt.which;

              if (keyCode === 13) {
                evt.returnValue = false;
                if (evt.preventDefault) evt.preventDefault()
              } }}
             ></div>



     </h6> : 

<input className="form-control" type="number" min={0}  name={cellInfo.column.id} 
 
    onChange={(e)=>{
       e.preventDefault();
      let list= featuresList;

  list[cellInfo.index][cellInfo.column.id] =e.target.value;
  setFeaturesList(list);
 
    }
  }
  />


      }</div>
      

    </>
    )
  }
  else{
     return (<>

    <div className="bkgrnd-grey"><h6> N/A </h6></div>
      </>
    )
  }
  }

const renderTableId=(cellInfo)=> {
     if(featuresList[cellInfo.index].is_active===true){
    return (

      <div className="bkgrnd-white"> <h6 className="feature-rt-td">{cellInfo.index+1}</h6> </div>
      
    );
  }
  else{
    return (

      <div className=""> {cellInfo.index+1}  

     </div>
      
    );
  }
  
  
  }
const renderTable=(cellInfo)=> {
  
     if(featuresList[cellInfo.index].is_active===true){
    return (

      <div className="bkgrnd-white"> {featuresList[cellInfo.index][cellInfo.column.id] ? <h6 className="feature-rt-td">{featuresList[cellInfo.index][cellInfo.column.id]}</h6> : null }</div>
      
    );
  }
  else{
    return (

      <div className="">   {featuresList[cellInfo.index][cellInfo.column.id] ? <h6 className="feature-rt-td">{featuresList[cellInfo.index][cellInfo.column.id]}</h6> : 

      null
   }</div>
      
    );
  }
  
  
  }


 const renderServiceType=(cellInfo)=> {
  
    
   return (

    <div className="caps">  {(featuresList[cellInfo.index][cellInfo.column.id]).replace("_"," ")}</div>
     
   );
 
}
  const renderService=(cellInfo)=> {
    if(featuresList[cellInfo.index].is_active===true){
      
     return (
  
       <div className="bkgrnd-white"> {featuresList[cellInfo.index][cellInfo.column.id]===true ? <h6 className="feature-rt-td">Yes</h6> :featuresList[cellInfo.index][cellInfo.column.id]===false? <h6 className="feature-rt-td">No</h6>: null }</div>
       
     );
   }
   else{
     return (
  
       <div > {featuresList[cellInfo.index][cellInfo.column.id]===true ? <h6 className="feature-rt-td">Yes</h6> :featuresList[cellInfo.index][cellInfo.column.id]===false? <h6 className="feature-rt-td">No</h6>: null }</div>
       
     );
  
   }
  }

  /*const renderDefault=(cellInfo)=> {
     
    return (

      <div > {featuresList[cellInfo.index].is_default ? <button className="btn btn-secondary btn-smallest btn-square btn-default"> Default </button>: <button className="btn btn-info btn-smallest btn-square btn-default"> Custom </button>}</div>
      
    );
  
  
  } */

  const renderDisable=(cellInfo)=> {
    
    const pos= index
    if(pos.includes(featuresList[cellInfo.index].feature)){
      return (
   
        <button className="btn btn-danger btn-smallest btn-square" 
           onClick={()=>{disableFeature(cellInfo.index)}}> Disable </button>
        
        )
    }

   /*if(features[cellInfo.index].is_active===true){
     return (
   
    <button className="btn btn-danger btn-smallest btn-square" 
       onClick={()=>{disableFeature(cellInfo.index)}}> Disable </button>
    
    )
  }*/
  if(featuresList[cellInfo.index].is_active===false){
    return (
   
    <button className="btn btn-primary btn-col btn-smallest btn-square" 
       onClick={()=>{enableFeature(cellInfo.index)}}> Enable </button>
    
    )
  }
  
  
  
}

const disableFeature=i=>{

let list=featuresList;
  list[i].is_active=false;

  const pos= index
  const data= pos.indexOf(list[i].feature);
  pos.splice(data,1)
  setIndex(pos)

  setFeaturesList(list);
  setRefresh(!refresh);
  

}

const enableFeature=i=>{

let list=featuresList;
  list[i].is_active=true;

  setIndex((index)=> [ ...index, list[i].feature])
  setFeaturesList(list);
  setRefresh(!refresh);

}

  const columnsFeaturesList= [
  {
  Header: "ID",
  accessor: "feature",
  Cell: renderTableId,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:70,
   sortable:false

},
{
  Header: "Feature",
  accessor: "title",
  Cell: renderTable,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:280,
   sortable:false

},{
  Header: <div className="header-features-table"><h6>*Free usage</h6>
  <h6>(in unit service)</h6>
           </div>,
  accessor: "limit",
  Cell: renderLimit,
   sortable:false,
  minWidth:160

},
{
   Header: <div className="header-features-table"><h6>*Extra Price</h6>
          <h6>(for additional </h6> 
           <h6> unit service)</h6> </div>,
  accessor: "extra_cost",
  Cell: renderExtraCost,
   sortable:false,
   minWidth:120,
   

},
/**
{
  Header: "Service Request",
  accessor: "on_request_service",
  Cell: renderService,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  width:150,
   sortable:false

},

**/

{
  Header: "Service Type",
  accessor: "service_type",
  Cell: renderServiceType,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  width:150,
   sortable:false

},
{
  Header: <div className="header-features-table"><h6>Enable/</h6>
          <h6>Disable </h6> </div>,
  accessor: "enable",
  Cell:renderDisable, 
   sortable:false,
   filterable:false
   

}]

 
  featuresTable=<ReactTable 
         columns={columnsFeaturesList}
         data={featuresList}
         pageSize={featuresList.length>0 ? featuresList.length : 10}
         resizable
        className="features-reacttable"    
             
            showPagination={false}          
                           
                 
         >

         </ReactTable> 


const handleBack=(e)=>{
   history.goBack();
}
  
  const handleChangeTitle=(e)=>{
    setTitle(e.target.value)
  }

const handleDiscountChange=(e)=>{
  e.preventDefault();
  setDiscount(e.target.value);
}
const handlePricingChange=(data)=>{

  setPricingType(data);
}
const handleTaxChange=(e)=>{
  e.preventDefault();
  const val =e.target.value

  if(val.length<=3){
    setTax(val)
  } else{
    return false;
} 
}

const handleStandardPriceChange=(e)=>{
  e.preventDefault();
  setPrice(e.target.value);
}



const handleOfferTextChange=(e)=>{
  e.preventDefault();
  setOfferText(e.target.value);
}

const validate=()=>{
  let isValid = true;
  let errors = {};
setError({});

if(title==="" || title===null || title===undefined){
  isValid = false;
  errors["title"] = "Please enter Title";
}
if(price==="" || price===null || price===undefined){
  isValid = false;
  errors["price"] = "Please enter Price";
}
if(price!=="" && price!==null && price!==undefined && price<0){
  isValid = false;
  errors["price"] = "Please enter a valid Price";
}
if(discount==="" || discount===null || discount===undefined){
  isValid = false;
  errors["discount"] = "Please enter Discounted price";
}
if(discount!=="" && discount!==null && discount!==undefined && discount<0){
  isValid = false;
  errors["discount"] = "Please enter a valid Discounted price";
}
if(discount!=="" && discount!==null && discount!==undefined && discount>price){
  isValid = false;
  errors["discount"] = "Discounted price can't be greater than standard price";
}
if(currency==="" || currency===null || currency===undefined){
  isValid = false;
  errors["currency"] = "Please enter Currency";
}
if(pricingType==="" || pricingType===null || pricingType===undefined){
  isValid = false;
  errors["pricingType"] = "Please enter Price plan";
}
if(tax!==null && tax!==undefined){
  if(tax<0 || tax>100){
  isValid = false;
  errors["tax"] = "Tax % should be within 0-100 range";
}
}
const prevPrice= sessionStorage.getItem('price')
if( prevPrice < parseInt(price)){
  isValid = false;
  errors["price"] = "You cannot Increase existing Price";
}

const prevDiscount= sessionStorage.getItem('discount')
if( prevDiscount < parseInt(discount)){
  isValid = false;
  errors["discount"] = "You cannot Increase existing Discounted Price";
}

const feature1= featuresList
const data2= sessionStorage.getItem('data')
const feature2= JSON.parse(data2)
let arr=[]
let arr1=[]
  for(let i=0; i<feature1.length;i++){
    if(parseInt(feature1[i].limit) < parseInt(feature2[i].limit)){
      arr.push(i+1)
    }
    else{
      delete arr[i]
    }
    if(parseInt(feature1[i].extra_cost) < parseInt(feature2[i].extra_cost)){
      arr1.push(i+1)
    }
    else{
      delete arr1[i]
    }
  }

if(arr.length > 0){
  isValid = false;
  setMsg(`You cannot degrade existing Service Count in row ${arr}`)
  setShow(true)
}
if(arr1.length > 0){
  isValid = false;
  setMsg(`You cannot degrade existing Extra Price in row ${arr1}`)
  setShow(true)
}

if(isValid===false){
 errors["submit"] = "Error in submission!";
}

 setError(currentstate=>({
            ...currentstate,
            ...errors
          }));

      

      return isValid;

}

const validate2=(featuresToSend)=>{
  let isValid = true;
  let errors = {};
  setError({});
let activelist= featuresToSend.filter((item,index)=>{
  return (item.is_active===true)
 })
if(activelist.length<=0){
     isValid = false;
  errors["features"] = `Minimum one feature is mandatory for a care plan `;
  
  }
  featuresToSend.reverse().map((item,index)=>{
      if(item.is_active===true){
    if((item.limit===null || item.limit==="" || item.limit===undefined) && (item.extra_cost===null || item.extra_cost==="" || item.extra_cost===undefined) ){
  isValid = false;
  errors["features"] = `Please enter *Free limit & *Extra price for feature ID ${featuresToSend.length - (index)}. (Or, click on Disable button to disable the feature)`;
  
}
   if(item.limit==="0" && item.extra_cost==="0"){
  isValid = false;
  errors["features"] = `Free usage and Extra cost cannot be zero for feature ID ${featuresToSend.length - (index)}. (Or, click on Disable button to disable the feature)`;
  
}
if(item.extra_cost==="0"){
  isValid = false;
  errors["features"] = `Extra cost cannot be zero at feature ID ${featuresToSend.length - (index)}. `;
  
}
 if(item.extra_cost===null || item.extra_cost==="" || item.extra_cost===undefined){
  isValid = false;
  errors["features"] = `Please enter *Extra price for feature ID ${featuresToSend.length - (index)}. (Or, click on Disable button to disable the feature)`;
  
}
 if(item.extra_cost!==null && item.extra_cost!=="" && item.extra_cost<0){
  isValid = false;
  errors["features"] = `Please enter valid *Extra price for feature ID ${featuresToSend.length - (index)}`;
  
}
 else if(item.limit===null || item.limit==="" || item.limit===undefined){
  isValid = false;
  errors["features"] = `Please enter *Free limit for feature ID ${featuresToSend.length - (index)} . (Or, click on Disable button to disable the feature)`;
  
}
  if(item.limit!==null && item.limit!=="" && item.limit<0){
  isValid = false;
  errors["features"] = `Please enter valid *Free limit for feature ID ${featuresToSend.length - (index)}`;
  
}
}
})
 



 setError(currentstate=>({
            ...currentstate,
            ...errors
          }));

      

      return isValid;

}
const handleConfirm=()=>{

 
  setConfirmMsg("confirm");
   setModalShow(true);

}
const handleSubmit=()=>{
 const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;

let dataToSend={
  id:careProgramId,
  title:  title,
  price:price,
  discounted_price:discount,
  currency: currency.value,
  tax_percentage: tax,
  pricing_type:pricingType.value,
  description: offerText? offerText: "Nil",
  features: finalFeatures.reverse()
}
  
    axios.put(`${APIURL}/api/v1/hospital/care-programs/`, dataToSend, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){


      setSubmitMsg(res.data.message? res.data.message : "Care Plan updated successfully!");

          setSuccessSubmit(true);
   

      }
       else if(res.data.status==="error"){
        
        setErrorSubmit(true);
                        setErrorMsg(res.data.message ? res.data.message :'Error in submission!');
      }
        else{
        
        setErrorSubmit(true);
                        setErrorMsg('Error in submission!');
      }
      


    })
    .catch(err=>{

    
        setErrorSubmit(true);
                        setErrorMsg('Error in submission!');
})

}

const handleSave=(e)=>{
   e.preventDefault();
 
  
  if(validate()){

try{

const tokenString= localStorage.getItem('usertoken');
   
let featuresToSend=featuresList.length>0 ? featuresList.map((item,index)=>{
  if(item.is_active===true){
  return {
            feature:item.feature,
            limit:item.limit,
            extra_cost:item.extra_cost,
            is_active:item.is_active
            
          }
        }
        else{
           return {
            feature:item.feature,
            limit:"",
            extra_cost:"",
            is_active:false
            
          }
        }
}) : [];
setFinalFeatures(featuresToSend)

if(validate2(featuresToSend)){
handleConfirm();


}
}
catch(err){

   
        setErrorSubmit(true);
                        setErrorMsg('Error in submission!');
}
}



  
  }


  return (
    <> <HospBdoNavbar />
    


       <div className="hospital-dashboard-container hosp-bdo-page">
       <br/>

         {/* <h2 className="title-bdo" > Care Plan Id: {careProgramId} </h2> */}
      
   <div className="flex-head">
          <button className="btn btn-info topleft-single-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <h2 className="title-of-tasks title-mg" > Edit Care Plan Details </h2>
    </div> 
    <br/>
    <br/>
    <div className=" features-subs ">



   {/*******************details **********/}
   <div className="pgm-details-box">
    <div className="pgm-details-header box-theme">
                               

                      <h6 className="sub-section-title pgm-header-title"> Care Program Details </h6>
                      </div>
   <br/>
   <div className="pgm-details-body">
   <h6 className="features-form text-left">*Care Program Title</h6>
   <input type="text" name="title" maxLength="30" className="edit-table-field form-control" value={title} onChange={handleChangeTitle}/><br/>
    
   {error.title ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.title}</div> : null}
    <h6 className="features-form text-left">*Standard Price/month</h6>
    <input type="text" maxLength="30" name="price" className="edit-table-field form-control" value={price} onChange={handleStandardPriceChange}/><br/>
      {error.price ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.price}</div> : null}
      <h6 className="features-form text-left">*Price after discount</h6>
      <input type="text" maxLength="30" name="discount" className="edit-table-field form-control" value={discount} onChange={handleDiscountChange} /><br/>
     {error.discount ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>  {error.discount}</div> : null}
      <h6 className="features-form text-left">*Currency</h6>
      <Select
        value={currency}
        onChange={(data)=>{handleCurrencyChange(data)
            }}
        options={currencyOptions}
        className="select-currency sel-parent"
      />
      {error.currency ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.currency}</div> : null}
<br/>
          <h6 className="features-form text-left">Tax Percentage</h6>
      <input type="number" name="tax" min={0} max={100} className="edit-table-field form-control" placeholder="Eg: 15" value={tax} onChange={handleTaxChange} /><br/>
     {error.tax ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.tax}</div> : null}

 <h6 className="features-form text-left">*Price plan</h6>
    <Select
        value={pricingType}
        onChange={(data)=>{handlePricingChange(data)
            }}
        options={pricingOptions}
        className="select-currency sel-parent"
      />
      {error.pricingType ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.pricingType}</div> : null}
   <br/>
       <br/>
        <h6 className="features-form text-left"> Offer/Info Text</h6>
        <textarea  name="offerText" className="edit-table-field text-area offer-text-area" value={offerText}  rows="5" onChange={handleOfferTextChange} />
        <br/>
   </div>
   </div>

 {/******************************************************************/}
  

    <div className="">
     {featuresTable}
      <br/>
    
      {error.features ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.features}</div> : null}
    
     <br/>
     
     <h6 style={{color:"orange"}}> ** To disable/enable any feature , click on 'Disable'/'Enable' button .</h6>
      </div>
 </div>

<br/>
<br/>

    
<br/>
<br/>
<br/>

<button className="btn btn-secondary btn-cancel" onClick={handleBack} > Cancel </button>
<button className="btn btn-primary btn-col" onClick={handleSave} > Save changes </button><br/>
<br/>
<br/>
{confirmMsg!=="" ?
    <ConfirmPopup
        show={modalShow}
        
        onHide={() => { setModalShow(false);
                        setConfirmMsg("");
                       }}
       
      />: ''}
{submitMsg !=="" ?
    <SuccessPopup
        show={successSubmit}
        msg={submitMsg}
        onHide={() => { setSuccessSubmit(false);
                        setSubmitMsg('');
                       setReRender(!reRender)}}
       
      />: ''}

 {errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                        setErrorMsg('');
                       setReRender(!reRender)}}
       
      />: ''
    }
     {errorLog!=='' ?
    <ErrorPopup
        show={errorStatus}
        onHide={() =>  {setErrorStatus(false);
                        setErrorLog('');
                       setReRender(!reRender)}}
       
      />: ''
    }

{msg!=="" ?
    <FeaturePopup
        show={show}
        
        onHide={() => { setShow(false);
                        setMsg("");
                       }}
       
      />: ''}
</div>

    </>
  );
}
export default CareProgramEdit;
