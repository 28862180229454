import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function AddInventory(props) {

    const[data, setData]= useState({
        price:'',
        buy:'',
        distributor:'',
        company_address: '',
        distributor_address: ''
    })

    const [error,setError]=useState({
        price:'',
        buy:'',
        distributor:'',
        company_address: '',
        distributor_address: ''
     });

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)


    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>Inventory Details Added Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key]: val
        }))
      }

      const handlePrice= (e)=> {
        e.preventDefault();
        const val =e.target.value
        const key=e.target.name;

        if(val.length<=8){
          setData( current => ({
            ...current , [key]: val
        }))
        } else{
          return false;
      }
    }

  const validate=()=>{
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["price"] || input["price"]==="" ) {
      isValid = false;
      errors["price"] = "Please enter Price";
    }
    if (!input["buy"] || input["buy"]==="" ) {
        isValid = false;
        errors["buy"] = "Please enter Buying Price";
      }
    if (!input["distributor"] || input["distributor"]==="" ) {
      isValid = false;
      errors["distributor"] = "Please enter Distributor";
    }
    if (!input["company_address"] || input["company_address"]==="" ) {
      isValid = false;
      errors["company_address"] = "Please enter Company Address";
    }
    if (!input["distributor_address"] || input["distributor_address"]==="" ) {
      isValid = false;
      errors["distributor_address"] = "Please enter Distributor Address";
    }

     setError(currentstate=>({
          ...currentstate,
          ...errors
        }));
    

    return isValid;
}


  const saveChanges = (e)=> {
    e.preventDefault();

    const id = sessionStorage.getItem('rentalId')
    if (validate()){
      const tokenString = localStorage.getItem("usertoken");
 
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
  
   const dataToSend = {
      price : data.price,
      buying_price : data.buy,
      distributor : data.distributor,
      company_address : data.company_address,
      distributor_address : data.distributor_address
   }
  
      axios
        .put(`${APIURL}/api/v1/staff/update-cp-services/?id=${id}`, dataToSend , {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            
            setSuccessShow(true)
          } else {
            setErrorShow(true)
          }
        })
        .catch((err) => {
          setErrorShow(true)
        });
    }
    

}


    return (
        <>
    <h2 style={{marginTop:'0'}} className="title-of-page">Add Inventory Details</h2>
        <div style={{maxHeight:'860px'}} className='config-box fit-content'>
            
            <div className='flex-row name-content'>
                <span>Distributor : </span>
                <input type='text' className='form-control title-input' name='distributor' value={data.distributor} onChange={handleTitle} />
                {error.distributor ? <div className="error-validation-msg error-feature">{error.distributor}</div> : null}
            </div>
            <div className='flex-row name-content'>
                <span>Price : </span>
                <input type='number' className='form-control title-input' min='0' name='price' value={data.price} onChange={handlePrice} />
                {error.price ? <div className="error-validation-msg error-feature">{error.price}</div> : null}
            </div>
            <div className='flex-row name-content'>
                <span>Buying Price : </span>
                <input type='number' className='form-control title-input' min='0' name='buy' value={data.buy} onChange={handlePrice} />
                {error.buy ? <div className="error-validation-msg error-feature">{error.buy}</div> : null}
            </div>
            <div className='flex-row name-content'>
                <span>Company Address: </span>
                {/* <input type='text' className='form-control title-input' name='distributor' value={data.distributor} onChange={handleTitle} /> */}
                <textarea  className="text-area" placeholder="Company Address" name='company_address'  cols="100" rows="4" value={data.company_address} onChange={handleTitle}/>
                {error.company_address ? <div className="error-validation-msg error-feature-area">{error.company_address}</div> : null}
            </div>
            <div className='flex-row name-content'>
                <span>Distributor Address: </span>
                {/* <input type='text' className='form-control title-input' name='distributor' value={data.distributor} onChange={handleTitle} /> */}
                <textarea  className="text-area" placeholder="Distributor Address" name='distributor_address'  cols="100" rows="4" value={data.distributor_address} onChange={handleTitle}/>
                {error.distributor_address ? <div className="error-validation-msg error-feature-area">{error.distributor_address}</div> : null}
            </div>



            {/* <div className='flex-row name-content'>
                <span>Service Type : </span>
                <Select
                            value={data.type}
                            onChange={(data,e)=>{handleServiceChange(data,e)
                            }}
                            options={serviceOptions}
                            name='service_type'
                            className="select-currency serv-width"
                        />
                        {error.type ? <div className="error-validation-msg error-feature">{error.type}</div> : null}
            </div>

            <div className='flex-row name-content'>
                <span>Service Unit : </span>
                <Select
                            value={data.unit}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={unitOptions}
                            name='service_unit'
                            className="select-currency serv-width"
                        />
                        {error.type ? <div className="error-validation-msg error-feature">{error.type}</div> : null}
            </div>

            <div className='flex-row name-content'>
                <span>On Request Service : </span>

                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-input">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='request'  value= {true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='request'  value= {false}  onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
                {error.service ? <div className="error-validation-msg error-feature">{error.service}</div> : null}
            </div>

            <div className='flex-row name-content'>
                <span>Active Feature : </span>
                
                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-input">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='active'  value= {true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='active'  value= {false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
                {error.active ? <div className="error-validation-msg error-feature">{error.active}</div> : null}
            </div> */}
            <br/>
            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col'>Save</button>
                
            </div>
            <br/>
            {successShow ? 
<SuccessPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false); props.modalClose(); }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
            
        </div>
        </>
    )
}

export default AddInventory
