import React from "react";



function UpdatedSoon() {
  return (
    <>
      <div className="container">
        <h2 className="title-of-page"> This Page will be updated soon </h2>
      </div>
    </>
  );
}
export default UpdatedSoon;
