import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import printJS from 'print-js'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import moment from 'moment'


function GenerateInvoiceBdo(props) {
  debugger;

    const [invoiceData ,]= useState(props.invoiceData);
    const [method, setMethod]= useState('')
    const [amount, setAmount]= useState('')
    const [errorShow,setErrorShow]=useState(false);
    const [errorMsg,setErrorMsg]=useState("");
    const [type, setType]= useState("full")
    const [discount, setDiscount]= useState(0)
    const [show, setShow]= useState(false)
     const[data1,setData1]=useState({

     invoice_no: "",
      mobile_number: "",
       patient: ""

    })
    const [bdo_Data,]=useState(JSON.parse(sessionStorage.getItem('bdodata'))? JSON.parse(sessionStorage.getItem('bdodata')) : null);
    const [refresh, setRefresh]= useState(false)

    const [apiData, setApiData]= useState({

       amount_paid: "",
       id:"",
       balance_amount:"",
       gross_total_amount:"",
       total_amount:"",
       payment_status:"",
       vat:"",
       discount:"",
 
    })

    const [procedure, setProcedure]= useState([])

    let initial = useRef(true)

    useEffect(()=> {

      if( initial.current ){

        initial.current = false
      }
      else{
        setAmount(getPayable())
      }

        


    },[type,apiData,discount])

    useEffect(()=> {
      debugger;

        const tokenString= localStorage.getItem('usertoken');
      
        let str= tokenString.replace(/["]+/g, '');
       
         axios.get(`${APIURL}/api/v1/hospital/patient-payment/?paid_for=procedure&patient_id=${invoiceData.patient_id}`, 
         { headers: {
           'Content-Type': 'application/json',
           "Authorization" : 'Token '+str  }})
            .then(res=>{
         
       
              debugger;
       
           if(res.status==200){
             debugger;
            
            setData1(res.data)
            setApiData(res.data.payment_details)
            setProcedure(res.data.procedures)
       
           }else{
           
            setErrorMsg(res.data.message)
            setErrorShow(true)

           }
       
       
         })
         .catch(err=>{
       
            setErrorMsg('Error in Retreiving data.Please try again')
            setErrorShow(true)
       })
     
     },[])

    //  const handleProcedureInvoice = async() => {

    //   const tokenString= localStorage.getItem('usertoken');
      
    //   let str= tokenString.replace(/["]+/g, '');
     
    //    await axios.get(`${APIURL}/api/v1/hospital/patient-hospital-payment_v1/?payment_for=procedure&patient_id=${invoiceData.patient_id}`, 
    //    { headers: {
    //      'Content-Type': 'application/json',
    //      "Authorization" : 'Token '+str  }})
    //       .then(res=>{
       
          
     
    //      if(res.data.status==="success"){

    //       setApiData(res.data)
    //       setProcedure(res.data.procedure_details)

     
    //      }else{
         
    //       setErrorMsg(res.data.message)
    //       setErrorShow(true)


    //      }
     
     
    //    })
    //    .catch(err=>{
     
    //       setErrorMsg('Error in Retreiving data.Please try again')
    //       setErrorShow(true)

    //  })

    //  }

     const handleMethod =(e) => {
        setMethod(e.target.value)
    }

    const handleAmount = (e)=> {
        const val = e.target.value
        if(val <= apiData.balance_amount - discount ){
            setAmount(e.target.value)
        }
        else return false
    }

    useEffect(()=> {
      setProcedure(procedure)
    },[refresh])

    const handleSave = async()=> {
      initial.current= true
        const tokenString= localStorage.getItem('usertoken');
      
        let str= tokenString.replace(/["]+/g, '');

        const dataToUpdate = {
            payment_mode : method,
            paid_for:'procedure',
            amount_paid: amount,
            invoice_no: data1.invoice_no,
            discount: discount,
            patient_id:invoiceData.patient_id
        }
       
         axios.post(`${APIURL}/api/v1/hospital/patient-payment/`, dataToUpdate ,
         { headers: {
           'Content-Type': 'application/json',
           "Authorization" : 'Token '+str  }})
            .then(res=>{
       
           if(res.status==200){

            // async function callGet(callBack){

            //   if(type !== "full"){
            //     await callBack()
            //   }
            //   setShow(true)
            //   if(type === "full"){

            //     const procedureData = procedure
            //     const data = procedureData.map(item => {
            //       return {
            //         procedure_name: item.procedure_name,
            //         procedure_type: item.procedure_type,
            //         currency: item.currency,
            //         amount_without_vat: item.amount_without_vat,
            //         vat: item.vat,
            //       }
                    
                  
            //     })
            //     setProcedure(data)
            //     setRefresh(!refresh)

            //   }

            //   handlePrint()
            // }

            setShow(true)

            const procedureData = procedure
            const data = procedureData.map(item => {
              debugger;
              return {
                patient_procedure: item.patient_procedure,
                procedure_type: item.procedure_type,
                currency: item.currency,
                procedure_amount: item.procedure_amount,
                vat: item.vat,
                quantity:item.quantity
              }
                
              
            })
            setProcedure(data)
            setRefresh(!refresh)

            handlePrint()

            // callGet(handleProcedureInvoice)

              


            
       
           }else{
             setShow(false)
            setErrorMsg(res.data.message)
            setErrorShow(true)
           }
       
       
         })
         .catch(err=>{
            setErrorMsg('Error in Data Submission.Please try again')
            setErrorShow(true)
       })

    }

     const handlePrint = () => {
    
        printJS({
           printable: 'print-invoice',
           type: 'html',
           targetStyles: ['*'],
           header: 'Procedure Invoice'
        })
      
      }


      const SubmitErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Data Submission. Please try again!" } </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      } 

      const data = procedure.map(item => {
        debugger;
          return (

                  <tr>
                      <td>{item.patient_procedure}</td>
                      <td>{item.procedure_type}</td>
                      <td>{item.procedure_amount}</td>
                      <td>{item.vat}</td>
                      <td>{item.quantity}</td>
                  </tr>

          )
      })

      const handlePayment = (e)=> {
        setType(e.target.value)
      }

      const handleDiscount = (e)=> {

        if(parseInt(e.target.value) < parseInt(apiData.balance_amount)){
          setDiscount(e.target.value)
        }
        else return false

        
      }

      const getPayable = () => {

        const data = apiData.balance_amount - discount

        return data.toFixed(3)
      }

   return (<div>
      
      <h2 style={{textAlign:'center'}} className="title-of-tasks"> Procedure Invoice  </h2><br/>

      <div className='flex-row invoice-radio'>
           <span className='text-muted font-weight-bold'>Select payment method :</span>
           <input type='radio' className='hideme' checked={method==='card'} id='card' value='card' onChange={handleMethod} />
           <label for='card' className='invoice-label'>
            Card
           </label>

        <input type='radio' className='hideme' checked={method==='cash'} id='cash' value='cash' onChange={handleMethod} />
        <label for='cash' className='invoice-label'>
            Cash 
        </label>

        <input type='radio' className='hideme' checked={method==='benefit_pay'} id='benefit_pay' value='benefit_pay' onChange={handleMethod} />
        <label for='benefit_pay' className='invoice-label'>
          Benefit Pay 
        </label>
       </div>
       <br/>
       <div id='print-invoice' className="invoice-table flex-col to-print invoice-print-size ">
        <div className="invoice-row1 invoice-header">

              <div>
                <h5 className='font-weight-bold text-left'>Invoice {data1.invoice_no}</h5>
                <h6 className='text-left'>Patient: {invoiceData.patient}</h6>
                <h6 className='text-left'>Mobile: {data1.mobile_number}</h6>
                
              </div>

             <div>
              <h3 className="invoice-heading caps text-center">{bdo_Data.hospital_name}</h3>
              <h6 className="invoice-heading caps text-center">{bdo_Data.hospital_state},{bdo_Data.hospital_location},{bdo_Data.hospital_street}</h6>
             </div>
           {/* <h6 className="invoice-heading caps">Al Janabiyah North</h6> */}

        <div className='invoice-detail mr-2'>

            <h5>{moment().format('DD-MM-YYYY')}</h5>
            <h6 className="invoice-heading invoice-heading-normal">{bdo_Data.hospital_mobile}</h6>
            <h6 className="invoice-heading invoice-heading-normal">{bdo_Data.hospital_email}</h6>

        </div>
       </div>
       {/* <div className="invoice-row2">
  
       <div>
          <h5 className="grey-title caps">{invoiceData.patient_name}</h5>
    </div>
       </div> */}
       <div className="invoice-row3">
          <table >
          <tr>
            <th> Procedure </th>
  
            <th>Type</th>
        
            <th> Amount </th>
            <th>VAT</th>
            <th>Quantity</th>
  
          </tr>

          {data}
  
          {/* <tr>
            <td>
                
               <span className="caps">Patient</span><br/>
               <span className="caps">Registration </span>
               
  
             </td>
             <td>{apiData.amount_paid}</td>
             
            <td>{apiData.currency}</td>
            <td>{apiData.vat}</td>
  
          </tr> */}
  
          </table>
       </div>
       <div className="invoice-row4">
         <div className="row4-grid">

           <div>
             <div className='d-flex align-items-center justify-content-around mb-2'>
                {
                  show ?
                  <>
                  <h6 className="text-left">Discount </h6>
                  <h6>{parseFloat(discount).toFixed(3)}</h6>
                  </>
                  :
                  <>
                  <h6 style={{width:"40%"}} className="text-left">Discount :</h6>
                  <input type="number" className="form-control discount-input" min="0" value={discount} onChange={handleDiscount} />
                  </>
                }
                
             </div>
                {
                  show === false ?
                  <h6 className="text-left mb-3">Payable Amount : INR {getPayable()}</h6>
                  :
                  null
                }
              

              <div className='d-flex align-items-center mb-3'>

                  {
                    show ?
                    <>
                    <h6 className="text-left mb-0 mr-3">Payment Type  </h6>
                    <h6 className='mb-0'>{type}</h6>
                    </>
                    :
                    <>
                    <h6 style={{width:"40%"}} className="text-left mb-0">Payment Type : </h6>
                    <input type='radio' checked={type==="partial"} id='partial' value="partial" onChange={handlePayment} />
                    <label style={{marginBottom:'0'}} className='mr-2' for='partial'>
                    Partial
                    </label>
  
                    <input type='radio' checked={type==="full"} id='full' value="full" onChange={handlePayment} />
                    <label style={{marginBottom:'0'}} for='full'>
                    Complete 
                    </label>
                    </>
                  }

              </div>

              {
                show?
                <div className='d-flex align-items-center justify-content-around paid-highlight mt-2'>
                    <h6 className='mb-0'>Paid</h6>
                    <h6 className='mb-0'>{parseFloat(amount).toFixed(3)}</h6>
                </div>

                : null
              }

{
                show?
                <div className='d-flex align-items-center justify-content-around paid-highlight mt-2'>
                    <h6 className='mb-0'>Balance </h6>
                    <h6 className='mb-0 '>{(getPayable() - amount).toFixed(3)}</h6>
                </div>

                : null
              }

              {
               show === false ? type === "partial"?
                <>
                <div className='d-flex align-items-center'>
                  <span className='text-muted font-weight-bold'>Amount Paid : </span>
                  <input type='number' className='form-control discount-input' style={{width:'150px'}} max='100000' min='0' value={amount} onChange={handleAmount} />
                </div>
                <br/>
                <h6 style={{width:"60%"}} className="balance-highlight">Balance : {(getPayable() - amount).toFixed(3)}</h6>
                </>
                :  null : null
              }
           </div>

          <div style={{justifySelf:"stretch"}}>
              <h6 className="text-right mb-3">Gross Total : <span className="caps-full">INR </span>{apiData.total_amount}</h6>
              <h6 className="text-right mb-3">VAT Amount : <span className="caps-full">INR </span>{apiData.vat}</h6>
              <h6 className="text-right">Net Total : <span className="caps-full">INR </span>{apiData.gross_total_amount}</h6>
              {/* <h6 className="text-right">Balance : BD {apiData.balance_payment}</h6> */}
              <br/>
              <h6 className="text-right">Thanks,</h6>
              <h6 className="text-right">{bdo_Data.name}</h6>
          </div>


         </div>

       </div>
       </div>
       <br/>

       {/**<div className="to-print">
        <a href="https://www.felixacare.com" target="_blank"><h5 className="felixa-site" > www.felixacare.com</h5></a>
  
      </div>**/}
       
       <div className="do-not-print text-auto" id="pr">
                   <button className="btn btn-primary btn-col btn-square" disabled={method === ''} onClick={handleSave}> Save and Print </button>
                   <br/>
                   <br/>
                   <h6 style={{color:"grey"}}>** If in case 'Print' does not work, please cross check if popup blocker is switched 'off' in your Browser settings.</h6>
                    <br/>
                    <br/>
             </div> 
  

             {errorMsg!=='' ? 
    <SubmitErrorPopup
        show={errorShow}
        onHide={() =>  {setErrorShow(false);
                        setErrorMsg('');}}
       
      />: ''
    }

    </div>
       )
}

export default GenerateInvoiceBdo
