import React, { useEffect,useState} from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import axios from "axios";

import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {useHistory} from "react-router-dom";
import {APIURL} from "../../../Global";
import HospBdoNavbar from "../../HospBdoNavbar/HospBdoNavbar";
import "./subscription.css"
import TenureUpdate from "./TenureUpdate";

function Subscription(props) {
	
    const [list,setList]=useState([]);
    const [id]=useState(props.match.params.id);
    const [modalShow, setModalShow]= useState(false);
    const [careName,setCareName]=useState();
    const [patientName,]=useState(props.location.name)
    const [patientPhn,]=useState(props.location.phn)
    const [pgmId, setPgmId]= useState(0)
    const [refresh, setRefresh]= useState(false)
    const [confirmShow, setConfirmShow]= useState(false)
    const [suspendShow, setSuspendShow]= useState(false)
    const [cancelConfirmShow, setCancelConfirmShow]= useState(false)
    const [cancelShow, setCancelShow]= useState(false)
    const [errorShow ,setErrorShow]= useState(false);
    const [refund, setRefund]= useState('')
    const[name, setName]= useState('')

  
   let history=useHistory();
   
   const [keys,] = useState({'u': false,'c': false,'n': false,'a': false,'s': false,'q': false,'l': false,});


   useEffect(()=>{
   
     window.onkeydown =(e) => {
       
       keys[e.key]=true
   
       if(e.altKey && keys['u']){
         history.push('/directory')
       }
       if(e.altKey && keys['c']){
         history.push('/careplans-db')
       }
       if(e.altKey && keys['n']){
         history.push('/notifications')
       }
       if(e.altKey && keys['a']){
         history.push('/analytics')
       }
       if(e.altKey && keys['s']){
         history.push('/service-requests')
       }
       if(e.altKey && keys['q']){
         history.push('/qrcode')
       }
       if(e.altKey && keys['l']){
         history.push('/call-log')
       }
   
   
     }
     window.onkeyup=(ev)=> {
       
       keys[ev.key]= false
     }
   
     return()=>{
       window.onkeydown=null
       window.onkeyup=null
     }
   },[]);
   useEffect(() => { window.scrollTo(0, 0) }, [])
useEffect(()=>{
    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

   
    
   
    axios.get(`${APIURL}/api/v1/hospital/care-programs/subscribers/${id}/`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   

      if(res.data.status==="success"){
      
     setList(res.data.data.care_programs);
     setName(res.data.data.full_name)
  

   
      

      }else{
      
      }


    })
    .catch(err=>{
  
 
})
 

 
},[refresh])

const handleSuspend= ()=> {
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

   let data= {
     suspend:true,
     patient_id:id,
     care_program_id:careId
   }

   axios.post(`${APIURL}/api/v1/hospital/care-programs/suspend/`,data , {headers: {

    'Content-Type': 'application/json',
      Authorization : 'Token '+str  }})

    .then(res=> {

      if(res.data.status==='success'){
        setSuspendShow(true)
      }
      else{
        setErrorShow(true)
      }
    })
    .catch((err)=> {
      setErrorShow(true)
    })
}

const handleCancel= ()=> {
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

   let data= {
     patient_cp_id: pgmId
   }

   axios.post(`${APIURL}/api/v1/hospital/cancel-care-program/`,data , {headers: {

    'Content-Type': 'application/json',
      Authorization : 'Token '+str  }})

    .then(res=> {

      if(res.data.status==='success'){
        setCancelShow(true)
        setRefund(res.data.refund_amount)
      }
      else{
        setErrorShow(true)
      }
    })
    .catch((err)=> {
      setErrorShow(true)
    })
}


const filteredList=list.filter((carepgm,index)=>{
  return (carepgm.is_subscribed===true)
})

let careId = filteredList.length>0 ? filteredList[0].id : null

let priceType=null;

const display= filteredList.length>0 ? filteredList.map((carepgm,index)=>{

  

  if(carepgm.pricing_type==="one_month"){
    priceType="1 month (Monthly)";
  }
   else if(carepgm.pricing_type==="three_months"){
    priceType="3 months (Qaurterly)";
  }
  else if(carepgm.pricing_type==="six_months"){
    priceType="6 months (Half-yearly)";
  }
  else if(carepgm.pricing_type==="one_year"){
    priceType="1 year (Yearly)";
  }
  else if(carepgm.pricing_type==="two_year"){
    priceType="Life time(2 years)";
  }

  const renderTable=(cellInfo)=> {


  return (<h6> {carepgm.subscriptions[cellInfo.index][cellInfo.column.id]}</h6>)
 
}
const renderTableDate=(cellInfo)=> {
    let dateString = carepgm.subscriptions[cellInfo.index][cellInfo.column.id];

let dateObject = new Date(dateString);

const updatedDate = dateObject.toString();

    return (<div >
      <h6> { dateString ? updatedDate.slice(4,21) : 'NIL' }</h6>
     </div>)


 
 
}
const renderPricing=(cellInfo)=> {


  return (<h6> {priceType}</h6>)
 
}

 const columns = [
{
  Header: "Pricing Plan",
  accessor: "pricing_type",
  Cell: renderPricing,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },

  minWidth:250,

   sortable:false

},
{
  Header: "Amount Paid",
  accessor: "total_paid",
 Cell: renderTable,
   sortable:false,
   width:130

},
{
  Header: "Valid From",
  accessor: "valid_from",
 Cell: renderTableDate,
   sortable:false,
   width:140

},
{
  Header: "Valid Until",
  accessor: "valid_till",
 Cell: renderTableDate,
   sortable:false,
   width:140

},
{
  Header: "Added by",
  accessor: "added_by",
 Cell: renderTable,
   sortable:false,
   width:140

}]



  return(
    <div className="flex-row-around">
     <div className="pgm-details-box">
    <div className="pgm-details-header box-theme">
                               

                      <h6 className="sub-section-title pgm-header-title"> Care Plan: {carepgm.title} </h6>
                      </div>
   <br/>
   <div className="care-pgm-details-body">
   
    <h6 className="features-form text-left">Standard Price: <span className="price-box"><span className="currency-uppercase"> {carepgm.currency? carepgm.currency : null }</span> {carepgm.price}</span></h6>
    <br/>
      <h6 className="features-form text-left">Discount Price : <span className="price-box"><span className="currency-uppercase">{carepgm.currency? carepgm.currency : null }</span> {carepgm.discounted_price}</span></h6>
      <br/>
      <h6 className="features-form text-left"> Price plan :<span className="price-box"> {priceType} </span></h6>


      <br/>
      <h6 className="features-form text-left"> Tax amount: <span className="price-box"> {carepgm.tax_amount ? carepgm.tax_amount  : "nil"} </span> </h6>
      

    </div>
    
   </div>


   <div> 
 <ReactTable 
         columns={columns}
         data={carepgm.subscriptions}
         defaultPageSize = {5}
         className="current-target-table"
         resizable
                           
                
         >

         </ReactTable> 

         <br/>
<br/>

  <button onClick={()=> {setModalShow(true);
                          setCareName(carepgm.title)}} className="btn btn-primary btn-col" > Renew </button> 
  <button onClick={()=> { setCancelConfirmShow(true); setPgmId(carepgm.patient_cp_id) }} className='btn btn-danger ml-2'>Cancel Subscription</button>
 </div>
<div className="divider"></div>
    </div>)

}
	 
   )  : <h6 className="text-muted">No subscriptions at the moment</h6>
  

const handleBack=(e)=>{
  history.goBack();
}

const modalClose = ()=> {
  setModalShow(false)
  setRefresh(!refresh)
}

const TenurePopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName='modal-70w'
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TenureUpdate id={id} modalStatus={modalClose} careId={careId} priceType={priceType}  careName={careName} patientPhn={patientPhn} patientName={patientName}/>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}

const ConfirmPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 className='text-danger'>Are you sure to Suspend this user ?</h4>
         
        </Modal.Body>
        <Modal.Footer>

           <Button variant="secondary" onClick={props.onHide}>No</Button>
           <Button variant="danger" onClick={props.onCall}>Yes</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const CancelConfirmPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 className='text-danger'>Are you sure to Cancel Subscription of this user ?</h4>
         
        </Modal.Body>
        <Modal.Footer>

           <Button variant="secondary" onClick={props.onHide}>No</Button>
           <Button variant="danger" onClick={props.onCall}>Yes</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const CancelPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 className='text-success'>Cancelled subscription to care plan successfully</h4>
        <h4 className='text-success'>Refund Amount : {refund==='0.00'?'Nil' : refund}</h4>

         
        </Modal.Body>
        <Modal.Footer>

           <Button variant="secondary" onClick={props.onHide}>Ok</Button>        
  
        </Modal.Footer>
      </Modal>
    )
}


const SuspendPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 className='text-success'>User suspended successfully</h4>
         
        </Modal.Body>
        <Modal.Footer>

           <Button variant="secondary" onClick={props.onHide}>Ok</Button>        
  
        </Modal.Footer>
      </Modal>
    )
}

const ErrorPopup =(props)=> {
  return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
          <h4 className="text-danger">Error in Data Submission. Please try again!</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
}

  return (
    <> <div className="subscriber-detailspage">
          <HospBdoNavbar />
          <br/>
          <div className="flex-row">
        
          <button className="btn btn-info topleft-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <h2 className=" title-of-tasks"> Care Plan Subscription details </h2>
          <button onClick={()=> setConfirmShow(true)} className='btn btn-danger topright-btn'>Suspend User</button>
         </div> 
          <br/>
         <div className="flex-row">
           <h6 className='name-contain'>Patient Name: {name}</h6>
          </div>

         <br/>
                
       
       
   

   {/***************************/}

     
                  
 
            
            <div className="flex-col">
         {display}
          </div> 









<br/>
<br/>

{ modalShow? 
<TenurePopup 
      show={modalShow}
      onHide={()=> setModalShow(false)}
      
    />

: null  }

{
        confirmShow ? 
        <ConfirmPopUp 
            show={confirmShow}
            onCall={() => { handleSuspend();  setConfirmShow(false) }}
            onHide={() => setConfirmShow(false) }/> : null
    }

{ suspendShow? 
<SuspendPopUp 
      show={suspendShow}
      onHide={()=> {setSuspendShow(false); history.push('/subscriberslist') }}
      
    />

: null  }

{
        cancelConfirmShow ? 
        <CancelConfirmPopUp 
            show={cancelConfirmShow}
            onCall={() => { handleCancel();  setCancelConfirmShow(false) }}
            onHide={() => setCancelConfirmShow(false) }/> : null
    }

{ cancelShow? 
<CancelPopUp 
      show={cancelShow}
      onHide={()=> {setCancelShow(false); history.push('/subscriberslist') }}
      
    />

: null  }

{
        errorShow ? 
        <ErrorPopup
        show={errorShow}
        onHide={()=> setErrorShow(false)} /> : null
      } 

</div>
    </>
  );
}
export default Subscription;
