import React from "react";
import graphic3 from "../../images/graphic3.svg";
import "./error_reg.css";
import { Link } from "react-router-dom";

function SuccessReg() {
  return (
    <div className="container page-container">
      <div className="error-msg">
        <h3>Sorry, there was some error in your registration !! </h3>
        <h4>Please try to Register again </h4>
        <p>
          {" "}
          Goto <Link to="/register">Register</Link>{" "}
        </p>

        <img src={graphic3} width="30%" height="auto" alt="error message"/>
      </div>
    </div>
  );
}

export default SuccessReg;
