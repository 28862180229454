import React, { useEffect, useState } from "react";
import axios from "axios";

import "./failedlabreportlist.css";
import { Link } from "react-router-dom";
import {APIURL} from "../../../Global";
function FailedReportList() {
  const [pendingDataList, setPendingDataList] = useState([]);

  useEffect(() => {
    const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

 

    axios
      .get(`${APIURL}/api/v1/staff/lab-reports/?status=failed`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.pending_verifications;

          setPendingDataList(datas);
        } else {

        }
      })
      .catch((err) => {

      });
  }, []);

  const tokenString = localStorage.getItem("usertoken");

  let v2 = tokenString.replace(
    /["]+/g,
    ""
  ); /* REGEX To remove double quotes from tokenstring */

 

  let dataDisplay = pendingDataList.map((data, index) => {
    let idval = data.report.id;
    let url = `/failed-report/${idval}`;
    return (
      <div className="list-item">
        <Link to={url}>
          {" "}
          <h5 className="each-list-link">
            {index + 1}. {data.patient.name} _ Report ID {data.report.id}{" "}
          </h5>
        </Link>
        <div className="details">
          <p> Patient Id: {data.patient.id} </p>
          <p> Date : {data.uploaded_at} </p>
        </div>
      </div>
    );
  });

  const handleRefresh = (e) => {
    e.preventDefault();

    axios
      .get(`${APIURL}/api/v1/staff/lab-reports/?status=failed`,
        { headers: { Authorization: "Token " + v2 } }
      )
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.pending_verifications;

          setPendingDataList(datas);
        } else {

        }
      })
      .catch((err) => {

      });
  };

  return (
    <div className="container">
      <div className="dashboard-navigation">
        <div>
          {" "}
          <Link to="/dashboard">
            {" "}
            <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
          </Link>
        </div>

        <div>
          {" "}
          <i className="fas fa-angle-double-right angle-icon"></i>{" "}
        </div>
        <div>
          {" "}
          <Link to="/failedreports"> Failed Report List </Link>{" "}
        </div>
      </div>

      <div className="container failedlist-parentcontainer">
        <h2 className="title-of-page">Failed Lab Report List</h2>

        <div className="failed-list-section">
          <div className="container">
            <div className="test-reports-display-list">
              {dataDisplay.length ? (
                dataDisplay
              ) : (
                <span style={{ color: "red", fontSize: "25px" }}>
                  No more Pending list to show!!
                </span>
              )}
              <div className="header-display">
                <button className=" btn btn-secondary" onClick={handleRefresh}>
                  Refresh List <i className="fas fa-sync-alt"></i>
                </button>
              </div>
            </div>

            {/*
      
      
                                <button type="submit" className="next-btn">Next</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default FailedReportList;
