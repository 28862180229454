import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";
import "./hospitalbdo.css";
import pic from "../../images/graphic5.svg";
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function HospitalBdo() {
  const { t } = useTranslation();
  const [notification, setNotification] = useState();
  const [serviceCount, setServiceCount] = useState();
  const [bdo_Data] = useState(
    JSON.parse(sessionStorage.getItem("bdodata"))
      ? JSON.parse(sessionStorage.getItem("bdodata"))
      : null
  );
  //const bdo_name= bdo_Data ? bdo_Data.name : null;
  /* const doctor_specializations = hospBdoData ? hospBdoData.doctor_specializations : null; */
  const department = bdo_Data ? bdo_Data.department : null;
  //const Id = bdo_Data ? bdo_Data.user_id : null;
  const QR_Id = bdo_Data ? bdo_Data.hospital_id : null;
  const QR_usertype = bdo_Data ? bdo_Data.user_type : "hospital";
  const hospitalName = bdo_Data ? bdo_Data.hospital_username : null;
  /*const specializations = hospBdoData ? hospBdoData.specializations : null;*/
  //const location = bdo_Data ? bdo_Data.location: null;
  const [modalShow, setModalShow] = useState(false);

  const history = useHistory();

  const [keys] = useState({
    u: false,
    c: false,
    n: false,
    a: false,
    s: false,
    q: false,
    l: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  useEffect(() => {
    window.onkeydown = (e) => {
      keys[e.key] = true;

      if (e.altKey && keys["u"]) {
        history.push("/directory");
      }
      if (e.altKey && keys["c"]) {
        history.push("/careplans-db");
      }
      if (e.altKey && keys["n"]) {
        history.push("/notifications");
      }
      if (e.altKey && keys["a"]) {
        history.push("/analytics");
      }
      if (e.altKey && keys["s"]) {
        history.push("/service-requests");
      }
      if (e.altKey && keys["q"]) {
        history.push("/qrcode");
      }
      if (e.altKey && keys["l"]) {
        history.push("/call-log");
      }
      if (e.altKey && keys["m"]) {
        history.push("/rental-requests");
      }
      if (e.altKey && keys["p"]) {
        history.push("/procedure-names-bdo");
      }
      if (e.altKey && keys["x"]) {
        history.push("/customer-service");
      }
    };
    window.onkeyup = (ev) => {
      keys[ev.key] = false;
    };

    return () => {
      window.onkeydown = null;
      window.onkeyup = null;
    };
  }, []);

  useEffect(() => {
    let qr_value = `${QR_Id} ${QR_usertype}`;
    sessionStorage.setItem("qr_string", JSON.stringify(qr_value));
    sessionStorage.setItem("hospital", JSON.stringify(hospitalName));
  }, [QR_Id, QR_usertype, hospitalName]);

  useEffect(() => {
    const tokenString = localStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/account/web-notification/?key=new`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const data = res.data.new_notifications;

          setNotification(data);
          setServiceCount(res.data.new_request_count);
        } else {
        }
      })
      .catch((err) => {});
  }, []);

  const ShortcutPopup = ({ onHide }) => {
    return (
      <Modal
        show
        onHide
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h4>Shortcuts</h4>
            <br />
            <span className="shortcut-class">alt+u </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("User Directory")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+c </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">
              {t("Care Plans Dashboard")}
            </span>
            <br />
            <br />
            <span className="shortcut-class">alt+n </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Notifications")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+a </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">
              {t("Analytics and Reports")}
            </span>
            <br />
            <br />
            <span className="shortcut-class">alt+s </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">
              {t("Service Fulfillment")}
            </span>
            <br />
            <br />
            <span className="shortcut-class">alt+q </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("QR Code")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+l </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Emergency Call Log")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+p </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Procedure Names")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+m </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">
              {t("Equipment fulfillment")}
            </span>
            <br />
            <br />
            <span className="shortcut-class">alt+x </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">
              {t("Customer Service Center")}
            </span>
            <br />
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {" "}
      <HospBdoNavbar />
      <div className="hospital-dashboard-container hosp-bdo-page">
        {" "}
        <br />
        <h1 className="additional-text ">
          {" "}
          {hospitalName ? hospitalName : null}{" "}
        </h1>
        <h4 className="additional-text text-bdo">
          {" "}
          {department !== "" && department !== null
            ? department + " Department"
            : null}
        </h4>
        {/*<HospitalJumbotron /> */}
        <div className="hospital-dashboard-container dr-dashboard">
          {/***
         <div className="jumbotron jumbotron-fluid  jumbo-div-bdo" >
            ***/}

          <div className=" jumbo-text-content bdo-tasks">
            <div className="flex-center ">
              <div
                onClick={() => history.push("/directory")}
                className="each-list-title col-lg-2 box-theme"
              >
                <i
                  className="fas fa-search task-icon"
                  id="task-icon-color"
                  aria-hidden="true"
                ></i>

                <h5 className="each-title">
                  {" "}
                  <h4>{t("User Directory")} </h4>
                </h5>
              </div>

              {/**************

           <div onClick={()=> history.push('/care-programs')} className="each-list-title col-lg-3 box-theme">
           <i className="fa fa-hospital task-icon" id="task-icon-color" aria-hidden="true"></i>
           
            <h5 className="each-title">

            
              {" "}

               <h4> Care Programs </h4>

            </h5>

           
          </div>
         *******/}
              <div
                onClick={() => history.push("/careplans-db")}
                className="each-list-title col-lg-2 box-theme"
              >
                <i
                  className="fa fa-hospital task-icon"
                  id="task-icon-color"
                  aria-hidden="true"
                ></i>

                <h5 className="each-title">
                  {" "}
                  <h4> {t("Care Plans Dashboard")} </h4>
                </h5>
              </div>

              <div
                onClick={() => history.push("/notifications")}
                className="each-list-title col-lg-2 box-theme"
              >
                {/*} <i className="fa fa-bell" id="notification-icon"  aria-hidden="true"></i>*/}
                {notification >= 1 ? (
                  <div
                    style={{ pointerEvents: "none" }}
                    className="notification"
                  >
                    <svg width="2.3em" height="2.3em" viewBox="0 0 36 36">
                      <path
                        className="clr-i-solid-doc--badged clr-i-solid-path-1--badged"
                        d="M18 34.28A2.67 2.67 0 0 0 20.58 32h-5.26A2.67 2.67 0 0 0 18 34.28z"
                        fill="red"
                      ></path>
                      <path
                        className="clr-i-solid-doc--badged clr-i-solid-path-2--badged"
                        d="M32.85 28.13l-.34-.3A14.37 14.37 0 0 1 30 24.9a12.63 12.63 0 0 1-1.35-4.81v-4.94a10.92 10.92 0 0 0-.16-1.79A7.5 7.5 0 0 1 22.5 6v-.63a10.57 10.57 0 0 0-3.32-1V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.3 10.73v4.94a12.63 12.63 0 0 1-1.35 4.81a14.4 14.4 0 0 1-2.47 2.93l-.34.3v2.82h29.8z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    <span
                      style={{
                        position: "absolute",
                        top: "-6px",
                        right: "-2px",
                      }}
                      className="badge"
                    >
                      {notification}
                    </span>
                  </div>
                ) : (
                  <svg width="2.3em" height="2.3em" viewBox="0 0 36 36">
                    <path
                      className="clr-i-solid-doc clr-i-solid-path-1"
                      d="M32.85 28.13l-.34-.3A14.37 14.37 0 0 1 30 24.9a12.63 12.63 0 0 1-1.35-4.81v-4.94A10.81 10.81 0 0 0 19.21 4.4V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.33 10.73v4.94a12.63 12.63 0 0 1-1.35 4.81a14.4 14.4 0 0 1-2.47 2.93l-.34.3v2.82h29.8z"
                      fill="currentColor"
                    ></path>
                    <path
                      className="clr-i-solid-doc clr-i-solid-path-2"
                      d="M15.32 32a2.65 2.65 0 0 0 5.25 0z"
                      fill="currentColor"
                    ></path>
                  </svg>
                )}
                <h5 style={{ marginTop: "35px" }} className="each-title">
                  {" "}
                  <h4> {t("Notifications")} </h4>
                </h5>
              </div>

              <div
                onClick={() => history.push("/analytics")}
                className="each-list-title col-lg-2 box-theme"
              >
                <i
                  className="fas fa-chart-bar"
                  id="task-icon-color"
                  aria-hidden="true"
                ></i>

                <h5 className="each-title">
                  {" "}
                  <h4> {t("Analytics & Reports")} </h4>
                </h5>
              </div>

              <div
                onClick={() => history.push("/service-requests")}
                className="each-list-title col-lg-2 box-theme"
              >
                {serviceCount >= 1 ? (
                  <div
                    style={{ pointerEvents: "none" }}
                    className="notification"
                  >
                    <i
                      className="fas fa-ambulance"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>
                    <span
                      style={{
                        position: "absolute",
                        top: "-6px",
                        right: "-2px",
                      }}
                      className="badge"
                    >
                      {serviceCount}
                    </span>
                  </div>
                ) : (
                  <i
                    className="fas fa-ambulance"
                    id="task-icon-color"
                    aria-hidden="true"
                  ></i>
                )}

                <h5 className="each-title">
                  {" "}
                  <h4> {t("Service Fulfillment")} </h4>
                </h5>
              </div>

              <div
                onClick={() => history.push("/rental-requests")}
                className="each-list-title col-lg-2 box-theme"
              >
                <i
                  className="fas fa-wheelchair"
                  id="task-icon-color"
                  aria-hidden="true"
                ></i>

                <h5 className="each-title">
                  {" "}
                  <h4> {t("Equipment fulfillment")} </h4>
                </h5>
              </div>

              <div
                onClick={() => history.push("/procedure-names-bdo")}
                className="each-list-title col-lg-2 box-theme"
              >
                <i
                  className="fas fa-edit"
                  id="task-icon-color"
                  aria-hidden="true"
                ></i>

                <h5 className="each-title">
                  {" "}
                  <h4> {t("Procedure Names")} </h4>
                </h5>
              </div>

              <div
                onClick={() => history.push("/qrcode")}
                className="each-list-title col-lg-2 box-theme"
              >
                <i className="fas fa-qrcode task-icon" id="task-icon-color"></i>
                <h5 className="each-title">
                  {" "}
                  <h4>{t("QR Code")} </h4>
                </h5>
              </div>

              {/* <div onClick={()=> history.push('/payments')} className="each-list-title col-lg-2 box-theme">

       
<i className="fab fa-paypal" id="task-icon-color" aria-hidden="true"></i>

 <h5 className="each-title">

 
   {" "}

    <h4> Payment </h4>

 </h5>


</div> */}

              {/* <div onClick={()=> history.push('/procedure-fulfillment')} className="each-list-title col-lg-2 box-theme">
          <i className="fas fa-ambulance" id="task-icon-color" aria-hidden="true"></i>
          
            <h5 className="each-title">

            
              {" "}

               <h4> Procedure Fulfillment </h4>

            </h5>

           
          </div> */}

              <div
                onClick={() => history.push("/call-log")}
                className="each-list-title col-lg-2 box-theme"
              >
                <i className="fas fa-list-ul" id="task-icon-color"></i>
                <h5 className="each-title">
                  {" "}
                  <h4> {t("Emergency Call Log")} </h4>
                </h5>
              </div>

              <div
                onClick={() => history.push("/customer-service")}
                className="each-list-title col-lg-2 box-theme mb-0"
              >
                <i className="fas fa-handshake" id="task-icon-color"></i>

                <h5 className="each-title">
                  {" "}
                  <h4> {t("Customer Service Center")}</h4>
                </h5>
              </div>

              {/* <div onClick={()=> history.push('/procedure-requests-bdo')} className="each-list-title col-lg-2 box-theme">
   <i className="fas fa-plus-square" id="task-icon-color" aria-hidden="true"></i>
   
     <h5 className="each-title">

     
       {" "}

        <h4> Procedure Fulfillment </h4>

     </h5>

    
   </div>  */}
            </div>
            <br />
            <p className="short-align" title="shortcuts">
              <i
                onClick={() => setModalShow(true)}
                className="fas fa-map-signs"
              ></i>
            </p>

            <div className="divider"></div>

            <div className="row-jumbo-content">
              <div className="illustration-bdo">
                <img src={pic} width="350px" height="auto" alt="illustration" />
              </div>

              {/*}


              <div className="captions">
            */}

              {/*  <h3 className="title-bdo">  {bdo_name} </h3>
                  <h5 className="additional-text">  BDO ID: {Id} </h5>
                   <h5 className="additional-text">  {department} Department,</h5> */}

              {/*  <h2 className="title-bdo">  Analytics & Reports </h2>
                  <h4 className="additional-text text-bdo">  {hospitalName} Hospital</h4><br/>
                  <Link to="/analytics"><button className="btn btn-info" > Goto Analytics </button></Link>
                  
                 
              </div>
            */}
            </div>
          </div>
        </div>
        {/*<h2 className="title-of-lists title-of-tasks"> My Tasks </h2>   */}
        <div className="patients-section">
          {/*
<div className="search-form-div">

<form >
          <div className="search-div">
            <input
              className="form-control search-input"
              type="text"
              placeholder="Search Patient Name/phone"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="submit" className="btn btn-primary btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </button>
          </div>
        </form>  

</div>
*/}
        </div>
        <br />
        <br />
        {/*

<div className="illustration">
<img src={pic} width="400px" height="auto" alt="illustration"/> 
</div>

*/}
      </div>
      {modalShow ? (
        <ShortcutPopup
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
      ) : null}
    </>
  );
}
export default HospitalBdo;
