import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function AddFeature(props) {

    const[data, setData]= useState({
        title:'',
        type:{ value:'', label:'' },
        unit:{ value:'', label:'' },
        request: null,
        active: null
    })

    const [error,setError]=useState({
      title:"",
      type: '',
      unit:"",
      service:"",
      active:""
     });

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)

    const serviceOptions = [{ value:'general', label:'General' },
    { value:'service_request', label:'Service Request' },
    { value:'rental', label:'Equipment Request' }
]

    const unitOptions = [{ value:'days', label:'Days' },
    { value: 'count', label: 'count' }
]

    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>Added Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , title: val
        }))
      }

      const handleServiceChange= (data) => {

        setData( current => ({
            ...current , type: data
        }))
   }

   const handleUnitChange= (data) => {

    setData( current => ({
        ...current , unit: data
    }))
  }

  const handleRequest = (e)=> {
   const val = e.target.value
   const key = e.target.name

   setData((current) => ({
       ...current , [key] : val==='true'? true : val==='false'? false : null
   }))

  }


  const validate=()=>{
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["title"] || input["title"]==="" ) {
      isValid = false;
      errors["title"] = "Please enter Feature Name";
    }
    if (!input["type"] || input["type"].value==="" ) {
      isValid = false;
      errors["type"] = "Please select a Service Type";
    }
    if (!input["unit"] || input["unit"].value==="" ) {
      isValid = false;
      errors["unit"] = "Please select a Service Unit";
    }
    if (input["request"]===null ) {
      isValid = false;
      errors["service"] = "Please select on request service";
    }
    if (input["active"]===null ) {
      isValid = false;
      errors["active"] = "Please select active feature";
    }

     setError(currentstate=>({
          ...currentstate,
          ...errors
        }));
    

    return isValid;
}


  const saveChanges = (e)=> {
    e.preventDefault();

    if (validate()){
      const tokenString = localStorage.getItem("usertoken");
 
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
  
   const dataToSend = {
      title2 : data.title,
      service_type : data.type.value,
      service_unit : data.unit.value,
      on_request_service : data.request,
      is_active : data.active
   }
  
      axios
        .post(`${APIURL}/api/v1/staff/update-cp-services/`, dataToSend , {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            
            setSuccessShow(true)
          } else {
            setErrorShow(true)
          }
        })
        .catch((err) => {
          setErrorShow(true)
        });
    }
    

}


    return (
        <>
    <h2 style={{marginTop:'0'}} className="title-of-page">Add Feature</h2>
        <div  style={{maxHeight:'1000px'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
            <div className='flex-col justify-feature'>
                <span className='align-rental'>Feature Name : </span>
                <input type='text' className='form-control title-input' name='title2' value={data.title2} onChange={handleTitle} />
                {error.title ? <div className="error-validation-msg error-feature">{error.title}</div> : null}
            </div>
            <div className='flex-row name-content'>
                <span className='align-rental'>Service Type : </span>
                <Select
                            value={data.type}
                            onChange={(data,e)=>{handleServiceChange(data,e)
                            }}
                            options={serviceOptions}
                            name='service_type'
                            className="select-currency select-flex"
                        />
                        {error.type ? <div className="error-validation-msg error-feature">{error.type}</div> : null}
            </div>

            <div className='flex-row name-content'>
                <span className='align-rental'>Service Unit : </span>
                <Select
                            value={data.unit}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={unitOptions}
                            name='service_unit'
                            className="select-currency select-flex"
                        />
                        {error.unit ? <div className="error-validation-msg error-feature">{error.unit}</div> : null}
            </div>
            <div className='feature-data pad-features'>
            <div className='flex-col justify-feature'>
                <span className='align-rental'>On Request Service : </span>

                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-input">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='request'  value= {true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='request'  value= {false}  onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
                {error.service ? <div className="error-validation-msg error-feature">{error.service}</div> : null}
            </div>
            

            <div className='feature-data pad-features'>
            <div className='flex-col justify-feature'>
                <span className='align-rental' >Active Feature : </span>
                
                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-input">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='active'  value= {true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='active'  value= {false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
                {error.active ? <div className="error-validation-msg error-feature">{error.active}</div> : null}
            </div>
            </div>
            </div>
            </div>
            <br/>
            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col'>Save</button>
                
            </div>
            
            <br/>
            {successShow ? 
<SuccessPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false); props.modalClose(); }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
            
        </div>
        </>
    )
}

export default AddFeature
