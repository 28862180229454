import React, { useEffect,useState} from "react";
import Chart from "chart.js";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {useHistory} from "react-router-dom";


function TestsDetails(props) {
  
  const [test,]=useState(props.location.state.name);
 const [arrayData,]=useState(props.location.state.arrayData)
 const [testArray,setTestArray]=useState([]);
 const [datesArray,setDatesArray]=useState([]);  
   const [testList,setTestList]=useState([]);
   const [show,setShow]=useState(false);
    let history=useHistory();

useEffect(()=>{
  setTestList(arrayData);
  let dates= arrayData.map((item)=>{
    return item.date.slice(0,10)

  })
  let Tvalues= arrayData.map((item)=>{
    return item.value

  })
  let isValid=true;
  
  Tvalues.map((item,index)=>{
      
       
        if(isNaN(Number(item))){
        isValid=false
      
       }
     

  })

  if(isValid===true){
    setShow(true);
     setTestArray(Tvalues);
  setDatesArray(dates);
  }
  else{
    setShow(false);
  }
 
},[])
 

  useEffect(() => {
    if(datesArray.length>0 && testArray.length>0){
    const ctx = document.getElementById("chart-bp");
    new Chart(ctx, {
      type: "line",
      data: {
        labels:datesArray ,
        datasets: [
          {
            label: test,
            data: testArray,
            backgroundColor: 'rgba(54, 162, 235, 0.9)',
              
              
              pointBorderWidth:2,
              pointHoverRadius: 5,
            fill: false,
            borderColor: 'rgba(54, 162, 235, 0.9)',

           
          }
        ],
        tooltips: {
          backgroundColor: 'purple',
             
              

        }
      },

      options:{
        scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Test Values'
          },

        ticks: {
          beginAtZero: false,
          stepSize: 10,
         
          padding: 20,
        },
        }],
        xAxes:[{
          scaleLabel: {
          display: true,
            labelString: 'Date'
          }
        }
        ]
      },
          legend:{
          position:'right',
          labels:{
            fontColor: '#000'
          }
        }
      }
    });
  }
  },[testArray,datesArray,show===true]);


  










const renderTest=(cellInfo)=> {
  return(<h6> {testList[cellInfo.index][cellInfo.column.id]} </h6>)
}
const renderDate=(cellInfo)=> {
  return(<h6> {testList[cellInfo.index][cellInfo.column.id].slice(0,10)} </h6>)
}


const columnsTest= [
{
  Header: "Date",
  accessor: "date",
  Cell: renderDate,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width: 120,
  minWidth:50,
   
},
{
  Header: "Value",
  accessor: "value",
  Cell: renderTest,
  
  
  minWidth:50,
   
}]
const Test_Table = <ReactTable 
         columns={columnsTest}
         data={testList}
         defaultPageSize = {10}
         
         resizable
                           
                 
         >

         </ReactTable> 

const handleBack=(e)=>{
  history.goBack();
}
  return (
 

    <>
     <div className="food-habits-page">
     <div className="food-habits-header">
    <h2 className="title-of-tasks"> {test} : Test Analysis </h2>
    <div className=" text-left">
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> 
         {/* <h3 className=""> Patient  ID : {patientId} </h3><br/><br/> */}


</div>

<div className="food-habits-sections">

  {show? 
   <div>
   <h5 className="title-of-lists"> Data Graph </h5>
    <div className="chart-div-glucose">
      
      <canvas id="chart-bp" className="chart-box" />

      
    </div>
    </div>
    : null}

     <div className="food-table">
     <h5 className=" title-of-lists"> Data Table</h5>
    {testList.length>0 ?
     <>
    

 
     
   {Test_Table}
     </> 
     : <h6 style={{color: "red"}}> No data to show! </h6>
   }
     </div>
   
     </div>
     </div>
     </>
  );
}

export default TestsDetails;