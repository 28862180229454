import React, { useState, createContext } from "react";

export const SubscribersContext = createContext();

export const SubscribersProvider = (props) => {
  const [refresh,setRefresh] = useState(false);
   
  return (
    <SubscribersContext.Provider value={[refresh,setRefresh]}>
      {props.children}
    </SubscribersContext.Provider>
  );
};
