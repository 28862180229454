import React, { Component } from "react";
import ContentEditable from "react-contenteditable";

class EditableRowElement extends Component {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.state = {
      index: props.index,

      syn: props.syn,
      synonyms: props.synonyms,
    };
  }
  render() {
    const handleChange = (e) => {
      const newarray = synonyms;

      if (e.target.value !== "") {
        newarray[index] = e.target.value;
      } else {
        newarray.splice(index, 1);
      }
      this.setState({ synonyms: newarray });

      this.setState({ syn: e.target.value });
    };
    const { index, syn, synonyms } = this.state;
    return (
      <ContentEditable
        innerRef={this.contentEditable}
        html={syn} // innerHTML of the editable div
        disabled={false} // use true to disable editing
        onChange={handleChange}
        style={{
          backgroundColor: "rgba(0,0,0,0.1)",
          padding: "8px 15px",
          marginBottom: "10px",
          width: "auto",
          marginRight: "5px",
        }}
      />
    );
  }
}

export default EditableRowElement;
