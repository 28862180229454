import React,{useState,useEffect} from 'react';
import "./displayreportandheadings.css";
import {Link,useHistory} from 'react-router-dom';
import ReportImageDisplay from "../Headings/ReportImageDisplay";
import AddHeadingsToReport from "../AddHeadingsToReport/AddHeadingsToReport";
import RejectReport from "../RejectReport/RejectReport";
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import Select from 'react-select';
import {APIURL} from "../../../Global";
function DisplayReportAndHeadings(props) {
  
         const [parentUrl,] = useState(props.location.parentUrl);
     const [parentName,] = useState(props.location.parentName);
 const [docType,setDocType]=useState();
 const [modalShow,setModalShow]=useState(false);
  const [submitmsg,setSubmitmsg]=useState("");
  const [submitErrorMsg,setSubmitErrorMsg]=useState("");
   const [modalErrorShow,setModalErrorShow]=useState(false);
     const history =useHistory();

     const [keys,] = useState({'h': false,'d': false,'u': false,'r': false,'l': false,'i': false,'a': false,'p': false,'v': false,'t': false,'n': false});
     
     
     useEffect(()=>{
     
     window.onkeydown =(e) => {
      
      keys[e.key]=true
     
      if(e.altKey && keys['h']){
        history.push('/hospitallist')
      }
      if(e.altKey && keys['d']){
        history.push('/doctorslist')
      }
      if(e.altKey && keys['u']){
        history.push('/search-hospitals')
      }
      if(e.altKey && keys['r']){
        history.push('/rejectedlist')
      }
      if(e.altKey && keys['l']){
        history.push('/reportlist')
      }
      if(e.altKey && keys['i']){
        history.push('/addsynonyms')
      }
      if(e.altKey && keys['a']){
        history.push('/failedlist')
      }
      if(e.altKey && keys['p']){
        history.push('/prescriptionlist')
      }
      if(e.altKey && keys['v']){
        history.push('/written-prescriptionlist')
      }
      if(e.altKey && keys['t']){
        history.push('/testlist')
      }
      if(e.altKey && keys['n']){
       history.push('/nutrition')
     }
     
     }
     window.onkeyup=(ev)=> {
      
      keys[ev.key]= false
     }
     
     return()=>{
      window.onkeydown=null
      window.onkeyup=null
     }
     },[]);
    

const moveOptions = [      {value: 'lab_report' , label: 'Lab Report'},
                            {value: 'prescription' , label: 'Prescription'},
                           {value: 'hand_written_prescription' , label: 'Handwritten Prescription'},
                          {value: 'discharge_summary' , label: 'Discharge Summary'},
                          {value: 'misc' , label: 'Miscellaneous'},
                          {value: null , label: <span className="text-muted">Cancel</span>}

      
       
    ];

const handleMove=(data)=>{
  
  setDocType(data);

}

const handleMoveSubmit=async (e)=>{
  e.preventDefault();
  const tokenString= localStorage.getItem('usertoken');
   let dataToPost={
    key: docType.value,
    move_from: "header_issue_lab_report",
    report_id: parseInt(props.match.params.id)
   }
   let str= tokenString.replace(/["]+/g, '') ;

       axios.post(`${APIURL}/api/v1/patient/upload/`,dataToPost , { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str }})
  .then(res => {
    if(res.data.status==="success"){
  
  
    
   
  
    setSubmitmsg(res.data.message? res.data.message : 'Document moved successfully!');
   setModalShow(true);

  }
  else {
   
     setSubmitErrorMsg(res.data.message? res.data.message : "Error in submission");
    setModalErrorShow(true);
    
  }
}).catch(err=>{
   setSubmitErrorMsg( "Error in submission");
    setModalErrorShow(true);
})


}

const SubmitPopUp=(props)=>{
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{submitmsg}</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/prescriptionlist"><Button className='btn btn-primary btn-col' > Headers issue Lab report list </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}


const ErrorSubmitPopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {submitErrorMsg}</h5>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
      </Modal.Footer>
    </Modal>
  );
}


return (
     <div className="">
     <div className="dashboard-navigation">
        <div> <Link to="/dashboard"> <i className="fas fa-home dasboard-icon"> </i>Dashboard </Link></div>
       
       <div> <i className="fas fa-angle-double-right angle-icon"></i> </div>
        <div> <Link to="/addsynonyms">HeadingsMismatch Report List </Link> </div>
        <div> <i className="fas fa-angle-double-right angle-icon"></i> </div>
        <div> Add Synonyms </div>
     </div>
      
     <h2 className="title-of-page">Headings Mismatch - Add Synonyms </h2>
    
  <div className="failedlist-parent-container">
  
     
   
       
       
       
          <ReportImageDisplay id={props.match.params.id} parentURL={parentUrl} parentName={parentName}/>
       
       


        
      
  

    

<div className="seperator"> </div>
<div className="bottom-section-buttons">
   <RejectReport {...props} />
  <div className=" flex-row move-select"><span style={{color:"grey"}}>Move to : </span>
    <Select
        value={docType}
        onChange={(data)=>{handleMove(data)
            }}
        options={moveOptions}
        className="select-move"
      /> 
       <button className=" btn btn-primary btn-col " type="submit" onClick={handleMoveSubmit}>Move Document </button>
      
      </div><br/>
   <AddHeadingsToReport id={props.match.params.id} parentURL={parentUrl} parentName={parentName}/>
   
</div>
   </div>
  

   {submitmsg!=="" ? 

          <SubmitPopUp
        show={modalShow}
        onHide={() => {setModalShow(false)
                      setSubmitmsg("")}}
          msg={submitmsg}
                />

           : null}



      {submitErrorMsg!== "" ? 

          <ErrorSubmitPopUp
        show={modalErrorShow}
        onHide={() => {
             setModalErrorShow(false);
             setSubmitErrorMsg("")}}
          msg={submitErrorMsg}
      />
      : null}
  </div>
    
   
    
  );
  
}

export default DisplayReportAndHeadings;