import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link,useHistory } from "react-router-dom";
//import Pagination from "react-js-pagination";
//import "./pagination.css";
import { Dropdown ,Tooltip,OverlayTrigger } from 'react-bootstrap';
import {APIURL} from "../../../Global";

function RejectedUsers() {

    const [rejected ,setRejected] =useState([]);
    const [rejectedDoc ,setRejectedDoc] =useState([])
    const [filter ,setFilter] =useState('All')

    const history =useHistory();

const [keys,] = useState({'h': false,'d': false,'u': false,'l': false,'i': false,'a': false,'p': false,'v': false,'t': false,'n': false});


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['h']){
   history.push('/hospitallist')
 }
 if(e.altKey && keys['d']){
   history.push('/doctorslist')
 }
 if(e.altKey && keys['u']){
   history.push('/search-hospitals')
 }
 if(e.altKey && keys['l']){
   history.push('/reportlist')
 }
 if(e.altKey && keys['i']){
   history.push('/addsynonyms')
 }
 if(e.altKey && keys['a']){
   history.push('/failedlist')
 }
 if(e.altKey && keys['p']){
   history.push('/prescriptionlist')
 }
 if(e.altKey && keys['v']){
   history.push('/written-prescriptionlist')
 }
 if(e.altKey && keys['t']){
   history.push('/testlist')
 }
 if(e.altKey && keys['n']){
  history.push('/nutrition')
}

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);

let resultDisplay=null; 
let hospDisplay=null;
let doctorDisplay=null;   

    useEffect(()=>{
        const tokenString = localStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
            
        axios.get(`${APIURL}/api/v1/staff/hospital-approve/?status=rejected`, {
             headers: { Authorization: "Token " + v2 },
        })
        .then((res) =>{
            if(res.data.status==='success'){
                setRejected(res.data.hospitals)
            }
            else{

            }
        })
        .catch((err) => {

        })

    },[])

    useEffect(()=>{
      const tokenString = localStorage.getItem("usertoken");

      let v2 = tokenString.replace(
          /["]+/g,
          ""
        );
          
      axios.get(`${APIURL}/api/v1/staff/doctor-approve/?status=rejected`, {
           headers: { Authorization: "Token " + v2 },
      })
      .then((res) =>{
          if(res.data.status==='success'){
              setRejectedDoc(res.data.doctors)
          }
          else{

          }
      })
      .catch((err) => {

      })

  },[])

  const hospList = ()=> {
    const tokenString = localStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
            
        axios.get(`${APIURL}/api/v1/staff/hospital-approve/?status=rejected`, {
             headers: { Authorization: "Token " + v2 },
        })
        .then((res) =>{
            if(res.data.status==='success'){
                setRejected(res.data.hospitals)
            }
            else{

            }
        })
        .catch((err) => {

        })
  }

  const docList = ()=> {
    const tokenString = localStorage.getItem("usertoken");

      let v2 = tokenString.replace(
          /["]+/g,
          ""
        );
          
      axios.get(`${APIURL}/api/v1/staff/doctor-approve/?status=rejected`, {
           headers: { Authorization: "Token " + v2 },
      })
      .then((res) =>{
          if(res.data.status==='success'){
              setRejectedDoc(res.data.doctors)
          }
          else{

          }
      })
      .catch((err) => {

      })
  }

    const handleRefresh = () => {
        const tokenString = localStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
   
        axios
          .get(
            `${APIURL}/api/v1/staff/hospital-approve/?status=rejected`,
            { headers: { Authorization: "Token " + v2 } }
          )
          .then((res) => {
            if (res.data.status === "success") {
                setRejected(res.data.hospitals)
            } else {
              
            }
          })
          .catch((err) => {
                  });

                  axios.get(`${APIURL}/api/v1/staff/doctor-approve/?status=rejected`, {
                    headers: { Authorization: "Token " + v2 },
               })
               .then((res) =>{
                   if(res.data.status==='success'){
                       setRejectedDoc(res.data.doctors)
                   }
                   else{
         
                   }
               })
               .catch((err) => {
         
               })

      }
    

    const dataDisplay= rejected.length > 0 ? rejected.map((item, index)=>{
        return(
            <div className='list-item'>           
          {" "}
    <h5 className="each-list-link">
       {index + 1}.{item.name}{" "}<OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          Email: {item.email}<br/>
          Phn: {item.mobile_number}<br/>
          Location: {item.location}
          </Tooltip> }><i style={{color:'green'}} className="fas fa-info-circle"></i></OverlayTrigger>{" "}
    </h5>
    <p className='detail-style'>ID : {item.id}</p>
  
      </div>
        )
    }) : null

    const docDisplay =rejectedDoc.length > 0 ? rejectedDoc.map((item, index)=>{
      return(
          <div className='list-item'>           
        {" "}
  <h5 className="each-list-link">
     {index + 1}.{item.name}{" "}<OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          Email: {item.email}<br/>
          Phn: {item.mobile_number}<br/>
          DOB: {item.dob}
          </Tooltip> }><i style={{color:'green'}} className="fas fa-info-circle"></i></OverlayTrigger>{" "}
  </h5>
  <p className='detail-style'>ID : {item.id}</p>

    </div>
      )
  }) : null


  resultDisplay= <>{rejected.length>0 ?
    <div >
    <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing rejected </p>{'  '}
     <h3 style={{display:'inline-block'}} className=" additional-text">Hospitals</h3>
     </div> : null}
  {
    rejected.length>0 ?
      dataDisplay:null
  }
  {rejectedDoc.length>0 ?
    <div >
    <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing rejected </p>{'  '}
     <h3 style={{display:'inline-block'}} className=" additional-text">Doctors</h3>
     </div> : null}
     {
    rejectedDoc.length>0?
      docDisplay:null
  }
  {filter==='All'? rejected<1 && rejectedDoc<1 && <h6 style={{color:"red"}}>No rejected Users found</h6> :null}
  
  </>

  hospDisplay = <>{rejected.length>0 ?
    <div >
    <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing rejected </p>{'  '}
     <h3 style={{display:'inline-block'}} className=" additional-text">Hospitals</h3>
     </div> : null}
  {
    rejected.length>0 ?
      dataDisplay:null
  }
  {filter==='Hospital'? rejected<1 && <h6 style={{color:"red"}}>No rejected hospitals found</h6> :null}
  </>

  doctorDisplay = <>{rejectedDoc.length>0 ?
    <div >
    <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing rejected </p>{'  '}
     <h3 style={{display:'inline-block'}} className=" additional-text">Doctors</h3>
     </div> : null}
     {
    rejectedDoc.length>0?
      docDisplay:null
  }
  {filter==='Doctor'? rejectedDoc<1 && <h6 style={{color:"red"}}>No rejected doctors found</h6> :null}
  </>

    return (
        <div className="container">
      <div className="dashboard-navigation">
        <div>
          {" "}
          <Link to="/dashboard">
            {" "}
            <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
          </Link>
        </div>

        <div>
          {" "}
          <i className="fas fa-angle-double-right angle-icon"></i>{" "}
        </div>
        <div>
          {" "}
          <Link to="/rejectedlist"> Rejected User List</Link>{" "}
        </div>
      </div>

      <div className="container failedlist-parentcontainer">
        <h2 className="title-of-page">Rejected Users List</h2>
 
       <div className="buttons-wrapper">
          <button className="btn btn-secondary" onClick={handleRefresh}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button>
          
          <Dropdown>


<Dropdown.Toggle variant="secondary" id="dropdown-basic">
  {filter}
</Dropdown.Toggle>

<Dropdown.Menu>
  <Dropdown.Item onClick={()=>{handleRefresh(); setFilter("All")}}>All</Dropdown.Item>
  <Dropdown.Item onClick={()=>{docList(); setFilter("Doctor")}}>Doctor</Dropdown.Item>
  <Dropdown.Item  onClick={()=>{hospList(); setFilter("Hospital")}}>Hospital</Dropdown.Item>


</Dropdown.Menu>
</Dropdown>



</div>


        <div className="failed-list-section">
          <div className="container">
            <div className="test-reports-display-list">
              {filter==='All' ? (
                resultDisplay
              ) : filter==='Hospital'?(
                hospDisplay
              ) : filter==='Doctor'?(
                doctorDisplay
              ) : (
                <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
              <h4>No Rejected Users to show!!</h4>
            </div>
              )}
             
              
            </div>

          </div>
        </div>
      </div>
    </div>
    )
}

export default RejectedUsers
