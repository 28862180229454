import React, {useState} from 'react'
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import axios from "axios";
import GenerateInvoice from "../GenerateInvoice/GenerateInvoice"
function TenureUpdate(props) {

    const [tenure ,setTenure]= useState(1)
    const [successShow ,setSuccessShow]= useState(false);
    const [errorShow ,setErrorShow]= useState(false);
    const [errorMsg ,setErrorMsg]= useState('');
    const [successMsg ,setSuccessMsg]= useState('')
    const [careId,]=useState(props.careId);
        const [careName,]=useState(props.careName);
    const [patientId,]=useState(props.id);
 const [patientName,]=useState(props.patientName);
    const [patientPhn,]=useState(props.patientPhn);
    const [pricingType,]= useState(props.priceType)
        const [invoiceShow,setInvoiceShow]=useState(false)


    const handleSelect = () => {

        const tokenString= localStorage.getItem('usertoken');
       
       let str= tokenString.replace(/["]+/g, '');

       const dataToSend={
        patient_id: props.id,
        care_program_id: props.careId,
        tenure:tenure
       }
     
        axios.post(`${APIURL}/api/v1/hospital/care-programs/subscribers/manage/`,dataToSend, { headers: {
          
          "Authorization" : 'Token '+str,
          'Content-Type': 'application/json'  }
    
    
        })
    
        .then(res=>{
         
          if(res.data.status=== "success"){
         
            setSuccessShow(true);
            setSuccessMsg(res.data.message);
            
          }
          else{
           setErrorShow(true)
           if(res.data.message==='Already subscribed'){
               setErrorMsg('Please Renew after some Days')
           }
           else {
            setErrorMsg(res.data.message? res.data.message : "Error in submission")
           }
           
          }
        })
        .catch(err=>{
            setErrorShow(true)
        
    
    })


    }

    const handleSuccess = ()=> {
        setSuccessShow(false)
        props.modalStatus()
        //let toggle = !props.refresh
        //props.modalToggle(toggle)
    }

    const handleChange =(e)=> {
      const val =e.target.value
      if(val.length<=2){
        setTenure(val)
      } else{
        return false;
    } 
  }

    const SuccessPopup =(props)=> {
        return(
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                 backdrop="static"
                  keyboard={false}
                   dialogClassName='modal-90w'
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="tenure-content">

           {invoiceShow?
           

   <div className="invoice-holder">
                <GenerateInvoice careId={careId} careName={careName} tenure={tenure} patientId={patientId} name={patientName} phn={patientPhn}/>
          <br/>
                            <div className="divider"></div>
                            <div className="text-right"><button  className="btn btn-primary btn-col btn-small text-right" onClick={()=>setInvoiceShow(false)}>X Close</button>
                </div>
                <br/>
    
                
               </div>
               : 
              <div>
              <h5 className="text-success">{successMsg!==''?successMsg : <h5 className="text-danger">Renewed successfully!</h5>}</h5>
                  <button style={{}} className="btn btn-danger btn-small btn-square" onClick={()=>setInvoiceShow(true)}>Generate Invoice</button>
          </div>
           }
                 </div>   
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
          
                </Modal.Footer>
              </Modal>
            )
    }

    const ErrorPopup =(props)=> {
        return(
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                 backdrop="static"
                  keyboard={false}

              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
        
               <h5 className="text-danger">{errorMsg!==''?errorMsg : <h5 className="text-danger">Error in Data Submission. Please try again!</h5>}</h5>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
          
                </Modal.Footer>
              </Modal>
            )
    }

    return (
        <>
            <div className="hospital-dashboard-container hosp-bdo-page">      
                <h2 style={{textAlign:'center'}} className="title-bdo"> Renew Subscription </h2><br/>

                <div className="flex-display">

            
              <div className='flex-row'>
                <h5 className="text-muted font-weight-bold">Select Period :</h5><br/>
                <input type='number' style={{height:'30px', width:'80px',marginLeft:'10px'}} min='1'max='20' value={tenure} onChange={handleChange}></input>
                <h5 className="care-period">{pricingType==='1 month (Monthly)'? 'Month': pricingType==='3 months (Qaurterly)'? 'Quarter': pricingType==='6 months (Half-yearly)'? 'Half Year': pricingType==='1 year (Yearly)'? 'Year': pricingType==='Life time(2 years)'? 'Year': 'Month' }</h5>
                
               </div> 
               <br/>
               <button  className="btn btn-primary btn-col btn-small btn-square ml-2" onClick={handleSelect}>Renew/Extend</button>
            </div>
              


                {
        successShow ? 
        <SuccessPopup
        show={successShow}
        onHide={handleSuccess} /> : null
      } 

{
        errorShow ? 
        <ErrorPopup
        show={errorShow}
        onHide={()=> setErrorShow(false)} /> : null
      } 

            </div>
        </>
    )
}

export default TenureUpdate
