import React,{useState,useEffect} from "react";
import {Link,useHistory} from "react-router-dom";
import "./careprograms.css";
import axios from "axios";
import {APIURL} from "../../Global";

function CareProgramsList() {
	
    const [careList,setCareList]=useState([]);
    const [loading,setLoading]=useState(true);
   let history=useHistory();
   useEffect(() => { window.scrollTo(0, 0) }, [])
useEffect(()=>{
    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

   
    
   
    axios.get(`${APIURL}/api/v1/hospital/care-programs/`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   
 setLoading(false);
      if(res.data.status==="success"){
      
     setCareList(res.data.care_programs);

    

   
      

      }else{
      
      }


    })
    .catch(err=>{
  
 
})
 

 
},[])


	 
  const newList= loading===true ?
  <h6 className="text-muted">Loading...</h6> 
  : 
  careList.length> 0 ? careList.map((care,index)=>{
    let cp_url= `care-program/${care.id}`;
  return(<span className="outer-box-name">
     <Link to={{
                   pathname:cp_url,

                   

                   }}>
                     <div className={care.is_obselete===true ?  " each-list-title box-care-pgm-obsolete" : care.is_active===false ? " each-list-title box-care-pgm-inactive " : "each-list-title box-care-pgm-2" }>
     
           
            <h5 className="each-title">


              {" "}
             <span className="content-inside">
                <h5>{care.title} </h5>
                <h6 style={{fontWeight:'bold'}}>ID : {care.id} {care.is_obselete===true ? <span style={{color:"red"}}>Obsolete</span> : care.is_active===false ? ", Inactive" : null} </h6>
                  </span>
              
                
            </h5>
            </div> </Link></span>
)
}) : <h6 className="text-muted">Care plans list is empty</h6> ;

  
  return (
    <> 
       
   

   {/***************************/}

     <div className="flex-row">
                     
            <div className=" jumbo-text-content bdo-tasks">
                  
            <div className="container flex-center ">


{newList}

</div> 



</div>






<br/>
<br/>


</div>

    </>
  );
}
export default CareProgramsList;
