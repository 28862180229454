import React,{useState,useEffect} from "react";
import {Link} from "react-router-dom";
import {APIURL} from "../../Global";
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import "./webnotifications.css";
import InfiniteScroll from 'react-infinite-scroller';
let dataList=[];
function WebNotifications({baselink}) {


const [hasMore,setHasMore] =useState(true);
const [errorMsg,setErrorMsg]=useState("");
const [,setPage]=useState(1);
const [counter,setCounter]=useState(0);
const [total,setTotal]=useState(10);
const [modalShow ,setModalShow] =useState(false)
const base_link= baselink;
useEffect(()=>{
  setPage(1);
  dataList=[];
  setCounter(0);

},[])
  
useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  let display= dataList.length> 0 ? dataList.map((item,index)=>{
    let str = item.type.replace(/_|-/g, " ");

   let link_url=`${base_link}${item.patient_id}`;
   let updatedDate=null;
    if(item.date_time){
   let dateString = item.date_time;

let dateObject = new Date(dateString);

updatedDate = dateObject.toString();
   }
    return(<div key={index}>
      {
        item.is_connected===true ? 
        <Link className="link-record" to={link_url}>

     <div className={item.read_recipient===true? "each-notfn read-notfn" : 'each-notfn unread-notfn'}> 
     <div className="flex-row notfn1-header"> 
     
     <div className="flex-row each-notfn-header"> 
     

      <h6 className="notfn-patient">ID:{item.patient_id} {item.patient}</h6>
      <h6 className="notfn-type">{str}</h6> 
    
     </div>
     <h6 className="text-muted">{updatedDate.slice(16,21)} ,{updatedDate.slice(0,16)} </h6> 
      
     </div>
     <h6 className="notfn-msg">{item.message}</h6></div>

    </Link>
    :item.is_connected===false ? <div onClick={()=> setModalShow(true)}> <div className={item.read_recipient===true? "each-notfn read-notfn" : 'each-notfn unread-notfn'}> 
    <div className="flex-row notfn1-header"> 
    
    <div className="flex-row each-notfn-header"> 
    

     <h6 className="notfn-patient">ID:{item.patient_id} {item.patient}</h6>
     <h6 className="notfn-type">{str}</h6> 
   
    </div>
    <h6 className="text-muted">{updatedDate.slice(16,21)} ,{updatedDate.slice(0,16)} </h6> 
     
    </div>
    <h6 className="notfn-msg">{item.message}</h6></div>
    </div> : null
  }
     </div>)
  }) : 
   errorMsg!=="" ? <h6 style={{color:"red",padding:"20px"}}> {errorMsg}</h6> 
   : 
    <h6 style={{color:"grey",padding:"20px"}}> Loading data.. </h6>;


    function SuccessPopup(props){
    

      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <h4 id="confirm-reject">This patient is disconnected</h4>
      
          </Modal.Body>
          <Modal.Footer>
             
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>
    
             
    
           
           
    
          </Modal.Footer>
        </Modal>
      );
    }

const loadFunc =async (page)=>{

  
    try{
        const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

         let dataUpdated=null;
        if(page!=null && page > 1 && page<=total ) {
        

    setTimeout(async () => {
   
    await axios.get(`${APIURL}/api/v1/account/web-notification/?per_page=10&page=${page}`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   

      if(res.data.status==="success"){
       
     dataUpdated = res.data.notifications;

     setHasMore(res.data.has_next);
      const newList = dataList.concat(dataUpdated);
   
        dataList=newList;
        let pageNow= page;
     setPage(pageNow+1);
     
      

      }else{
      
      }


    })
    .catch(err=>{
  
 
})


      }, 1500) 

      }

     
       else if(page===1 && counter===0) {
        dataList=[];
       
        await axios.get(`${APIURL}/api/v1/account/web-notification/?per_page=10&page=1`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   

      if(res.data.status==="success"){
     
     if(res.data.notifications.length>0){
      dataUpdated =res.data.notifications;
     setHasMore(res.data.has_next);

 const newList2 = dataUpdated;
      dataList=newList2;
       
        let pageNow= page;
     setPage(pageNow+1);
     setCounter(1);
     setTotal(res.data.total_pages);

   
      }
      else{
        setErrorMsg("No Notifications to display at the moment");
      }

      }else{
       setErrorMsg(res.data.message? res.data.message : "No notifications at the moment");
      
      }


    })
    .catch(err=>{
       setErrorMsg("Error in fetching data");
 
})

    

      }            
        
       
       
     }
     catch(err){
       setErrorMsg("Error in fetching data");
     }
    
    
  

}
 


  return (<>
  
  <div className="content-notfn">

  <div className="notfn-holder">
  <div className="notfn-header"> <h5> Notifications </h5></div>
  <div className="notfn-inner-holder">
   <InfiniteScroll
    pageStart={0}
    loadMore={loadFunc}
    hasMore={hasMore}
    useWindow={false}
>
    {display}
</InfiniteScroll>
</div>
 
  
 </div>

  </div>

{ modalShow ?
 <SuccessPopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        />
    :null
        }
   </>
  
  );
}

export default WebNotifications;
