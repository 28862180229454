import React from "react";

import "./Appointment.css";
import "./appointment2.css";
import { Link} from "react-router-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import "./pagination.css";
import {APIURL} from "../../Global";


class Appointments extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        date:"",
        datalist: [],
         activePage: 1,
        order: "asc",
        dropdownOpen : false,
        total_page:1,
        sort: "sort",
        messagesShow: false,
        idval:0,
        keys : {
          'p': false,
          'n': false,
          'm': false,
          'l': false,
          's': false
         }
  
      };

    }
    
  
    componentDidMount() {
   

      const tokenString = localStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
  
     
      let date=this.props.date

      axios
        .get(`${APIURL}/api/v1/doctor/appointments/patients/v2/?date=${date}`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            
            
            // this.setState({total_page:res.data.total_pages})
            const datas = res.data.appointments;
      
  
            this.setState({ datalist: datas });
          } else {
           
          }
        })
        .catch((err) => {
       
        });

            //////* Shortcuts ********/ 

    window.onkeydown =(e) => {
    
      this.state.keys[e.key]=true

      if(e.altKey && this.state.keys['p']){
        this.props.history.push('/patients-directory')
      }
      if(e.altKey && this.state.keys['n']){
        this.props.history.push('/doctor/notifications')
      }
      if(e.altKey && this.state.keys['m']){
        this.props.history.push('/message')
      }
      if(e.altKey && this.state.keys['l']){
        this.props.history.push('/doctor/analytics')
      }
      if(e.altKey && this.state.keys['s']){
        this.props.history.push('/service-requestlist')
      }
  
  
    }
    window.onkeyup=(ev)=> {
      
      this.state.keys[ev.key]= false
    }
}
  
componentWillUnmount(){ 
  window.onkeydown=null
  window.onkeyup=null
}
  
    render() {

            const {datalist} =this.state;     
     
      let dataDisplay = this.state.datalist.length>0 ? this.state.datalist.map((data, index) => {
        const idval1 = data.patient_id;

      //let url = `/patient-record/${idval1}`;
      
    //  let serialNum = this.state.activePage*10 - 10;
     return (



     
<div className="flex-col holder-appointment">
 <div className="badge badge-pill badge-info booking-badge">  
 <span className="flex-row flex-row-between">
 <h5 className="booking-title"> {data.heading} </h5> 
 <h5 className="booking-title"> {(data.patients).length} Bookings</h5></span>
 </div>

    
 <div className="flex-row appt-details-cards">
      {data.patients.length>0 ? data.patients.map((patient, index) =>{
        let url=`/patient-record/${patient.id}`;
        return(      <Link to={url} >
       <div className="list-item list-msg flex-col appt-details-card ">
                  <img className="appt-patient-pic" src={patient.photo} alt='Patient Photo' width="50px" height="auto"/>                

         <div className="d-flex justify-content-start ">
      

<div className="p-6 sm-highlight mb-6 appt-patient-details  ">
<h5> <span className="grey-text key-text">Name :</span><span className="caps">{patient.name}</span></h5>
<h5> <span className="grey-text key-text">PatientId :</span><span className="caps">{patient.id}</span></h5>
      <h5><span className="grey-text key-text">Gender :</span> <span className="caps"> {patient.gender}</span></h5>
      <h5><span className="grey-text key-text"> Age :</span>{patient.age}</h5>


         
  

</div>

      
    </div> 
   
 </div> 
      
   </Link>    
     )}  
       ): null}
        </div>
       </div>
     );
   })
   : null;


        




     
       









         
  
      const handlePageChange=(pageNumber)=> {
     
      this.setState({activePage: pageNumber});
      const tokenString = localStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
  
     
  
      axios
      .get(`${APIURL}/api/v1/doctor/message-list/`, {
        headers: { Authorization: "Token "+ v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          
          
          this.setState({total_page:res.data.total_pages})
          const datas = res.data.data;
       

          this.setState({ datalist: datas });
        } else {
          
        }
      })
      .catch((err) => {
      
      });
  }


  
     


   

  
      return (
          
        
        <div className="container displaylist-container ">
        
          <div>
      <h2 className="sub-title"> Appointments on {this.props.date}</h2>
</div>
          <div className="header-display">
  
  
           <div className="buttons-wrapper">




  
  


  
  </div>
  
  
          </div>
  
          <div className="test-reports-display-list">
          
             {datalist.length>0 ? (
               dataDisplay
             ) : (


             <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
                <h4>No more Pending list to show!!</h4>
              </div>
            )}
          </div>
          
          {this.state.total_page*10 >10 ?
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.total_page*10}
            pageRangeDisplayed={this.state.total_page}
            onChange={handlePageChange}
            disabledClass = "disabled-class"
          />
         : null}
        </div>
      );
    }
  }
  
  export default Appointments;
  