import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link,useHistory } from "react-router-dom";
import {APIURL} from "../../../Global";
import Pagination from "react-js-pagination";
import "./pagination.css";

function RegisteredHospitals() {

    const [pending ,setPending] =useState([])
    const [searchTerm ,setSearchTerm] =useState('');
    const [currentPage,setCurrentPage]=useState(1);
    const [perPage,]=useState(10);
    const history =useHistory();

const [keys,] = useState({'d': false,'u': false,'r': false,'l': false,'i': false,'a': false,'p': false,'v': false,'t': false,'n': false});


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['d']){
   history.push('/doctorslist')
 }
 if(e.altKey && keys['u']){
   history.push('/search-hospitals')
 }
 if(e.altKey && keys['r']){
   history.push('/rejectedlist')
 }
 if(e.altKey && keys['l']){
   history.push('/reportlist')
 }
 if(e.altKey && keys['i']){
   history.push('/addsynonyms')
 }
 if(e.altKey && keys['a']){
   history.push('/failedlist')
 }
 if(e.altKey && keys['p']){
   history.push('/prescriptionlist')
 }
 if(e.altKey && keys['v']){
   history.push('/written-prescriptionlist')
 }
 if(e.altKey && keys['t']){
   history.push('/testlist')
 }
 if(e.altKey && keys['n']){
  history.push('/nutrition')
}

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);

    useEffect(()=>{
        const tokenString = localStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
            
        axios.get(`${APIURL}/api/v1/staff/hospital-approve/`, {
             headers: { Authorization: "Token " + v2 },
        })
        .then((res) =>{
            if(res.data.status==='success'){
                const data =res.data.hospitals
                setPending(data)
            }
            else{

            }
        })
        .catch((err) => {

        })

    },[])

    const handleRefresh = () => {
        const tokenString = localStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
   
        axios
          .get(
            `${APIURL}/api/v1/staff/hospital-approve/`,
            { headers: { Authorization: "Token " + v2 } }
          )
          .then((res) => {
            if (res.data.status === "success") {
                const data =res.data.hospitals
                setPending(data)
            } else {
              
            }
          })
          .catch((err) => {
                  });
      };

      const handleSearchChange= (e)=> {
        e.preventDefault();
        setCurrentPage(1)
        setSearchTerm(e.target.value)
    }

    const handlePageChange = (pageNumber)=> {
      setCurrentPage(pageNumber)
    }
        
     

        let data =   pending.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
      })
      .filter((value)=>{
        if(searchTerm===''){
            return value
        } else if (value.name.toLowerCase().includes(searchTerm.toLowerCase())){
            return value
        }
    })


        const indexLast= currentPage * perPage;
        const indexFirst= indexLast - perPage;
        const current = data.slice(indexFirst ,indexLast);
        const total = data.length;

        const pageNumbers = []
        for(let i=0 ;i<= Math.ceil(total/perPage) ;i++){
          pageNumbers.push(i)
        }
        const totalPages=pageNumbers.length;

    let display =current.length >0 ?  current.map((data ,index) => {
        let id= data.id
        
        return (
            <div className='list-item'>
                <Link to={{pathname:`/hospital-details/${id}`,
                           id : id }}>
                {" "}
          <h5 className="each-list-link">
          {(currentPage-1)*10 + index+1}.{data.name}{" "}
          </h5>
          <div className="details">
            <p>ID : {data.id} </p>            
        </div>
        </Link>
            </div>
        )
    }) : null
    return (
        <div className="container">
      <div className="dashboard-navigation">
        <div>
          {" "}
          <Link to="/dashboard">
            {" "}
            <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
          </Link>
        </div>

        <div>
          {" "}
          <i className="fas fa-angle-double-right angle-icon"></i>{" "}
        </div>
        <div>
          {" "}
          <Link to="/hospitallist">Registered Hospital List</Link>{" "}
        </div>
      </div>

      <div className="container failedlist-parentcontainer">
        <h2 className="title-of-page">Registered Hospital List</h2>

        <form >
          <div className="search-div">
            <input
              className="form-control search-input"
              type="text"
              placeholder="Search a User"
              value={searchTerm}
              maxLength="30"
              onChange={handleSearchChange}
            />
            <button type="submit" className="btn btn-primary btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </button>
          </div>
        </form>
       <div className="buttons-wrapper">
          <button className="btn btn-secondary" onClick={handleRefresh}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button>
       </div>

       <div className="failed-list-section">
          <div className="container">
            <div className="test-reports-display-list">
              {display ? (
                display
              ) : (
                <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
              <h4>No more Pending list to show!!</h4>
            </div>
              )}
             </div>

            </div>
        </div>

        {totalPages > 1 ?
    <div className="pagn pagn-small">
 <br/>
    <br/>
            <Pagination
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={total}
          pageRangeDisplayed={totalPages}
          onChange={handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null}

      </div>
    </div>
    )
}

export default RegisteredHospitals
