import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "./phoneinput.css";
import axios from "axios";
import { useTranslation } from "react-i18next";

import "./emergencynumber.css";
import { APIURL } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";

function EmergencyNumber() {
  const { t } = useTranslation();
  const [emergencyNumber, setEmergencyNumber] = useState({
    dialcode: "",
    rawphone: "",
    mobile_number: "",
  });
  const [currentNumber, setCurrentNumber] = useState(null);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [getError, setGetError] = useState("");
  const [getErrorShow, setGetErrorShow] = useState(false);
  const [, setPostError] = useState("");
  const [getMessage, setGetMessage] = useState(null);
  const [addMessage, setAddMessage] = useState("");
  const [successAdd, setSuccessAdd] = useState(false);
  const [offMessage, setOffMessage] = useState("");
  const [successOff, setSuccessOff] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  let history = useHistory();

  const [keys] = useState({
    d: false,
    a: false,
    b: false,
    o: false,
    s: false,
    l: false,
    p: false,
    c: false,
    h: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.onkeydown = (e) => {
      keys[e.key] = true;

      if (e.altKey && keys["d"]) {
        history.push("/doctors-list");
      }
      if (e.altKey && keys["a"]) {
        history.push("/hospital/add");
      }
      if (e.altKey && keys["b"]) {
        history.push("/bdo-list");
      }
      if (e.altKey && keys["o"]) {
        history.push("/hospital/add-bdo");
      }
      if (e.altKey && keys["s"]) {
        history.push("/service-request-list");
      }
      if (e.altKey && keys["l"]) {
        history.push("/license-details");
      }
      if (e.altKey && keys["p"]) {
        history.push("/license-payment");
      }
      if (e.altKey && keys["c"]) {
        history.push("/hosp-calllog");
      }
      if (e.altKey && keys["h"]) {
        history.push("/payment-history");
      }
    };
    window.onkeyup = (ev) => {
      keys[ev.key] = false;
    };

    return () => {
      window.onkeydown = null;
      window.onkeyup = null;
    };
  }, []);
  useEffect(() => {
    setCurrentNumber(null);
    let emergncyNum = {
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    };
    setEmergencyNumber(emergncyNum);
    setGetMessage("");
    setAddMessage("");
    setOffMessage("");
    setGetError("");
    const tokenString = localStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/patient/emergency-contact/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.contact) {
            setCurrentNumber(res.data.contact);
          }

          if (res.data.message) {
            setGetMessage(res.data.message);
          }
        } else {
          setGetError(res.data.message);

          setGetErrorShow(true);
        }
      })
      .catch((err) => {
        setGetError("Error in fetching data");

        setGetErrorShow(true);
      });
  }, [reRender]);

  const handleBack = (e) => {
    history.goBack();
  };

  const SuccessAddPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }} className="capitalize-text">
            {props.msg}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const ErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "red" }} className="capitalize-text">
            {props.msg}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SuccessOffPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }} className="capitalize-text">
            {props.msg}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const handlePhoneInput = (value, data, event, formattedValue) => {
    const rawPhone = value.slice(data.dialCode.length);

    const dialcode = value.slice(0, data.dialCode.length);
    let emergency_Number = {
      dialcode: dialcode,
      rawphone: rawPhone,
      mobile_number: value,
    };
    setEmergencyNumber(emergency_Number);
  };

  /* const CallUpdate=() =>{
  
    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
    let numberToSend="+" +emergencyNumber.mobile_number;
    
    
  const dataToSend={
    key: "update",
    emergency_number: numberToSend
  }
    axios.post(`${APIURL}/api/v1/patient/emergency-contact/`,dataToSend, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
  
         setCurrentNumber(res.data.contact);
     

      }

        else{
         setPostError(res.data.message);
      }
      


    })
    .catch(err=>{

 setPostError("Error in fetching data");
})

  }
  const handleChangeNumber=e =>{
  	setDisplayEdit(true);
    
  }  */

  const handleTurnOff = (e) => {
    e.preventDefault();
    const tokenString = localStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const dataToSend = {
      key: "off",
      emergency_number: currentNumber,
    };
    axios
      .post(`${APIURL}/api/v1/patient/emergency-contact/`, dataToSend, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setOffMessage(res.data.message);
          setSuccessOff(true);
        } else {
          setPostError(res.data.message);
        }
      })
      .catch((err) => {
        setPostError("Error in fetching data");
      });
  };
  const handleCancelUpdate = (e) => {
    setDisplayEdit(false);
    setReRender(!reRender);
  };
  const validate = (e) => {
    setErrorMessage("");
    let isValid = true;
    if (!emergencyNumber.mobile_number) {
      isValid = false;
      setErrorMessage("Please enter phone number");
    }
    if (
      !emergencyNumber.dialcode ||
      emergencyNumber.dialcode === "" ||
      !emergencyNumber.rawphone ||
      emergencyNumber.rawphone === ""
    ) {
      isValid = false;
      setErrorMessage("Please enter phone number");
    }
    return isValid;
  };
  const handleAddNumber = (e) => {
    e.preventDefault();
    if (validate()) {
      const tokenString = localStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");

      let numberToSend = "+" + emergencyNumber.mobile_number;

      const dataToSend = {
        key: "update",
        emergency_number: numberToSend,
      };
      axios
        .post(`${APIURL}/api/v1/patient/emergency-contact/`, dataToSend, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setAddMessage(res.data.message);
            setSuccessAdd(true);
            setDisplayEdit(false);
          } else {
            setPostError(res.data.message);
          }
        })
        .catch((err) => {
          setPostError("Error in fetching data");
        });
    }
  };

  return (
    <>
      <HospitalNavbar />
      <br />
      <div className=" text-left">
        <button className="btn btn-info back-btn" onClick={handleBack}>
          {" "}
          <i className="fas fa-chevron-left back-btn-icon"></i>{t("Back")}
        </button>
      </div>
      <div className="container">
        <h2 className="title-of-page">
          {" "}
          <span className="icon-bkgrnd">
            <i className="fas fa-phone" id="emergency-icon"></i>
          </span>{" "}
          {t("Emergency Number")}
        </h2>
        <br />
        <div className="flex-col emergency-holder">
          {currentNumber !== null && getError === "" ? (
            <>
              <div className="current-num">
                <span className="emergency-display">{currentNumber}</span>
              </div>

              {displayEdit && getError === "" ? (
                <>
                  <h2 className="title-of-page">{t("Update Emergency Number")}</h2>
                  <div className="form-control phone-input">
                    <PhoneInput
                      inputProps={{}}
                      country={"in"}
                      value={emergencyNumber.mobile_number}
                      onChange={handlePhoneInput}
                    />
                  </div>

                  <br />
                  <div className="flex-row flex-btns">
                    <button
                      id="submit"
                      type="submit"
                      className="btn btn-secondary "
                      onClick={handleCancelUpdate}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      id="submit"
                      type="submit"
                      className="btn btn-primary btn-col "
                      onClick={handleAddNumber}
                    >
                      {t("Update")}
                    </button>
                  </div>
                </>
              ) : (
                <div className="flex-row flex-btns">
                  <button className="btn btn-danger" onClick={handleTurnOff}>
                  {t("Stop/Reset")}
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              <h6 className="text-muted capitalize-text">{getMessage}</h6>
              <br />
              <div className="form-control phone-input">
                <PhoneInput
                  inputProps={{}}
                  country={"in"}
                  value={emergencyNumber.mobile_number}
                  onChange={handlePhoneInput}
                />
              </div>

              <br />

              <button
                id="submit"
                type="submit"
                className="btn btn-primary btn-col "
                onClick={handleAddNumber}
              >
                {t("Add Emergency Number")}
              </button>
            </>
          )}
        </div>

        {addMessage !== "" ? (
          <SuccessAddPopup
            show={successAdd}
            msg={addMessage}
            onHide={() => {
              setSuccessAdd(false);
              setAddMessage("");
              setReRender(!reRender);
            }}
          />
        ) : (
          ""
        )}

        {offMessage !== "" ? (
          <SuccessOffPopup
            show={successOff}
            msg={offMessage}
            onHide={() => {
              setSuccessOff(false);
              setOffMessage("");
              setReRender(!reRender);
            }}
          />
        ) : (
          ""
        )}

        {getError !== "" ? (
          <ErrorPopup
            show={getErrorShow}
            msg={getError}
            onHide={() => {
              setGetErrorShow(false);
              setGetError("");
            }}
          />
        ) : (
          ""
        )}
        <h6 style={{ color: "red" }}>{errorMessage}</h6>
      </div>
    </>
  );
}

export default EmergencyNumber;
