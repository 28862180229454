import React from "react";
import graphic4 from "../../images/graphic4.svg";
import "./success_reg.css";
import { Link } from "react-router-dom";

function SuccessReg() {
  return (
    <div className="container page-container">
      <div className="success-msg">
        <h3>You have successfully registered !! </h3>
        <h4>
          We have send you a confirmation email.
          <br />
        </h4>
        <h4>Please check your inbox to confirm registration. </h4>
        <p>
          {" "}
          Goto <Link to="/login">Login</Link>
        </p>

        <img src={graphic4} width="30%" height="auto" alt="success"/>
      </div>
    </div>
  );
}

export default SuccessReg;
