import React, {useState} from 'react'

function ViewRemark(props) {

    const [remarkList,]= useState(props.data)

    const comments = remarkList.length > 0 ? remarkList.map((item,index)=> {
        let dateObject= new Date(item.commented_at)
        let updatedDate = dateObject.toString();
        return (
            <div className='comment-container'>
                <div className='comment-flex' key={index}>
                    <span><i class="fas fa-user mr-2"></i>{item.name}</span>
                    <span><i class="fas fa-calendar-alt mr-2"></i>{updatedDate.slice(0,21)}</span>
                </div>    
                <span className='comment-span'>{item.comment}</span>
            </div>
           
        )
    }) : <h5 className='text-danger'>No Remarks to show!!</h5>

    return (

        <div>
            <h4 className='comment-head'>Comments</h4> 
            <br/>

            <div className='flex-col'>  
                {comments}
            </div>

        </div>


       /* <div className='flex-col'>        

                <textarea 
                type="text" 
                className="form-group text-area remark-add"
                value= {props.data}
                name="remarks" 
            
            autoComplete="off"
                />
        </div>*/
    )
}

export default ViewRemark
