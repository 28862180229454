import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function EditScan(props) {

    const[data, setData]= useState(props.data)
   const [group2, setGroup2]= useState({value:props.data.group3, label: props.data.group3})
   const [country2, setCountry2]= useState({value:props.data.country3, label: props.data.country3}
    )

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)

   // const [year, setYear]= useState(data.year_of_manufacture=== null ? '' : new Date(data.year_of_manufacture))
    //const [expiry, setExpiry]= useState(data.expiry_date=== null ? '' : new Date(data.expiry_date))

  //  const [yearData, setYearData]= useState(data.year_of_manufacture)
    //const [expiryData, setExpiryData]= useState(data.expiry_date)
    const group3= [
        { value: "male", label: "MALE" },
        { value: "female", label: "FEMALE" },
        { value: "kids", label: "KIDS" },
        { value: "pregnancy", label: "PREGNANCY" },
        { value: "foetal", label: "FOETAL" },
        { value: "others", label: "OTHERS" },
        { value: "na", label: "NA" },
      ];
    
      const country3 = [
        { value: "India", label: "INDIA" },
        { value: "US", label: "USA" },
      ];

const handleServiceChange= (data) => {
  setGroup2(data)
}

const handleUnitChange= (data) => {

  setCountry2(data)
}


    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>Updated Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

//       const handleServiceChange= (data) => {

//         setType(data)
//    }

//    const handleUnitChange= (data) => {

//         setUnit(data)
//   }

//   const handleRequest = (e)=> {
//    const val = e.target.value
//    const key = e.target.name

//    setData((current) => ({
//        ...current , [key] : val==='true'? true : val==='false'? false : null
//    }))

//   }

//   const handleYear = (date)=> {
//     setYear(date);
      
//       let dateUpdated= date;
//         if(date!==null){
//       const offset = date.getTimezoneOffset();
      
//       dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
//       let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
//       /*let dateString= DateExtracted.toString(); */
//       setYearData(DateExtracted);
      
//       }
//   }

//   const handleExpiry = (date)=> {
//     setExpiry(date);
      
//       let dateUpdated= date;
//         if(date!==null){
//       const offset = date.getTimezoneOffset();
      
//       dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
//       let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
//       /*let dateString= DateExtracted.toString(); */
//       setExpiryData(DateExtracted);
      
//       }
//   }


const saveChanges = (e)=> {
    e.preventDefault();


        const tokenString = localStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
     const dataToSend = {
        scan_name : data.scanname,
        scan_type : data.scantype,
        scan_method : data.scanmethod,
        scan_area :  data.scansarea,                                
       cpt_code: data.cptcode,
        country :       data.country.value,           
        group : data.group.value,
        //description:data.description
    
      }
     axios
       .put(`${APIURL}/api/v1/staff/master-scan-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.status==200) {
          setSuccessShow(true);
          setData({
            scanname:'',
            scantype:'',
            scanmethod:'',
            scansarea:'',
           group:{ value:'', label:'' },
           cptcode:'',   
         // description:'',
          country : { value:'', label:'' }
          })
      
         }
          else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
    }




    return (
        <>
    <h2 style={{ marginTop: "0" }} className="title-of-page">
        Edit Scan Test
      </h2>
      <div style={{maxHeight:'1000px'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Scan Name: </span>
                    <input type='text' className='form-control title-flex' name='scanname' value={data.scanname} onChange={handleTitle} />
                   
                </div>

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Scan Type : </span>
                    <input type='text' className='form-control title-flex' name='scantype' value={data.scantype} onChange={handleTitle} />
                   
                </div>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Scan Method : </span>
                    <input type='text' className='form-control title-flex' name='scanmethod' value={data.scanmethod} onChange={handleTitle} />
               
                </div>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Scan Area: </span>
                    <input type='text' className='form-control title-flex' name='scansarea' value={data.scansarea} onChange={handleTitle} />
                    {/* {error.tolerance ? <div className="error-validation-msg error-feature-inventory">{error.tolerance}</div> : null} */}
                </div>
                

                <div className='flex-row name-content'>
                <span className='align-rental'>Group: </span>
                <Select
                            value={data.group}
                            onChange={(data,e)=>{handleServiceChange(data,e)
                            }}
                            options={group3}
                            name='group'
                            className="select-currency select-flex"
                        />
                        
            </div>
            <div className='flex-row name-content'>
                <span className='align-rental'>Country: </span>
                <Select
                            value={data.country}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={country3}
                            name='country'
                            className="select-currency select-flex"
                        />
                       
            </div>
              
                
            </div>

        <div className="text-center">
          <button onClick={saveChanges} className="btn btn-primary btn-col mb-2">
            Save
          </button>
        </div>
            {successShow ? 
<SuccessPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false); }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
            
        </div>
        </>
    )
}

export default EditScan
