
import React,{useState,useEffect, useRef} from "react";

import InfiniteScroll from 'react-infinite-scroller';
import "./drmessages.css";

import {Modal ,Button} from 'react-bootstrap';
import axios from "axios";
import {APIURL} from "../../Global";
import Linkify from 'react-linkify';
import ImagePopUp from './ImagePopUp.js'

let page=2;


function HospDrMessages(props) {

     
      const [patientId,] =useState(props.patientId);
      const [newMsg,setNewMsg]=useState(null);
      const [refresh,setRefresh]=useState(false);
  const [totalPages,setTotalPages]=useState();
  const [hasNext,setHasNext]=useState(true);
     const [messagesList,setMessagesList] =useState([]);
     const [render,setRender]=useState(false);
     const [audioChunks, setAudioChunks] = useState([]);
     const [,setAudioUrl] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [img ,setImg] =useState()
  const [imgURL ,setImgURL] =useState()
  const [second, setSecond] = useState('00');
const [minute, setMinute] = useState('00');
const [isActive, setIsActive] = useState(false);
const [counter, setCounter] = useState(0);
const [show ,setShow] =useState(false)
const [showSend, setShowSend] =useState(false)
const [showDelete ,setShowDelete]= useState(false)
const [startShow ,setStartShow] =useState(true)
const [camShow ,setCamShow] =useState(true)
const [showTimer ,setShowTimer] =useState(false)
const [modalShow ,setModalShow] =useState(false)
const [imgSend ,setImgSend]= useState(false)
const [sizeShow ,setSizeShow] =useState(false)
const [validShow ,setValidShow] =useState(false)
const [contentShow ,setContentShow] =useState(false)
const [uploadShow ,setUploadShow] =useState(false)
const [deleteId, setDeleteId]= useState('')
const [deleteConfirm, setDeleteConfirm]= useState(false)
const [deleteSuccess, setDeleteSuccess]= useState(false)
const [deleteFail, setDeleteFail]= useState(false)
const [deleteShow ,setDeleteShow] =useState(false)
const [audio ,setAudio] =useState()
const [imgShow ,setImgShow] =useState(false)
const [image ,setImage] =useState();
const [reRender ,setReRender] =useState(false)

const ref1= useRef(props.msgId)




useEffect(()=>{

loaderInitial();
  

 },[refresh]);

 
const loaderInitial=async ()=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
  
     page=2;
     setTotalPages(null);
     setMessagesList([]);

    await axios.get(`${APIURL}/api/v1/doctor/message/?patient_id=${patientId}&per_page=100&page=1`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
  
     
   
      if(res.data.status ==="success"){
          
        const updatedList=res.data.data;

        updatedList.map((item)=>{
          if(item.images===null){
          }
          else{
          setAudioUrl((prevUrl)=>[...prevUrl,item.images])
        
          }
        })
    
        setMessagesList(updatedList);
        setRender(!render);
       setTotalPages(res.data.total_pages);
       setHasNext(res.data.has_next);
      
       
    

   

          }else{
       
      }


    })
    .catch(err=>{
 
})
 
}


const handleSend=e=>{
  e.preventDefault();

  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  
   
   const data = {
    patient_id:patientId,
    message: newMsg
   }

    axios.post(`${APIURL}/api/v1/doctor/message/`,data, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
  

   
      if(res.data.status==="success"){
       
       setNewMsg("");
       setRefresh(!refresh);
      
      setMessagesList([]);

     
    
      }else{
       
      }


    })
    .catch(err=>{

 
})
 


}



const reader =new FileReader();
  const uploadImage= e=>{
    
     const image=e.target.files[0]
    
    if(!image){
      setUploadShow(!uploadShow)
      //setInvalidPic('Please Select an Image')
      return false
    }
    if (!image.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setValidShow(!validShow)
      //setInvalidPic('Please select valid image')
      return false;
    }
    if(image.size>2e6){
      setSizeShow(!sizeShow)
      return false
    }
  
    reader.onload = (e) => {

      const img1 = new Image();
      img1.onload = () => {
       setImg(image)
       setImgSend(true)
       setCamShow(false)
       setStartShow(false)
      };
      img1.onerror = () => {
        setContentShow(!contentShow)
        //setInvalidPic('Invalid Image Content')

        return false;
      };
      img1.src = e.target.result;
    };
    reader.readAsDataURL(image);
  
    const url =URL.createObjectURL(image)
    setImgURL(url)
    
  }

  const SizePopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
         <h4 id="confirm-reject">You have Exceeded the size limit 2MB</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

  const LoadPopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
          <h4 id="confirm-reject">Please select an Image</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

  const ValidPopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
          <h4 id="confirm-reject">Please select a valid Image</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

  const ContentPopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
          <h4 id="confirm-reject">Invalid Image Content</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

 const ImgPopUp = (props)=> {

    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  

          <ImagePopUp img={image} />

           
          </Modal.Body>
          <Modal.Footer>
           

             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          

    
          </Modal.Footer>
        </Modal>
      )
  }


 const DeletePopUp = (props)=> {

    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  

          <h4 id="confirm-reject">Please confirm to delete this recording</h4>

           
          </Modal.Body>
          <Modal.Footer>
           

             <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
             <Button variant="danger" onClick={props.onDelete}>Confirm</Button>           

    
          </Modal.Footer>
        </Modal>
      )
  }

  const DeleteConfirmPopup =(props)=>{
    

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete this Message!</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
  
          <Button variant="danger" onClick={handleDelete}> Confirm</Button>
  
         
  
        </Modal.Footer>
      </Modal>
    );
  }

  const DeleteSuccessPopUp=(props)=> {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Successfully Deleted the Message.</h4>
        
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }

  const DeleteFailPopUp=(props)=> {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h5 style={{ color: "red" }}>
            
            Error in data submission.Please check again!
          </h5>
        
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }


  const editImage = () =>{
    setImgSend(false)
    setStartShow(true)
    setCamShow(true)
    const tokenString= localStorage.getItem('usertoken');
      let str= tokenString.replace(/["]+/g, '')  
      let fd=new FormData();
   fd.append('images',img,img.name)
   fd.append('patient_id',patientId);
   axios.post(`${APIURL}/api/v1/doctor/message/`,fd, { headers: {
    "Content-Type": "multipart/form-data",
      "Authorization" : 'Token '+str  }})
    .then(res=>{
  
      if(res.data.status==="success"){
       
       setImgURL('');
       setRefresh(!refresh);
      
      setMessagesList([]);

     
    
      }else{
       
      }


    })
    .catch(err=>{

 
})
    
  }

  const getFile= ()=> {
    const fileInput = document.getElementById('imageInput')
    fileInput.click();
  }
 
  
  const sendVoice = (e) => {
    e.preventDefault();
    setShow(false)
    setStartShow(true)
    setShowDelete(false)
    setShowSend(false)
    setCamShow(true)
    setShowTimer(false)
  
    
    const tokenString= localStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '')  
     
     //let file = new File([audioChunks], "recording");
     let blob =new Blob([audioChunks],{type:'audio/aac'})
     let fd=new FormData();
     fd.append('images',blob,'audio.aac')
     fd.append('patient_id',patientId);
  
     axios.post(`${APIURL}/api/v1/doctor/message/`,fd, { headers: {
      "Content-Type": "multipart/form-data",
        "Authorization" : 'Token '+str  }})
      .then(res=>{
    
  
     
        if(res.data.status==="success"){
         
         setNewMsg("");
         setRefresh(!refresh);
        
        setMessagesList([]);
  
       
      
        }else{
         
        }
  
  
      })
      .catch(err=>{

   
  })
  
  }

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}`: secondCounter;
        const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}`: minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);
        if(minute>'02'){
          stopRecording()
          setModalShow(true)
        }
        setCounter(counter => counter + 1);
      }, 1000)
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter])



const getMoreMessages=()=>{
         setTimeout(()=>{
  if(page<= totalPages || typeof totalPages=="undefined") {
  
     const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
    
 

    axios.get(`${APIURL}/api/v1/doctor/message/?patient_id=${patientId}&per_page=100&page=${page}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
  
     

      if(res.data.status==="success"){
  setTotalPages(res.data.total_pages);
       setHasNext(res.data.has_next);
      const list=res.data.data;
      const oldList= messagesList;
     
    
         const newList=[...oldList,...list];
         
     setMessagesList(newList);
     setHasNext(res.data.has_next);
 page=page+1;
      }else{
       
      }


    })
    .catch(err=>{
    

 
})



  }
},1000);
  }
const handleDelete=async ()=>{
  
  const tokenString= localStorage.getItem('usertoken');
   const dataToSend={
    del_id: deleteId,
    patient_id:patientId
   }
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
     
   axios.put(`${APIURL}/api/v1/doctor/message/`,dataToSend, { headers: {
      
      "Authorization" : 'Token '+str,
      }
        })
    .then(res=>{
  
     
   
      if(res.data.status ==="success"){
          
        
       
    

setDeleteConfirm(false)
setDeleteSuccess(true)
      
      setMessagesList([]);
          }else{
            setDeleteConfirm(false)
            setDeleteFail(true)
      }


    })
    .catch(err=>{
      setDeleteConfirm(false)
      setDeleteFail(true)
})
 
}

useEffect(() => {
  if (recorder === null) {
    if (isRecording) {
      requestRecorder().then(setRecorder, console.error);
    }
    return;
  }

  // Manage recorder state.
  if (isRecording) {
    recorder.start();
    setIsActive(true)
  } else {
    recorder.stop();   
     
  }

  const handleData = e => {
    setAudioChunks(e.data);    
    setAudioUrl(URL.createObjectURL(e.data)) 
  };
  

  recorder.addEventListener("dataavailable", handleData);
  
  return () => recorder.removeEventListener("dataavailable", handleData);

  
  
}, [recorder, isRecording]);

const startRecording = () => {
  setIsRecording(true);
  setCounter(0);
  setSecond('00');
  setMinute('00') 
  setShow(true)
  setStartShow(false)
  setCamShow(false)
  setShowTimer(true)
  
};

const stopRecording = () => {
  setIsRecording(false);
  setIsActive(false)
  setShowSend(true)
  setShowDelete(true)
  setShow(false)

let track = audio.getAudioTracks()[0]
props.track(track)
    //track.stop();  

};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  setAudio(stream)
  return new MediaRecorder(stream);
}

const TimePopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">You have Exceeded the time limit</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}


const handleModal = (img)=> {

setImage(img)
  setImgShow(true)
 
}



useEffect(()=>{
  messagesList.map((item)=> {
    if(item.id === props.msgId){
      if(!reRender){


        ref1.current.scrollIntoView({
          behavior: 'smooth',
            block: 'start',     
        })
      setReRender(true)
      }
    }
  })
})


  const msgDisplay=
   messagesList.length>0 ? messagesList.map((item,index)=>{
    let updatedDate=null;
    if(item.date_time){
      let dateString = item.date_time;

let dateObject = new Date(dateString);

updatedDate = dateObject.toString();


    }
    
  return(

    <div key={index} className="msg-item" ref={item.id===props.msgId? ref1 : null} >
        {item.is_patient_message ===false ? item.images ===null ? <><span className="userDr" > <Linkify >{item.message} </Linkify></span> 

                                
        <div className="msg-item-footer">
              
                <span className="date-msg">{item.date_time? <>{updatedDate.slice(0,16)} , {updatedDate.slice(16,21)} </> : null} </span>
                <span><h6>  {item.recipient_read? <i className="far fa-check-circle read-tick"></i> : <i className="fas fa-check not-read-tick"></i>}</h6> </span>
                    <span onClick={()=>{setDeleteConfirm(true); setDeleteId(item.id)}}><h6>  <span className="del-msg-icon"><i className="far fa-trash-alt del-msg-icon"></i> </span></h6> </span>
                   
        </div>

        </>
        : item.images.includes('.aac') ? <><span className="userDr"> <audio src={item.images} controls /></span>
        <div className="msg-item-footer">
              
                <span className="date-msg">{item.date_time? <>{updatedDate.slice(0,16)} , {updatedDate.slice(16,21)} </> : null} </span>
                <span><h6>  {item.recipient_read? <i className="far fa-check-circle read-tick"></i> : <i className="fas fa-check not-read-tick"></i>}</h6> </span>
                    <span onClick={()=>{setDeleteConfirm(true); setDeleteId(item.id)}}><h6>  <span className="del-msg-icon"><i className="far fa-trash-alt del-msg-icon"></i> </span></h6> </span>
                    </div></>
        

        : <><span className="userDr"><img style={{cursor:'pointer'}} alt='Img' onClick={()=>handleModal(item.images)} height="100px" width="100px" src={item.images} /></span>

        <div className="msg-item-footer">
              
                <span className="date-msg">{item.date_time? <>{updatedDate.slice(0,16)} , {updatedDate.slice(16,21)} </> : null} </span>
                <span><h6>  {item.recipient_read? <i className="far fa-check-circle read-tick"></i> : <i className="fas fa-check not-read-tick"></i>}</h6> </span>
                    <span onClick={()=>{setDeleteConfirm(true); setDeleteId(item.id)}}><h6>  <span className="del-msg-icon"><i className="far fa-trash-alt del-msg-icon"></i> </span></h6> </span>
                    </div></> : null
                    }
         {item.is_patient_message ===true ? item.images===null ? <>
                <span className="userP" > <Linkify >{item.message} </Linkify></span>
                 <div className="msg-item-footer msg-p">
              
                <span className="date-msg">{item.date_time? <>{updatedDate.slice(0,16)} , {updatedDate.slice(16,21)} </> : null} </span>
                
                    <span onClick={()=>{setDeleteConfirm(true); setDeleteId(item.id)}}><h6>  <span className="del-msg-icon"><i className="far fa-trash-alt del-msg-icon"></i> </span></h6> </span>
                    
        </div>

        </>: item.images.includes('.aac') ? <><span className="userP"> <audio src={item.images} controls /></span>
        <div className="msg-item-footer msg-p">
              
                <span className="date-msg">{item.date_time? <>{updatedDate.slice(0,16)} , {updatedDate.slice(16,21)} </> : null} </span>
                 
                    <span onClick={()=>{setDeleteConfirm(true); setDeleteId(item.id)}}><h6>  <span className="del-msg-icon"><i className="far fa-trash-alt del-msg-icon"></i> </span></h6> </span>
                    </div></>
        
        : <><span className="userP"><img style={{cursor:'pointer'}} alt='Img' onClick={()=>handleModal(item.images)} height="100px" width="100px" src={item.images} /></span>
        <div className="msg-item-footer msg-p">
              
                <span className="date-msg">{item.date_time? <>{updatedDate.slice(0,16)} , {updatedDate.slice(16,21)} </> : null} </span>
                
                    <span onClick={()=>{setDeleteConfirm(true); setDeleteId(item.id)}}><h6>  <span className="del-msg-icon"><i className="far fa-trash-alt del-msg-icon"></i> </span></h6> </span>
                    </div></> : null
      }
      
      
    </div>

    
    
    )
}) 
:  <div>  </div>;

const handleInput=(e)=>{
e.preventDefault();


setNewMsg(e.target.value);


}

const audioDelete = (e) =>{
  e.preventDefault();
  setDeleteShow(true)
}

const cancelSend= (e)=> {
  e.preventDefault();
    setImgSend(false)
    setCamShow(true)
    setStartShow(true)
}

 return(
    <>   
    <div className="dr-messages-page"><br/>
    
    <h3 className="title-of-tasks header">   <i className="fas fa-comments messenger-icon"></i>Felixa Messenger  </h3>
    <div className="msg-list-display">

 {/* <div style={{height:"700px",overflow:"auto"}} ref={(ref) => this.scrollParentRef = ref}>
   <div>
 */}
 <div className="send-msg-input">
 <form className="msg-input-form full-msg-form" onSubmit={handleSend}>
 

       <input type="text" maxLength="150" className=" form-control" name="newMsg" placeholder="Send message" value={newMsg} onChange={handleInput}/>
    <button type="submit" className="btn btn-primary btn-col btn-smallest btn-square  btn-msg-send" ><i className="fas fa-share"></i> </button>
    </form>

    {startShow ? <button style={{alignSelf:'center'}} onClick={startRecording} value={startShow}  className="btn btn-primary btn-col btn-smallest btn-square btn-msg-send" ><i className="fas fa-microphone"></i> </button> : null }
    
    

    

    {showDelete ? <button style={{marginRight:'10px', marginLeft:'5px'}} onClick={audioDelete} value={showDelete} className="btn btn-voice-delete btn-smallest btn-square btn-msg-send" ><i className="fas fa-trash-alt"></i> </button> : null }
    
    {showTimer ? <div className="time">
      <span value={showTimer} className="minute">{minute}</span>
        <span>:</span>
        <span value={showTimer} className="second">{second}</span>
    </div> : null}


  

    {show ? < button onClick={stopRecording} value={show} className="btn btn-primary btn-col btn-smallest btn-square btn-msg-send" > <i className="far fa-stop-circle stop-record-icon"> Stop Recording</i></button> : null }

    {showSend ? <button onClick={sendVoice} value={showSend} className="btn btn-primary btn-col btn-smallest btn-square btn-msg-send">   <i  className="fas fa-paper-plane">  </i> </button> : null }

    <input type="file" style={{display:'none'}} id="imageInput"  onChange={uploadImage}></input>
    {camShow ?<button style={{alignSelf:'center'}} className="btn btn-primary btn-col btn-smallest btn-square btn-msg-send" value={camShow} onClick={getFile}><i className="fas fa-camera"></i></button>: null }
    {imgSend ? <button style={{alignSelf:'center'}} onClick={cancelSend} value={imgSend}  className="btn btn-primary btn-col btn-smallest btn-square btn-msg-send">Cancel</button>:null}
    {imgSend ? <img className='img-prev' src={imgURL} alt='Img' width='150px' height='150px'></img>: null}
    {imgSend ? <button style={{alignSelf:'center'}} onClick={editImage} value={imgSend}  className="btn btn-primary btn-col btn-smallest btn-square btn-msg-send"><svg width="1em" height="1em" viewBox="0 0 24 24"><path d="M21.426 11.095l-17-8A1 1 0 0 0 3.03 4.242l1.212 4.849L12 12l-7.758 2.909l-1.212 4.849a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81z" fill="currentColor"></path></svg></button>: null }

    </div>
    
 

 <div className="scroll-div" >
     <InfiniteScroll  
     threshold={250}
    pageStart={0}
    loadMore={getMoreMessages}  
    hasMore={hasNext} 
    useWindow={false} 

      >  

      {
        modalShow ? 
        <TimePopUp
        show={modalShow}
        onHide={()=>{
          setModalShow(false)
        }} /> : null
      }
  
  {
        sizeShow ? 
        <SizePopUp
        show={sizeShow}
        onHide={()=>{
          setSizeShow(false)
        }} /> : null
      } 

{
        uploadShow ? 
        <LoadPopUp
        show={uploadShow}
        onHide={()=>{
          setUploadShow(false)
        }} /> : null
      } 

{
        validShow ? 
        <ValidPopUp
        show={validShow}
        onHide={()=>{
          setValidShow(false)
        }} /> : null
      } 

{
        contentShow ? 
        <ContentPopUp
        show={contentShow}
        onHide={()=>{
          setContentShow(false)
        }} /> : null
      } 


{
        deleteShow ? 
        <DeletePopUp
        show={deleteShow}
        onHide={()=>{
          setDeleteShow(false)
        }}
        onDelete={()=>{
          setDeleteShow(false)
          setShowSend(false)
          setImgURL('')
          setAudioChunks([])
          setShow(false)
          setShowDelete(false)
          setShowTimer(false)
          setCamShow(true)
          setStartShow(true)
        }} /> : null
      }

{
        imgShow ? 
        <ImgPopUp
        show={imgShow}
        onHide={()=>{
          setImgShow(false)
        }} /> : null
      }
      


      {
        deleteShow ? 
        <DeletePopUp
        show={deleteShow}
        onHide={()=>{
          setDeleteShow(false)
        }}
        onDelete={()=>{
          setDeleteShow(false)
          setShowSend(false)
          setImgURL('')
          setAudioChunks([])
          setShow(false)
          setShowDelete(false)
          setShowTimer(false)
          setCamShow(true)
          setStartShow(true)
        }} /> : null
      }
      
{
        imgShow ? 
        <ImgPopUp
        show={imgShow}
        onHide={()=>{
          setImgShow(false)
        }} /> : null
   }

{
        deleteConfirm ? 
        <DeleteConfirmPopup 
            show={deleteConfirm}
            onHide= {() => {setDeleteConfirm(false);}}/> : null
    }

{
        deleteSuccess ? 
        <DeleteSuccessPopUp 
            show={deleteSuccess}
            onHide= {() => {setRefresh(!refresh); setDeleteSuccess(false);}}/> : null
    }

{
        deleteFail ? 
        <DeleteFailPopUp 
            show={deleteFail}
            onHide= {() => {setDeleteFail(false);}}/> : null
    }
{msgDisplay}

    </InfiniteScroll>  
  </div>
 


        </div>

   


</div>
</>

  );
  
}

export default HospDrMessages;
