import React from "react";
import { Link } from "react-router-dom";
import "./patientslist.css";


function PatientsList(){
 
   
  const List = [{
      name : "Kishore",
      id:2 ,
      phn : "9898987668",
      dob: "12-01-1990",
      gender : "Male"

  } ,
  {
      name : "Anagha",
      id:4 ,
      phn : "989892222",
      dob: "2-01-1990",
      gender : "Female"

  } ,
  {
      name : "Anwar",
      id:6 ,
      phn : "98999900",
      dob: "12-01-1966",
      gender : "Male"

  } ,
  {
      name : "Ahmed",
      id: 12 ,
      phn : "989444448",
      dob: "14-01-1990",
      gender : "Male"

  } ,
  {
      name : "Kishore",
      id:2 ,
      phn : "9898987668",
      dob: "12-01-1990",
      gender : "Male"

  } ,
  {
      name : "Anagha",
      id:4 ,
      phn : "989892222",
      dob: "2-01-1990",
      gender : "Female"

  } ,
  {
      name : "Anwar",
      id:6 ,
      phn : "98999900",
      dob: "12-01-1966",
      gender : "Male"

  } ,
  {
      name : "Ahmed",
      id: 12 ,
      phn : "989444448",
      dob: "14-01-1990",
      gender : "Male"

  } 
  ];
 
	return (
      <div className="drlist-page">
   

		<h2 className="title-of-page"> Patients Directory </h2>
    <div className="flex-row">

 


{List.map((data,index)=>{
      return (
           <div className="list-item list-item-patient col-lg-4">
          <h5 className="each-list-link">
               {index + 1}. <Link to="/"> {data.name}</Link>
          </h5>
          <div className="details">
            <p>  {data.phn} </p>
           
          </div>
        </div>

      )
    })}
		</div>

    </div>)
		
    
}

export default PatientsList;