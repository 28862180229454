import React,{useState,useEffect, useRef, useCallback} from 'react'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import BookAppointment from './BookAppointment';
import Pagination from "react-js-pagination";
import axios from "axios";
import {APIURL} from "../../Global";
import debounce from 'lodash.debounce';

function AppointmentService(props) {
const [currentEvents,setCurrentEvents]=useState([]);
const [display,setDisplay]=useState(false);
const [infoDate,setInfoDate]=useState();
const [show, setShow]= useState(false)
const [searchTerm,setSearchTerm]= useState("")
const [doctorId, setDoctorId]= useState("")
const [startDate, setStartDate]= useState(new Date().toISOString().slice(0,10))
const [endDate, setEndDate]= useState(getEndDate(new Date()))
const [doctorList, setDoctorList]= useState([])
const [currentPage, setCurrentPage]= useState(1)
const [totalPages, setTotalPages]= useState(1)
const [totalRecords, setTotalRecords]= useState(0)
const [bookingData, setBookingData]= useState()
const [schedule, setSchedule]= useState([])
const [slot, setSlot]= useState('')
const [refresh,setRefresh]= useState(false)
const [msgShow, setMsgShow]= useState(false)
const [msg, setMsg]= useState("")
const [validateErrorShow, setValidateErrorShow]= useState(false)


function getEndDate(date){
  const updated = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return moment(updated).format('YYYY-MM-DD')
}


let eventGuid = 1

// useEffect(()=>{
//     let data = [{
//         id:createEventId(),
//         title: "2 slots",
//         slots:["08:00 - 09:00","10:00 - 11:00"],
//         start:"2021-10-25"
//     },
//     {
//         id:createEventId(),
//         title: "1 slot",
//         slots:["13:00 - 15:00","19:00 - 21:00"],
//         start:"2021-10-30"
//     },
//     {
//         id:createEventId(),
//         title: "1 slot",
//         slots:["13:00 - 15:00","19:00 - 21:00"],
//         start:"2021-10-31"
//     },
//     {
//         id:createEventId(),
//         title: "1 slot",
//         slots:["13:00 - 15:00","19:00 - 21:00"],
//         start:"2021-10-29"
//     }]
//     setCurrentEvents(data)
// },[])

useEffect(() => {
  handleSearch(searchTerm,currentPage)
},[])

const handleSearch = (val,page) => {

  const tokenString = localStorage.getItem("usertoken");

  let str = tokenString.replace(/["]+/g, "");

  axios
    .get(`${APIURL}/api/v1/connect/doctorslist/?q=${val}&page=${page}&per_page=5`, {
      headers: {
        Authorization: "Token " + str,
      },
    })
    .then((res) => {

      if (res.data.status === "success") {
        setDoctorList(res.data.results)
        setTotalPages(res.data.total_pages)
        setTotalRecords(res.data.total_records)
      }
    })
    .catch((err) => {

    });

}

const debouncedSearch = useCallback(
  debounce((val,page) => handleSearch(val,page) , 500 ),
  [],
)

const handleSchedule = (date) => {

  const tokenString = localStorage.getItem("usertoken");

  let str = tokenString.replace(/["]+/g, "");

  axios
    .get(`${APIURL}/api/v1/patient/appointments/availability/?start_date=${date}&end_date=${endDate}&doctor_id=${doctorId}&src=web`, {
      headers: {
        Authorization: "Token " + str,
      },
    })
    .then((res) => {

      if (res.data.status === "success") {
        const data = res.data.data
        const filtered = data.filter(item => {
          return item.slots.length>0
        })

        let currentEv = filtered.map(item => {

          let str;
          if(item.slots.length > 1){
            str = "slots"
          }
          else str = "slot"

          return{
            id: createEventId(),
            title: item.slots.length + " " + str,
            start: item.day,
            slots: item.slots
          }
        })

        setCurrentEvents(currentEv)

        setSchedule(filtered)

      }
    })
    .catch((err) => {

    });

}

useEffect(() => {
  if(doctorId !== ""){

    const tokenString = localStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
  
    axios
      .get(`${APIURL}/api/v1/patient/appointments/availability/?start_date=${startDate}&end_date=${endDate}&doctor_id=${doctorId}&src=web`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
  
        if (res.data.status === "success") {
          const data = res.data.data

          const reduceData = data.reduce((acc,item) => {
            if(new Date(item.day).getMonth()+1 === new Date().getMonth()+1){
              return acc + 1
            }
            else return acc+0
          },0)

          if(reduceData > (data.length/2)){
            setStartDate(new Date().toISOString().slice(0,10))
            handleSchedule(new Date().toISOString().slice(0,10))
          }
          else {
            const filtered = data.filter(item => {
              return item.slots.length>0
            })

            let currentEv = filtered.map(item => {

              let str;
              if(item.slots.length > 1){
                str = "slots"
              }
              else str = "slot"

              return{
                id: createEventId(),
                title: item.slots.length + " " + str ,
                start: item.day,
                slots: item.slots
              }
            })

            setCurrentEvents(currentEv)
            setSchedule(filtered)
          }
        }

      })
      .catch((err) => {

      });
    
  }

}, [startDate,doctorId, refresh]);

const handleDoctorSelect = (id) => {
  setDoctorId(id)
}

const docDisplay = doctorList.map((value,index) => {
  return(
        <div onClick={() => handleDoctorSelect(value.id)} style={{cursor:"pointer",width:"90%"}} key ={value.id} className={doctorId === value.id ? 'list-item flex-row doc-select-bg':'list-item flex-row'}>               
            <h5 className="each-list-link">{(currentPage-1)*10 + index+1}.{value.full_name}</h5>  
            <div style={{marginLeft:"auto"}} className="details">
              <span> Spec: {value.specialization} </span>
              {/* <p> Phn : {value.mobile_number} </p> */}
            </div>                                                                 
        </div>
    )
})

const handleSearchChange = e => {
  if(currentPage > 1){
    setCurrentPage(1)
  }
  const page = 1
  setSearchTerm(e.target.value)
  debouncedSearch(e.target.value,page)
}

const handlePageChange = (pageNumber)=> {
  setCurrentPage(pageNumber)
  handleSearch(searchTerm,pageNumber)
}

const createEventId=()=> {
    return String(eventGuid++)
  }

  const initRef = useRef(true)

    const handleDateChange = (date) => {
      if(initRef.current){
        initRef.current = false
      }
      
      else {
        const start = date.startStr.slice(0,10)
        const end = date.endStr.slice(0,10)

        setStartDate(start)
        setEndDate(end)
      }
      

    }

    const renderSidebar=()=> {
      const eventDate = currentEvents.filter(item =>{
        return item.start === infoDate
      })

        return (
          <div style={{overflow:"auto", width:"410px"}} className='calender-app-sidebar'>
            <div className='calender-app-sidebar-section p-2'>
             
            </div>
            
            <div style={{maxHeight:"460px"}} className='calender-app-sidebar-section'>
              <div className="inline-slot-details">
                <h2>Available Slots </h2>
                <h2>( {currentEvents.length} days )</h2>
                {/* <h2>Total Bookings : 20</h2>
                <h2>Remaining : 12</h2>
                <h2>Overbooking: 0 </h2> */}
              </div>
              
              <ul>
                {eventDate.map(renderSidebarEvent)}
              </ul>
            </div>
            {/* <div className='calender-app-sidebar-section'>
              <label>
                <input
                  type='checkbox'
                  checked={weekendsVisible}
                  onChange={handleWeekendsToggle}
                ></input> 
                toggle weekends
              </label>
            </div> */}
          </div>
        )
      
    
      }

      const handleEventClick = (clickInfo) => {
   
        let dateFormat=moment(clickInfo.event.start).format("YYYY-MM-DD");
     //setDisplay(true)
     setInfoDate(dateFormat); 
     setShow(true)
   }

   const ValidateErrorPopup =(props)=>{
          
      
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{color: "red"}}>Please Select a Patient !!</h5>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>
  
         
         
  
        </Modal.Footer>
      </Modal>
    );
  }

   const handleBooking = (eventData) => {

    const patientId = props.id

    if(patientId){

      let time

      if(eventData.slots.length === 1){
        time = eventData.slots[0].id
      }
  
      const tokenString = localStorage.getItem("usertoken");
  
      let str = tokenString.replace(/["]+/g, "");
  
      const data = {
        patient_id: patientId,
        doctor_id: doctorId,
        slot_id: eventData.slots.length === 1 ? time : slot,
        date: infoDate
      }
    
      axios
        .post(`${APIURL}/api/v1/patient/doctor-appointment/?src=web`, data , {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
    
          if (res.data.status === "success") {
            setBookingData(res.data)
            setDisplay(true)
          }
          else {
            setMsgShow(true)
            setMsg(res.data.message)
          }
        })
        .catch((err) => {
          setMsgShow(true)
          setMsg("Error in Data Submission. Please try again")
        });

    }

    else {
      setValidateErrorShow(true)
    }
     
   }

   const handleSlots = (id) => {
     setSlot(id)
   }

    const renderSidebarEvent=(event)=> {
      // if(event.slots.length === 1){
      //   setSlot(event.slots[0].id)
      // }
        return (

        <div><li style={{cursor:"default"}} key={event.id}>
            <h6>Slots available on <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})} </b></h6>
            {event.slots.map((item,index) => {
              
              return (
                event.slots.length < 2 ? <div className='d-flex justify-content-center align-items-baseline'>
              <input className="align-self-center" type="radio" checked={true} onChange={() => handleSlots(item.id)} id={`slot${index}`} name="slot" />
              <div className='slot-booking'>
                <label className="font-weight-bold" htmlFor={`slot${index}`}><i className="far fa-clock pr-1"></i>{item.from.slice(0,5)} - {item.to.slice(0,5)}</label>
                <span className="text-left">Tokens : {item.total_tokens}</span>
                <span className="text-left">Remaining : {item.remaining_token}</span>
                <span className="text-left text-danger">Overbooking : {item.over_booking_count}</span>
              </div>
                <br/><br/>   
                </div>

                :

                <div className='d-flex justify-content-center align-items-baseline'>
              <input className="align-self-center" type="radio" onChange={() => handleSlots(item.id)} id={`slot${index}`} name="slot" />
              <div className='slot-booking'>
                <label className="font-weight-bold" htmlFor={`slot${index}`}><i className="far fa-clock pr-1"></i>{item.from.slice(0,5)} - {item.to.slice(0,5)}</label>
                <span className="text-left">Tokens : {item.total_tokens}</span>
                <span className="text-left">Remaining : {item.remaining_token}</span>
                <span className="text-left text-danger">Overbooking : {item.over_booking_count}</span>
              </div>
                <br/><br/>   
                </div>
              )
            })}
          </li>
          <button onClick={() => handleBooking(event)} className="btn btn-secondary btn-col btn-smallest">Book</button>
        </div>
        )
      }

      const DisplayPopup=(props)=>{
    

        return (
          <Modal
            {...props}
            dialogClassName="book-modal-width"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <h5 style={{color:"green"}}>{props.msg} </h5>
              
              
           <BookAppointment data={bookingData} />
             
            </Modal.Body>
            <Modal.Footer>
               
               {/* <Button variant="success"  onClick={props.onHide}>Ok</Button> */}
               <Button variant="success" onClick={props.onHide}>Ok</Button>
      
               
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      function ErrorPopUp(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className="caps" style={{color:"red"}}>{msg}</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
      
            <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
            </Modal.Footer>
          </Modal>
        );
      }
    
    return (

        <div style={{gap:"10px"}} className='d-flex pl-5 pr-5'>

        <div className='flex-col appointment-doctor-list justify-content-start'>
          <h4 className="title-of-tasks mt-4">Doctor List</h4>
          <div className="search-div">
              <input
                className="form-control search-input"
                style={{width:"77%"}}
                type="text"
                maxLength="150"
                placeholder="Enter Name"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button  className="btn btn-primary btn-col search-btn">
                {" "}
                <i className="fas fa-search"></i>{" "}
              </button>        </div>
            {docDisplay}

            <br/>
            <br/>

            {totalPages > 0 ?
    <div className="pagn">

            <Pagination
          activePage={currentPage}
          itemsCountPerPage={5}
          totalItemsCount={totalRecords}
          pageRangeDisplayed={totalPages}
          onChange={handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null}
        </div>

        <div className='calender-app calender-holder'>
        
        <div className='calender-app-main'>
         <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev today',
              center: 'title',
              right: 'next'
            }}
            datesSet={handleDateChange}
            height="auto"
            initialView='dayGridMonth'
            editable={false}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            // dateClick={handleDateClick}
            eventClick={handleEventClick}
            // select={handleDateClick}
           
            events={[...currentEvents]}
           
          />
        </div>
        {renderSidebar()}

      </div>


      {display ?
    <DisplayPopup
        show={display}
        infoDate={infoDate}
        onHide={() => { setDisplay(false);
                        setInfoDate(null);
                        setRefresh(!refresh)
                      //  setRender(!render)
                    }}
       
      />: ''}

{msg!=="" ?
   <ErrorPopUp
        show={msgShow}
        onHide={() => {setMsgShow(false);
                       setMsg("")}}
      />

 : null }

{validateErrorShow ?
    <ValidateErrorPopup
        show={validateErrorShow}
        onHide={() =>  setValidateErrorShow(false)}
       
      />: ''
    }

      </div>
    )
}

export default AppointmentService
