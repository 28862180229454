import React,{useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";

import QRCode from 'qrcode-react';
import "./qrcode.css";
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";
function QRCodePrint(){
	
	const [hospital,]=useState(JSON.parse(sessionStorage.getItem('hospital'))? JSON.parse(sessionStorage.getItem('hospital')) : null);;
	const [QR_value,]=useState(JSON.parse(sessionStorage.getItem('qr_string'))? JSON.parse(sessionStorage.getItem('qr_string')) : null);
	  let history=useHistory();

	   const handlePrint=()=>{
		window.print();
	}
	const handleBack=(e)=>{
  history.goBack();
}

const [keys,] = useState({'u': false,'c': false,'n': false,'a': false,'s': false,'l': false,});


useEffect(()=>{

  window.onkeydown =(e) => {
	
	keys[e.key]=true

	if(e.altKey && keys['u']){
	  history.push('/directory')
	}
	if(e.altKey && keys['c']){
	  history.push('/careplans-db')
	}
	if(e.altKey && keys['n']){
	  history.push('/notifications')
	}
	if(e.altKey && keys['a']){
	  history.push('/analytics')
	}
	if(e.altKey && keys['s']){
	  history.push('/service-requests')
	}
	if(e.altKey && keys['l']){
	  history.push('/call-log')
	}


  }
  window.onkeyup=(ev)=> {
	
	keys[ev.key]= false
  }

  return()=>{
	window.onkeydown=null
	window.onkeyup=null
  }
},[]);

	return(<>
		<HospBdoNavbar />

		 <div className=" text-left">
		 		<br/>
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> 
		<div className="container">
		 {QR_value!==null && QR_value!==undefined ?
		 	<div className="qr-container">
		<div className="to-print text-center">
		<div className="print-layout">
		
		{hospital?
			 <h3 className="title-bdo" > {hospital} </h3>
			 : null}
		<h4 className="felixa-text"> A Felixacare Hospital </h4> <br/> 
       <h5 className="title-of-tasks" > Scan QR Code </h5>

		<div className="qrcode-holder">
		
        {/*<h6 className="title-of-tasks"> Scan QR Code </h6>*/}
        <br/>
       
		<QRCode value={QR_value} />
		
         </div>
          <a href="https://www.felixacare.com" target="_blank" rel="noopener noreferrer"><h5 className="felixa-site" > www.felixacare.com</h5></a>

         </div>
         </div>
           <div className="do-not-print" id="pr">
		         <button className="btn btn-primary btn-col btn-square" onClick={handlePrint}> Print </button>
		         <br/>
		         <br/>
		         <h6 style={{color:"grey"}}>** If in case 'Print' does not work, please cross check if popup blocker is switched 'off' in your Browser settings.</h6>
                  <br/>
                  <br/>
           </div>
           </div>
         : <h6 style={{color:'red',margin:"100px auto 0px",padding:"20px",border:"2px solid grey",borderRadius:"4px"}}> Error in fetching QR Code!</h6>}
         </div>
        </>
		)
}
export default QRCodePrint;



