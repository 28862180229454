import React, { useState, createContext } from "react";

export const RazorpayContext = createContext();

export const RazorpayProvider = (props) => {
  const [loadRP,setLoadRP] = useState(false);
  return (
    <RazorpayContext.Provider value={[loadRP, setLoadRP]}>
      {props.children}
    </RazorpayContext.Provider>
  );
};
