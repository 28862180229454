import React, {useState} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function EditInventory(props) {

    const [data, setData]= useState(props.data)
    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)

    
    const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;

        setData((current) => ({
            ...current , [key] : val
        }))
    }

    const handlePrice= (e)=> {
        e.preventDefault();
        const val =e.target.value
        const key=e.target.name;

        if(val.length<=8){
          setData( current => ({
            ...current , [key]: val
        }))
        } else{
          return false;
      }
    }


   const saveChanges = (e)=> {
       e.preventDefault();

       const tokenString = localStorage.getItem("usertoken");
    
        let v2 = tokenString.replace(
          /["]+/g,
          ""
        ); /* REGEX To remove double quotes from tokenstring */
    
     const dataToSend = {
        price : data.price,
        buying_price : data.buying_price,
        distributor : data.distributor,
        distributor_address : data.distributor_address,
        company_address : data.company_address
     }
    
        axios
          .put(`${APIURL}/api/v1/staff/update-cp-services/?id=${data.id}`, dataToSend , {
            headers: { Authorization: "Token " + v2 },
          })
          .then((res) => {
            if (res.data.status === "success") {
              
              setSuccessShow(true)
            } else {
              setErrorShow(true)
            }
          })
          .catch((err) => {
            setErrorShow(true)
          });

   }

   const SuccessPopUp=(props)=> {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-success'>Updated Successfully</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
         
        
        </Modal.Footer>
      </Modal>
    );
  }

  const ErrorPopUp=(props)=> {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
         
        
        </Modal.Footer>
      </Modal>
    );
  }

    return (<>
    <h2 style={{marginTop:'0'}} className="title-of-page">Edit Inventory Details</h2>
    <div style={{maxHeight:'860px'}} className='config-box fit-content'>
            
            <div className='flex-row name-content'>
                <span>Distributor : </span>
                <input style={{marginLeft:'0'}} type='text' className='form-control title-input' name='distributor' value={data.distributor} onChange={handleTitle} />
            </div>
            <div className='flex-row name-content'>
                <span>Price : </span>
                <input style={{marginLeft:'0'}} type='number' className='form-control title-input' min='0' name='price' value={data.price} onChange={handlePrice} />
            </div>
            <div className='flex-row name-content'>
                <span>Buying Price : </span>
                <input style={{marginLeft:'0'}} type='number' className='form-control title-input' min='0' name='buying_price' value={data.buying_price} onChange={handlePrice} />
            </div>
            <div className='flex-row name-content'>
                <span>Company Address: </span>
                {/* <input type='text' className='form-control title-input' name='distributor' value={data.distributor} onChange={handleTitle} /> */}
                <textarea  className="text-area" placeholder="Company Address" name='company_address'  cols="100" rows="4" value={data.company_address} onChange={handleTitle}/>
            </div>
            <div className='flex-row name-content'>
                <span>Distributor Address: </span>
                {/* <input type='text' className='form-control title-input' name='distributor' value={data.distributor} onChange={handleTitle} /> */}
                <textarea  className="text-area" placeholder="Distributor Address" name='distributor_address'  cols="100" rows="4" value={data.distributor_address} onChange={handleTitle}/>
            </div>

            <br/>
            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col'>Save</button>
                
            </div>
            <br/>
            {successShow ? 
<SuccessPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false); props.modalClose(); }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
            
        </div>
        </>
    )
}

export default EditInventory
