import React, { useState, createContext } from "react";

export const RepContext = createContext();

export const ReportProvider = (props) => {
  const [patientDatas, setPatientDatas] = useState({
    image: "",
    tests: [],
  });
  return (
    <RepContext.Provider value={[patientDatas, setPatientDatas]}>
      {props.children}
    </RepContext.Provider>
  );
};
