import React, {useState, useEffect} from 'react'

function EditRemark(props) {

    const [remarkData, setRemarkData]= useState(props.data)
    const [remarkList,]= useState(props.list)
    const [show, setShow]= useState(false)
    const [commentShow, setCommentShow]=useState(false)

    useEffect(()=> {
        if(remarkList.length < 1){
            setCommentShow(false)
        }
    },[])

    const handleShow =()=> {
        if(show===false){
            setShow(!show)
        }
        else{
            setRemarkData(props.data)
            setShow(!show)
        }
    }

    const handleComment =() => {
        setCommentShow(false)
        setShow(false)
    }

    useEffect(()=> {
        let data = remarkData
        props.update(data)
    },[remarkData])
      
    const comments = remarkList.length > 0 ? remarkList.map((item,index)=> {
        let dateObject= new Date(item.commented_at)
        let updatedDate = dateObject.toString();
        return (
            <div className='comment-container'>
                <div className='comment-flex' key={index}>
                    <span><i class="fas fa-user mr-2"></i>{item.name}</span>
                    <span><i class="fas fa-calendar-alt mr-2"></i>{updatedDate.slice(0,21)}</span>
                </div>    
                <span className='comment-span'>{item.comment}</span>
            </div>
           
        )
    }) : []

    return (
        <>
        <div className='comment-flex'>
            {
                commentShow && remarkList.length > 0 ?
                <>
                <h4 className='comment-head'>Comments</h4> 
                <span className='current-comment'>Current Comment<i onClick={handleComment} class="fas fa-arrow-circle-right ml-2"></i></span>
                {/* <button onClick={handleComment} className='btn btn-primary btn-col'>View Current Comment</button> */}
                
                </> : null
            }
            
        </div>
        <br/>
        <div className='flex-col'>  
            
            {commentShow && remarkList.length > 0 ? comments  : null }

            {
                commentShow===false && remarkList.length > 0  ?
                <>
                   <span className='comment-history'>Comment History<i onClick={()=> setCommentShow(true)} class="fas fa-arrow-circle-right arrow-ic ml-2"></i></span>
                   <br/>
                </>
                : null
            }

            {
                commentShow===false ?  show ? 
                
                <textarea 
                type="text" 
                className="form-group text-area remark-edit"
                value= {remarkData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setRemarkData(e.target.value)
                  //props.update(data)
          } }
            autoComplete="off"
                /> : <p className='initial-remarks'>{remarkData}</p>
                
                : null
            }
{
    commentShow===false ?

        <button onClick={handleShow} className={show===false?'btn btn-primary btn-col btn-square btn-small':'btn btn-danger btn-small btn-square'}>{show===false?'Edit':'Discard'}</button>
        : null
    
}
{/* <button onClick={()=> setCommentShow(false)} className='btn btn-primary btn-col'>Show Comment History</button> */}
            
        </div>
        </>
    )
}

export default EditRemark
