import React,{useState,useEffect} from "react";
import "../SuspendHospitalDoctor/suspend.css";
import axios from 'axios';
import { Link, useHistory } from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import HospitalNavbar  from "../HospitalNavbar/HospitalNavbar";
import {APIURL} from "../../Global";

function SubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4> Bdo is Deleted Sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={props.onHide}>Close</Button> */}
        

        <Link to="/bdo-list"><Button className='btn btn-primary btn-col' > Goto BDO List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}

function DeleteBdo(props) {

 const [modalShow, setModalShow] = useState(false);
      const [submitMsg, setSubmitMsg]= useState("");

   const id = props.match.params.id;

   const history =useHistory();

   const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});
   
   useEffect(()=> {
    window.scrollTo(0,0)
  },[])
  
   useEffect(()=>{
   
   window.onkeydown =(e) => {
    
    keys[e.key]=true
   
    if(e.altKey && keys['d']){
      history.push('/doctors-list')
    }
    if(e.altKey && keys['a']){
      history.push('/hospital/add')
    }
    if(e.altKey && keys['b']){
      history.push('/bdo-list')
    }
    if(e.altKey && keys['o']){
      history.push('/hospital/add-bdo')
    }
    if(e.altKey && keys['n']){
      history.push('/emergency-number')
    }
    if(e.altKey && keys['s']){
      history.push('/service-request-list')
    }
    if(e.altKey && keys['l']){
      history.push('/license-details')
    }
    if(e.altKey && keys['p']){
      history.push('/license-payment')
    }
    if(e.altKey && keys['c']){
      history.push('/hosp-calllog')
    }
    if(e.altKey && keys['h']){
      history.push('/payment-history')
    }
   
   }
   window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
   }
   
   return()=>{
    window.onkeydown=null
    window.onkeyup=null
   }
   },[]);


  //  const handleSubmit=(e)=>{
  //  	e.preventDefault();

  //  	const tokenString = localStorage.getItem("usertoken");

  //   let v2 = tokenString.replace(
  //     /["]+/g,
  //     ""
  //   ); 

    
  // const data = {
  // 	is_suspended :true
  // }

  //   axios.put(`${APIURL}/api/v1/hospital/doctors/${id}/`, data, {
  //       headers: { 'Content-Type': 'application/json',
  //                    Authorization: "Token " + v2 },
  //     })
  //     .then((res) => {
  //       if (res.data.status === "success") {
  //         setModalShow(true);
          
          
  //       } else {
  //          setSubmitMsg("error");
  //       }
  //     })
  //     .catch((err) => {
  //        setSubmitMsg("error");
  //     });
  // }

const handleDelete = (e) => {

    // console.log("delete");
   	e.preventDefault();

    const tokenString = localStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');

    axios.post(`${APIURL}/api/v1/hospital/hospital-user-delete/?user_id=${id}&role=bdo`,
      {},
      {
        headers: {

          "Authorization": 'Token ' + str,
          'Content-Type': 'application/json'
        }


      })




      .then((res) => {
                if (res.data.status === "success") {
                  setModalShow(true);
                  // props.onHide();
              
                  
                  
                } else {
                  console.log('Else called');
                   setSubmitMsg("error");
                }
              })
              .catch((err) => {
                console.log('Catch called');
                 setSubmitMsg("error");
              });

  }
   
 return( 
 <>
<HospitalNavbar />
 <div className="liner">

  </div>
    <div className="container">
       <form className="create-app-user-form col-lg-6"
        onSubmit={handleDelete}
        >
       <h4 className="title-of-page"> Delete BDO  </h4><br/>
       <h6> Please Confirm to Delete the BDO user  ID {id}</h6><br/>
       


  <div className="form-group ">
         <button type="button" className="btn btn-danger btn-cancel"> <Link to="/bdo-list" className="cancel-btn">Cancel </Link> </button> 
          <button type="submit" className="btn btn-primary btn-col" >Confirm </button>
  </div>







       </form>

        <SubmitPopUp
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {submitMsg=== "error" ?
       <h5 style={{ color: "red" }}>
            
            Error in data submission.Please try again!
          </h5>
      :
      null}


    </div>
 </>
);
}
export default DeleteBdo;