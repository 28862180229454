// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/wall.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Caveat&family=Just+Another+Hand&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".dr-notes-page{\n\tbackground-color: var(--theme-blue-variant);\n background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n background-position: center;\n background-size: cover;\n\theight:100vh;\n\toverflow-y: auto;\n\tpadding:20px 10px;\n}\n/*\n\n.red-pin{\n  width:15px;\n  height:15px;\n  background-color: var(--red);\n  box-shadow: 4px 4px 4px rgba(0,0,0,0.2);\n  border-radius: 10px;\n\n}\n*/\n.each-note{\n\twidth: 300px;\n\theight: 250px;\n\tmargin:15px;\n\tpadding:10px;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n\n\t\nbox-shadow:5px 5px 7px rgba(33,33,33,0.7);\n\n}\n.each-note p{\n\tfont-family: 'Caveat', cursive;\n    font-size:25px;\n   \n}\n.notes-holder{\n\tdisplay: flex;\n\tflex-direction: row;\n\tflex-wrap: wrap;\n\tjustify-content: center;\n}\n\n\n.each-note:nth-child(even) {\n /* -o-transform:rotate(3deg);\n  //-webkit-transform:rotate(3deg);\n  //-moz-transform:rotate(3deg); \n   top:5px;*/\n  position:relative;\n \n    background:#cfc !important;\n}\n.each-note:nth-child(3n) {\n /* -o-transform:rotate(-3deg);\n // -webkit-transform:rotate(-3deg);\n  //-moz-transform:rotate(-3deg);\n  top:-5px; */\n  position:relative;\n  \n    background:#ccf !important;\n}\n.each-note:nth-child(5n) {\n  /*-o-transform:rotate(5deg);\n  //-webkit-transform:rotate(5deg);\n  //-moz-transform:rotate(5deg);\n  top:-10px; */\n  position:relative;\n  \n}\n\n.p-notes{\n\theight: 100%;\n\toverflow-y: hidden;\n font-family: 'Caveat', cursive;\n  font-size:25px;\n}\n\n.note-card-header{\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n.note-card-time{\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  justify-content: flex-end;\n}\n.save-btn{\n  align-self: flex-end;\n }\n\n ", ""]);
// Exports
module.exports = exports;
