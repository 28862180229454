import React, { useEffect, useState, useRef } from "react";
import Chart from "chart.js";
import "./vitalsbp.css";
import axios from "axios";
import DatePicker from "react-date-picker";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { APIURL } from "../../Global";
let myChart;
function VitalsBP(props) {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
  const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [patientId] = useState(props.id);

  const [inference, setInference] = useState();
  const [inferenceLoad, setInferenceLoad] = useState("");
  const [systolicArray, setSystolicArray] = useState([]);
  const [diastolicArray, setDiastolicArray] = useState([]);
  const [bpDatesArray, setBpDatesArray] = useState([]);
  const [bpList, setBpList] = useState([]);
  const [updateRender, setUpdateRender] = useState(false);
  const [unit, setUnit] = useState();
  const [load, setLoad] = useState("");
  const [errorSubmit,setErrorSubmit]=useState(false);
  const [errorMsg,setErrorMsg]=useState("");
  const [deleteIndex, setDeleteIndex]= useState('');
  const [deleteShow, setDeleteShow]= useState(false)
  const [submitMsg,setSubmitMsg] = useState("");
  const [modalShow,setModalShow]=useState(false);
  const [glucoDeleteRowValue, setGlucoDeleteRowValue] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const ctx = document.getElementById("chart-bp").getContext("2d");

    if (myChart) {
      myChart.destroy();
    }
    myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: bpDatesArray,
        datasets: [
          {
            label: "Systolic BP",
            data: systolicArray,
            backgroundColor: "rgba(54, 162, 235, 0.9)",

            pointBorderWidth: 2,
            pointHoverRadius: 5,
            fill: false,
            borderColor: "rgba(54, 162, 235, 0.9)",
          },
          {
            label: "Diastolic BP",
            data: diastolicArray,
            backgroundColor: "rgba(255, 99, 132, 0.9)",

            pointBorderWidth: 2,
            pointHoverRadius: 5,
            fill: false,
            borderColor: "rgba(255, 99, 132, 0.9)",
          },
        ],
        tooltips: {
          backgroundColor: "purple",
        },
      },

      options: {
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: `Blood Pressure Values  ${
                  unit ? "(" + unit + ")" : null
                } `,
              },

              ticks: {
                beginAtZero: true,
                stepSize: 10,
                padding: 20,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Date",
              },
            },
          ],
        },
        legend: {
          position: "right",
          labels: {
            fontColor: "#000",
          },
        },
      },
    });
  }, [systolicArray, diastolicArray, bpDatesArray, unit]);

  useEffect(() => {
    handleTableData();
  }, []);
  useEffect(() => {
    handleGraph();
  }, []);

  const handleTableData =() =>
  {
    const tokenString = localStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    setLoad("Loading Data...");

    axios
      .get(
        `${APIURL}/api/v1/doctor/patient-blood-pressure/?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}&timeZone=${timezoneOffset}&sort_by=asc`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setLoad("");
          let arr = res.data.results;
          let systolic = [];
          let diastolic = [];
          arr.map((result) => {
            systolic.push(result.systolic);
            diastolic.push(result.diastolic);
          });
          setSystolicArray(systolic);
          setDiastolicArray(diastolic);

          let newArrayDates = [];
          arr.map((result) => {
            newArrayDates.push(result.date.slice(0, 10));
          });
          setBpDatesArray(newArrayDates);

          setBpList(res.data.results);
          let unit1 = null;
          if (res.data.results.length > 0) {
            unit1 = res.data.results[0].unit;
          } else {
            unit1 = null;
          }

          setUnit(unit1);
        } else {
          setLoad("Error in Fetching Data");
        }
      })
      .catch((err) => {
        setLoad("Error in Fetching Data");
      });
  }

const handleGraph = () =>
{
  const tokenString = localStorage.getItem("usertoken");

  let str = tokenString.replace(/["]+/g, "");

  setInferenceLoad("Loading Data...");

  axios
    .get(
      `${APIURL}/api/v1/patient/inferences/?patient_id=${patientId}&date_from=${startDate}&date_to=${endDate}&timeZone=${timezoneOffset}`,
      {
        headers: {
          Authorization: "Token " + str,
        },
      }
    )
    .then((res) => {
      if (res.data.status === "success") {
        setInferenceLoad("");
        setInference(res.data.data);
      } else {
        setInferenceLoad("Error in Fetching Data");
      }
    })
    .catch((err) => {
      setInferenceLoad("Error in Fetching Data");
    });
}


  const callDelete=()=>{
    ;
    const tokenString= localStorage.getItem('usertoken');
    let idToDelete=glucoDeleteRowValue;
          let str= tokenString.replace(/["]+/g, '');
          const headers= {
             
            "Authorization" : 'Token '+str,
            'Content-Type': 'application/json'  }
          const data =
          {
                    patient_id : "1780"
          }
         
           axios  .delete(`${APIURL}/api/v1/patient/tests/${idToDelete}`, {
            data: { patient_id: patientId },
            headers: { Authorization: "Token " + str },
          })
  
       
           .then(res=>{
          ;
             if(res.status==200){
  
  
              setSubmitMsg("Deleted Successfully")
              setModalShow(true)
             //setInferenceLoad('')
             
              
                 
         }
         else{
           setErrorMsg('in Data Submission. Please try again!')
           setErrorSubmit(true)
          
         }
  
        
         setDeleteShow(false);
       
           })
           .catch(err=>{
             ;
              setErrorMsg('in Data Submission. Please try again!')
              setErrorSubmit(true)
       })
  
  }

  const SubmitPopUp=({show,onHide})=> {
 

    return (
      <Modal
        show
        onHide
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{submitMsg}</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button onClick={onHide}>Ok</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }
  
  
  const deleteRow=(index)=>{
   ; 
  
    let b=bpList[index.index]
   
    callDelete(b.test_id);
  
  }
  const handleDeleteClick = (e) =>
  {
    
    
    ;
    let testID=e.row.test_id
    
    setDeleteShow(true)
    setGlucoDeleteRowValue(testID)
    
    //setDeleteIndex(props)
  }
  
  const DeleteConfirmPopup =(props)=>{
      
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Are you sure you want to delete?</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
  
          <Button variant="danger" onClick={callDelete}> Confirm</Button>
  
         
  
        </Modal.Footer>
      </Modal>
    );
  }

  const handleGo = (e) => {
    e.preventDefault();
    setUpdateRender(!updateRender);
  };

  const handleFromDate = (date) => {
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setStartDate(DateExtracted);
    }
  };

  const handleToDate = (date) => {
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setEndDate(DateExtracted);
    }
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };
  const renderBP = (cellInfo) => {
    const date4 = bpList;
    const date5 = [...date4].reverse();
    return <h6> {date5[cellInfo.index][cellInfo.column.id]} </h6>;
  };
  const renderDate = (cellInfo) => {
    const date4 = bpList;
    const date5 = [...date4].reverse();
    return <h6> {date5[cellInfo.index].date.slice(0, 10)} </h6>;
  };
  const renderFitData = (cellInfo) => {
    const data = bpList;
    const fitData = [...data];

    return (
      <h6> {fitData[cellInfo.index][cellInfo.column.id] ? "Yes" : "No"} </h6>
    );
  };

  const columnsBp = [
    {
      Header: "Date",
      accessor: "date",
      Cell: renderDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 120,
    },
    {
      Header: (
        <>
          <h6>Systolic</h6>
          <h6>(mm/Hg)</h6>
        </>
      ),
      accessor: "systolic",
      Cell: renderBP,
      width: 80,
      minWidth: 50,
    },
    {
    
      accessor: "test_id",
      //Cell: renderFitData,
      show: false,
    },
    {
      Header: (
        <>
          <h6>Diastolic</h6>
          <h6>(mm/Hg)</h6>
        </>
      ),
      accessor: "diastolic",
      Cell: renderBP,
      width: 80,
      minWidth: 50,
    },
    {
      Header: "Smart Device Data",
      accessor: "is_wearables",
      Cell: renderFitData,
      width: 140,
      minWidth: 50,
    },
    {
      Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
                   data-placement = "top" title = "Delete Row"><i className="fas fa-trash-alt"></i></span>,
      Cell: props=>{
        return (
          <i  className="fas fa-times close-button"    onClick={() => handleDeleteClick(props)}     ></i>
        )
      },
      sortable:false,
      filterable:false,
      minWidth:50,
      width:50
       
    }
  ];
  const BP_Table = (
    <ReactTable
      columns={columnsBp}
      data={bpList}
      defaultPageSize={10}
      resizable
    ></ReactTable>
  );

  //let summary =inference.summary;
  let data = [];

  if (inference !== undefined) {
    let summary = inference.summary;
    data = summary.split("\n");
  }

  const inferenceData =
    inferenceLoad === "" ? (
      inference !== undefined ? (
        <div
          style={{ maxHeight: "410px" }}
          className="inference-container analysis-subsection"
        >
          <div className="inference-container-header box-theme">
            <h6 className="inference-container-title"> Analysis</h6>
          </div>
          <div className="inference-container-body">
            {data.length > 1 ? (
              <>
                <h5 className="text-success font-weight-bold">{data[0]}</h5>
                <h6 className="text-muted font-weight-bold">
                  Maximum BP Level :{" "}
                </h6>{" "}
                <p>
                  {inference.max_sys_bp}mm/Hg {inference.max_sys_date}
                </p>
                <br />
                <h6 className="text-muted font-weight-bold">
                  Minimum BP Level :{" "}
                </h6>{" "}
                <p>
                  {inference.min_sys_bp}mm/Hg {inference.min_sys_date}
                </p>
                <br />
                <h6 className="text-muted font-weight-bold">
                  BP Deviation :{" "}
                </h6>{" "}
                <p>{inference.sys_bp_sd}</p>
                <br />
                <br />
                <h5 className="text-success font-weight-bold">{data[1]}</h5>
                <h6 className="text-muted font-weight-bold">
                  Maximum BP Level :{" "}
                </h6>{" "}
                <p>
                  {inference.max_dia_bp}mm/Hg {inference.max_dia_date}
                </p>
                <br />
                <h6 className="text-muted font-weight-bold">
                  Minimum BP Level :{" "}
                </h6>{" "}
                <p>
                  {inference.min_dia_bp}mm/Hg {inference.min_dia_date}
                </p>
                <br />
                <h6 className="text-muted font-weight-bold">
                  BP Deviation :{" "}
                </h6>{" "}
                <p>{inference.dia_bp_sd}</p>
                <br />{" "}
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <h6 style={{ color: "red" }}> No data to show! </h6>
      )
    ) : inferenceLoad === "Error in Fetching Data" ? (
      <h6 style={{ color: "red" }}>{inferenceLoad}</h6>
    ) : (
      <h6 style={{ color: "grey" }}>{inferenceLoad}</h6>
    );

  return (
    <>
      <div className="food-habits-page">
        <div className="food-habits-header">
          <h2 className="title-of-tasks"> Blood Pressure Details </h2>
          {/* <h3 className=""> Patient  ID : {patientId} </h3><br/><br/> */}

          <div className="calender-pickers-from-to">
            <div className="span-date">
              <span> From </span>

              <DatePicker
                value={new Date(startDate)}
                onChange={(date) => {
                  handleFromDate(date);
                }}
                maxDate={new Date()}
              />
            </div>
            <div className="span-date">
              <span> To </span>

              <DatePicker
                value={new Date(endDate)}
                onChange={(date) => {
                  handleToDate(date);
                }}
                maxDate={new Date()}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={handleGo}
            >
              {" "}
              Check{" "}
            </button>
          </div>
        </div>
        <div className="food-habits-sections">
          <div>
            <h5 className="title-of-lists"> Graph </h5>
            <div className="chart-div-glucose">
              <canvas id="chart-bp" width="600" height="400"></canvas>
            </div>
          </div>

          <div className="food-table">
            <h5 className=" title-of-lists"> Table </h5>
            {load === "" ? (
              bpList.length > 0 ? (
                <>{BP_Table}</>
              ) : (
                <h6 style={{ color: "red" }}> No data to show! </h6>
              )
            ) : load === "Error in Fetching Data" ? (
              <h6 style={{ color: "red" }}>{load}</h6>
            ) : (
              <h6 style={{ color: "grey" }}>{load}</h6>
            )}
          </div>

          <div className="food-table">
            <h5 className=" title-of-lists"> Inference </h5>

            {inferenceData}
          </div>
        </div>
      </div>
      {submitMsg!=="" && submitMsg!== null ? 
          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {
          handleGraph();
          handleTableData();
          setModalShow(false);
          setSubmitMsg('')
                      }}

       
      /> : null }
       
    <DeleteConfirmPopup
        show={deleteShow}
        onHide={() =>  {setDeleteShow(false);
                       }}
       
      />: ''

      
    
    </>
  );
}

export default VitalsBP;
