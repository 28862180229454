import React,{useState, useContext} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import './profile.css'
import {Button ,Modal } from 'react-bootstrap';
import { RegionDropdown } from 'react-country-region-selector';

function CountryUpdate(props) {
    const [region ,setRegion] =useState('');
    const [show, setShow]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);

    const [error,setError]=useState({
        region:''
       });

    const selectRegion=(val)=>{
        setRegion(val)
        }

  const  validate = () => {
      //let input = email;
      let errors = {};
      let isValid = true;

      if (region==="") {
        isValid = false;
        errors["region"] = "Please select a region";
      }
  
      setError(currentstate=>({
        ...currentstate,...errors
      }))
  
      return isValid;
  }

  const handleContinue =async(e) => {
    e.preventDefault();

    if( validate() ){
      submitRegion()
    }
  }

    const submitRegion = (e) =>{
        const tokenString= localStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '') 
      
         const data = {
           region : region
         }
      
         axios.put(`${APIURL}/api/v1/account/profile/` ,data, { headers: {
      
          "Authorization" : 'Token '+str  }})
        .then(res=>{
        
              
          if(res.data.status==="success"){

            setSubmitMsg("success");
            setShow(true)        
        
          }else{
            setErrorMsg(res.data.message);
            setErrorSubmit(true);
          
           
          }
        
        })
        .catch(err=>{
          setErrorMsg("error");
  
          setErrorSubmit(true);

        
        })
      
      }


      const ConfirmPopUp = ({onHide}) => {
        return(
        <Modal
            show
            onHide
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 id="confirm-reject">Updated Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={onHide}>Ok</Button>          
      
            </Modal.Footer>
          </Modal>
        )
      }
      
      
      const SubmitErrorPopup =(props)=>{
          
      
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }




    return (
        <div className='flex-phone'>

            <div className="region-selector item-left-row">
                    <RegionDropdown
                        country={props.country}
                        value={region}
                        onChange={(val) => selectRegion(val)} />
                {error.region ? <div className="error-validation-msg"> {error.region}</div> : null}
                <small className="form-text text-muted">Enabled once you select Country</small>
            </div>

            <div className="form-button">
                <button style={{marginLeft:'10px'}} onClick={handleContinue} disabled={props.country===''} className="btn btn-primary btn-col" >Update Region</button>
            </div>

            {
         submitMsg==="success" ?
        <ConfirmPopUp
            show={show}
            onHide= { ()=>{
              setShow(false)     
              setSubmitMsg('');
              props.handle();
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }


        </div>
    )
}

export default CountryUpdate
