import React,{useState,useEffect} from "react";
import {Link,useHistory} from "react-router-dom";
import "./careprograms.css";
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";
//import {BdoLoginContext} from "../../contexts/BdoLoginContext";
import axios from "axios";
import {APIURL} from "../../Global";

function CarePrograms() {
	 //const [,setSearchTerm] = useState("");
    //const [bdoData,] =useContext(BdoLoginContext);
    const [careList,setCareList]=useState([]);
    const [loading,setLoading]=useState(true);
   /*const bdo_name= bdoData ? bdoData.name : null;
    const doctor_specializations = hospBdoData ? hospBdoData.doctor_specializations : null;
   const department  = bdoData ? bdoData.department : null;
   const Id = bdoData ? bdoData.user_id : null;
   const hospitalName= bdoData ? bdoData.hospital_username: null;
   const specializations = hospBdoData ? hospBdoData.specializations : null;
   const location = bdoData ? bdoData.location: null;*/
   
   let history=useHistory();
   
   const [keys,] = useState({'u': false,'c': false,'n': false,'a': false,'s': false,'q': false,'l': false,});


   useEffect(()=>{
   
     window.onkeydown =(e) => {
       
       keys[e.key]=true
   
       if(e.altKey && keys['u']){
         history.push('/directory')
       }
       if(e.altKey && keys['c']){
         history.push('/careplans-db')
       }
       if(e.altKey && keys['n']){
         history.push('/notifications')
       }
       if(e.altKey && keys['a']){
         history.push('/analytics')
       }
       if(e.altKey && keys['s']){
         history.push('/service-requests')
       }
       if(e.altKey && keys['q']){
         history.push('/qrcode')
       }
       if(e.altKey && keys['l']){
         history.push('/call-log')
       }
   
   
     }
     window.onkeyup=(ev)=> {
       
       keys[ev.key]= false
     }
   
     return()=>{
       window.onkeydown=null
       window.onkeyup=null
     }
   },[]);

   
useEffect(()=>{
    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

   
    
   
    axios.get(`${APIURL}/api/v1/hospital/care-programs/`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   
     setLoading(false);
      if(res.data.status==="success"){
      
     setCareList(res.data.care_programs);

    

   
      

      }else{
      
      }


    })
    .catch(err=>{
  
 
})
 

 
},[])


	/* const handleSearchChange = (e) => {
    e.preventDefault();

    setSearchTerm(e.target.value);
   
  } */
  const newList= loading===true ?
  <h6 className="text-muted">Loading...</h6> 
  : 
careList.length> 0 ? careList.map((care,index)=>{
    let cp_url= `care-program/${care.id}`;
  return(<span className="outer-box-name">
     <Link to={{
                   pathname:cp_url,

                   

                   }}>
     <div className={care.is_obselete===true ?  " each-list-title box-care-pgm-obsoletelist " : care.is_active===false ? " each-list-title box-care-pgm-inactivelist " :  "each-list-title box-care-pgm " }>

          
           
            <h5 className="each-title">


              {" "}
             <span className="content-inside">
                <h4>{care.title} </h4>
                <h6 style={{fontWeight:'bold'}}>ID : {care.id} {care.is_obselete===true ? <span style={{color:"red"}}>Obsolete</span> : care.is_active===false ? ", Inactive" :  null} </h6>
</span>
              
                
            </h5>
            </div> </Link></span>
)
}) : <h6 className="text-muted">Care plans list is empty</h6> ;


const handleBack=(e)=>{
  history.goBack();
}
  
  return (
    <> <HospBdoNavbar />
       <div className="hospital-dashboard-container hosp-bdo-page"> <br/>
      
        <div className="flex-head">
          <button className="btn btn-info topleft-single-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <h2 className="title-bdo title-mg">   Care Plans </h2>
    </div> 
    <br/>
   

   {/***************************/}

     <div className="row-jumbo-content">
                     
            <div className=" jumbo-text-content bdo-tasks">
                  
            <div className="container flex-center ">


{newList}


</div> 
<br/>
<br/>

 <div className="">
            

         

              
                  <Link to="/care-program-add"><button className="btn btn-primary btn-col" > + Add Care Program </button></Link>
                  
                 
              </div>
</div>
</div>





<div className="patients-section">




</div>
<br/>
<br/>


</div>

    </>
  );
}
export default CarePrograms;
