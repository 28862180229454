
/*export const APIURL= "https://pc.felixacare.com";

https://api.cianlogic.com/
*/

let backendHost;


const hostname = window && window.location && window.location.hostname;

if(hostname === 'cianlogic.com') {
  backendHost = 'https://api.cianlogic.com';
} else if(hostname === 'apps.felixacare.com') {
  backendHost = 'https://pc.felixacare.com';
} else if(hostname === 'stageapp.cianlogic.com') {
  backendHost = 'https://stage.cianlogic.com';
} else if(hostname === 'localhost') {
  backendHost = 'https://api.cianlogic.com';
}

export const APIURL = `${backendHost}`;




