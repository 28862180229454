import React from "react";
import graphic from "../../images/warning2.svg";
import "./default404.css";
import { Link } from "react-router-dom";

function Default404() {
  return (
    <div className="container page-container">
      <div className="default-msg">
        <h4>
          <span className="red-color"> 404 Error!! Not Found!! </span>
          <br />
        </h4>
        <h6>Looks like this page does not exist.</h6>
        <p>
          Goto <Link to="/">Home</Link>
        </p>

        <img src={graphic} width="30%" height="auto" alt="banner"/>
      </div>
    </div>
  );
}

export default Default404;
