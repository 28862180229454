import React ,{Component} from 'react';
import {Link} from "react-router-dom";
import Pagination from "react-js-pagination";
import "./pagination.css";
import './userlist.css'
import axios from "axios";
import {APIURL} from '../../Global'
import {Link as Scroll} from 'react-scroll'
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";

// let prev =['']

// let j=0

// const menu = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z'.split(',')

class UserList extends Component {
    constructor(props) {
        super(props)
        this.state = {
             patientdata : [] ,
             searchTerm : '',
             currentPage : 1,
             perPage : 10,
             keys : {
              'c': false,
              'n': false,
              'a': false,
              's': false,
              'q': false,
              'l': false
             }     
        }
    }
   

    
  handleBack=(e)=>{
    this.props.history.goBack();
}


    componentDidMount(){
        const tokenString = localStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */


        axios
        .get(`${APIURL}/api/v1/doctor/patient-record-search/?per_page=1000&page=1`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            const data = res.data.patients       
            this.setState({patientdata : data})
        }
        else{

        }
    }).catch((error)=>{

    })

  //////* Shotcuts ********/ 

    window.onkeydown =(e) => {
    
      this.state.keys[e.key]=true

      if(e.altKey && this.state.keys['c']){
        this.props.history.push('/careplans-db')
      }
      if(e.altKey && this.state.keys['n']){
        this.props.history.push('/notifications')
      }
      if(e.altKey && this.state.keys['a']){
        this.props.history.push('/analytics')
      }
      if(e.altKey && this.state.keys['s']){
        this.props.history.push('/service-requests')
      }
      if(e.altKey && this.state.keys['q']){
        this.props.history.push('/qrcode')
      }
      if(e.altKey && this.state.keys['l']){
        this.props.history.push('/call-log')
      }
  
  
    }
    window.onkeyup=(ev)=> {
      
      this.state.keys[ev.key]= false
    }

}

componentWillUnmount(){ 
    window.onkeydown=null
    window.onkeyup=null
}

    handleSearchChange = (event)=> {
      this.setState({currentPage:1})
        this.setState({searchTerm: event.target.value})

    }

    handlePageChange = (pageNumber)=> {
      this.setState({currentPage:pageNumber})
    }

    render() {
          
        const{patientdata ,searchTerm, currentPage, perPage}=this.state

        let data = patientdata.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
      })
      .filter((value)=>{
        if(this.state.searchTerm===''){
            return value
        } else if (value.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
            return value
        }
    })


        const indexLast= currentPage * perPage;
        const indexFirst= indexLast - perPage;
        const current = data.slice(indexFirst ,indexLast);
        const total = data.length;

        const pageNumbers = []
        for(let i=0 ;i<= Math.ceil(total/perPage) ;i++){
          pageNumbers.push(i)
        }
        const totalPages=pageNumbers.length;

            const display = current.map((val,index)=> ({
                name: val.name.charAt(0).toLowerCase(),
                id: val.id
              })                   
            )
            
            //  const filteredDisplay= display.filter((val,index)=> {
            //    return display.indexOf(val.name) === index
            //  })

            // const unique = [...new Set(display.map(item => (
            //   {
            //     name: item.name,
            //     id: item.id
            //   }
            // )

            //  ))]
            const unique = []
              display.map(x => unique.filter(a => a.name == x.name).length > 0 ? null : unique.push(x));

        return (<>
          <HospBdoNavbar />
          <br/>
          <div className="flex-head">
        
          <button className="btn btn-info topleft-single-btn" onClick={this.handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <h2 className=" title-of-tasks title-mg"> User Directory </h2><br/>
    </div> 
          
            <div className="search-patient-page directory-page">
                
        <div className="search-section">
  
       
            <div className="search-div">
              <input
                className="form-control search-input"
                type="text"
                maxLength="150"
                placeholder="Enter Name"
                value={searchTerm}
                onChange={this.handleSearchChange}
              />
              <button  className="btn btn-primary btn-col search-btn">
                {" "}
                <i className="fas fa-search"></i>{" "}
              </button>        </div>
          <div className="container search-results">  
          {
        current.length>0?        
          current.map((value,index)=>{
            //let link_url=`/record/${value.id}`;
              return(
                <Link to={{pathname:`/record/${value.id}`, hospId:value.hospital_patient_id}}>
                  <div ref={this.scrollRef} key ={index} className='list-item'>               
                      <h5 className="each-list-link" id={value.id} key={value.id}>{(currentPage-1)*10 + index+1}.{value.name}</h5>  
                      <div className="details">
                        <p> Felixa Id: {value.id} </p>
                        <span> Hospital Id: {value.hospital_patient_id} </span>
                        <p> Phn : {value.mobile_number} </p>
                      </div>                                                                 
                  </div>
                </Link>
              )          
            }) : <h6 style={{color:"red"}}>No Patients Found</h6>
          }
          
            <span className="scroll-class-bdo">

              {
                unique.map(val => {
                  return (
                    <div >
                              {searchTerm==="" ? 

                               <Scroll activeClass="active" to={val.id} spy={true} offset={-80} smooth={true} duration={750}> <button style={{position:'relative',top:'-30%',display:'block'}}  class ='btn btn-small btn-secondary btn-square btn-letter' >{val.name}</button></Scroll>

                              : null

                              }
                              </div>
                  )
                })
              }

              {/* {filteredDisplay.map(item => {
                return (
                  <div style={{color:'black'}}>
                    <button style={{position:'relative',top:'-30%',display:'block'}} onClick={this.handleClick}  className ='btn btn-small btn-secondary btn-square btn-letter'>{item}</button>
                  </div>
                )
              })} */}
         {/* {
        current.length > 0 ? 
          current.map((val)=>{
                         
              prev[j] = val.name
              j++
              for(let i=0 ; i<menu.length;i++){
                  if(j>1){
                      if(prev[j-2].charAt(0).toLowerCase()===val.name.charAt(0).toLowerCase()){
                        if(current[0].name.toLowerCase()===val.name.toLowerCase()){
                          if(val.name.charAt(0).toLowerCase()===prev[j-2].charAt(0).toLowerCase()){
                            if(menu[i] === val.name.charAt(0).toLowerCase()){                             
                            return(
                                                 
                                <div >
                                {searchTerm==="" ? 
                                 <Scroll activeClass="active" to={val.id} spy={true} offset={-80} smooth={true} duration={450}> <button class ='btn btn-small btn-secondary btn-square btn-letter' style={{position:"relative",bottom:"150px",right:"500px"}}>{menu[i]}</button></Scroll>
                                : null
  
                                }
                                </div>
                                                    
                                  )
                              }
                        }
                          }
                          
                        else{
                          continue
                        }
                      }
                  }                                       
                  if(menu[i] !== val.name.charAt(0).toLowerCase()){
                      continue                                                          
                  }
                  
                  else if(menu[i] === val.name.charAt(0).toLowerCase()){
                      
                          return(
                                               
                              <div >
                              {searchTerm==="" ? 

                               <Scroll activeClass="active" to={val.id} spy={true} offset={-80} smooth={true} duration={750}> <button style={{position:'relative',top:'-30%',display:'block'}}  class ='btn btn-small btn-secondary btn-square btn-letter' >{menu[i]}</button></Scroll>

                              : null

                              }
                              </div>
                                                  
                                )  
                      } 
                  }                  
              }
          ) : null
        }*/}
        </span>
          </div>
              
            </div><br/>
            <br/>
            {totalPages > 0 ?
    <div className="pagn">

            <Pagination
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={total}
          pageRangeDisplayed={totalPages}
          onChange={this.handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null}
            
        </div>
        </>
        )
    }
}

export default UserList