import React ,{useState, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {SearchContext} from "../../contexts/GeneralSearchContext"

function GeneralSearchBar(props) {
    const [id] = useState(props.match.params.id)
    const [,setSearchTerm]= useContext(SearchContext)
    
    const history =useHistory();

    const handleSearchChange = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value)
    }

    const handleSubmit= (e)=>{
        e.preventDefault();       
        history.push(`/patient-general-search/${id}`)
    }


    return (
        <div className="srch-bar">
            <div className="flex-row ">
            <input
              className="form-control search-input"
              type="text"
              placeholder="Search Here" 
              maxLength="150"            
              onChange={handleSearchChange}
            />
            <button  onClick={handleSubmit} className="btn btn-primary btn-col btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </button>        </div>
        </div>
    )
}

export default GeneralSearchBar
