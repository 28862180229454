import React, {useState,useEffect} from "react";

import "./service.css";

import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";

import RentalHistory from "./RentalHistory";
import {useHistory} from "react-router-dom";



	 
function RentalHistoryPage() {
	let history=useHistory();
	 useEffect(() => { window.scrollTo(0, 0) }, [])
const handleBack=(e)=>{
  history.goBack();
}

const [keys,] = useState({'u': false,'c': false,'n': false,'a': false,'s': false,'q': false,'l': false,});


useEffect(()=>{

  window.onkeydown =(e) => {
    
    keys[e.key]=true

    if(e.altKey && keys['u']){
      history.push('/directory')
    }
    if(e.altKey && keys['c']){
      history.push('/careplans-db')
    }
    if(e.altKey && keys['n']){
      history.push('/notifications')
    }
    if(e.altKey && keys['a']){
      history.push('/analytics')
    }
    if(e.altKey && keys['s']){
      history.push('/service-requests')
    }
    if(e.altKey && keys['q']){
      history.push('/qrcode')
    }
    if(e.altKey && keys['l']){
      history.push('/call-log')
    }


  }
  window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
  }

  return()=>{
    window.onkeydown=null
    window.onkeyup=null
  }
},[]);

  return (<>
  <HospBdoNavbar />
  <div className=" flex-row-end">
  <div className=" text-left">
        <br/>
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> 
    <div className=" text-right">
        <br/>
          <button className="btn btn-info back-btn" onClick={()=>history.push("/rental-requests")}> Equipment Requests<i className="fas fa-chevron-right back-btn-icon"></i></button> 
    </div>
    </div> 
    <div className="">
    <div className='d-flex justify-content-center'>
     <h2 className="title-of-tasks"> Equipment History</h2>
     <button className="btn btn-info back-btn rental-btn-align" onClick={()=>history.push("/rental-history-details")}> Equipment Details<i className="fas fa-chevron-right back-btn-icon"></i></button> 
     </div>
     <br/>
      <div className="text-center">
      <RentalHistory />
        </div>
    
     
    </div>
    
    
    <br/>
    
    <br/>
    <br/>
    </>
  );
}

export default RentalHistoryPage;
