import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { APIURL } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

function AddObs(props) {
  const [obsData, setObsData] = useState("");
  const [observations, setObservations] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [display, setDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorShow, setErrorShow] = useState(false);

  const handleClick = (item) => {
    initialRender.current = true;
    setSearchTerm(item.observation_name);
    setObsData(item.observation_name);
    // setPatientDetails(item)

    setDisplay(false);
    // setAllData([])
    // setRefresh(!refresh)
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (searchTerm === "") {
      setObservations([]);
      setObsData("");
      setDisplay(false);
    } else {
      const tokenString = localStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      axios
        .get(`${APIURL}/api/v1/doctor/observations/?search=${searchTerm}`, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          console.log(res);
          if (res && res.data) {
            setObservations(res.data);

            setDisplay(true);
            // setTotalPages(res.data.total_pages)
            // setCurrentPage(res.data.page)
            // setTotalRecords(res.data.total_records)
          } else {
          }
        })
        .catch((error) => {});
    }
  }, [searchTerm]);

  const handleSave = () => {
    if (searchTerm !=="" && searchTerm !== null) {
    const id = props.id;
    const tokenString = localStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const dataToUpdate = {
      observations: searchTerm,
      patient_id: id,
    };

    axios
      .post(`${APIURL}/api/v1/patient/observations-list/`, dataToUpdate, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
          setModalShow(true);
        } else {
          setErrorMessage(res.data.message);
          setErrorShow(true);
        }
      })
      .catch((err) => {
        setErrorShow(true);
      });
    } else {
      setErrorShow(true);
    }
  };

  const SubmitPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-success">Observation added Successfully !!</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {
          errorMessage === "" ? "Please Select a Valid Observation!" : errorMessage
  }</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="flex-col">
      <div className="comorbidities-body-content">
        <input
          type="text"
          maxLength="100"
          placeholder="Add Observation"
          value={searchTerm}
          onChange={handleSearchChange}
          // value={comorbValue}

          // onChange={handleChange}

          className="form-control"
        />

        <button
          type="submit"
          className="btn btn-smallest btn-square btn-primary btn-col btn-col btn-add-comorbidity"
          onClick={handleSave}
        >
          {" "}
          Add{" "}
        </button>
      </div>
      {/* <textarea 
                type="text" 
                className="form-group text-area remark-add"
                value= {obsData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setObsData(e.target.value)
          } }
            autoComplete="off"
                />
            <button disabled={obsData===''} onClick={handleSave} className='btn btn-primary btn-col'>Save</button> */}

      {display ? (
        <div className="suggest-display adjust-dimensions comor-auto-suggest ">
          {observations.length > 0 ? (
            observations.map((item) => {
              return (
                <p
                  onClick={() => handleClick(item)}
                  className="suggest-item text-secondary"
                >
                  {item.observation_name}
                </p>
              );
            })
          ) : (
            <h6 className="text-muted">No observations found !!</h6>
          )}
        </div>
      ) : null}

      {modalShow ? (
        <SubmitPopup
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            props.handleAdd();
          }}
        />
      ) : null}

      {errorShow ? (
        <ErrorPopup
          show={errorShow}
          onHide={() => {
            setErrorMessage("");
            setErrorShow(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default AddObs;
